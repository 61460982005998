import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const VPrint = (props) => {
	const { className, onAction } = props;

	const config = useConfig('pages.onboarding.box.v-print', { deep: true });

	const actions = useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<div className={cn('asteria-component__v-print', className)}>
			<div className="asteria-component__v-print__section-left">
				<Contenter content={config?.content} actions={actions} />
			</div>
			<div className="asteria-component__v-print__section-right">
				<Contenter content={config?.placeholder} actions={actions} />
			</div>
		</div>
	);
};

VPrint.displayName = 'VPrint';

VPrint.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default VPrint;
