import { useMemo } from 'react';

import AsteriaCore from '@asteria/core';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import { setIntegrations } from '@asteria/datalayer/stores/integrations';

import { useSubscription as useBaseSubscription } from '@asteria/utils-websocket/hooks';
import { FIELDS } from '@asteria/widget-base/api/integrations';

export async function fetch({
	accessToken,
	dispatch,
	variables,
	fields = FIELDS,
}) {
	const response = await IntegrationGateway.integration
		.fetch(
			{ fields: fields, isBulk: true, ...variables },
			{ token: accessToken },
		)
		.catch(() => []);

	dispatch?.(setIntegrations(response));

	return response;
}

export async function create({ accessToken, dispatch, input }) {
	const response = await IntegrationGateway.integration
		.create(
			{ fields: `ok error integration { ${FIELDS} }`, input: input },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await fetch({ accessToken: accessToken, dispatch: dispatch });

	return response?.integration?.[0];
}

export async function missing({ accessToken, input }) {
	return IntegrationGateway.integration
		.addCustomERP(
			{
				input: input,
				fields: `
					ok
					error
					erp {
						id
						email
						title
						version
					}
				`,
			},
			{ token: accessToken },
		)
		.catch(() => null);
}

export async function remove({ accessToken, dispatch, _id }) {
	const response = await IntegrationGateway.integration
		.remove({ ids: _id }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ accessToken: accessToken, dispatch: dispatch });
}

export async function useSubscription({ accessToken, dispatch }) {
	return useBaseSubscription({
		token: accessToken,
		query: `
			subscription IntegrationUpdated {
				integrationUpdated {
					_id
				}
			}
		`,
		onNext: useMemo(
			() =>
				AsteriaCore.utils.throttle(
					() => fetch({ accessToken, dispatch }),
					5_000,
				),
			[accessToken, dispatch],
		),
	});
}

export async function recreate({ accessToken, input, dispatch }) {
	const response = await IntegrationGateway.integration
		.recreate(
			{ fields: `ok error integration { ${FIELDS} }`, input: input },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await fetch({ accessToken: accessToken, dispatch: dispatch });

	return response?.integration;
}

export async function enable({ accessToken, _id, dispatch }) {
	const response = await IntegrationGateway.integration
		.enable({ ids: _id }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ token: accessToken, dispatch: dispatch });
}

export async function disable({ accessToken, _id, dispatch }) {
	const response = await IntegrationGateway.integration
		.disable({ ids: _id }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ token: accessToken, dispatch: dispatch });
}

export async function reimport({ accessToken, _id, dispatch }) {
	const response = await IntegrationGateway.integration
		.importSingle({ integrationId: _id }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ token: accessToken, dispatch: dispatch });
}
