import { useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import AsteriaCore from '@asteria/core';

import { VoucherService } from '@asteria/backend-utils-services';
import { IntegrationGateway } from '@asteria/backend-utils-services';

import { AuthContext } from '../context';

const DEFAULT_FIELDS = `
accountingResults {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}

accountingBalanceSheet {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    currency
    badges
}

accountingWorkingCapital {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}

accountingMisc {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}
`;

VoucherService.voucher.extend({
	getQuery: (fields = DEFAULT_FIELDS) => `
    query Statement {
        ${fields}
    }
  `,
	key: `results`,
	loggerMethod: `services.transactionService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.transactionService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const StatementLogic = (props) => {
	const { children } = props;
	const { accessToken } = useContext(AuthContext);
	const [statement, setStatement] = useState({});
	const [integrations, setIntegrations] = useState([]);

	const settings = useSelector((state) => state?.app?.user?.settings);
	useEffect(() => {
		if (accessToken) {
			VoucherService.voucher.extension
				.results({}, { token: accessToken })
				.then((data) => {
					setStatement(data);
				});
		}
	}, []);

	useEffect(() => {
		if (accessToken) {
			IntegrationGateway.integration
				.fetch(
					{
						fields: `
						type
						disabled
						status {
							state
						}
					`,
					},
					{ token: accessToken },
				)
				.then((data) => {
					setIntegrations(data);
				})
				.catch(() => {});
		}
	}, []);

	return children({ statement, integrations, settings });
};

export default StatementLogic;
