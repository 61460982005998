import React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import Title from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Splash from '@asteria/component-splash';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import { AuthContext } from '../context';

const IntegrationsLogic = (props) => {
	const { onAction } = props;
	const { accessToken } = useContext(AuthContext);
	const [integrations, setIntegrations] = useState(null);

	useEffect(() => {
		if (accessToken) {
			IntegrationGateway.integration
				.fetch(
					{
						fields: `
						id
						name: key
						type
						disabled
						lastSync
						status {
							state
							progress
							entities
						}
						actions {
							_id
							action
							status
							data
						}
					`,
					},
					{ token: accessToken },
				)
				.then((data) => {
					setIntegrations(data);
				})
				.catch(() => {});
		}
	}, []);

	const hasDismieed = useSelector(
		(store) =>
			store?.app?.user?.settings?.flags?.dismissedErpOnboarding ?? null,
	);

	const splashOptions = useMemo(
		() => ({
			dismiss: false,
			title: TranslationService.get(
				'widget.splash.integrations.title',
				// eslint-disable-next-line spellcheck/spell-checker
				'Koppla på ditt bokföringssystem',
			),
			description: TranslationService.get(
				'widget.splash.integrations.description',
				// eslint-disable-next-line spellcheck/spell-checker
				'Du kan koppla på ditt bokföringssystem för att se dina fakturor, mer historik och på så sätt få en bättre prognos. I dagsläget har vi stöd för:',
			),
			items: [
				{
					type: 'text',
					value: 'Fortnox',
				},
				{
					type: 'text',
					value: 'Visma eEkonomi',
				},
				{
					type: 'text',
					// eslint-disable-next-line spellcheck/spell-checker
					value: 'Björn Lundén',
				},
				{
					type: 'text',
					value: 'Wint',
				},
			],
			// eslint-disable-next-line spellcheck/spell-checker
			/*
			actions: [
				{
					variant: 'link',
					label: TranslationService.get(
						'widget.splash.integrations.missing',
						'Mitt bokföringssystem saknas',
					),
					iconPosition: 'last',
					icon: 'triangle-right',
					size: 'medium',
					action: () => {},
				},
			],
            */
		}),
		[onAction],
	);

	const onClose = useCallback(() => {
		onAction?.('updateUserSettings', {
			flags: { dismissedErpOnboarding: true },
		});
	}, [onAction]);

	const hasErp = integrations?.filter(({ type }) => type === 'erp');
	const hasIntegrationsSplash = useFeature('show-integrations-splash');

	if (
		!hasIntegrationsSplash ||
		hasDismieed ||
		hasErp?.length > 0 ||
		integrations === null
	) {
		return null;
	}

	return (
		<Modal onClose={onClose} size="sm">
			<Wrapper className="asteria-component__category__wrapper asteria-component__splash">
				<Header onClose={onClose}>
					<Title type="title" size="lg">
						{TranslationService.get(
							'widget.splash.integrations.header',
							// eslint-disable-next-line spellcheck/spell-checker
							'Hantera integrationer',
						)}
					</Title>
				</Header>
				<Content>
					<Splash {...splashOptions} />
				</Content>
				<Footer>
					<Button
						analyticsKey="splash.integrations.add"
						variant="primary"
						label={TranslationService.get(
							'widget.splash.integrations.add',
							// eslint-disable-next-line spellcheck/spell-checker
							'Lägg till ett bokföringssystem',
						)}
						onClick={() => {
							onAction?.('go', '/integrations/add/erp');
							onAction?.('updateUserSettings', {
								flags: { dismissedErpOnboarding: true },
							});
						}}
					/>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

export default IntegrationsLogic;
