import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { isPossibleToClick } from '@asteria/component-core/utils';

import Checkbox from '@asteria/component-form/checkbox';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import Cell from './Cell';
import { useCurrencies } from './hooks';

import './styles.scss';

const Currency = React.memo((props) => {
	const { currency, onChange } = props;

	const ref = React.useRef(null);

	const selected = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'CURRENCY',
				value: currency,
			}).length,
	);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onChange?.({ value: currency });
		},
		[currency, onChange],
	);

	return (
		<DropdownItem
			onClick={handleClick}
			prefix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Checkbox
						uncontrolled
						checked={selected}
						onChange={handleClick}
					/>
				</Group>
			}
			ref={ref}
		>
			{currency}
		</DropdownItem>
	);
});

Currency.displayName = 'Currency';
Currency.propTypes = { currency: PropTypes.string, onChange: PropTypes.func };

const Currencies = React.memo(() => {
	const dispatch = useDispatch();

	const currencies = useCurrencies();

	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CURRENCY' }),
		(a, b) => isEqual(a, b),
	);

	const handleChange = React.useCallback(
		(event) => {
			dispatch(
				InvoiceStore.filter({ type: 'CURRENCY', value: event.value }),
			);
		},
		[dispatch],
	);

	const ToggleProps = React.useMemo(
		() => ({
			variant: 'select',
			label: TranslationService.get(
				[
					'invoices.search.currencies.placeholder',
					filters.length
						? 'invoices.search.currencies.value.placeholder'
						: null,
				],
				undefined,
				{ filters: filters },
			),
			icon: 'chevron-down',
			iconPosition: 'last',
			className: 'asteria-component__invoice-search__currencies',
		}),
		[filters],
	);

	if (currencies.length < 2) {
		return null;
	}

	return (
		<Cell className="asteria--type-currencies">
			<Dropdown
				toggle={ToggleProps}
				title={TranslationService.get([
					'invoices.search.currencies.placeholder',
				])}
				className="asteria-component__invoice-search__currencies-dropdown"
				offset={-1}
				scroll
			>
				{currencies.map((currency) => (
					<Currency
						key={currency}
						currency={currency}
						onChange={handleChange}
					/>
				))}
			</Dropdown>
		</Cell>
	);
});

Currencies.displayName = 'CurrenciesSelect';
Currencies.propTypes = {};

export default Currencies;
