import React from 'react';

import Button from '@asteria/component-core/button';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import LayoutContext from '../context';

import { MENU_ITEMS } from './constants';

import './styles.scss';

const Navigation = () => {
	const { onAction } = React.useContext(LayoutContext);

	return MENU_ITEMS.filter(
		({ feature }) => !feature || FeatureService.isActive(feature),
	).map(({ id, path, label }) => (
		<Button
			key={id}
			className="asteria-widget__layout-footer__navigation-item"
			label={TranslationService.get(
				label ?? [
					'layout.footer.navigation.item.label',
					`layout.footer.navigation.item.${id}.label`,
				],
			)}
			variant="tertiary"
			size="sm"
			onClick={() => onAction('go', path)}
		/>
	));
};

Navigation.displayName = 'Navigation';

Navigation.propTypes = {};

export default Navigation;
