import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Context from './context';

import './styles.scss';

const Help = React.memo((props) => {
	return null;

	// eslint-disable-next-line no-unreachable
	const { className } = props;

	const hasFAQFeature = useFeature('freja-faq');

	const { onAction, onAbort } = React.useContext(Context);

	const handleFAQClick = React.useCallback(
		(event) =>
			Promise.allSettled([
				onAbort?.(event, { skipRedirect: true }),
				onAction?.('go', '/faq'),
			]),
		[onAbort, onAction],
	);

	const handleSupportClick = React.useCallback(
		(event) =>
			Promise.allSettled([
				onAbort?.(event, { skipRedirect: true }),
				onAction?.('go', '/support'),
			]),
		[onAbort, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding__help',
				className,
			)}
		>
			<Text size="lg" separator>
				{TranslationService.get(['onboarding.linear.help.title'])}
			</Text>

			{hasFAQFeature ? (
				<Button
					variant="link"
					size="lg"
					label={TranslationService.get([
						'onboarding.linear.help.faq',
					])}
					onClick={handleFAQClick}
				/>
			) : null}

			<Button
				variant="link"
				size="lg"
				label={TranslationService.get([
					'onboarding.linear.help.support',
				])}
				onClick={handleSupportClick}
			/>
		</div>
	);
});

Help.displayName = 'Help';

Help.propTypes = { className: PropTypes.string };

export default Help;
