import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const WaitingStep = (props) => {
	const { nextStep } = props;

	const { os } = useParams();

	const { onAction } = React.useContext(LayoutContext);

	const hasIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				key: 'printer',
				status: 'IDLE',
				connected: true,
			}).length > 0,
	);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	React.useEffect(() => {
		if (hasIntegration) {
			onAction?.(
				'go',
				nextStep
					? `/integrations/erp/printer/${nextStep}`
					: `/integrations/erp/printer/success`,
			);
		}
	}, [hasIntegration, nextStep, onAction]);

	return <BaseStep {...props} os={os} onAction={onAction} onBack={onBack} />;
};

WaitingStep.displayName = 'WaitingStep';

WaitingStep.propTypes = {
	className: PropTypes.string,
	nextStep: PropTypes.string,
};

export default WaitingStep;
