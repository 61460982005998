import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';

import Stepper from '../../../components/Stepper';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import VariantEmpty from './empty';
import VariantList from './list';

const PAGE_VARIANT = {
	INITIAL: 'initial',
	EMPTY: 'empty',
	DETAILS: 'details',
};

function usePageVariant() {
	const hasInvoices = useSelector(
		(store) => !!InvoiceStore.selectors.selected(store).length,
	);

	if (!hasInvoices) {
		return PAGE_VARIANT.EMPTY;
	}

	return PAGE_VARIANT.INITIAL;
}

const InvoicesReview = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const variant = usePageVariant();

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices',
				{ [`asteria--variant-${variant}`]: variant },
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} step="review" />

			{variant === PAGE_VARIANT.INITIAL ? (
				<VariantList onAction={onAction} />
			) : null}

			{variant === PAGE_VARIANT.EMPTY ? (
				<VariantEmpty onAction={onAction} />
			) : null}

			<Support onAction={onAction} />
		</div>
	);
};

InvoicesReview.displayName = 'Invoices';

InvoicesReview.propTypes = { className: PropTypes.string };

export default InvoicesReview;
