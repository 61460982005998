import React from 'react';
import { useCallback, useContext, useEffect } from 'react';

import { ReactReduxContext, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import {
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	format,
	parseISO,
} from 'date-fns';
import PropTypes from 'prop-types';

import GraphLogic from '@asteria/component-graph/logic';

import { setGroups, updateAxis } from '@asteria/datalayer/stores/graph';

import { AuthContext } from '../context';

import fetchCashflow from './funcs/fetchCashflow';
import fetchSoldInvoice from './funcs/fetchSoldInvoice';
import updateSettings from './funcs/updateSettings';

const Graph = (props) => {
	const { onResize } = props;
	const { store } = useContext(ReactReduxContext);
	const { accessToken } = useContext(AuthContext);
	const dispatch = useDispatch();
	const transactions = useSelector(
		(store) => store?.transactions?.states || {},
	);
	const transactionItems = useSelector(
		(store) => store?.transactions?.items || {},
	);

	// ✅ Format a date to YYYY-MM-DD (or any other format)
	function padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	function formatDate(date) {
		return [
			date.getFullYear(),
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate()),
		].join('-');
	}

	const requestData = useCallback(
		async (startDate, filters) => {
			const state = store.getState();

			const scenarioId = state?.app?.user?.settings?.flags?.scenarioId;
			const size = state?.app?.timesize;

			let endOf = endOfMonth;
			if (size === 'week') {
				endOf = endOfISOWeek;
			} else if (size === 'year') {
				endOf = endOfYear;
			}
			const endDate = format(endOf(parseISO(startDate)), 'yyyy-MM-dd');
			// await fetchCashflow({
			// 	accessToken,
			// 	dispatch,
			// 	startDate,
			// 	tags: filters
			// 		.filter(({ type }) => type === 'tag')
			// 		.map(({ id }) => id),
			// 	clients: filters
			// 		.filter(({ type }) => type === 'client')
			// 		.map(({ id }) => id),
			// 	status: filters
			// 		.filter(({ type }) => type === 'status')
			// 		.map(({ id }) => id),
			// 	currencies: filters
			// 		.filter(({ type }) => type === 'currency')
			// 		.map(({ id }) => id),
			// 	endDate: `${endDate}T23:59:59.999Z`,
			// });
			if (
				Object.values(
					store?.getState?.()?.transactions?.states || {},
				)?.every((item) => item.selected === false)
			) {
				await fetchCashflow({
					accessToken,
					dispatch,
					startDate,
					tags: filters
						.filter(({ type }) => type === 'tag')
						.map(({ id }) => id),
					clients: filters
						.filter(({ type }) => type === 'client')
						.map(({ id }) => id),
					status: filters
						.filter(({ type }) => type === 'status')
						.map(({ id }) => id),
					currencies: filters
						.filter(({ type }) => type === 'currency')
						.map(({ id }) => id),
					endDate: `${endDate}T23:59:59.999Z`,
					scenarioId: scenarioId,
				});
			} else {
				let transactionIds = (
					Object.keys(
						store?.getState?.()?.transactions?.states || {},
					) || []
				).filter(
					(item) =>
						store?.getState?.()?.transactions?.states[item]
							.selected === true,
				);

				let startDate = transactionIds?.map((id) =>
					formatDate(
						new Date(
							store?.getState?.()?.transactions?.items[
								id
							]?.paymentDate,
						),
					),
				);
				await fetchSoldInvoice({
					accessToken,
					dispatch,
					startDate,
					transactionIds,
					// transactionIds.length
					// 	? transactionIds
					// : [
					// eslint-disable-next-line spellcheck/spell-checker
					// 		'626a52a06ffdc20b3c3b811f',
					// eslint-disable-next-line spellcheck/spell-checker
					// 		'626a52a06ffdc20b3c3b8121',
					//   ],
				});
			}
		},
		[store],
	);

	const layout = useSelector(
		(store) =>
			store?.app?.user?.settings?.layout?.graph?.layout || 'grouped',
	);
	const range = useSelector((store) => store?.graph?.data?.range || null);
	const barGroups = useSelector(
		(store) => store?.graph?.data?.barGroups || null,
	);

	useEffect(() => {
		dispatch(updateAxis({ barLayout: layout }));
	}, [range, barGroups, layout]);

	const userId = useSelector((state) => state?.app?.user?.id || null);
	const onUpdateSettings = useCallback(
		(form) => {
			updateSettings({ accessToken, id: userId, settings: form });
		},
		[userId],
	);

	useEffect(() => {
		const state = store.getState();

		let transactionIds = (Object.keys(transactions || {}) || []).filter(
			(item) => transactions[item].selected === true,
		);

		let factoringItems = transactionIds
			?.map((id) => transactionItems?.[id] || null)
			.filter((item) => item);

		const updatedGroups = {};

		for (let i = 0; i < factoringItems.length; i += 1) {
			/*
			let day = addDays(new Date(factoringItems[i].paymentDate), -30);
			if (isBefore(day, new Date())) {
				day = new Date();
			}
			*/
			const groupId = format(
				new Date(factoringItems[i].paymentDate),
				'yyyy-MM-01',
			);

			const total = factoringItems[i]?.sums?.display?.total || 0;

			const groupData =
				updatedGroups[groupId] ||
				state?.graph.data.originalGroups?.[groupId];

			const depositBar = groupData.bars.find(
				({ data: { type } = {}, types = [] }) =>
					type === 'deposit' && types.length === 1,
			);

			const forecastBar = depositBar.parts.find(
				({ data: { status } = {} }) => status === 'FORECAST',
			);

			let factoringBar = depositBar.parts.find(
				({ data: { status } = {} }) => status === 'FACTORING',
			);

			const parts = depositBar.parts.filter(
				(i) => i !== forecastBar && i !== factoringBar,
			);

			if (!factoringBar || !updatedGroups[groupId]) {
				factoringBar = {
					value: 0,
					types: [
						'deposit',
						'customer',
						'factoring',
						'invoices-customer',
					],
					parts: [],
					chip: {
						id: '61b21f7dbe36818286e8542a',
						type: 'tag',
						config: {
							skipTotal: false,
							name: '$customer',
							category: '$invoices',
							type: 'DEPOSIT',
							status: 'FACTORING',
							subtags: [],
						},
					},
					data: {
						type: 'deposit',
						status: 'FACTORING',
						probability: 1,
						max: 0,
						min: 0,
					},
					info: [],
				};
			}

			const newParts = [
				...parts,
				{
					...factoringBar,
					value: factoringBar.value + total,
				},
			];
			if (forecastBar.value - total > 0) {
				newParts.push({
					...forecastBar,
					value: forecastBar.value - total,
					originalValue:
						forecastBar.originalValue || forecastBar.value,
				});
			}

			updatedGroups[groupId] = {
				...groupData,
				bars: [
					...groupData.bars.filter((i) => i !== depositBar),
					{
						...depositBar,
						parts: newParts,
					},
				],
			};
		}

		if (factoringItems.length === 0) {
			dispatch(setGroups({ items: state?.graph.data.originalGroups }));
		} else {
			dispatch(setGroups({ items: updatedGroups }));
		}

		// TODO: Move this to backend ?

		// fetchSoldInvoiceData();
		// else {
		// 	fetchSoldInvoiceData();
		// }
	}, [transactions]);

	return (
		<GraphLogic
			requestData={requestData}
			onResize={onResize}
			onUpdateSettings={onUpdateSettings}
		/>
	);
};

Graph.propTypes = {
	onResize: PropTypes.func,
};

export default Graph;
