import React from 'react';

import { useSelector } from 'react-redux';

import { useQueries, useQuery } from '@tanstack/react-query';
import { isAfter, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useCompanyVersion } from '../../components/CompanyVersion';
import { MenuContext } from '../context';

import { MENU_ITEMS } from './constants';
import { useClick, useIsActive } from './hooks';

import './styles.scss';

function useBadge({ id, onSubmit }) {
	const reportTimestamp = useSelector(
		InvoiceStore.selectors.reports.timestamp,
	);

	const invoiceTimestamp = useSelector(
		(store) =>
			store?.app?.company?.settings?.flags?.['freja:invoices:timestamp'],
	);

	const [
		{ data: newInvoices },
		{ data: errorInvoice },
		{ data: unreadLayoutMessage },
		{ data: hasUnreadReports },
	] = useQueries({
		queries: [
			{
				queryKey: ['invoices', { timestamp: invoiceTimestamp }],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: {
							createdAt: { gt: invoiceTimestamp },
							services: { status: null },
						},
						pageFilters: { first: 1 },
						fields: `_id`,
					}),
				select: (response) => !!response?.pageInfo?.count,

				placeholderData: false,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['invoices', 'error'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: 'ERROR' } },
						pageFilters: { first: 1 },
					}),
				select: (response) => ({
					count: response?.pageInfo?.count ?? 0,
					node: response?.edges?.[0]?.node ?? null,
				}),

				placeholderData: null,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['navigation', 'layouts', 'unread'],
				queryFn: async () => {
					const response = await onSubmit?.('invoice-layout:unread');

					return response?.[0] ?? null;
				},
				placeholderData: null,
				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,
			},
			{
				queryKey: ['reports', 'unread'],
				queryFn: async () => {
					const response = await onSubmit?.('reports:list');

					return (response ?? []).some((object) =>
						isAfter(
							parseISO(object?.createdAt),
							parseISO(reportTimestamp),
						),
					);
				},
				placeholderData: false,
				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
				enabled: !!reportTimestamp,

				keepPreviousData: true,
			},
		],
	});

	const hasIntegrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { type: 'erp' })
				.length > 0,
	);

	const hasErrorIntegrations = useSelector((store) =>
		IntegrationStore.selectors
			.integrations(store, { type: 'erp' })
			.some(
				(object) =>
					object?.status?.state === 'ERROR' ||
					object?.config?.errors?.length,
			),
	);

	return React.useMemo(() => {
		if (id === 'connections') {
			if (hasErrorIntegrations) {
				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get([
						`layout.header.navigation.item.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip.error`,
					]),
				};
			}

			if (!hasIntegrations) {
				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get([
						`layout.header.navigation.item.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip.empty`,
					]),
				};
			}
		}

		if (id === 'invoices') {
			if (hasIntegrations && newInvoices) {
				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get([
						`layout.header.navigation.item.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip`,
					]),
				};
			}

			if (errorInvoice?.count) {
				const serviceInvoice = errorInvoice?.node?.lastService?.invoice;

				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get(
						[
							`layout.header.navigation.item.badge.tooltip`,
							`layout.header.navigation.item.${id}.badge.tooltip`,
							`layout.header.navigation.item.${id}.badge.tooltip.error`,
						],
						undefined,
						{
							count: errorInvoice?.count,
							last: errorInvoice?.node,
							errors: serviceInvoice?.errors,
						},
					),
				};
			}
		}

		if (id === 'pending-invoices') {
			if (unreadLayoutMessage) {
				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get(
						[
							`layout.header.navigation.item.badge.tooltip`,
							`layout.header.navigation.item.${id}.badge.tooltip`,
						],
						undefined,
						{ data: unreadLayoutMessage },
					),
				};
			}
		}

		if (id === 'reports') {
			if (hasUnreadReports) {
				return {
					icon: 'warning',
					size: 'sm',
					tooltip: TranslationService.get([
						`layout.header.navigation.item.badge.tooltip`,
						`layout.header.navigation.item.${id}.badge.tooltip`,
					]),
				};
			}
		}
	}, [
		errorInvoice?.count,
		errorInvoice?.node,
		hasErrorIntegrations,
		hasIntegrations,
		newInvoices,
		hasUnreadReports,
		id,
		unreadLayoutMessage,
	]);
}

const NavigationItemDropdownItem = React.memo((props) => {
	const { onClick, object } = props;

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username =
		[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
		user?.username ||
		user?.id;

	const isActive = useIsActive();

	const handleClick = React.useCallback(
		() => onClick?.({ path: object?.path, action: object?.action }),
		[object?.action, object?.path, onClick],
	);

	return (
		<DropdownItem onClick={handleClick} active={isActive(object?.path)}>
			{TranslationService.get(
				[
					'layout.header.navigation.item.label',
					`layout.header.navigation.item.${object.id}.label`,
				],
				undefined,
				{ navigation: object, user: user, username: username },
			)}
		</DropdownItem>
	);
});

NavigationItemDropdownItem.displayName = 'NavigationItemDropdownItem';
NavigationItemDropdownItem.propTypes = {
	onClick: PropTypes.func,
	object: PropTypes.object,
};

const NavigationItem = React.memo((props) => {
	const {
		id,
		icon,
		iconActive,
		icons,
		path,
		items = [],
		action,
		onSubmit,
	} = props;

	const { state } = React.useContext(MenuContext);

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username =
		[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
		user?.username ||
		user?.id;

	const hasMultiCompanies = (user?.companies ?? []).length > 1;

	const isActive = useIsActive();
	const onClick = useClick();

	const onButtonClick = React.useCallback(
		() => onClick?.({ path: path, action: action }),
		[action, onClick, path],
	);

	const badge = useBadge({ id: id, onSubmit: onSubmit });

	const ToggleProps = React.useMemo(() => {
		let active = undefined;

		if (id === 'mobile-menu') {
			active = state;
		} else if (items?.length) {
			if (items.some((object) => isActive(object?.path))) {
				active = true;
			}
		} else {
			active = isActive(path);
		}

		return {
			icon: icon,
			iconActive: iconActive,
			icons: icons,
			iconBadge: badge,

			className: cn('asteria-widget__layout-header__navigation-item', {
				[`asteria-navigation--type-${id}`]: id,
			}),
			label: TranslationService.get(
				[
					'layout.header.navigation.item.label',
					`layout.header.navigation.item.${id}.label`,
					id === 'settings' && hasMultiCompanies
						? `layout.header.navigation.item.${id}.multi.label`
						: null,
				],
				undefined,
				{
					navigation: props,
					username: username,
					company: user?.company,
				},
			),
			iconSize: 'md',
			active: active,
		};
	}, [
		badge,
		hasMultiCompanies,
		icon,
		iconActive,
		icons,
		id,
		isActive,
		items,
		path,
		props,
		state,
		user?.company,
		username,
	]);

	if (items.length) {
		return (
			<Dropdown
				size="sm"
				toggle={ToggleProps}
				className={cn(
					'asteria-widget__layout-header__navigation-dropdown',
					{ [`asteria-navigation--type-${id}`]: id },
				)}
			>
				{items.map((object) => (
					<NavigationItemDropdownItem
						key={object.id}
						onClick={onClick}
						object={object}
					/>
				))}
			</Dropdown>
		);
	}

	return <Button {...ToggleProps} onClick={onButtonClick} />;
});

NavigationItem.displayName = 'NavigationItem';
NavigationItem.propTypes = {
	id: PropTypes.string,
	path: PropTypes.string,
	action: PropTypes.arrayOf(PropTypes.string),
	icon: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.shape({ ...NavigationItem.propTypes })),

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const Navigation = React.memo(({ onSubmit, onAction }) => {
	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));
	const apiVersion = useCompanyVersion();

	const { data: layoutsCount } = useQuery({
		queryKey: ['navigation', 'layouts', 'count'],
		queryFn: async () =>
			onSubmit?.('invoice-layout:count', { skipDispatch: true }),
		placeholderData: 0,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,
	});

	const validationData = {
		user: user,
		hasLayouts: layoutsCount !== 0,
		apiVersion: apiVersion,
	};

	return (
		<div className="asteria-widget__layout-header__navigation">
			{MENU_ITEMS.filter(
				({ onValidate }) => !onValidate || onValidate(validationData),
			).map((object) => (
				<NavigationItem
					key={object.id}
					{...object}
					items={(object?.items ?? []).filter(
						({ onValidate }) =>
							!onValidate || onValidate(validationData),
					)}
					onSubmit={onSubmit}
					onAction={onAction}
				/>
			))}
		</div>
	);
});

Navigation.displayName = 'Navigation';
Navigation.propTypes = { onSubmit: PropTypes.func, onAction: PropTypes.func };

export default Navigation;
