import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import withRegister from '../withRegister';

import './styles.scss';

const Checkbox = React.memo(
	React.forwardRef((props, ref) => {
		const { className, name, onChange, onBlur } = props;

		return (
			<div
				className={cn(
					'asteria-component__form-v2__checkbox',
					className,
				)}
			>
				<input
					type="checkbox"
					name={name}
					ref={ref}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</div>
		);
	}),
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
};

export default withRegister(Checkbox);
