import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import Form, { useFormValues } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Select, { Option } from '@asteria/component-form/select';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import LayoutContext from '../../layout/context';

import './styles.scss';

function SupportFormContent({ loading }) {
	const value = useFormValues({ name: 'subject' });

	return (
		<>
			<Select
				name="subject"
				label={TranslationService.get(['support.form.subject.label'])}
				placeholder={TranslationService.get([
					'support.form.subject.placeholder',
				])}
				icon="chevron-down"
				iconActive="chevron-up"
				required
				scroll
			>
				<Option value="connection">
					{TranslationService.get([
						'support.form.subject.option.label.connection',
					])}
				</Option>
				<Option value="v-print">
					{TranslationService.get([
						'support.form.subject.option.label.v-print',
					])}
				</Option>
				<Option value="invoices">
					{TranslationService.get([
						'support.form.subject.option.label.invoices',
					])}
				</Option>
				<Option value="account">
					{TranslationService.get([
						'support.form.subject.option.label.account',
					])}
				</Option>
				<Option value="custom">
					{TranslationService.get([
						'support.form.subject.option.label.custom',
					])}
				</Option>
			</Select>
			{value === 'custom' ? (
				<Input
					name="subject-custom"
					label={TranslationService.get([
						'support.form.subject.custom.label',
					])}
					placeholder={TranslationService.get([
						'support.form.subject.custom.placeholder',
					])}
					required
				/>
			) : null}
			<Input
				name="email"
				type="email"
				label={TranslationService.get(['support.form.email.label'])}
				placeholder={TranslationService.get([
					'support.form.email.placeholder',
				])}
				required
			/>
			<Input
				name="message"
				type="textarea"
				label={TranslationService.get(['support.form.message.label'])}
				placeholder={TranslationService.get([
					'support.form.message.placeholder',
				])}
				required
			/>
			<div>
				<Button
					type="submit"
					label={TranslationService.get([
						'button.submit',
						'action.submit',
						'support.form.submit',
						'page.support.form.submit',
					])}
					variant="primary"
					loading={loading}
					disabled={loading}
				/>
			</div>
		</>
	);
}

SupportFormContent.displayName = 'SupportFormContent';
SupportFormContent.propTypes = { loading: PropTypes.bool };

function SupportForm(props) {
	const { onAction, country } = props;

	const [loading, setLoading] = React.useState(false);

	const { onSubmit } = React.useContext(LayoutContext);

	const placeholder = useConfig('pages.support.form.placeholder', {
		deep: true,
	});

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	const defaultValues = React.useMemo(
		() => ({ country: country }),
		[country],
	);

	const handleSubmit = React.useCallback(
		async ({ subject, 'subject-custom': custom, ...form }) => {
			setLoading(true);

			try {
				const response = await onSubmit?.('support', {
					...form,
					subject: subject === 'custom' ? custom : subject,
				});

				return onAction?.('form:success', response);
			} catch (err) {
				// handle error
			} finally {
				setLoading(false);
			}
		},
		[onAction, onSubmit],
	);

	return (
		<div
			className={cn('asteria-page__support-form', {
				'asteria--state-split': placeholder?.length,
			})}
		>
			<div className="asteria-page__support-form__content">
				<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
					<SupportFormContent loading={loading} />
				</Form>
			</div>
			{placeholder?.length ? (
				<div className="asteria-page__support-form__placeholder">
					<Contenter content={placeholder} actions={actions} />
				</div>
			) : null}
		</div>
	);
}

SupportForm.displayName = 'SupportForm';
SupportForm.propTypes = { onAction: PropTypes.func, country: PropTypes.string };

export default React.memo(SupportForm);
