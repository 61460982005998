import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';

import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import LoadingScreen from '@asteria/layout/loading';
import { cn } from '@asteria/utils-funcs/classes';

import InvoiceActionBar from '../../../components/Invoices/ActionBar';
// import InvoiceHistory from '../../../components/Invoices/History';
import InvoiceHistoryDropdown from '../../../components/Invoices/History/history-dropdown';
import InvoiceSearch from '../../../components/Invoices/Search';
import InvoiceTable from '../../../components/Invoices/Table';
import { useInvoices } from '../../../components/Invoices/Table/hooks';
// import OnboardingList from '../../../components/Onboarding/List';
import TourButton from '../../../components/TourButton';
import LayoutContext from '../../../layout/context';

import LayoutInfo from './LayoutsInfo';

import './styles.scss';

const Loader = () => {
	const hasIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				filters: [{ status: 'IMPORTING' }, { status: 'INITIATING' }],
			}).length,
	);

	if (!hasIntegrations) {
		return null;
	}

	return <LoadingScreen type="freja.invoice.table" blur />;
};

const VariantDetails = () => {
	const { onSubmit, onAction } = React.useContext(LayoutContext);

	const dispatch = useDispatch();

	const { data: hasInvoices } = useQuery({
		queryKey: ['invoices', 'exist'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				pageFilters: { first: 1 },
				fields: `_id`,
			}),
		select: (response) => !!response?.pageInfo?.count,

		placeholderData: false,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		retry: false,
	});

	const { data, isFetching } = useInvoices({ onSubmit, onAction });

	const fields = React.useMemo(
		() => [
			{ name: 'selector' },
			{ name: 'invoice-number', tooltip: true },
			{ name: 'client-number', tooltip: true },
			{ name: 'client-name' },
			// { name: 'message' },
			{ name: 'invoice-date-sent' },
			{ name: 'invoice-date-due' },
			{ name: 'batch' },
			{ name: 'message' },
			{ name: 'currency' },
			{ name: 'tax' },
			{ name: 'total' },
		],
		[],
	);

	React.useLayoutEffect(() => {
		dispatch(
			AppStore.setCompanySettingsFlags({
				'freja:invoices:timestamp': new Date().toISOString(),
			}),
		);

		onSubmit?.('company:settings:flags:refresh');
	}, [dispatch, onSubmit]);

	return [
		<div key="content" className="asteria-page__wrapper">
			{hasInvoices ? (
				<TourButton name="page:invoices:details" onAction={onAction} />
			) : null}
			<Group direction="horizontal">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get([
							`page.invoices.title`,
							`page.invoices.details.title`,
						])}
					</Title>
					<Text size="lg">
						{TranslationService.get([
							`page.invoices.content`,
							`page.invoices.details.content`,
						])}
					</Text>
				</TextGroup>
				<InvoiceHistoryDropdown
					onAction={onAction}
					onSubmit={onSubmit}
				/>
			</Group>

			<LayoutInfo type="pending" />
			<div className="asteria-page__invoices__content">
				{/* <div
					className={cn(
						'asteria-page__invoices__content-column',
						'asteria--type-statistics',
					)}
				>
					<OnboardingList onAction={onAction} onSubmit={onSubmit} />
					<InvoiceHistory onAction={onAction} onSubmit={onSubmit} />
				</div> */}
				<div
					className={cn(
						'asteria-page__invoices__content-column',
						'asteria--type-table',
					)}
				>
					<InvoiceActionBar onAction={onAction} />
					<div className="asteria-page__invoices__table">
						{hasInvoices ? <Loader /> : null}
						<InvoiceSearch
							onAction={onAction}
							onSubmit={onSubmit}
							data={data}
						/>
						<InvoiceTable
							fields={fields}
							data={data}
							loading={isFetching}
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					</div>
				</div>
			</div>
		</div>,
	];
};

VariantDetails.displayName = 'VariantDetails';

VariantDetails.propTypes = {};

export default VariantDetails;
