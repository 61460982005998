import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';

import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import { Text, UnorderedList } from '@asteria/component-core/typography';
import { Content } from '@asteria/component-core/wrapper';

import Accounts from '@asteria/component-accounts';
import Alert from '@asteria/component-alert';
import FormRejected from '@asteria/component-form-rejected';
import Guide from '@asteria/component-guide';
import Select from '@asteria/component-integrations/components/flows/generic/select';
import Modal from '@asteria/component-modal';
import Statement from '@asteria/component-statement';
import StepperForm from '@asteria/component-stepperform';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import Welcome from '@asteria/component-welcome';

import {
	setFilters,
	setMode,
	setStandalone,
	setUserSettings,
} from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import Navigation from '@asteria/layout/navigation';
import Overview from '@asteria/layout/overview';
import { cn } from '@asteria/utils-funcs/classes';

import SideDrawer from '../../components/side-drawer';

import image from './assets/Bild4a.jpg';
import DataLayer from './components/datalayer';
import Features from './components/features';
import NavigationActions from './components/navigationActions';
import Popups from './components/popups';
import Theme from './components/theme';
import { AuthContext } from './context';
import BankAccountsLogic from './logic/bankAccounts';
import CurrencyLogic from './logic/currency';
import FeedbackLogic from './logic/feedback';
import updateFeedback from './logic/funcs/updateFeedback';
import updateSettings from './logic/funcs/updateSettings';
import GraphLogic from './logic/graph';
import IntegrationsDialog from './logic/integrations';
import IntegrationsSplash from './logic/integrationsSplash';
import IntegrationsLogic from './logic/onboarding';
import OverviewLogic from './logic/overview';
import Settings from './logic/settings';
import StatementLogic from './logic/statement';
import SupportLogic from './logic/support';

import './components/index.scss';

const Wrapper = () => {
	const currentRoute = useSelector((state) => state.navigate.route);
	const isOpen = useSelector((state) => state.navigate.sideDrawerOpen);
	const [flow, setFlow] = useState({});
	const [integration, setIntegration] = useState({});
	const [showIntegrations, setShowIntegrations] = useState(false);
	const [showAccounts, setShowAccounts] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showStatement, setShowStatement] = useState(false);
	const [showGuide, setShowGuide] = useState(false);
	const [showSupport, setShowSupport] = useState(false);
	const [showCurrency, setShowCurrency] = useState(false);
	const [hasSidebar, setHasSidebar] = useState(false);
	const [showAddTransactions, setShowAddTransaction] = useState(false);

	const [feedbackParams, setFeedbackParams] = useState(null);

	const isTransactionListShown = useSelector(
		(store) => (store?.transactions?.state ?? null) !== null,
	);

	const dispatch = useDispatch();
	const { accessToken } = useContext(AuthContext);
	const { store } = useContext(ReactReduxContext);
	useEffect(() => {
		dispatch(setStandalone(false));
	}, []);

	const [layoutSize, setLayoutSize] = useState(null);

	const onAction = useCallback((action, data) => {
		const path = data?.path || data;

		if (action === 'go' && path === '/integrations') {
			setShowIntegrations({ page: 'list' });
		} else if (action === 'go' && path === '/integrations/add') {
			setShowIntegrations({ page: 'add' });
		} else if (action === 'go' && path === '/integrations/add/erp') {
			setShowIntegrations({ page: 'add', type: 'erp' });
		} else if (action === 'go' && path === '/accounts') {
			setShowAccounts(true);
		} else if (action === 'go' && path === '/settings') {
			setShowSettings(true);
		} else if (action === 'go' && path === '/guide') {
			setShowGuide(true);
		} else if (action === 'go' && path === '/support') {
			setShowSupport(true);
		} else if (action === 'go' && path === '/statement') {
			setShowStatement(true);
		} else if (action === 'go' && path === '/overview') {
			setShowCurrency(true);
		} else if (action === 'go' && path === '/logout') {
			localStorage.removeItem('wingsToken');

			const url = new URL('', window.location.origin);
			url.search = window.location.search;

			window.location.replace(url.toString());
		} else if (action === 'go' && path === '/credit') {
			setHasSidebar(true);
			dispatch(setMode('credit'));
		} else if (action === 'addTransaction') {
			setShowAddTransaction(true);
		} else if (action === 'setFilters') {
			const state = store.getState();
			const tags = data
				.filter(({ type }) => type === 'tag')
				.map((filter) =>
					state?.app?.tags?.find(
						(tag) =>
							filter?.tagName === tag?.name &&
							filter?.categoryName === tag?.category?.name,
					),
				)
				.filter((tag) => tag)
				.map((tag) => ({ id: tag.id, item: tag, type: 'tag' }));

			const statuses = data
				.filter(({ type }) => type === 'status')
				.map((status) => ({
					id: status.name,
					item: { name: status.name },
					type: 'status',
				}));

			const currencies = data
				.filter(({ type }) => type === 'currency')
				.map((currency) => ({
					id: currency.code,
					item: { code: currency.code },
					type: 'currency',
				}));

			const newFilters = [...tags, ...statuses, ...currencies];

			dispatch(setFilters(newFilters));
		} else if (action === 'updateUserSettings') {
			const state = store.getState();
			const settings = state?.app?.user?.settings || {};
			const newSettings = merge(merge({}, settings), data);

			updateSettings({
				accessToken,
				id: state?.app?.user?.id,
				settings: data,
			});
			dispatch(setUserSettings(newSettings));
		} else if (action === 'askForFeedback') {
			const state = store.getState();
			const { feedbackKey } = data;
			if (!state?.app?.user?.feedback?.[feedbackKey]) {
				setFeedbackParams(data);
			}
		} else if (action === 'updateFeedback') {
			const state = store.getState();
			return updateFeedback({
				accessToken,
				id: state?.app?.user?.id,
				feedback: {
					[data.feedbackKey]: data,
				},
				dispatch,
			});
		}
	}, []);

	const hideCurrency = useCallback(() => setShowCurrency(false), []);
	const hideSupport = useCallback(() => setShowSupport(false), []);
	const hideSettings = useCallback(() => setShowSettings(false), []);

	const hideFeedback = useCallback(() => setFeedbackParams(null), []);

	const isFullScreen = useSelector(
		(store) => store?.app?.user?.settings?.layout?.fullscreen ?? null,
	);

	const isEdit = useSelector((state) =>
		Object.values(state?.transactions?.states || {}).some(
			({ edit }) => edit,
		),
	);

	const renderComponent = useMemo(() => {
		if (currentRoute === '/') {
			return (
				<>
					<div
						className={cn('asteria-wrapper', {
							[`asteria-wrapper--size-${layoutSize}`]: layoutSize,
							[`asteria-fullscreen`]: isFullScreen,
							'asteria-wrapper--split': isTransactionListShown,
							'asteria-transactions__editing': isEdit,
							'asteria-transactions__adding': showAddTransactions,
							'asteria-wrapper--sidebar': hasSidebar,
						})}
					>
						<Popups onAction={onAction} />
						<IntegrationsDialog onAction={onAction} />
						<FeatureFlag feature="feedback-dialog">
							{feedbackParams && (
								<FeedbackLogic
									{...feedbackParams}
									onAction={onAction}
									onClose={hideFeedback}
								/>
							)}
						</FeatureFlag>

						<IntegrationsSplash onAction={onAction} />
						{showIntegrations && (
							<Modal
								onClose={() => setShowIntegrations(false)}
								size="sm"
							>
								<IntegrationsLogic
									onClose={() => setShowIntegrations(false)}
									onAction={onAction}
									{...showIntegrations}
								/>
							</Modal>
						)}
						{showSettings && <Settings onClose={hideSettings} />}
						{showAccounts && (
							<Modal
								onClose={() => setShowAccounts(false)}
								size="sm"
							>
								<BankAccountsLogic>
									{({ accounts, onAction }) => (
										<Accounts
											accounts={accounts}
											onClose={() =>
												setShowAccounts(false)
											}
											onAction={onAction}
										/>
									)}
								</BankAccountsLogic>
							</Modal>
						)}
						{showStatement && (
							<Modal
								onClose={() => setShowStatement(false)}
								size="md"
							>
								<StatementLogic>
									{({
										integrations,
										statement,
										settings,
									}) => (
										<Statement
											integrations={integrations}
											data={statement}
											onClose={() =>
												setShowStatement(false)
											}
											onAction={onAction}
											settings={settings}
											showHeader
										/>
									)}
								</StatementLogic>
							</Modal>
						)}
						{showGuide && (
							<Modal
								onClose={() => setShowGuide(false)}
								size="sm"
							>
								<Guide onEnd={() => setShowGuide(false)} />
							</Modal>
						)}
						{showSupport && (
							<Modal onClose={hideSupport} size="sm">
								<SupportLogic onClose={hideSupport} />
							</Modal>
						)}
						{showCurrency && (
							<Modal
								onClose={() => setShowCurrency(false)}
								size="md"
							>
								<CurrencyLogic onClose={hideCurrency} />
							</Modal>
						)}
						<div className="asteria-company-overview">
							<OverviewLogic
								onAction={onAction}
								showCurrency={showCurrency}
							/>
							{/* {!showCredit && (
							<FeatureFlag feature="header-action-credit">
								<Button
									analyticsKey="header.showcredit"
									variant="secondary"
									className="asteria-component__button--simulate-credit"
									tooltipClassName="asteria-component__tooltip__simulate-credit"
									size="sm"
									tooltip={TranslationService.get(
										'header.action.credit.tooltip',
										'Visste du att du kan simulera dina finansieringsbehov? Klicka här för att se ditt behov.',
									)}
									label={TranslationService.get(
										'header.action.credit',
										'Finansieringsbehov',
									)}
									onClick={() => onAction?.('go', '/credit')}
								/>
							</FeatureFlag>
						)} */}
						</div>
						<div className="asteria-search"></div>
						<div className="asteria-navigation">
							<Navigation onAction={onAction} />
							<NavigationActions onAction={onAction} />
						</div>
						<div className="asteria-graphs">
							<div>
								<GraphLogic onResize={setLayoutSize} />
							</div>
						</div>
						<div id="asteria-sidepane-container"></div>
						<div className="asteria-information">
							<Text>
								Vi jobbar på upplevelsen i mobilen. För att
								kunna använda Företagskollen rekommenderar vi
								dig att logga in på internetbanken på din dator
							</Text>
						</div>
					</div>
					<SideDrawer
						headerTitle={TranslationService.get(
							'side.drawer.header.title',
						)}
					/>
				</>
			);
		} else if (
			currentRoute === '/welcome' ||
			currentRoute.includes('success')
		) {
			return (
				<Welcome
					// image={`https://i.ibb.co/XFRs4xc/Group-631.png`}
					// eslint-disable-next-line spellcheck/spell-checker
					cardTitle={TranslationService.get('welcome.card.title')} //'Fakturor är ett bra gödningsmedel när företaget ska växa.'
					btnLabel={TranslationService.get('welcome.card.btnLabel')}
					description={TranslationService.get(
						'welcome.card.description',
					)}
					descriptionDetail={TranslationService.get(
						'welcome.card.descriptionDetail',
					)}
					subDescription={TranslationService.get(
						'welcome.card.subDescription',
					)}
				/>
			);
		} else if (currentRoute.includes('/welcome/welcomeForm')) {
			return <StepperForm />;
		} else if (currentRoute.includes('/welcomeForm/notification/error')) {
			return <FormRejected />;
		} else if (currentRoute.includes('welcome/SignContract')) {
			// eslint-disable-next-line spellcheck/spell-checker
			return <img src={image} alt="Bild4" />;
		} else if (currentRoute.includes('welcome/connectAccountingSoftware')) {
			return (
				<>
					<Welcome
						// image={`https://i.ibb.co/XFRs4xc/Group-631.png`}
						// eslint-disable-next-line spellcheck/spell-checker
						cardTitle={TranslationService.get('welcome.card.title')} //'Fakturor är ett bra gödningsmedel när företaget ska växa.'
						btnLabel={TranslationService.get(
							'welcome.card.btnLabel',
						)}
						description={TranslationService.get(
							'welcome.card.description',
						)}
						descriptionDetail={TranslationService.get(
							'welcome.card.descriptionDetail',
						)}
						subDescription={TranslationService.get(
							'welcome.card.subDescription',
						)}
					/>
					<Modal size="sm">
						<Select
							integration={integration}
							setIntegration={setIntegration}
							flow={flow}
							setFlow={setFlow}
							onClose={() => {}}
							nextBtnLabel={TranslationService.get(
								'action.continue',
							)}
							prevBtnLabel={TranslationService.get(
								'action.integration.cancel',
							)}
							selectDescription={
								<Content className="asteria-component__wrapper-content_no_top_bottom-padding">
									<Text>
										{TranslationService.get(
											'account.list.heading',
										)}
									</Text>
									<UnorderedList
										items={[
											{
												icon: 'bullet',
												type: 'text',
												value: TranslationService.get(
													'account.list.text.1',
												),
											},
											{
												icon: 'bullet',
												type: 'text',
												value: TranslationService.get(
													'account.list.text.2',
												),
											},
											{
												icon: 'bullet',
												type: 'text',
												value: TranslationService.get(
													'account.list.text.3',
												),
											},
										]}
									/>
								</Content>
							}
						/>
					</Modal>
				</>
			);
		} else if (currentRoute.includes('sideDrawer')) {
			return (
				<SideDrawer
					headerTitle={TranslationService.get(
						'side.drawer.header.title',
					)}
				/>
			);
		}
	}, [currentRoute]);
	let alertData = {
		success: {
			level: 'success',
			text: TranslationService.get('notification.alert.title.success'),
			type: 'feedback',
			titleStyle: { textAlign: 'left' },
			className: 'asteria-component__gap__no-feedback',
		},
		error: {
			level: 'error',
			text: TranslationService.get('notification.alert.title.error'),
			type: 'feedback',
			titleStyle: { textAlign: 'left' },
			className: 'asteria-component__gap__no-feedback',
		},
		info: {
			level: 'info',
			text: TranslationService.get('notification.alert.title.info'),
			titleStyle: { textAlign: 'left' },
		},
	};
	return (
		<div
			className={`asteria-component__width_full asteria-component__width_full-sidebar-${
				isOpen ? 'open' : 'close'
			}`}
		>
			{!currentRoute.includes('/') && (
				<Overview showRight={false} name="welcome.header.title" />
			)}
			{currentRoute.includes('/notification') && (
				<Alert
					className={
						alertData[
							currentRoute.replace(
								'/welcomeForm/notification/',
								'',
							)
						]?.className
					}
					icon="errorCircle"
					iconPosition="first"
					level={
						alertData[
							currentRoute.replace(
								'/welcomeForm/notification/',
								'',
							)
						]?.level
					}
					type={
						alertData[
							currentRoute.replace(
								'/welcomeForm/notification/',
								'',
							)
						]?.type
					}
				>
					<p className="asteria-component__align_left">
						{
							alertData[
								currentRoute.replace(
									'/welcomeForm/notification/',
									'',
								)
							]?.text
						}
					</p>
				</Alert>
			)}
			{renderComponent}
		</div>
	);
};

const Widget = (props) => {
	const [partner, setPartner] = useState(null);
	const { accessToken, partnerId, language, loader = true } = props;

	useEffect(() => {
		const fetch = async () => {
			const response = await PartnerService.partner.fetchOne(
				{ id: partnerId, fields: 'name settings { themeId }' },
				{ token: accessToken },
			);

			setPartner(response);
		};

		fetch();
	}, [partnerId]);

	if (!partner) {
		return null;
	}

	return (
		<AuthContext.Provider
			value={{
				accessToken: accessToken,
				partnerId: partnerId,
				themeId: partner?.settings?.themeId,
				language: language,
			}}
		>
			<DataLayer loader={loader}>
				<Theme themeId={partner?.settings?.themeId}>
					<Features>
						<Wrapper />
					</Features>
				</Theme>
			</DataLayer>
		</AuthContext.Provider>
	);
};

Widget.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	language: PropTypes.string,
};

export default Widget;
