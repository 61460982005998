import React from 'react';

import { loremIpsum } from 'lorem-ipsum';

import { Text } from '@asteria/component-core/typography';

import '../styles.scss';

const Rows = () => {
	return <Text>{loremIpsum({ count: 14 })}</Text>;
};

Rows.displayName = 'Rows';

Rows.propTypes = {};

export default Rows;
