import React from 'react';

const Context = React.createContext({
	onAbort: () => null,
	onBack: () => null,
	onClose: () => null,
	onSubmit: () => null,
	onAction: () => null,
});

export default Context;
