import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Calendar from './Calendar';
import Navigation from './Navigation';
import Types from './Types';

import './styles.scss';

const DatepickerContent = (props) => {
	const { className } = props;

	return (
		<div
			className={cn(
				'asteria-component__form-v2__datepicker-content',
				className,
			)}
		>
			<Types />
			<Navigation />
			<Calendar />
		</div>
	);
};

DatepickerContent.displayName = 'DatepickerContent';

DatepickerContent.propTypes = { className: PropTypes.string };

export default DatepickerContent;
