import { AuthService, SupportService } from '@asteria/backend-utils-services';

import { setFeedback } from '@asteria/datalayer/stores/app';

export async function fetch({ accessToken, dispatch }) {
	try {
		const response = await AuthService.auth.me(
			{ fields: `feedback` },
			{ token: accessToken },
		);

		dispatch?.(setFeedback(response?.feedback));

		return response?.feedback;
	} catch (err) {
		// TODO: Catch error
	}
}

export async function create({ accessToken, feedback, dispatch }) {
	await SupportService.feedback
		.add({ input: feedback }, { token: accessToken })
		.catch(() => {});

	return fetch({ accessToken: accessToken, dispatch: dispatch });
}
