import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Provider as FeatureProvider } from '@asteria/component-tools/featureflag/context';

const Features = (props) => {
	const { children } = props;

	const features = useSelector((store) => store?.features?.features || null);

	if (features === null) {
		return null;
	}

	return <FeatureProvider features={features}>{children}</FeatureProvider>;
};

Features.propTypes = {
	children: PropTypes.node,
};

export default Features;
