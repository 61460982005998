import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import cssVars from 'css-vars-ponyfill';
import { enableES5 } from 'immer';
import PropTypes from 'prop-types';

import { AuthService } from '@asteria/backend-utils-services';

import Analytics from '@asteria/utils-analytics';
import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import { decode } from '@asteria/utils-funcs/jwt';
import '@asteria/utils-graphql';

import ErrorWrapper from './error';
import Widget from './widget';

import '@asteria/themes/base/main.scss';

import { useTheme } from '@asteria/themes';

Configuration.apply();
cssVars({});
enableES5();

const getPartnerIdFromToken = (token) => decode(token)?.partnerId;

const AsteriaWidget = (props) => {
	const {
		accessToken: externalAccessToken,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		theme = 'swedbank',
	} = props;

	let { partnerId } = props;
	const [accessToken, setAccessToken] = useState(externalAccessToken);

	useEffect(() => {
		if (demo) {
			AuthService.auth
				.login({
					useTokenFromContext: false,
					username: 'demo.swedbank@asteria.ai',
					password: 'testar',
					partnerId,
				})
				.then(({ data }) => data)
				.then((data) => {
					const { accessToken } = data;
					setAccessToken(accessToken);
				})
				.catch(() => {});
		}
	}, [demo, partnerId]);

	useTheme(theme);

	if (!accessToken) {
		return null;
	}

	Configuration.setURLByToken(accessToken);

	partnerId = partnerId || getPartnerIdFromToken(accessToken);

	Analytics.accessToken = accessToken;
	Analytics.mode = analytics ? 'api' : 'console';
	Analytics.init();
	Analytics.event('load', {});

	return (
		<ErrorWrapper>
			<Widget
				accessToken={accessToken}
				language={languageCode}
				partnerId={partnerId}
			/>
		</ErrorWrapper>
	);
};

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	theme: PropTypes.string,
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			callback={callback}
			theme={theme}
		/>,
		element,
	);

	return true;
};

const createErp = async (element, config) =>
	create(element, { ...config, route: '/integrations/status' });

const cleanup = (element) => {
	ReactDOM.unmountComponentAtNode(element);
};

export default create;
export { AsteriaWidget, cleanup, create, createErp };
