import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Progress from '@asteria/component-core/progress';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import List, { ListGroup, ListHeader, ListItem } from '@asteria/component-list';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Integration from './Integration';

import './list.scss';

const selectors = {
	integrations: createSelector(
		(store) => store?.integrations?.items ?? [],
		(_, type) => type,
		(objects, type) =>
			objects
				.filter((object) => object?.type === type)
				.map((object) => object?._id ?? object?.id),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	hasIntegrations: createSelector(
		(store) => store?.integrations?.items ?? [],
		(_, hasBankFeature) => hasBankFeature,
		(objects, hasBankFeature) => {
			if (hasBankFeature) {
				return objects.length > 0;
			}

			return (
				objects.filter((object) => object?.type === 'erp').length > 0
			);
		},
	),
};

const IntegrationGroup = (props) => {
	const { type, loading, onAction, className } = props;

	const integrations = useSelector((store) =>
		selectors.integrations(store, type),
	);

	const handleCreate = useCallback(
		() => onAction?.('add', { type: type }),
		[onAction, type],
	);

	return (
		<ListGroup className={className}>
			<ListHeader
				className={
					!integrations.length
						? 'asteria-component__list__header-empty'
						: ''
				}
			>
				<ListItem>
					<Title>
						{TranslationService.get([
							'integrations.list.title',
							`integrations.list.${type}.title`,
						])}
					</Title>
				</ListItem>
			</ListHeader>
			{loading ? (
				<Progress progress={-1} />
			) : !integrations.length ? (
				<ListItem className="asteria-component__list__item--empty">
					<Text size="sm">
						{TranslationService.get([
							'integrations.list.title.empty',
							`integrations.list.${type}.title.empty`,
						])}
					</Text>
				</ListItem>
			) : (
				integrations.map((id) => (
					<Integration key={id} id={id} onAction={onAction} />
				))
			)}
			<ListItem className="asteria-component__list__item--create-integration">
				<Button
					analyticsKey="integrations.add.erp"
					variant="secondary"
					label={TranslationService.get([
						`integrations.list.add`,
						`integrations.list.${type}.add`,
					])}
					size="sm"
					onClick={handleCreate}
					tooltip={TranslationService.get([
						`integrations.list.add.tooltip`,
						`integrations.list.${type}.add.tooltip`,
					])}
				/>
			</ListItem>
		</ListGroup>
	);
};

const IntegrationsList = (props) => {
	const { loading, onClose, onAction } = props;

	const hasBankFeature = useFeature('bank-integrations');

	return (
		<Wrapper className="asteria-component__wrapper--integrations-list">
			<Header onClose={onClose}>
				{TranslationService.get('integrations.list.header')}
			</Header>
			<Content scroll>
				<List
					className={cn(`asteria-component__integration-list`)}
					size="lg"
					applyTypographySizes={false}
				>
					<IntegrationGroup
						className="asteria-component__list__group--erp"
						type="erp"
						loading={loading}
						onAction={onAction}
					/>
					{hasBankFeature ? (
						<IntegrationGroup
							className="asteria-component__list__group--bank"
							type="bank"
							loading={loading}
							onAction={onAction}
						/>
					) : null}
				</List>
			</Content>
		</Wrapper>
	);
};

IntegrationsList.propTypes = {
	integrations: PropTypes.arrayOf(PropTypes.shape(Integration.propTypes)),
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	loading: PropTypes.bool,
};

export default IntegrationsList;
