import React from 'react';

const GridItemContext = React.createContext({
	hide: () => null,
	show: () => null,
	showFull: () => null,
	displayBox: () => null,
	displayModal: () => null,
	closeModal: () => null,
	id: null,
});

export default GridItemContext;
