import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Cell = (props) => {
	const { className, children } = props;

	return (
		<div
			className={cn('asteria-component__invoice-search__cell', className)}
		>
			{children}
		</div>
	);
};

Cell.displayName = 'Cell';

Cell.propTypes = { className: PropTypes.string, children: PropTypes.node };

export default Cell;
