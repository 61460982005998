import React from 'react';

import PropTypes from 'prop-types';

import Progress from '@asteria/component-core/progress';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Loading = (props) => {
	const { onClose, className, name = 'init' } = props;

	React.useLayoutEffect(() => {
		const classNames = [
			`asteria--state-loading`,
			`asteria-loading--integration-${name}`,
		];

		const node = document.querySelector('.asteria-wrapper');

		node?.classList?.add?.(...classNames);

		return () => {
			node?.classList?.remove?.(...classNames);
		};
	}, [name]);

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'xs' }),
		[],
	);

	return (
		<Dialog
			className={cn(
				'asteria-component__integration-loading-dialog',
				className,
			)}
			onEnd={onClose}
			placement="bottom-right"
		>
			<Wrapper>
				<Header
					onClose={onClose}
					verticalAlign="top"
					postfix={HeaderPostfix}
				>
					<Title size="sm">
						{TranslationService.get(
							[
								'dialog.integration.importing.title',
								`dialog.integration.importing.${name}.title`,
							],
							// eslint-disable-next-line spellcheck/spell-checker
							'Vi hämtar och analyserar dina kategorier och transaktioner',
						)}
					</Title>
				</Header>
				<Content>
					<Progress progress={-1} />
					<Text>
						{TranslationService.get(
							[
								'dialog.integration.importing.text',
								`dialog.integration.importing.${name}.text`,
							],
							// eslint-disable-next-line spellcheck/spell-checker
							'Inläsning av dina transaktioner från internetbanken pågår. Du kan självklart logga ut eller göra annat under tiden.',
						)}
					</Text>
				</Content>
			</Wrapper>
		</Dialog>
	);
};

Loading.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	name: PropTypes.string,
};

export default Loading;
