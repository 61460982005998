import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Feedback from './Feedback';
import LanguageSelector from './LanguageSelector';
import Navigation from './Navigation';

import './styles.scss';

const LayoutFooter = (props) => {
	const { className } = props;

	return (
		<div
			key="footer-content"
			className={cn('asteria-widget__layout-footer', className)}
		>
			<div className="asteria-widget__layout-footer__content">
				<Group
					flex
					direction="horizontal"
					horizontalAlign="space-between"
				>
					<Group
						className="asteria-widget__layout-footer__section-left"
						direction="horizontal"
						horizontalAlign="left"
						verticalAlign="center"
					>
						<Navigation />
						<Feedback />
					</Group>
					<Group
						className="asteria-widget__layout-footer__section-right"
						direction="horizontal"
						horizontalAlign="right"
						verticalAlign="center"
					>
						<LanguageSelector />
						<Text size="sm">
							{TranslationService.get(
								'layout.footer.copyright',
								undefined,
								{ today: new Date() },
							)}
						</Text>
					</Group>
				</Group>
			</div>
		</div>
	);
};

LayoutFooter.displayName = 'LayoutFooter';

LayoutFooter.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default LayoutFooter;
