import React, { useContext, useEffect, useState } from 'react';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import store from '@asteria/datalayer';
import { setLanguage } from '@asteria/datalayer/stores/language';

import LoadingScreen from '@asteria/layout/loading';

import { AuthContext } from '../context';
import fetchActiveCompany from '../logic/funcs/fetchActiveCompany';
import fetchActiveFeatures from '../logic/funcs/fetchActiveFeatures';
import fetchConfig from '../logic/funcs/fetchConfig';
import fetchMe from '../logic/funcs/fetchMe';
import { fetch as fetchIntegrations } from '../logic/funcs/integrations';

import Languages from './language';

const selectors = {
	language: createSelector(
		(state) => state?.language?.language,
		(value) => value ?? 'sv',
	),
	integrations: createSelector(
		(store) => store?.integrations?.items ?? [],
		(value) =>
			value.filter(
				(object) =>
					object?.config?.connected &&
					(!object?.status?.state ||
						object?.status?.state === 'IDLE'),
			).length,
	),
};

const DataLoader = (props) => {
	const { children, loader } = props;

	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const {
		accessToken,
		partnerId,
		themeId,
		language: contextLanguage,
	} = useContext(AuthContext);

	const language = useSelector(selectors.language);
	const integrations = useSelector(selectors.integrations);

	useEffect(() => {
		if (!accessToken || !partnerId) {
			return;
		}

		const options = {
			token: accessToken, // used for fetchIntegrations
			accessToken: accessToken,
			dispatch: dispatch,
		};

		Promise.all([
			fetchActiveFeatures({ ...options, partnerId: partnerId }),
			fetchMe(options),
			fetchConfig(options),
			fetchActiveCompany(options),
			fetchIntegrations(options),
		])
			.then(() => setLoading(false))
			.catch(() => {});
	}, [accessToken, partnerId, themeId, language, integrations, dispatch]);

	useEffect(() => {
		if (!contextLanguage) {
			return;
		}

		dispatch(setLanguage(contextLanguage));
	}, [contextLanguage]);

	if (loading) {
		if (!loader) {
			return null;
		}
		return <LoadingScreen />;
	}

	return children;
};

const DataLayer = (props) => {
	const { children, loader } = props;
	return (
		<Provider store={store}>
			<Languages>
				<DataLoader loader={loader}>{children}</DataLoader>
			</Languages>
		</Provider>
	);
};

DataLayer.propTypes = {
	children: PropTypes.node,
};

export default DataLayer;
