import React from 'react';

import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import { TableCell } from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import { useChat } from '../hooks';

import '../styles.scss';

const CellEmpty = React.memo(() => {
	return (
		<TableCell className="asteria--type-empty">
			<Text>
				{TranslationService.get([
					'page.invoices.pending.table.cell.empty',
				])}
			</Text>
		</TableCell>
	);
});

CellEmpty.displayName = 'CellEmpty';

const CellLoading = React.memo(() => {
	return <TableCell className="asteria--type-loading" />;
});

CellLoading.displayName = 'CellLoading';

const CellOptions = React.memo((props) => {
	const { onAction, id } = props;

	const onClick = React.useCallback(
		() => onAction('open:message', id),
		[id, onAction],
	);

	return (
		<TableCell className="asteria--type-options">
			<Button icon="chat" onClick={onClick} />
		</TableCell>
	);
});

CellOptions.displayName = 'CellOptions';
CellOptions.propTypes = { onAction: PropTypes.func, id: PropTypes.string };

const CellMessages = React.memo((props) => {
	const { message, onAction, id, unread } = props;

	const hasChat = useChat();

	const onClick = React.useCallback(
		() => onAction('open:message', id),
		[id, onAction],
	);

	const tooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get(
				'page.invoices.pending.table.message.tooltip',
			),
			variant: 'alt',
		}),
		[],
	);

	return (
		<TableCell className="asteria--type-messages">
			{hasChat && unread ? (
				<Badge
					size="sm"
					icon="warning"
					onClick={onClick}
					tooltip={tooltip}
				/>
			) : null}
			<Text>{message}</Text>
		</TableCell>
	);
});

CellMessages.displayName = 'CellMessages';
CellMessages.propTypes = {
	message: PropTypes.string,
	onAction: PropTypes.func,
	id: PropTypes.string,
	unread: PropTypes.bool,
};

const CellPDF = React.memo((props) => {
	const { uri } = props;

	return (
		<TableCell className="asteria--type-pdfUri">
			<Button
				variant="link"
				href={TranslationService.get(
					'page.invoices.pending.table.open',
					undefined,
					{ uri: uri },
				)}
				target="__blank"
				icon="document"
				iconSize="md"
				label={TranslationService.get(
					'page.invoices.pending.table.open.document',
				)}
			/>
		</TableCell>
	);
});

CellPDF.displayName = 'CellPDF';
CellPDF.propTypes = { uri: PropTypes.string };

const Cell = React.memo((props) => {
	const { name, data, onAction, loading, empty, format } = props;

	const defaultValue = format ? format(data) : data?.[name];

	const tooltip = React.useMemo(
		() =>
			TranslationService.hasKey([
				`page.invoices.pending.table.${name}.tooltip`,
				`page.invoices.pending.table.${name}.${data?.[name]}.tooltip`,
			])
				? {
						tooltip: TranslationService.get(
							[
								`page.invoices.pending.table.${name}.tooltip`,
								`page.invoices.pending.table.${name}.${data?.[name]}.tooltip`,
							],
							defaultValue,
							{ data: defaultValue },
						),
						align: 'center',
				  }
				: null,
		[data, defaultValue, name],
	);

	if (empty) {
		return <CellEmpty />;
	}

	if (loading) {
		return <CellLoading />;
	}

	if (name === 'options') {
		return <CellOptions onAction={onAction} id={data?._id ?? data?.id} />;
	}

	if (name === 'messages') {
		const messages = data?.messages ?? [];

		const last = messages?.[messages?.length - 1];
		const unread = messages.some(
			({ read, userType }) => !read && userType !== 'UserToken',
		);

		return (
			<CellMessages
				onAction={onAction}
				id={data?._id ?? data?.id}
				message={last?.message}
				unread={unread}
			/>
		);
	}

	if (name === 'pdfUri') {
		return <CellPDF uri={data?.pdfUri} />;
	}

	return (
		<TableCell className={`asteria--type-${name}`} tooltip={tooltip}>
			<Text>
				{TranslationService.get(
					[
						`page.invoices.pending.table.${name}`,
						`page.invoices.pending.table.${name}.${data?.[name]}`,
					],
					defaultValue,
					{ data: defaultValue },
				)}
			</Text>
		</TableCell>
	);
});

Cell.displayName = 'Cell';

Cell.propTypes = {
	name: PropTypes.string,
	data: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
	empty: PropTypes.bool,

	format: PropTypes.func,
};

export default Cell;
