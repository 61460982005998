import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const SetupStep = (props) => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const [loading, setLoading] = React.useState(false);

	const form = useSelector(IntegrationStore.selectors.form, (a, b) =>
		isEqual(a, b),
	);

	const type = 'erp';
	const key = 'visma.administration';

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(async () => {
		let ID = form?.$id;

		if (!ID) {
			setLoading(true);

			const response = await onSubmit?.('integrations:create', {
				type: type,
				key: key,
			});

			setLoading(false);

			ID = response?._id ?? response?.id;
		}

		return onAction?.(
			'go',
			`/integrations/erp/visma.administration/${ID}/token`,
		);
	}, [form?.$id, onAction, onSubmit]);

	return (
		<BaseStep
			{...props}
			step="setup"
			onBack={onBack}
			onNext={onNext}
			loading={loading}
		/>
	);
};

SetupStep.displayName = 'SetupStep';

SetupStep.propTypes = { className: PropTypes.string };

export default SetupStep;
