import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';
import { stateClasses, statusClasses } from '@asteria/component-core/utils';

import { IntegrationErrorButton } from '@asteria/component-integrations-v2/components/Integration';
import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Actions from '../List/Actions';

import './styles.scss';

const UpdateBox = React.memo((props) => {
	const { className, id, onAction, onSubmit, variant } = props;

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const type = integration?.type;
	const key = integration?.key;
	const status = integration?.disabled
		? 'disabled'
		: integration?.status?.state?.toLowerCase?.();

	const flow = useFlow(type, key);

	const hasDisabled = status === 'disabled';
	const hasSuccessStatus =
		status === 'idle' && integration?.config?.connected;
	const hasImportingStatus = status === 'importing';
	const hasInitiatingStatus =
		status === 'initiating' ||
		(status === 'idle' && !integration?.config?.connected);
	const hasErrorStatus =
		status === 'error' || integration?.config?.errors?.length;

	return (
		<Group
			direction="horizontal"
			verticalAlign="top"
			horizontalAlign="space-between"
			className={cn(
				'asteria-component__onboarding-box',
				{ [`asteria-integration--variant-${variant}`]: variant },
				statusClasses({
					success: hasSuccessStatus || hasImportingStatus,
					error: hasErrorStatus,
					warning: hasInitiatingStatus,
				}),
				stateClasses({
					disabled: hasDisabled,
				}),
				className,
			)}
		>
			{hasSuccessStatus || hasImportingStatus ? (
				<Icon icon="check" />
			) : null}
			{/* {hasImportingStatus ? <Spinner /> : null} */}
			<div className="asteria-component__onboarding-box__content">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
					className="asteria-component__onboarding-box__content__info"
				>
					{integration?.lastSync ? (
						<Text>
							{TranslationService.get(
								[
									'integrations.list.item.lastSync',
									`integrations.list.item.${integration?.name}.lastSync`,
									`integrations.list.item.${type}.lastSync`,
									`integrations.list.item.${key}.lastSync`,
									`integrations.list.item.${type}.${key}.lastSync`,
									'onboarding.grid.integration.lastSync',
									`onboarding.grid.integration.${integration?.name}.lastSync`,
									`onboarding.grid.integration.${type}.lastSync`,
									`onboarding.grid.integration.${key}.lastSync`,
									`onboarding.grid.integration.${type}.${key}.lastSync`,
									`onboarding.grid.integration.${status}.lastSync`,
									`onboarding.grid.integration.${status}.${integration?.name}.lastSync`,
									`onboarding.grid.integration.${status}.${type}.lastSync`,
									`onboarding.grid.integration.${status}.${key}.lastSync`,
									`onboarding.grid.integration.${status}.${type}.${key}.lastSync`,
								],
								undefined,
								integration,
							)}
						</Text>
					) : (
						<Text>
							{TranslationService.get(
								[
									'onboarding.grid.integration.lastSync.empty',
									`onboarding.grid.integration.${integration?.name}.lastSync.empty`,
									`onboarding.grid.integration.${type}.lastSync.empty`,
									`onboarding.grid.integration.${key}.lastSync.empty`,
									`onboarding.grid.integration.${type}.${key}.lastSync.empty`,
									`onboarding.grid.integration.${status}.lastSync.empty`,
									`onboarding.grid.integration.${status}.${integration?.name}.lastSync.empty`,
									`onboarding.grid.integration.${status}.${type}.lastSync.empty`,
									`onboarding.grid.integration.${status}.${key}.lastSync.empty`,
									`onboarding.grid.integration.${status}.${type}.${key}.lastSync.empty`,
								],
								undefined,
								integration,
							)}
						</Text>
					)}
					<IntegrationErrorButton
						integration={integration}
						size="sm"
					/>
				</Group>

				<Contenter content={flow?.info?.box} />
			</div>
			<Group className="asteria-component__onboarding-box__actions">
				{hasInitiatingStatus || hasImportingStatus ? (
					<>
						<Button size="lg" loading />
						<Actions
							onAction={onAction}
							onSubmit={onSubmit}
							size="sm"
							object={integration}
						/>
					</>
				) : (
					<Actions
						onAction={onAction}
						onSubmit={onSubmit}
						size="sm"
						object={integration}
					/>
				)}
			</Group>
		</Group>
	);
});

UpdateBox.displayName = 'UpdateBox';

UpdateBox.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	variant: PropTypes.oneOf(['create', 'update']),
};

export default UpdateBox;
