import React from 'react';

import {
	addDays,
	differenceInCalendarDays,
	eachDayOfInterval,
	endOfMonth,
	endOfWeek,
	isFuture,
	isPast,
	isSameDay,
	isToday,
	isWeekend,
	startOfMonth,
	startOfWeek,
} from 'date-fns';

import { stateClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import DatepickerContext from '../../../context';
import { useDateFNSLocale } from '../../hooks';
import Cell from '../Cell';

import '../styles.scss';

const Day = React.memo(() => {
	const { isFutureDisabled, isPastDisabled } =
		React.useContext(DatepickerContext);

	const locale = useDateFNSLocale();

	const dates = React.useMemo(() => {
		let dates = eachDayOfInterval({
			start: startOfMonth(new Date()),
			end: endOfMonth(new Date()),
		});

		const startDate = dates[0];
		const endDate = dates.slice(-1)[0];

		if (!isSameDay(startDate, startOfWeek(startDate, { locale: locale }))) {
			const date = startOfWeek(startDate, { locale: locale });
			const difference = differenceInCalendarDays(startDate, date);

			dates = []
				.concat(
					new Array(difference)
						.fill()
						.map((_, index) => addDays(date, index)),
				)
				.concat(dates);
		}

		if (!isSameDay(endDate, endOfWeek(endDate, { locale: locale }))) {
			const date = endOfWeek(endDate, { locale: locale });
			const difference = differenceInCalendarDays(date, endDate);

			dates = []
				.concat(dates)
				.concat(
					new Array(difference)
						.fill()
						.map((_, index) => addDays(endDate, index + 1)),
				);
		}

		return dates;
	}, [locale]);

	return (
		<div className="asteria-component__form-v2__datepicker-content__calendar-content">
			{dates.map((date) => {
				const today = isToday(date);
				const past = !today && isPast(date);
				const future = !today && isFuture(date);
				const weekend = isWeekend(date);

				return (
					<Cell
						key={date}
						date={date}
						type="day"
						className={cn(
							{
								'asteria-datepicker--state-today': today,
								'asteria-datepicker--state-past': past,
								'asteria-datepicker--state-future': future,
								'asteria-datepicker--state-weekend': weekend,
								'asteria-datepicker--state-selected': null,
							},
							stateClasses({
								disabled:
									(isFutureDisabled && future) ||
									(isPastDisabled && past),
							}),
						)}
					/>
				);
			})}
		</div>
	);
});

Day.displayName = 'Day';
Day.propTypes = {};

export default Day;
