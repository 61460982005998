import PropTypes from 'prop-types';

function Login() {
	return null;
}

Login.displayName = 'Login';
Login.propTypes = {
	partnerId: PropTypes.string,
	setToken: PropTypes.func,
	setLanguage: PropTypes.func,
};

export default Login;
