import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isAfter, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import Chip from '@asteria/component-chip';
import { Datepicker, Input, useFormValues } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const ResetButton = React.memo(function ResetButton({ state, onResetState }) {
	const [search, date] = useFormValues({ name: ['search', 'date'] });

	const { reset } = useFormContext();

	const onReset = React.useCallback(() => {
		onResetState?.();

		reset(
			{},
			{
				keepDefaultValues: false,
				keepDirty: false,
				keepDirtyValues: false,
				keepErrors: false,
				keepIsSubmitSuccessful: false,
				keepIsSubmitted: false,
				keepIsValid: false,
				keepSubmitCount: false,
				keepTouched: false,
				keepValues: false,
			},
		);
	}, [onResetState, reset]);

	if (!(state !== 'ALL' || search || date)) {
		return null;
	}

	return (
		<Button
			variant="link"
			label={TranslationService.get([
				'invoices.search.reset.label',
				'page.reports.table.search.reset.label',
			])}
			onClick={onReset}
			className="asteria--variant-reset"
		/>
	);
});

ResetButton.propTypes = {
	state: PropTypes.string,
	onResetState: PropTypes.func,
};

const ChipDate = React.memo(function ChipDate() {
	const date = useFormValues({ name: 'date' });

	const { resetField } = useFormContext();

	const onDismiss = React.useCallback(() => {
		resetField('date');
	}, [resetField]);

	if (!date) {
		return null;
	}

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				[
					date?.startDate || date?.startDate === date?.endDate
						? 'invoices.search.quick.date.start.label'
						: null,
					date?.endDate
						? 'invoices.search.quick.date.end.label'
						: null,
					date?.startDate &&
					date?.endDate &&
					date?.startDate !== date?.endDate
						? 'invoices.search.quick.date.label'
						: null,
				],
				undefined,
				{ startDate: date?.startDate, endDate: date?.endDate },
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
});

const QuickFilters = React.memo((props) => {
	const { state, onAction, onSubmit } = props;

	const timestamp = useSelector(InvoiceStore.selectors.reports.timestamp);

	const { data: unreadReports } = useQuery({
		queryKey: ['reports', 'unread', 'length'],
		queryFn: async () => {
			const response = await onSubmit?.('reports:list');

			return (response ?? []).filter((object) =>
				isAfter(parseISO(object?.createdAt), parseISO(timestamp)),
			).length;
		},
		placeholderData: false,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		enabled: !!timestamp,

		keepPreviousData: true,
	});

	const onChipClick = React.useMemo(
		() => (type) => () => onAction?.('chip:select', type),
		[onAction],
	);

	return (
		<div className="asteria-component__quick-filters">
			<Input
				name="search"
				icon="magnifier"
				placeholder={TranslationService.get([
					`page.reports.table.quick.search.placeholder`,
				])}
				iconSize="md"
			/>
			<Datepicker name="date" variant="range" skipVisibleValue iconOnly />
			<div className="asteria-component__quick-filters-chips">
				<ChipDate />
				{unreadReports ? (
					<>
						<Chip
							size="sm"
							label={TranslationService.get([
								`page.reports.table.quick.chip.label`,
								`page.reports.table.quick.chip.all.label`,
							])}
							onClick={onChipClick('ALL')}
							active={state === 'ALL'}
						/>
						<Chip
							size="sm"
							label={TranslationService.get(
								[
									`page.reports.table.quick.chip.label`,
									`page.reports.table.quick.chip.new.label`,
								],
								undefined,
								{ count: unreadReports },
							)}
							onClick={onChipClick('NEW')}
							active={state === 'NEW'}
						/>
					</>
				) : null}
			</div>
			<ResetButton state={state} onResetState={onChipClick('ALL')} />
		</div>
	);
});

QuickFilters.displayName = 'QuickFilters';
QuickFilters.propTypes = {
	state: PropTypes.oneOf(['ALL', 'NEW']),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default QuickFilters;
