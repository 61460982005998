import AsteriaCore from '@asteria/core';

import { TransactionService } from '@asteria/backend-utils-services';

import { setCurrencies } from '@asteria/datalayer/stores/app';

const CURRENCY_FIELDS = `
	  currency
	  deposit {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }

	  withdraw {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
		exposure {
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		tags {
		  tag {
			id
			name
		  }
		  type
		  parts {
			status
			count
			original {
			  total
			  currency
			}
			display {
			  total
			  currency
			}
		  }
		  original {
			total
			currency
		  }
		  display {
			total
			currency
		  }
		}
		count
	  }

	  exposure {
		original {
		  total
		  currency
		}
		display {
		  total
		  currency
		}
	  }

	  risk {
		  original {
			  total
			  currency
		  }
		  display {
			  total
			  currency
		  }
		  risk
		  alert
	  }

`;

TransactionService.transaction.extend({
	getQuery: (fields = CURRENCY_FIELDS) => `
    query Currencies($filters: TransactionFiltersInput){
        transactionCurrencies(filters: $filters) {
            ${fields}
        }
    }
  `,
	key: `transactionCurrencies`,
	loggerMethod: `services.transactionService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.transactionService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export default async ({ dispatch, accessToken }) => {
	try {
		const currencies =
			await TransactionService.transaction.extension.transactionCurrencies(
				{
					fields: `
					id: currency
					code: currency
					currency
					deposit {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
						exposure {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						tags {
						tag {
							id
							name
						}
						type
						parts {
							status
							count
							original {
							total
							currency
							}
							display {
							total
							currency
							}
						}
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						count
					}

					withdraw {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
						exposure {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						tags {
						tag {
							id
							name
						}
						type
						parts {
							status
							count
							original {
							total
							currency
							}
							display {
							total
							currency
							}
						}
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						count
					}

					exposure {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
					}

					risk {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						risk
						alert
					}
				`,
				},
				{ token: accessToken },
			);

		if (dispatch) {
			dispatch(setCurrencies(currencies));
		}
		return currencies;
	} catch (e) {
		return null;
	}
};
