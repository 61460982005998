import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import '../styles.scss';

const DiscardButton = React.memo(function DiscardButton(props) {
	const { object } = props;

	const dispatch = useDispatch();

	const onClick = React.useCallback(() => {
		dispatch(InvoiceStore.select(object));
	}, [dispatch, object]);

	return <Button icon="remove" onClick={onClick} />;
});

DiscardButton.propTypes = { object: PropTypes.object };

export default DiscardButton;
