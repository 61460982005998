import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Option from './Option';

import './styles.scss';

const Select = (props) => {
	const { className } = props;

	return (
		<div className={cn('asteria-component__form-v2__select', className)}>
			Hello
		</div>
	);
};

Select.displayName = 'Select';

Select.propTypes = { className: PropTypes.string };

export default Select;
export { Option };
