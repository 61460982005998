import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { Text, TextGroup } from '@asteria/component-core/typography';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ErrorBadge from './badge';

import './styles.scss';

const HistoryRow = React.memo((props) => {
	const { onAction, id, index } = props;

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, id),
		(a, b) => isEqual(a, b),
	);

	const status = batch?.status;

	const openModal = React.useCallback(() => {
		onAction?.('open:review:modal', { id: id, index: index });
	}, [id, index, onAction]);

	return (
		<Group
			className={cn(
				'asteria-component__invoice-history__list-row',
				`asteria-component__invoice-history__list-row--status__${status?.toLowerCase?.()}`,
			)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="space-between"
			onClick={openModal}
		>
			<TextGroup>
				<Text size="md" className="asteria--type-title">
					{status === 'ERROR' ? (
						<ErrorBadge batch={batch} index={index} />
					) : null}
					{TranslationService.get(
						[
							'invoice.history.row.title',
							`invoice.history.row.${status?.toLowerCase?.()}.title`,
						],
						undefined,
						{ ...batch, index: index },
					)}
				</Text>
				<Text size="sm" className="asteria--type-description">
					{TranslationService.get(
						[
							'invoice.history.row.description',
							`invoice.history.row.${status?.toLowerCase?.()}.description`,
						],
						undefined,
						{ ...batch, index: index },
					)}
				</Text>
			</TextGroup>
		</Group>
	);
});

HistoryRow.displayName = 'HistoryRow';
HistoryRow.propTypes = {
	id: PropTypes.string,
	index: PropTypes.number,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const DropdownLabel = React.memo(() => {
	const hasErrorBatches = useSelector(
		(store) =>
			!!InvoiceStore.selectors.batches(store, { status: 'ERROR' }).length,
		(a, b) => isEqual(a, b),
	);

	return (
		<Group direction="horizontal" verticalAlign="center">
			<Text>
				{TranslationService.get('invoices.history.dropdown.label')}
			</Text>
			{hasErrorBatches ? <Badge size="sm" icon="warning" /> : null}
		</Group>
	);
});

DropdownLabel.displayName = 'DropdownLabel';

const HistoryDropdown = React.memo((props) => {
	const { className, onAction } = props;

	const dispatch = useDispatch();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'open:review:modal') {
				dispatch(
					ModalStore.open({
						type: 'BATCH_REVIEW',
						skipValidation: true,
						data: { id: data.id },
					}),
				);

				return;
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	const batchIDs = useSelector(
		(store) =>
			InvoiceStore.selectors
				.batches(store)
				.map((object) => object?._id ?? object?.id),
		(a, b) => isEqual(a, b),
	);

	const toggle = React.useMemo(
		() => ({
			children: <DropdownLabel />,
			icon: 'chevron-down',
			iconActive: 'chevron-up',
			iconPosition: 'last',
		}),
		[],
	);

	if (!batchIDs || !batchIDs?.length) {
		return null;
	}

	return (
		<>
			<div
				className={cn(
					'asteria-component__invoice-history-wrapper',
					className,
				)}
			>
				<Dropdown
					className="asteria--type-invoice-history"
					variant="assist"
					toggle={toggle}
					placement="bottom-end"
					scroll
				>
					{batchIDs.map((id, index) => (
						<DropdownItem key={id}>
							<HistoryRow
								id={id}
								index={index + 1}
								onAction={handleAction}
							/>
						</DropdownItem>
					))}
				</Dropdown>
			</div>
		</>
	);
});

HistoryDropdown.displayName = 'HistoryDropdown';
HistoryDropdown.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default HistoryDropdown;
