import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import cssVars from 'css-vars-ponyfill';
import { createMemoryHistory } from 'history';
import { enableES5 } from 'immer';
import PropTypes from 'prop-types';

import Analytics from '@asteria/utils-analytics';
import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import { decode } from '@asteria/utils-funcs/jwt';
import '@asteria/utils-graphql';

import ErrorWrapper from './error';
import Widget from './widget';

import '@asteria/themes/base/main.scss';

import { useTheme } from '@asteria/themes';

Configuration.apply();
cssVars({});
enableES5();

const getPartnerIdFromToken = (token) => decode(token)?.partnerId;

const AsteriaWidget = ({
	accessToken: externalAccessToken,
	languageCode = 'sv',
	analytics = true,
	partnerId: externalPartnerId,
	theme = 'treyd',
	callback,
} = {}) => {
	const [history, setHistory] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [accessToken] = useState(externalAccessToken);

	useEffect(() => {
		const h = createMemoryHistory();
		callback?.('create.history', h);
		setHistory(h);
	}, [callback]);

	const themeCallback = React.useCallback(() => {
		setLoaded(true);
	}, []);

	useTheme(theme, themeCallback);

	if (!accessToken || !loaded) {
		return null;
	}

	Configuration.setURLByToken(accessToken);

	const partnerId = externalPartnerId || getPartnerIdFromToken(accessToken);

	Analytics.accessToken = accessToken;
	Analytics.mode = analytics ? 'api' : 'console';
	Analytics.init();
	Analytics.event('load', {});

	return (
		<ErrorWrapper>
			<Widget
				accessToken={accessToken}
				language={languageCode}
				partnerId={partnerId}
				history={history}
				callback={callback}
				loader={false}
			/>
		</ErrorWrapper>
	);
};

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	theme: PropTypes.string,
	callback: PropTypes.func,
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			callback={callback}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			theme={theme}
		/>,
		element,
	);

	return true;
};

const createErp = async (element, config) =>
	create(element, { ...config, route: '/integrations/status' });

const cleanup = (element) => {
	ReactDOM.unmountComponentAtNode(element);
};

export default create;
export { AsteriaWidget, Widget, cleanup, create, createErp };
