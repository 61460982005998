import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper from '@asteria/component-core/wrapper';
import Content from '@asteria/component-core/wrapper/content';
import Header from '@asteria/component-core/wrapper/header';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const StepSuccess = React.memo((props) => {
	const { className, onReset, integration } = props;

	return (
		<Wrapper
			className={cn(
				'asteria-component__missing-integration',
				'asteria--step-success',
				className,
			)}
			scroll
		>
			<Header>
				<Icon icon="check" />
				<Title>
					{TranslationService.get(
						[
							'integration.missing.title',
							'integration.missing.success.title',
						],
						undefined,
						{ integration: integration },
					)}
				</Title>
			</Header>
			<Content scroll>
				<Text>
					{TranslationService.get(
						[
							'integration.missing.description',
							'integration.missing.success.description',
						],
						undefined,
						{ integration: integration },
					)}
				</Text>
				<Group verticalAlign="center" horizontalAlign="center">
					<Button
						variant="link"
						label={TranslationService.get(
							[
								'integration.missing.button.reset',
								'integration.missing.success.button.reset',
							],
							undefined,
							{ integration: integration },
						)}
						onClick={onReset}
					/>
				</Group>
			</Content>
		</Wrapper>
	);
});

StepSuccess.displayName = 'StepSuccess';
StepSuccess.propTypes = {
	className: PropTypes.string,
	onReset: PropTypes.func,
	integration: PropTypes.string,
};

const StepForm = React.memo((props) => {
	const { className, onSubmit } = props;

	return (
		<Wrapper
			className={cn(
				'asteria-component__missing-integration',
				'asteria--step-form',
				className,
			)}
			scroll
		>
			<Header>
				{TranslationService.get([
					'integration.missing.title',
					'integration.missing.form.title',
				])}
			</Header>
			<Content scroll>
				<Text>
					{TranslationService.get([
						'integration.missing.description',
						'integration.missing.form.description',
					])}
				</Text>
				<Form onSubmit={onSubmit}>
					<FormWrapper>
						<Input
							type="text"
							name="integration"
							label={TranslationService.get([
								'integration.missing.input.label',
								'integration.missing.form.input.label',
							])}
							placeholder={TranslationService.get([
								'integration.missing.input.placeholder',
								'integration.missing.form.input.placeholder',
							])}
						/>
						<Button
							variant="primary"
							type="submit"
							label={TranslationService.get([
								'button.submit',
								'integration.missing.button.submit',
								'integration.missing.form.button.submit',
							])}
						/>
					</FormWrapper>
				</Form>
			</Content>
		</Wrapper>
	);
});

StepForm.displayName = 'StepForm';
StepForm.propTypes = { className: PropTypes.string, onSubmit: PropTypes.func };

const initialState = { sent: false, integration: null };

const MissingIntegration = React.memo((props) => {
	const { className, onAction } = props;

	const [{ sent, integration }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'SENT':
					return {
						...state,
						sent: true,
						integration: action?.payload,
					};

				case 'RESET':
					return initialState;

				default:
					return state;
			}
		},
		initialState,
	);

	const handleSubmit = useCallback(
		async (data) => {
			await onAction?.('box:integration:missing:submit', data);

			dispatch({ type: 'SENT', payload: data?.integration });
		},
		[onAction],
	);

	const handleReset = useCallback(() => {
		dispatch({ type: 'RESET' });
	}, []);

	if (sent) {
		return (
			<StepSuccess
				className={className}
				onReset={handleReset}
				integration={integration}
			/>
		);
	}

	return <StepForm className={className} onSubmit={handleSubmit} />;
});

MissingIntegration.displayName = 'MissingIntegration';

MissingIntegration.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default MissingIntegration;
