export function getGridColumn(name) {
	switch (name) {
		case 'message':
			return `var(--size-invoice-table-cell-message)`;

		case 'invoice-number':
			return `var(--size-invoice-table-cell-number)`;

		case 'client-number':
			return `var(--size-invoice-table-cell-client-number)`;

		case 'client-name':
			return `var(--size-invoice-table-cell-client-name)`;

		case 'invoice-sell':
			return `var(--size-invoice-table-cell-selector)`;

		case 'details':
			return '40px';

		case 'flexible':
			return `var(--size-invoice-table-cell-flexible)`;

		default:
			return `var(--size-invoice-table-cell)`;
	}
}
