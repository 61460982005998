import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import LanguageSelect from '@asteria/component-settings/language';

import { setLanguage } from '@asteria/datalayer/stores/language';

import { TranslationService } from '@asteria/language';
import { getUrlParams } from '@asteria/utils-funcs/app';

import './styles.scss';

const getLanguage = () => {
	const { search } = location;
	const searchParams = getUrlParams(search);

	if (searchParams.lang) {
		return searchParams.lang;
	}

	return localStorage.getItem('asteriaLanguage') || 'sv';
};

const LanguageSelector = () => {
	const dispatch = useDispatch();
	const languageCode = getLanguage();
	const handleChange = useCallback(
		(option) => {
			const code = option?.target?.value;

			if (TranslationService.code === code) {
				dispatch(setLanguage(code));
				return;
			}

			localStorage.setItem('asteriaLanguage', code);
			TranslationService.code = code;

			dispatch(setLanguage(code));

			window.location.reload();
		},
		[dispatch],
	);

	return (
		<LanguageSelect
			className="asteria-widget__layout-footer__select-language"
			onChange={handleChange}
			language={languageCode}
		/>
	);
};

LanguageSelector.displayName = 'LanguageSelector';

LanguageSelector.propTypes = {};

export default LanguageSelector;
