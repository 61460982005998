import { IntegrationGateway } from '@asteria/backend-utils-services';

import { setIntegrations } from '@asteria/datalayer/stores/integrations';

export const DEFAULT_FIELDS = `
    _id
    id
    key
    name: key
    type
    lastSync
    disabled
    status {
        state
				progress
				entities
    }
    config {
        connected
        server
        client
        errors {
            code
            message
        }
    }
    actions {
        _id
        action
        status
        data
    }
`;

/**
 * @param { { token: string, variables?: object, fields?: string, dispatch?: unknown } } options
 */
export const fetch = async (options) => {
	const {
		token,
		fields = DEFAULT_FIELDS,
		variables = {},
		dispatch,
	} = options;

	const response = await IntegrationGateway.integration
		.fetch({ fields: fields, isBulk: true, ...variables }, { token: token })
		.catch(() => {
			return [];
		});

	dispatch?.(setIntegrations(response));

	return response;
};

export const addCustomERP = async (options) => {
	const { token, variables } = options;

	return IntegrationGateway.integration
		.addCustomERP(
			{
				input: variables,
				fields: `
					ok
					error
					erp {
						id
						email
						title
						version
					}
				`,
			},
			{ token: token },
		)
		.catch(() => null);
};

export const remove = async (options) => {
	const { token, _id, dispatch } = options;

	await IntegrationGateway.integration
		.remove({ ids: _id }, { token: token })
		.catch(() => null);

	return fetch({ token: token, dispatch: dispatch });
};
