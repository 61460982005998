import React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import IntegrationLoading from '@asteria/component-integrations/updating';

import { useSubscription } from '@asteria/utils-websocket/hooks';

import { AuthContext } from '../context';

const IntegrationsLogic = (props) => {
	const { accessToken } = useContext(AuthContext);
	const [integrations, setIntegrations] = useState([]);
	const [updated, setUpdated] = useState({});
	const [hide, setHide] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (accessToken) {
				IntegrationGateway.integration
					.fetch(
						{
							fields: `
							id
							name: key
							type
							disabled
							lastSync
							status {
								state
								progress
								entities
							}
							actions {
								_id
								action
								status
								data
							}
						`,
						},
						{ token: accessToken },
					)
					.then((data) => {
						setIntegrations(data);
					})
					.catch(() => {});
			}
		};

		fetchData();

		const timer = setInterval(fetchData, 30 * 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	const isLoading = useMemo(() => {
		return (
			Object.values(
				integrations.reduce((acc, { id, status: { state } }) => {
					acc[id] = updated[id] || state;
					return acc;
				}, {}),
			).includes('INITIATING') ||
			Object.values(updated).includes('INITIATING')
		);
	}, [integrations, updated]);

	const isFetching = useMemo(() => {
		return (
			Object.values(
				integrations.reduce((acc, { id, status: { state } }) => {
					acc[id] = updated[id] || state;
					return acc;
				}, {}),
			).includes('IMPORTING') ||
			Object.values(updated).includes('IMPORTING')
		);
	}, [integrations, updated]);

	const updateStatus = useCallback(
		(update) => {
			const integration = update?.data?.integrationUpdated;
			if (integrations) {
				setUpdated({
					...updated,
					[integration.id]: integration?.status?.state || 'IDLE',
				});
				if (integration) {
					props?.onAction('askForFeedback', {
						feedbackKey: `integration-updated-${integration.id}`,
					});
				}
			}
		},
		[updated],
	);

	useSubscription({
		token: accessToken,
		query: `
			subscription IntegrationUpdated {
				integrationUpdated {
					_id
					status {
						state
					}
				}
			}
		`,
		onNext: updateStatus,
	});

	const onHide = useCallback(() => {
		setHide(true);
	}, []);

	if ((!isLoading && !isFetching) || hide) {
		return null;
	}

	return (
		<IntegrationLoading
			onClose={onHide}
			name={isLoading ? 'init' : 'fetch'}
		/>
	);
};

IntegrationsLogic.propTypes = {
	onAction: PropTypes.func,
};

export default IntegrationsLogic;
