import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import { useAmounts } from '../hooks';

import '../styles.scss';

const ChipAmount = (props) => {
	const { from, to } = props;

	const dispatch = useDispatch();

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'AMOUNT',
				value: { from: from, to: to },
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'AMOUNT',
				value: { from: from, to: to },
			}),
		);
	}, [dispatch, from, to]);

	return (
		<TooltipWrapper
			tooltip={TranslationService.get(
				[
					'invoices.search.quick.amount.tooltip',
					from === to
						? 'invoices.search.quick.amount.same.tooltip'
						: null,
				],
				undefined,
				props,
			)}
		>
			<Chip
				size="sm"
				label={TranslationService.get(
					[
						'invoices.search.quick.amount.label',
						from === to
							? 'invoices.search.quick.amount.same.label'
							: null,
					],
					undefined,
					props,
				)}
				onClick={onClick}
				active={active}
			/>
		</TooltipWrapper>
	);
};

ChipAmount.displayName = 'ChipAmount';
ChipAmount.propTypes = { from: PropTypes.number, to: PropTypes.number };

const ChipAmountWrapper = () => {
	const amounts = useAmounts();

	return amounts.map((object, index) => (
		<ChipAmount key={index} {...object} />
	));
};

ChipAmountWrapper.displayName = 'ChipAmountWrapper';
ChipAmountWrapper.propTypes = {};

export default ChipAmount;
export { ChipAmountWrapper };
