import React, { useCallback, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Modal from '@asteria/component-modal';
import Settings from '@asteria/component-settings';

import { AuthContext } from '../context';

import updateSettings from './funcs/updateSettings';

const SettingsLogic = (props) => {
	const { onClose } = props;
	const { accessToken } = useContext(AuthContext);
	const dispatch = useDispatch();
	const userId = useSelector((state) => state?.app?.user?.id || null);

	const onSave = useCallback(
		(form) => {
			onClose?.();
			updateSettings({
				accessToken,
				id: userId,
				settings: form,
				dispatch,
			});
		},
		[onClose, userId, dispatch],
	);

	return (
		<Modal onClose={onClose} size="sm">
			<Settings onClose={onClose} onSave={onSave} />
		</Modal>
	);
};

export default SettingsLogic;
