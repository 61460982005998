import React from 'react';

import 'currency-flags/dist/currency-flags.min.css';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import './index.scss';

const Stepper = (props) => {
	const { steps, activeStep } = props;

	return (
		<div>
			{steps?.map((step, index) => {
				return (
					<div
						className={
							index + 1 === activeStep
								? 'asteria-component__step step-active'
								: 'asteria-component__step'
						}
						key={index + 1}
					>
						<div>
							<div className="asteria-component__step__circle">
								{index + 1}
							</div>
						</div>
						<div>
							<Text
								size="base"
								className={'asteria-component__step__title'}
							>
								{step}
							</Text>
						</div>
					</div>
				);
			})}
		</div>
	);
};

Stepper.propTypes = {
	steps: PropTypes.array,
	activeStep: PropTypes.number,
};

Stepper.defaultProps = {
	activeStep: 1,
};

export default Stepper;
