import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import { useAnalytics } from '@asteria/component-integrations-v2/flows/Error';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Help from './Help';
import Context from './context';
import { usePlaceholder } from './hooks';

import './styles.scss';

const Error = React.memo((props) => {
	const { integration, error, type: $type } = props;

	const type = $type ?? integration?.type;
	const key = integration?.key;
	const code = error?.code;
	const message = error?.message;

	const title = TranslationService.get(
		[
			'onboarding.linear.error.details.title',
			`onboarding.linear.${type}.error.details.title`,
			`onboarding.linear.${key}.error.details.title`,
			`onboarding.linear.${type}.${key}.error.details.title`,
			`onboarding.linear.error.details.${code}.title`,
			`onboarding.linear.${type}.error.details.${code}.title`,
			`onboarding.linear.${key}.error.details.${code}.title`,
			`onboarding.linear.${type}.${key}.error.details.${code}.title`,
		],
		undefined,
		{ error: error, integration: integration },
	);

	const description = TranslationService.get(
		[
			message,
			'onboarding.linear.error.details.description',
			`onboarding.linear.error.details.description.${message}`,
			`onboarding.linear.${type}.error.details.description`,
			`onboarding.linear.${type}.error.details.description.${message}`,
			`onboarding.linear.${key}.error.details.description`,
			`onboarding.linear.${key}.error.details.description.${message}`,
			`onboarding.linear.${type}.${key}.error.details.description`,
			`onboarding.linear.${type}.${key}.error.details.description.${message}`,
			`onboarding.linear.error.details.${code}.description`,
			`onboarding.linear.error.details.${code}.description.${message}`,
			`onboarding.linear.${type}.error.details.${code}.description`,
			`onboarding.linear.${type}.error.details.${code}.description.${message}`,
			`onboarding.linear.${key}.error.details.${code}.description`,
			`onboarding.linear.${key}.error.details.${code}.description.${message}`,
			`onboarding.linear.${type}.${key}.error.details.${code}.description`,
			`onboarding.linear.${type}.${key}.error.details.${code}.description.${message}`,
		],
		message,
		{ error: error, integration: integration },
	);

	return (
		<div className="asteria-component__linear-onboarding__errors__error">
			{title || description ? (
				<TextGroup>
					{title ? <Title size="xs">{title}</Title> : null}
					{description ? <Text>{description}</Text> : null}
				</TextGroup>
			) : null}
		</div>
	);
});

Error.displayName = 'Error';
Error.propTypes = {
	integration: PropTypes.object,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
	type: PropTypes.string,
};

const Errors = React.memo((props) => {
	const { integration, type } = props;

	const errors = integration?.config?.errors ?? [];

	if (!errors?.length) {
		return null;
	}

	return (
		<div className="asteria-component__linear-onboarding__errors">
			{errors.map((error, index) => (
				<Error
					key={[index, error?.code, error?.message].join('-')}
					integration={integration}
					error={error}
					type={type}
				/>
			))}
		</div>
	);
});

Errors.displayName = 'Errors';
Errors.propTypes = { integration: PropTypes.object, type: PropTypes.string };

const ErrorAlert = React.memo((props) => {
	const { integration, type: $type } = props;

	const type = $type ?? integration?.type;
	const key = integration?.key;

	const title = TranslationService.get(
		[
			'onboarding.linear.error.alert.title',
			`onboarding.linear.${type}.error.alert.title`,
			`onboarding.linear.${key}.error.alert.title`,
			`onboarding.linear.${type}.${key}.error.alert.title`,
		],
		undefined,
		{ integration: integration },
	);

	const content = TranslationService.get(
		[
			'onboarding.linear.error.alert.content',
			`onboarding.linear.${type}.error.alert.content`,
			`onboarding.linear.${key}.error.alert.content`,
			`onboarding.linear.${type}.${key}.error.alert.content`,
		],
		undefined,
		{ integration: integration },
	);

	if (!title && !content) {
		return null;
	}

	return (
		<Alert level="error" title={title}>
			{content ? <Text>{content}</Text> : null}
		</Alert>
	);
});

ErrorAlert.displayName = 'ErrorAlert';
ErrorAlert.propTypes = {
	integration: PropTypes.object,
	type: PropTypes.string,
};

const OnboardingError = React.memo((props) => {
	const { className, type: $type } = props;

	const _id = useSelector(IntegrationStore.selectors.navigation._id);

	const { onBack, onAbort } = React.useContext(Context);

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, _id),
		(a, b) => isEqual(a, b),
	);

	const type = $type ?? integration?.type;
	const key = integration?.key;

	const analyticsFlow = useAnalytics(integration);

	const Placeholder = usePlaceholder(type, key, 'error');

	const handleAbort = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onAbort?.(event);
		},
		[analyticsFlow, onAbort],
	);

	const handleBack = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onBack?.(event);
		},
		[analyticsFlow, onBack],
	);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__error',
				{ 'asteria--variant-split': Placeholder },
				className,
			)}
		>
			<div className="asteria-component__linear-onboarding__content">
				<Wrapper scroll>
					<Header>
						{TranslationService.get(
							[
								`integrations.add.title`,
								`integrations.${type}.add.title`,
								`integrations.${key}.add.title`,
								`integrations.${type}.${key}.add.title`,
								`integrations.error.title`,
								`integrations.${type}.error.title`,
								`integrations.${key}.error.title`,
								`integrations.${type}.${key}.error.title`,
							],
							undefined,
							{ integration: integration },
						)}
					</Header>
					<Content scroll>
						<ErrorAlert integration={integration} type={type} />
						<Errors integration={integration} type={type} />
					</Content>
					<Footer>
						<FooterSection>
							<Button
								analyticsKey={`integrations.${key}.abort`}
								variant="tertiary"
								label={TranslationService.get([
									'action.abort',
									'integrations.action.abort',
									'integrations.action.error.abort',
								])}
								onClick={handleAbort}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								analyticsKey={`integrations.${key}.back`}
								variant="primary"
								label={TranslationService.get([
									'action.back',
									'integrations.action.back',
									'integrations.action.error.back',
								])}
								onClick={handleBack}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
				<Help />
			</div>
			{Placeholder}
		</div>
	);
});

OnboardingError.displayName = 'OnboardingError';

OnboardingError.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
};

export default OnboardingError;
