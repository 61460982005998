import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import Country from './Country';

import './styles.scss';

function Countries(props) {
	const { countries, country, onAction, Wrapper = React.Fragment } = props;

	const openChat = React.useCallback(() => {
		onAction?.('country:chat', country);
	}, [country, onAction]);

	const hasSupportChatFeature = useFeature('asteria-support-countries');

	if (!hasSupportChatFeature) {
		return null;
	}

	if ((countries?.length ?? 0) < 2) {
		return (
			<Wrapper>
				<div>
					<Button
						variant="primary"
						label={TranslationService.get([
							'support.country.action.chat',
							'support.country.sv.action.chat',
						])}
						onClick={openChat}
					/>
				</div>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<div className="asteria-page__support-countries">
				{countries.map((object, index) => (
					<Country
						key={object?.id ?? index}
						{...object}
						onAction={onAction}
						active={object?.id === country}
					/>
				))}
			</div>
		</Wrapper>
	);
}
Countries.displayName = 'Countries';
Countries.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.object),
	country: PropTypes.string,
	onAction: PropTypes.func,
	Wrapper: PropTypes.node,
};
export default React.memo(Countries);
