import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip/chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const ChipCurrency = (props) => {
	const { value } = props;

	const dispatch = useDispatch();

	const onDismiss = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'CURRENCY',
				value: value,
			}),
		);
	}, [dispatch, value]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				['invoices.search.quick.currency.label'],
				undefined,
				{ currency: value },
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
};

ChipCurrency.displayName = 'ChipCurrency';
ChipCurrency.propTypes = { value: PropTypes.string };

const ChipCurrencyWrapper = () => {
	const currencies = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CURRENCY' }),
		(a, b) => isEqual(a, b),
	);

	return currencies.map((object, index) => (
		<ChipCurrency key={index} value={object?.value} />
	));
};

ChipCurrencyWrapper.displayName = 'ChipCurrencyWrapper';
ChipCurrencyWrapper.propTypes = {};

export default ChipCurrency;
export { ChipCurrencyWrapper };
