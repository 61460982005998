import React from 'react';

import PropTypes from 'prop-types';

import CreateBox from './Create';
import UpdateBox from './Update';

import './styles.scss';

const Box = React.memo((props) => {
	const { variant } = props;

	if (variant === 'create') {
		return <CreateBox {...props} />;
	}

	if (variant === 'update') {
		return <UpdateBox {...props} />;
	}

	return null;
});

Box.displayName = 'Box';

Box.propTypes = {
	...CreateBox.propTypes,
	...UpdateBox.propTypes,
	variant: PropTypes.oneOf(['create', 'update']),
};

export default Box;
