import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useGenericProperties } from '@asteria/component-feedback';
import FeedbackModal from '@asteria/component-feedback/FormModal';
import FeedbackSuccessModal from '@asteria/component-feedback/SuccessModal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import LayoutContext from '../context';

import './styles.scss';

const Feedback = (props) => {
	const { className } = props;

	const { onAction } = React.useContext(LayoutContext);

	const handleFeedbackSubmit = React.useCallback(
		(data) => onAction?.('updateFeedback', data),
		[onAction],
	);

	const {
		loading,
		state,
		onClose: onFeedbackClose,
		onOpen: onFeedbackOpen,
		onSubmit: onFeedbackSubmit,
		rating: FeedbackRating,
	} = useGenericProperties({ onSubmit: handleFeedbackSubmit });

	const onSupportRequest = React.useCallback(() => {
		onFeedbackClose?.();
		return onAction?.('go', '/support');
	}, [onAction, onFeedbackClose]);

	return [
		<FeedbackModal
			key="feedback-modal"
			open={state === 'OPEN'}
			type="generic"
			onClose={onFeedbackClose}
			onSubmit={onFeedbackSubmit}
			onSupportRequest={onSupportRequest}
			loading={loading}
			rating={FeedbackRating}
		/>,
		<FeedbackSuccessModal
			key="feedback-success-modal"
			open={state === 'DONE'}
			type="generic"
			onClose={onFeedbackClose}
		/>,
		<Button
			key="feedback-button"
			size="sm"
			variant="tertiary"
			className={cn(
				'asteria-widget__layout-footer__navigation-item',
				className,
			)}
			label={TranslationService.get([
				'layout.footer.navigation.item.feedback',
			])}
			onClick={onFeedbackOpen}
		/>,
	];
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
	className: PropTypes.string,
};

export default Feedback;
