import AsteriaCore from '@asteria/core';

import { TransactionService } from '@asteria/backend-utils-services';

import { setGroup } from '@asteria/datalayer/stores/graph';

const DEFAULT_FIELDS = `
sums {
	original {
		total
	}
}
max {
	original {
		total
	}
}
min {
	original {
		total
	}
}
probability
type
status
count
badge
chip {
	id
	type
	config
}
info {
	id
	type
	data
}
`;

TransactionService.transaction.extend({
	getQuery: (fields = DEFAULT_FIELDS) => `
		query QueryCashflows(
			$dates: [CashflowQueryFilterInputType]!
			$tags: [ObjectId]
			$clients: [ObjectId]
			$status: [String]
			$currencies: [String]
			$preview: CashFlowQueryPreviewInputType
			$scenarioId: ObjectId
		) {
			cashflows(
				dates: $dates
				filters: {
					search: {
						tags: $tags
						clients: $clients
						status: $status
						currencies: $currencies
						scenarioId: $scenarioId
					}
					preview: $preview
				}
			) {
				${fields}
			}
		}
	`,
	key: `cashflow`,
	loggerMethod: `services.transactionService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.transactionService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const buildBar = (data, barType, types = []) => {
	const pieces =
		data?.filter(({ type }) => type === barType.toUpperCase()) || [];

	return {
		value: pieces
			.filter(({ badge }) => !badge)
			.reduce(
				(
					acc,
					{
						sums: {
							original: { total },
						},
					},
				) => acc + total,
				0,
			),
		types: [barType.toLowerCase(), ...types],
		badges: pieces
			.filter(({ badge }) => badge)
			.map(
				({
					status,
					type,
					count,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						config: { name, tagType = name },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
					],
					parts: [],
					chip,
					count,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		parts: pieces
			.filter(({ badge }) => !badge)
			.map(
				({
					status,
					type,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						config: { name, tagType = name, category },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
						...(category
							? [
									`${category.replace(
										'$',
										'',
									)}-${tagType.replace('$', '')}`,
							  ]
							: []),
					],
					parts: [],
					chip,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		data: {
			type: barType.toLowerCase(),
			probability: 1,
		},
	};
};

const queue = [];
const fetchCashflows = AsteriaCore.utils.throttle(
	async ({ accessToken, dispatch }) => {
		const ids = [...new Set(queue.map(({ startDate }) => startDate))];
		const items = ids.map((id) =>
			queue.find(({ startDate }) => startDate === id),
		);
		const first = items?.[0] || {};

		try {
			const promise = TransactionService.transaction.extension.cashflow(
				{
					dates: [
						...items.map(({ startDate, endDate }) => ({
							startDate,
							endDate,
						})),
					],
					tags: first?.tags,
					clients: first?.clients,
					status: first?.status,
					currencies: first?.currencies,
					scenarioId: first?.scenarioId,
				},
				{ token: accessToken },
			);

			queue.length = 0;

			const response = await promise;

			response.forEach((data, index) => {
				const deposit = buildBar(
					data.filter(({ status }) => status !== 'BACKGROUND'),
					'deposit',
				);
				const withdraw = buildBar(
					data.filter(({ status }) => status !== 'BACKGROUND'),
					'withdraw',
				);

				dispatch(
					setGroup({
						id: ids[index],
						data: {
							id: ids[index],
							lines: data
								.filter(
									({ type }) =>
										type === 'ACCOUNT' || type === 'CREDIT',
								)
								.map(
									({
										sums: {
											original: { total },
										},
										max,
										min,
										status,
										probability,
										type,
										info,
									}) => ({
										value: total,
										max: max?.original?.total || total,
										min: min?.original?.total || total,
										probability,
										types: [
											type.toLowerCase(),
											status === 'FORECAST'
												? 'forecast'
												: 'history',
											...(type === 'CREDIT'
												? ['sharp']
												: []),
										],
										info: info || [],
									}),
								),
							bars: [
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'deposit',
									['background'],
								),
								deposit,
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'withdraw',
									['background'],
								),
								withdraw,
							],
						},
					}),
				);
			});
		} catch (e) {
			// Do nothing
		}
	},
	250,
);

/**
 * @param { { accessToken: string, fields?: string, scenarioId?: string, dates?: { startDate: string, endDate: string }[], tags?: string[], clients?: string[], status?: string[], currencies?: string[], preview?: { factoring?: { clients?: string[], transactions?: string[] } } } } options
 */
export const fetchCustomCashflow = async (options) => {
	return TransactionService.transaction.extension.cashflow(
		{
			dates: options.dates,
			tags: options.tags,
			clients: options.clients,
			status: options.status,
			currencies: options.currencies,
			preview: options.preview,
			scenarioId: options.scenarioId,
			fields: options.fields,
		},
		{ token: options.accessToken },
	);
};

export default async ({
	accessToken,
	dispatch,
	tags,
	status,
	currencies,
	clients,
	startDate,
	endDate,
	scenarioId,
	batch = true,
}) => {
	if (batch) {
		queue.push({
			startDate,
			endDate,
			tags,
			status,
			currencies,
			clients,
			scenarioId,
		});

		fetchCashflows({ accessToken, dispatch });
	} else {
		return TransactionService.transaction.extension.cashflow(
			{
				dates: [
					{
						startDate,
						endDate,
					},
				],
				tags: tags,
				clients: clients,
				status: status,
				currencies: currencies,
				scenarioId: scenarioId,
			},
			{ token: accessToken },
		);
	}
};
