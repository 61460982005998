import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es/array/virtual/keys';
import 'core-js/features/string/repeat';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.array.filter';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';
import 'core-js/modules/es.array.flat';
import 'core-js/modules/es.array.from';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.math.log10';
import 'core-js/modules/es.math.sign';
import 'core-js/modules/es.number.is-integer';
import 'core-js/modules/es.number.is-nan';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.entries';
import 'core-js/modules/es.object.from-entries';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.set';
import 'core-js/modules/es.string.ends-with';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.repeat';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.weak-set';
import { enableES5 } from 'immer';
import 'whatwg-fetch';

import Analytics from '@asteria/utils-analytics';
import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import '@asteria/utils-graphql';

import App from './App';
import Toolbar from './features';
import reportWebVitals from './reportWebVitals';

Configuration.apply();

window.AsteriaAnalytics = Analytics;

const originalSetItem = window.localStorage.setItem;

window.localStorage.setItem = function (key, value) {
	const event = new Event('localStorage.set');

	event.value = value;
	event.key = key;

	document.dispatchEvent(event);

	originalSetItem.apply(this, arguments);
};

enableES5();

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
	require('./devconfig');
}

ReactDOM.render(
	<React.StrictMode>
		<App />
		<Toolbar />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
