import { UserService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';

import Analytics from '@asteria/utils-analytics';
import * as UserAPI from '@asteria/widget-base/api/user';

export async function update({ accessToken, id, input, dispatch }) {
	await UserAPI.update({ accessToken, id, input });

	return UserAPI.me({ accessToken: accessToken, dispatch: dispatch });
}

export async function refreshSettings({ accessToken, dispatch }) {
	const user = store.getState()?.app?.user;

	const userId = user?.id ?? null;
	const settings = user?.settings ?? {};

	Analytics.event('settings.refresh', {
		user: user,
		userId: userId,
		data: settings,
	});

	return update({
		accessToken: accessToken,
		id: userId,
		input: { settings },
		dispatch: dispatch,
	});
}

export async function remove({ accessToken, id }) {
	await UserService.user.remove({ id: id }, { token: accessToken });

	localStorage.removeItem('wingsRefreshToken');
	localStorage.removeItem('wingsToken');

	const url = new URL('', window.location.origin);
	url.search = window.location.search;

	window.location.replace(url.toString());
}
