import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Grid, { GridItem } from '@asteria/component-grid';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useIntegrations } from '../../../hooks';
import MissingIntegration from '../../MissingIntegration';
import VPrint from '../../VPrint';
import Box from '../Box';
import OnboardingSearch from '../Search';

import './styles.scss';

function findDeep(value) {
	if (typeof value === 'string') {
		return value;
	}

	if (typeof value?.props?.children === 'string') {
		return value?.props?.children;
	}

	const children = value?.props?.children ?? null;

	if (!children) {
		return null;
	}

	if (Array.isArray(children)) {
		return children.map((value) => findDeep(value));
	}

	return findDeep(children);
}

function findTexts(integration) {
	return [integration?.key, integration?.name]
		.concat(
			(integration?.info?.box ?? []).flatMap((value) => findDeep(value)),
		)
		.filter(Boolean)
		.flatMap((value) => [value, TranslationService.get(value, value)]);
}

const OnboardingGrid = React.memo((props) => {
	const { className, onAction, onSubmit } = props;

	const integrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { type: 'erp' }),
		(a, b) => isEqual(a, b),
	);

	const $available = useIntegrations();
	const [available, setAvailable] = React.useState($available);

	const handleChange = React.useCallback(
		({ value }) => {
			setAvailable(
				$available.filter((object) =>
					findTexts(object).some((text) =>
						text.toLowerCase().includes(value.toLowerCase()),
					),
				),
			);
		},
		[$available],
	);

	return (
		<div className={cn('asteria-component__onboarding-grid', className)}>
			<OnboardingSearch onChange={handleChange} />
			{integrations.length
				? [
						<Text key="title-connected" size="lg" separator>
							{TranslationService.get(
								['onboarding.grid.title.connected'],
								undefined,
								{ count: integrations.length },
							)}
						</Text>,
						<Grid key="connected-integrations">
							{integrations.map((object) => (
								<GridItem key={object?._id ?? object.id}>
									<Box
										id={object?._id ?? object.id}
										variant="update"
										onAction={onAction}
										onSubmit={onSubmit}
									/>
								</GridItem>
							))}
						</Grid>,
						<Text key="title-available" size="lg" separator>
							{TranslationService.get([
								'onboarding.grid.title.available',
							])}
						</Text>,
				  ]
				: null}

			<Grid>
				{available.map((object) => (
					<GridItem key={[object?.type, object?.key].join('-')}>
						<Box
							type={object?.type}
							name={object?.key}
							variant="create"
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					</GridItem>
				))}
				<GridItem rows={2} cols={2}>
					<VPrint onAction={onAction} />
				</GridItem>
				<GridItem rows={2} cols={2}>
					<MissingIntegration onAction={onAction} />
				</GridItem>
			</Grid>
		</div>
	);
});

OnboardingGrid.displayName = 'OnboardingGrid';

OnboardingGrid.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default OnboardingGrid;
