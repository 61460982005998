import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import AsteriaCore from '@asteria/core';

import * as AppStore from '@asteria/datalayer/stores/app';

import SearchContext from './context';

export function useClients() {
	const { data } = useContext(SearchContext);

	return useSelector(
		(store) =>
			AppStore.selectors.clients(store, {
				id: data.map((object) => object?.clientId),
			}),
		(a, b) => isEqual(a, b),
	);
}

export function useCurrencies() {
	const { data } = useContext(SearchContext);

	return useMemo(
		() =>
			Array.from(
				new Set(data.map((object) => object?.sums?.original?.currency)),
			),
		[data],
	);
}

export function useAmounts() {
	const { data } = useContext(SearchContext);

	return useMemo(() => {
		if (data.length < 3) {
			return [];
		}

		return AsteriaCore.utils
			.chunk(
				data
					.map((object) => object?.sums?.original?.total)
					.filter(Boolean)
					.sort((a, b) => a - b),
				data.length / 3,
			)
			.map((values) => ({
				from: values?.[0],
				to: values.slice(-1)[0],
			}));
	}, [data]);
}
