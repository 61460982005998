import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

// import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const LayoutInfo = React.memo(({ type }) => {
	const hasPrinters = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [{ key: 'printer' }],
			}).length,
	);

	const layouts = useSelector(
		(store) =>
			InvoiceStore.selectors.layouts(store, {
				status: ['PENDING', 'LOADING'],
			}),
		(a, b) => isEqual(a, b),
	);

	if (hasPrinters && layouts.length) {
		return (
			<div className="asteria-page__placeholder">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					<Icon icon="document" />
					<Title size="sm" align="center">
						{TranslationService.get(
							[`invoices.layouts.${type}.title`],
							undefined,
							{ layouts: layouts },
						)}
					</Title>
				</Group>
				<Text align="center">
					{TranslationService.get(
						[`invoices.layouts.${type}.content`],
						undefined,
						{ layouts: layouts },
					)}
				</Text>
				<Text align="center">
					{TranslationService.get(
						[`invoices.layouts.${type}.content.1`],
						undefined,
						{ layouts: layouts },
					)}
				</Text>
			</div>
		);
	}
});

LayoutInfo.displayName = 'LayoutInfo';

LayoutInfo.propTypes = { type: PropTypes.string };

export default LayoutInfo;
