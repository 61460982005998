import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';
import WaitingStep from './waiting';

import './styles.scss';

function TextStep(props) {
	const { nextStep } = props;
	const { onAction } = React.useContext(LayoutContext);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() => onAction?.('go', `/integrations/erp/printer/${nextStep}`),
		[onAction, nextStep],
	);

	return <BaseStep {...props} onBack={onBack} onNext={onNext} />;
}

const InstallationStep = (props) => {
	return (
		<Routes>
			<Route
				index
				element={
					<TextStep
						{...props}
						step="os/windows/add"
						nextStep="os/windows/configure"
					/>
				}
			/>
			<Route
				path="configure"
				element={
					<TextStep
						{...props}
						step="os/windows/configure"
						nextStep="os/windows/authentication"
					/>
				}
			/>
			<Route
				path="authentication"
				element={
					<TextStep
						{...props}
						step="os/windows/authentication"
						nextStep="os/windows/waiting"
					/>
				}
			/>

			<Route
				path="waiting"
				element={
					<WaitingStep
						{...props}
						step="os/windows/waiting"
						nextStep="success"
					/>
				}
			/>
		</Routes>
	);
};

InstallationStep.displayName = 'InstallationStep';

InstallationStep.propTypes = { className: PropTypes.string };

export default InstallationStep;
