import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import LayoutContext from '../context';

import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';

import './styles.scss';

const LayoutHeader = React.memo((props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<div className={cn('asteria-widget__layout-header', className)}>
			<div className="asteria-widget__layout-header__content">
				<Navigation onAction={onAction} onSubmit={onSubmit} />
			</div>
			<MobileNavigation onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
});

LayoutHeader.displayName = 'LayoutHeader';

LayoutHeader.propTypes = { className: PropTypes.string };

export default LayoutHeader;
