/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import { Title } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';
import Form from '@asteria/component-form/form';
import Input from '@asteria/component-form/input';

import { TranslationService } from '@asteria/language';

import './index.scss';

const WelcomeForm = () => {
	return (
		<div className="asteria-component__main-form">
			<Alert
				icon="check"
				level="success"
				title={TranslationService.get('welcomeForm.alert.title')}
			/>
			<div className="asteria-component__form_body">
				<Form>
					<Title
						size="base"
						className="asteria-component__form__section_title"
					>
						{TranslationService.get('welcomeForm.form.heading')}
					</Title>
					<div className="asteria-component__form__input_row">
						<div className="asteria-component__form__input_width">
							<Input
								name="foretagsnamn"
								required
								placeholder={TranslationService.get(
									'welcomeForm.form.field.placeholder.1',
								)}
								direction="vertical"
								label={TranslationService.get(
									'welcomeForm.form.field.label.1',
								)}
							/>
						</div>
						<div className="asteria-component__form__input_width">
							<Input
								name="bransch"
								required
								direction="vertical"
								placeholder={TranslationService.get(
									'welcomeForm.form.field.placeholder.2',
								)}
								label={TranslationService.get(
									'welcomeForm.form.field.label.2',
								)}
							/>
						</div>
					</div>
					<div className="asteria-component__form__input_row">
						<div className="asteria-component__form__input_width">
							<Input
								name="postadress"
								required
								placeholder={TranslationService.get(
									'welcomeForm.form.field.placeholder.3',
								)}
								direction="vertical"
								label={TranslationService.get(
									'welcomeForm.form.field.label.3',
								)}
							/>
						</div>
						<div className="asteria-component__form__input_row_nested">
							<div className="asteria-component__form__input_width-30">
								<Input
									name="postnr2"
									required
									placeholder={TranslationService.get(
										'welcomeForm.form.field.placeholder.4',
									)}
									direction="vertical"
									label={TranslationService.get(
										'welcomeForm.form.field.label.4',
									)}
								/>
							</div>
							<div className="asteria-component__form__input_width-65">
								<Input
									name="postnr2"
									required
									placeholder={TranslationService.get(
										'welcomeForm.form.field.placeholder.5',
									)}
									direction="vertical"
								/>
							</div>
						</div>
					</div>
					<div className="asteria-component__form__input_row">
						<div className="asteria-component__form__input_width">
							<Input
								name="bransch2"
								required
								direction="vertical"
								placeholder={TranslationService.get(
									'welcomeForm.form.field.placeholder.6',
								)}
								label={TranslationService.get(
									'welcomeForm.form.field.label.5',
								)}
							/>
						</div>
						<div className="asteria-component__form__input_width">
							<Input
								name="land"
								required
								placeholder={TranslationService.get(
									'welcomeForm.form.field.placeholder.7',
								)}
								direction="vertical"
								label={TranslationService.get(
									'welcomeForm.form.field.label.6',
								)}
							/>
						</div>
					</div>
					<Title
						size="base"
						className="asteria-component__form__section_title"
					>
						{TranslationService.get('welcomeForm.form.heading.2')}
					</Title>

					<div className="asteria-component__form__input_row">
						<div className="asteria-component__form__input_row_nested">
							<div className="asteria-component__form__input_width-30">
								<Input
									name="fornamn"
									required
									placeholder={TranslationService.get(
										'welcomeForm.form.field.2.placeholder.1',
									)}
									direction="vertical"
									label={TranslationService.get(
										'welcomeForm.form.field.2.label.1',
									)}
								/>
							</div>
							<div className="asteria-component__form__input_width-65">
								<Input
									name="efternamn"
									required
									placeholder={TranslationService.get(
										'welcomeForm.form.field.2.placeholder.2',
									)}
									direction="vertical"
									label={TranslationService.get(
										'welcomeForm.form.field.2.label.2',
									)}
								/>
							</div>
						</div>
						<div className="asteria-component__form__input_width">
							<Input
								name="personnummer"
								required
								placeholder={TranslationService.get(
									'welcomeForm.form.field.2.placeholder.3',
								)}
								direction="vertical"
								label={TranslationService.get(
									'welcomeForm.form.field.2.label.3',
								)}
							/>
						</div>
					</div>
					<div className="asteria-component__form__input_row">
						<div className="asteria-component__form__input_width">
							<Input
								name="bransch3"
								required
								direction="vertical"
								placeholder={TranslationService.get(
									'welcomeForm.form.field.2.placeholder.4',
								)}
								label={TranslationService.get(
									'welcomeForm.form.field.2.label.4',
								)}
							/>
						</div>
						<div className="asteria-component__form__input_width">
							<Input
								name="epost"
								required
								placeholder={TranslationService.get(
									'welcomeForm.form.field.2.placeholder.5',
								)}
								direction="vertical"
								label={TranslationService.get(
									'welcomeForm.form.field.2.label.5',
								)}
							/>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
};
export default WelcomeForm;
