import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const RemoveModal = React.memo((props) => {
	const { open, onClose, onSubmit, object } = props;

	const [loading, setLoading] = React.useState(false);

	const handleRemove = React.useCallback(
		async (event) => {
			setLoading(true);
			await onSubmit?.('integrations:delete', object);
			setLoading(false);

			return onClose?.(event);
		},
		[object, onClose, onSubmit],
	);

	return (
		<Modal
			open={open}
			onClose={onClose}
			size="sm"
			className={cn(
				'asteria-component__onboarding-modal',
				'asteria--type-remove',
			)}
		>
			<Wrapper scroll>
				<Header onClose={onClose}>
					{TranslationService.get([
						'onboarding.integration.remove.title',
					])}
				</Header>
				<Content scroll>
					<Text>
						{TranslationService.get([
							'onboarding.integration.remove.content',
						])}
					</Text>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
								'onboarding.integration.remove.button.close',
							])}
							analyticsKey="onboarding.integration.remove.button.close"
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.remove',
								'action.remove',
								'onboarding.integration.remove.button.remove',
							])}
							analyticsKey="onboarding.integration.remove.button.remove"
							disabled={loading}
							loading={loading}
							onClick={handleRemove}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
});

RemoveModal.displayName = 'RemoveModal';
RemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	object: PropTypes.object,
};

export default RemoveModal;
