import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const VariantEmpty = ({ onAction }) => {
	const onClick = React.useCallback(() => onAction?.('go', -1), [onAction]);

	return [
		<div key="content" className="asteria-page__wrapper">
			<TextGroup>
				<Title size="page-title">
					{TranslationService.get([
						`page.invoices.title`,
						`page.invoices.empty.title`,
					])}
				</Title>
				<Text size="lg">
					{TranslationService.get([
						`page.invoices.content`,
						`page.invoices.empty.content`,
					])}
				</Text>
			</TextGroup>
			<div className="asteria-page__placeholder">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					<Icon icon="document" />
					<Title size="sm" align="center">
						{TranslationService.get([
							'page.invoices.placeholder.title',
							'page.invoices.empty.placeholder.title',
						])}
					</Title>
				</Group>
				<Text align="center">
					{TranslationService.get([
						'page.invoices.placeholder.content',
						'page.invoices.empty.placeholder.content',
					])}
				</Text>
				{/* <Button
					variant="link"
					label={TranslationService.get([
						'page.invoices.placeholder.link',
						'page.invoices.empty.placeholder.link',
					])}
				/> */}
				<Button
					variant="link"
					label={TranslationService.get([
						'page.invoices.review.placeholder.back.label',
						'page.invoices.review.empty.placeholder.back.label',
					])}
					onClick={onClick}
				/>
			</div>
		</div>,
	];
};

VariantEmpty.displayName = 'VariantEmpty';

VariantEmpty.propTypes = { onAction: PropTypes.func };

export default VariantEmpty;
