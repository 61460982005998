import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Form from '@asteria/component-form/form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

const getSitePartners = async (site) => {
	const response = await PartnerService.partner
		.fetchByDomain({
			useTokenFromContext: false,
			domain: site.toLowerCase(),
			fields: `
			id
			name
			settings {
				themeId
				theme {
					name
				}
				hosting {
					language
					domain
				}
			}
		`,
		})
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.error(err);

			return {
				id: site,
				name:
					site === 'wings'
						? 'Asteria AB'
						: site[0].toUpperCase() + site.slice(1),
				settings: {
					themeId: null,
					theme: { name: site },
					hosting: { language: 'sv', domain: site },
				},
			};
		});

	if (response?.settings?.hosting?.domain !== site.toLowerCase()) {
		return null;
	}

	return response;
};

const PartnerSelector = (props) => {
	const { partner: { state: partner, onChange: setPartner } = {} } = props;

	const [edit, setEdit] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const onSubmit = useCallback(
		(form) => {
			setLoading(true);
			setError(null);

			const fetch = async () => {
				const partner = await getSitePartners(form.partner);
				if (!partner) {
					setError('Unable to find partner');
				} else {
					setPartner?.(partner);
					setEdit(false);
				}
				setLoading(false);
			};

			fetch();
		},
		[setPartner],
	);

	if (partner && !edit) {
		return (
			<Button
				className="site-id"
				label={partner.name}
				onClick={() => setEdit(true)}
				icon="edit"
			/>
		);
	}

	return (
		<Modal>
			<Form onSubmit={onSubmit} className="select-partner-form">
				<Wrapper>
					<Header onClose={() => setEdit(false)}>
						Please select partner
					</Header>
					<Content>
						{error && (
							<Alert level="error">
								<Text>{error}</Text>
							</Alert>
						)}
						<Input
							type="text"
							name="partner"
							placeholder="Partner"
							required
						/>
					</Content>
					<Footer>
						<FooterSection>
							<Button
								className="partner-btn"
								type="submit"
								label="Select"
								variant="primary"
								loading={loading}
								disabled={loading}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
		</Modal>
	);
};

PartnerSelector.propTypes = {
	partner: PropTypes.shape({
		state: PropTypes.object,
		onChange: PropTypes.func,
	}),
};

export default PartnerSelector;
