import React from 'react';

import { TableRow } from '@asteria/component-core/table';

import { COLUMNS } from './constants';
import Cell from './table/cell';

import './styles.scss';

const Placeholder = React.memo(() => {
	return Array.from({ length: 5 }).map((value, index) => (
		<TableRow key={['loading', index].join('-')}>
			{(COLUMNS ?? []).map(({ name }) => (
				<Cell key={`table-${name}`} name={name} loading />
			))}
		</TableRow>
	));
});

Placeholder.displayName = 'Placeholder';
Placeholder.propTypes = {};

export default Placeholder;
