import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';

import List, { ListItem } from '@asteria/component-list';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { SectionPropTypes } from './PropTypes';
import FAQQuestion from './Question';

import './styles.scss';

const FAQSection = (props) => {
	const {
		className,
		name,
		content = [],
		questions = [],
		count = 3,
		title,
		onAction,
	} = props;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	const handleShowDetails = React.useCallback(
		() => onAction?.('go', `/faq/${name}`),
		[name, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__faq-section',
				{ [`asteria-component__faq-section--name-${name}`]: name },
				className,
			)}
		>
			<div className="asteria-component__faq-section__content">
				<Title size="lg">
					{title
						? title
						: TranslationService.get([
								'faq.content.title',
								`faq.content.title.${name}`,
						  ])}
				</Title>
				{content?.length ? (
					<Contenter content={content} actions={actions} />
				) : null}
			</div>
			<List
				className="asteria-component__faq-section__questions"
				size="lg"
			>
				{(count ? questions.slice(0, count) : questions).map(
					(question, index) => (
						<FAQQuestion
							key={question.id ?? index}
							{...question}
							section={name}
							onAction={onAction}
						/>
					),
				)}
				{count ? (
					<ListItem>
						<Button
							className="asteria-component__faq-section__details"
							variant="link"
							label={TranslationService.get([
								'faq.questions.button.details',
								`faq.questions.${name}.button.details`,
							])}
							onClick={handleShowDetails}
						/>
					</ListItem>
				) : null}
			</List>
		</div>
	);
};

FAQSection.displayName = 'FAQSection';

FAQSection.propTypes = {
	...SectionPropTypes,
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default FAQSection;
