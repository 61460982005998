import enUS from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';

import { TranslationService } from '@asteria/language';

const LOCALES = {
	sv: sv,
	en: enUS,
	pt: pt,
};

export function useDateFNSLocale() {
	let locale = TranslationService.code ?? 'sv';

	if (locale === 'en') {
		locale = 'enUS';
	}

	return LOCALES[locale];
}
