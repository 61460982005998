import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import FAQDetails from './Details';

import './styles.scss';

const FAQModal = (props) => {
	const { className, open, onClose, title, content } = props;

	return (
		<Modal
			className={cn('asteria-component__faq-modal', className)}
			open={open}
			onClose={onClose}
		>
			<Wrapper scroll>
				<Header onClose={onClose}>
					{TranslationService.get([title], title)}
				</Header>
				<Content scroll>
					<FAQDetails content={content} />
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							variant="primary"
							onClick={onClose}
							label={TranslationService.get([
								'button.close',
								'action.close',
								'faq.modal.close',
							])}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

FAQModal.displayName = 'FAQModal';

FAQModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	content: PropTypes.arrayOf(PropTypes.object),
};

export default FAQModal;
