import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import BaseTable, {
	TableCell as BaseCell,
	TableHeader as BaseHeader,
	TableRow as BaseRow,
} from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const FIELDS = [
	{ name: 'name', sortBy: 'name', iconPosition: 'last' },
	{ name: 'date', sortBy: 'createdAt', iconPosition: 'last' },
	{ name: 'currency', sortBy: 'currency', iconPosition: 'first' },
	{ name: 'total', sortBy: 'total', iconPosition: 'first' },
];

const HeaderCell = React.memo(function HeaderCell(props) {
	const { name, sortBy, iconPosition, active, sortDirection, onAction } =
		props;

	const onSortClick = React.useCallback(
		() => onAction?.('table:filter', sortBy),
		[onAction, sortBy],
	);

	return (
		<BaseCell className={`asteria--variant-${name}`}>
			<Button
				variant="table-filter"
				onClick={onSortClick}
				active={active}
				icon="chevron-down"
				iconActive={
					sortDirection === 'ASC' ? 'chevron-down' : 'chevron-up'
				}
				iconPosition={iconPosition}
				label={TranslationService.get([
					'page.reports.table.header.label',
					`page.reports.table.header.${name}.label`,
				])}
			/>
		</BaseCell>
	);
});

HeaderCell.propTypes = {
	name: PropTypes.string,
	sortBy: PropTypes.string,
	iconPosition: PropTypes.string,
	onAction: PropTypes.func,

	active: PropTypes.bool,
	sortDirection: PropTypes.string,
};

const Table = React.memo((props) => {
	const { loading, data, sort, onAction } = props;

	return (
		<BaseTable>
			<BaseHeader>
				{FIELDS.map((field) => (
					<HeaderCell
						key={field.name}
						{...field}
						active={sort?.field === field?.sortBy}
						sortDirection={sort?.direction}
						onAction={onAction}
					/>
				))}
			</BaseHeader>
			{loading
				? Array.from({ length: 5 }).map((_, index) => (
						<BaseRow key={index}>
							<BaseCell className="asteria--variant-loading" />
							<BaseCell className="asteria--variant-loading" />
							<BaseCell className="asteria--variant-loading" />
							<BaseCell className="asteria--variant-loading" />
						</BaseRow>
				  ))
				: data.map((object) => (
						<BaseRow key={object?._id ?? object?.id}>
							<BaseCell className="asteria--variant-name">
								<Button
									variant="link"
									label={TranslationService.get(
										[
											'page.reports.table.cell.label',
											'page.reports.table.cell.name.label',
										],
										undefined,
										{ object: object },
									)}
									href={object?.attachments?.[0]}
									target="_blank"
								/>
							</BaseCell>
							<BaseCell className="asteria--variant-date">
								<Text>
									{TranslationService.get(
										[
											'page.reports.table.cell.label',
											'page.reports.table.cell.date.label',
										],
										undefined,
										{ object: object },
									)}
								</Text>
							</BaseCell>
							<BaseCell className="asteria--variant-currency">
								<Text>
									{TranslationService.get(
										[
											'page.reports.table.cell.label',
											'page.reports.table.cell.currency.label',
										],
										undefined,
										{ object: object },
									)}
								</Text>
							</BaseCell>
							<BaseCell className="asteria--variant-total">
								<Text>
									{TranslationService.get(
										[
											'page.reports.table.cell.label',
											'page.reports.table.cell.total.label',
										],
										undefined,
										{ object: object },
									)}
								</Text>
							</BaseCell>
						</BaseRow>
				  ))}
		</BaseTable>
	);
});

Table.displayName = 'Table';

Table.propTypes = {
	loading: PropTypes.bool,
	data: PropTypes.arrayOf(PropTypes.object),
	sort: PropTypes.shape({
		field: PropTypes.string,
		direction: PropTypes.string,
	}),
	onAction: PropTypes.func,
};

export default Table;
