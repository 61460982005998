import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip/chip';

import { TranslationService } from '@asteria/language';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const InfoStep = (props) => {
	const { onAction } = React.useContext(LayoutContext);

	const [os, setOS] = React.useState('windows');

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(async () => {
		return onAction?.('go', `/integrations/erp/printer/os/${os}/`);
	}, [os, onAction]);

	return (
		<BaseStep {...props} step={`os/${os}`} onBack={onBack} onNext={onNext}>
			<Group
				direction="horizontal"
				horizontalAlign="left"
				className="asteria-component__linear-onboarding-vprint__os-selector"
			>
				<Chip
					size="sm"
					active={os === 'windows'}
					onClick={() => setOS('windows')}
					label={TranslationService.get(
						'onboarding.vprint.step.os.windows',
					)}
				/>
				<Chip
					size="sm"
					active={os === 'macosx'}
					onClick={() => setOS('macosx')}
					label={TranslationService.get(
						'onboarding.vprint.step.os.macosx',
					)}
				/>
			</Group>
		</BaseStep>
	);
};

InfoStep.displayName = 'InfoStep';

InfoStep.propTypes = { className: PropTypes.string };

export default InfoStep;
