import AsteriaCore from '@asteria/core';

import { InvoiceService } from '@asteria/backend-utils-services';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { withPagination } from '@asteria/utils-funcs/request';

InvoiceService.service.extend({
	getQuery: () => `
		mutation AddInvoiceLayoutMessage($id: ID!, $message: String!) {
			addInvoiceLayoutMessage(id: $id, input: { message: $message }) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `addInvoiceLayoutMessage`,
	loggerMethod: `services.InvoiceService.addInvoiceLayoutMessage`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.addInvoiceLayoutMessage`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

InvoiceService.service.extend({
	getQuery: () => `
		mutation UpdateInvoiceLayoutMessages(
			$layoutId: ID!
			$input: [UpdateInvoiceLayoutMessageInputType!]
		) {
			updateInvoiceLayoutMessages(layoutId: $layoutId, input: $input) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `updateInvoiceLayoutMessages`,
	loggerMethod: `services.InvoiceService.updateInvoiceLayoutMessages`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.updateInvoiceLayoutMessages`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const FIELDS = `
_id
companyId
partnerId
status
createdAt
pdfUri
messages {
	id
	userId
	userType
	message
	read
	createdAt
	updatedAt
}
`;

export async function fetch(options) {
	const {
		filters,
		pageFilters,
		accessToken,
		dispatch,
		skipDispatch,
		skipPagination,
		raw,
		fields,
	} = options;

	const fn = skipPagination
		? InvoiceService.layout.fetch
		: withPagination(InvoiceService.layout.fetch);

	const response = await fn(
		{
			isBulk: true,
			pageFilters: pageFilters ?? { first: 1_000 },
			filters: filters,
			fields: `
				pageInfo {
					count
					startCursor
					endCursor
					hasNextPage
					hasPreviousPage
				}
				edges {
					cursor
					node {
						${fields ?? FIELDS}
					}
				}
			`,
		},
		{ token: accessToken },
	);

	if (!skipDispatch) {
		dispatch?.(InvoiceStore.setLayouts(response));
	}

	if (skipPagination) {
		if (raw) {
			return response;
		}

		return (response?.edges ?? []).map(({ node }) => node);
	}

	return response;
}

export async function details(options) {
	const { id, accessToken } = options;

	return InvoiceService.layout.fetchOne(
		{ id: id, isBulk: true, fields: FIELDS },
		{ token: accessToken },
	);
}

export async function count(options) {
	const { filters, accessToken } = options;

	const response = await InvoiceService.layout.fetch(
		{
			isBulk: true,
			pageFilters: { first: 1 },
			filters: filters,
			fields: `pageInfo { count } edges { node { _id } }`,
		},
		{ token: accessToken },
	);

	return response?.pageInfo?.count;
}

export async function unread(options) {
	const {
		filters = {
			messages: {
				read: false,
				userType: AsteriaCore.constants.ADMIN_TOKEN_TYPE,
			},
		},
		pageFilters = { first: 1 },
		accessToken,
	} = options;

	const response = await InvoiceService.layout.fetch(
		{
			isBulk: true,
			pageFilters: pageFilters,
			filters: filters,
			fields: `
				pageInfo {
					count
					startCursor
					endCursor
					hasNextPage
					hasPreviousPage
				}
				edges {
					cursor
					node {
						${FIELDS}
					}
				}
			`,
		},
		{ token: accessToken },
	);

	return (response?.edges ?? []).map(({ node }) => node);
}

export async function sendMessage({ accessToken, id, message, dispatch }) {
	const response = await InvoiceService.service.extension
		.addInvoiceLayoutMessage(
			{ id: id, message: message },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await fetch({ accessToken: accessToken, dispatch: dispatch });

	return id;
}

export async function updateMessage({
	accessToken,
	layoutId,
	input,
	dispatch,
}) {
	const response = await InvoiceService.service.extension
		.updateInvoiceLayoutMessages(
			{ layoutId: layoutId, input: input },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await fetch({ accessToken: accessToken, dispatch: dispatch });

	return layoutId;
}
