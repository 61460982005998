import PropTypes from 'prop-types';

export const QuestionPropTypes = {
	key: PropTypes.string,
	title: PropTypes.string,
	modal: PropTypes.bool,
	content: PropTypes.arrayOf(PropTypes.object),
};

export const SectionPropTypes = {
	name: PropTypes.string,
	content: PropTypes.arrayOf(PropTypes.object),
	questions: PropTypes.arrayOf(PropTypes.shape({ ...QuestionPropTypes })),
	count: PropTypes.number,
	title: PropTypes.string,
};
