import React from 'react';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import { animationListener } from '@asteria/component-core/utils';
import ElementAnchor from '@asteria/component-core/utils/elementAnchor';
import withAnalytics from '@asteria/component-core/utils/withAnalytics';

import { cn } from '@asteria/utils-funcs/classes';

import InputWrapper from '../generic/InputWrapper';
import { withLabel } from '../generic/Label';
import withRegister from '../withRegister';

import DatepickerContent from './DatepickerContent';
import DatepickerContext from './context';
import { parseDate } from './utils';

import './styles.scss';

/**
 * @type { React.ForwardRefExoticComponent<import('./Datepicker').DatepickerProps> }
 */
const Datepicker = React.forwardRef((props, ref) => {
	const {
		className,
		active,
		disabled,
		error,
		prefix,
		postfix,
		icon,
		size,
		name,
		helpText,
		value: $value,
		onChange,

		minDate,
		maxDate,
		isPastDisabled,
		isFutureDisabled,
		date,
		variant,
		children,

		type: $type,
		types,

		repeatable,

		...input
	} = props;

	const [type, setType] = React.useState(() => {
		if (types?.length) {
			if (types.includes($type)) {
				return $type;
			}

			return types[0];
		}

		return $type ?? 'day';
	});

	React.useEffect(() => {
		setType(() => {
			if (types?.length) {
				if (types.includes($type)) {
					return $type;
				}

				return types[0];
			}

			return $type ?? 'day';
		});
	}, [types, $type]);

	const [open, setOpen] = React.useState(false);

	const postfixRef = React.useRef(null);

	// eslint-disable-next-line no-unused-vars
	const handleOpen = React.useCallback(() => {
		setOpen(true);
	}, []);

	// eslint-disable-next-line no-unused-vars
	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleToggle = React.useCallback(() => {
		setOpen((value) => !value);
	}, []);

	delete input.label;
	delete input.uncontrolled;
	delete input.defaultValue;

	const [value, setValue] = React.useState(parseDate($value));

	React.useEffect(() => {
		setValue(parseDate($value));
	}, [$value]);

	const Postfix = React.useMemo(
		() => ({
			...postfix,
			icon: 'date',
			onClick: handleToggle,
			ref: postfixRef,
		}),
		[handleToggle, postfix],
	);

	const handleChange = React.useCallback(() => {}, []);

	const ctx = React.useMemo(
		() => ({
			type: type,
			types: types,
			repeatable: repeatable,
			variant: variant,

			value: value,
			onChange: handleChange,

			date: parseDate(date) ?? value,
			minDate: parseDate(minDate),
			maxDate: parseDate(maxDate),
			isFutureDisabled: isFutureDisabled,
			isPastDisabled: isPastDisabled,
		}),
		[
			date,
			handleChange,
			isFutureDisabled,
			isPastDisabled,
			maxDate,
			minDate,
			repeatable,
			type,
			types,
			value,
			variant,
		],
	);

	return [
		<InputWrapper
			key="datepicker-input"
			active={active}
			disabled={disabled}
			error={error}
			className={cn(
				'asteria-component__form-v2__input',
				'asteria-component__form-v2__datepicker',
				className,
			)}
			prefix={prefix}
			postfix={Postfix}
			icon={icon}
			size={size}
			helpText={helpText}
			value={value}
			name={name}
		>
			<input
				ref={ref}
				name={name}
				onChange={onChange}
				readOnly
				{...input}
			/>
		</InputWrapper>,
		<CSSTransition
			key="datepicker-content"
			in={open}
			appear
			unmountOnExit
			classNames="my-node"
			addEndListener={animationListener}
		>
			<ElementAnchor
				element={postfixRef}
				className="asteria-component__form-v2__datepicker-content__wrapper"
			>
				<DatepickerContext.Provider value={ctx}>
					{React.isValidElement(children?.[0] ?? children) ? (
						children
					) : (
						<DatepickerContent />
					)}
				</DatepickerContext.Provider>
			</ElementAnchor>
		</CSSTransition>,
	];
});

Datepicker.displayName = 'Datepicker';

Datepicker.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	prefix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	postfix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	helpText: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
		PropTypes.node,
	]),
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	size: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,

	minDate: PropTypes.string,
	maxDate: PropTypes.string,
	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,
	date: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'multiple', 'range']),
	children: PropTypes.node,

	type: PropTypes.oneOf(['day', 'week', 'month', 'year']),
	types: PropTypes.arrayOf(PropTypes.oneOf(['day', 'week', 'month', 'year'])),

	repeatable: PropTypes.bool,
};

Datepicker.__docgenInfo = {
	displayName: Datepicker.displayName,
	props: {
		className: { type: { name: 'string' } },
		active: { type: { name: 'bool' } },
		disabled: { type: { name: 'bool' } },
		error: { type: { name: 'object' } },
		prefix: { type: { name: 'object' } },
		postfix: { type: { name: 'object' } },
		helpText: { type: { name: 'func | string | node' } },
		icon: { type: { name: 'string | object' } },
		size: { type: { name: 'string' } },
		name: { type: { name: 'string' } },
		value: { type: { name: 'string' } },

		onFocus: { type: { name: 'func' } },
		onBlur: { type: { name: 'func' } },
		onChange: { type: { name: 'func' } },

		minDate: { type: { name: 'string' } },
		maxDate: { type: { name: 'string' } },
		isPastDisabled: { type: { name: 'bool' } },
		isFutureDisabled: { type: { name: 'bool' } },
		date: { type: { name: 'string' } },

		variant: { type: { name: 'string' } },
		children: { type: { name: 'node' } },

		type: { type: { name: 'string' } },
		types: { type: { name: 'string[]' } },

		repeatable: { type: { name: 'bool' } },
	},
};

export { DatepickerContent };

export default withRegister(
	withAnalytics(withLabel(Datepicker), {
		event: 'form.datepicker',
		methods: [
			{
				key: 'onChange',
				format: (event, { analyticsKey }) => ({
					name: event?.target?.name,
					value: event?.target?.value,
					analyticsKey: analyticsKey,
				}),
			},
			'onBlur',
			'onFocus',
		],
	}),
	{
		exclude: [
			'debounce',
			'min',
			'max',
			'maxLength',
			'minLength',
			'validate',
			'valueAsNumber',
			'setValueAs',
			'disabled',
			'onChange',
			'onBlur',
			'value',
			'shouldUnregister',
			'deps',
		],
	},
);
