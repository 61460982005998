import React from 'react';

import PropTypes from 'prop-types';

import Countries from './Countries';
import SupportForm from './Form';

import './styles.scss';

function SupportPageCountry(props) {
	const { countries, onAction, country } = props;
	return [
		<Countries
			key="countries"
			country={country}
			countries={countries}
			onAction={onAction}
		/>,
		<SupportForm
			key="support-form"
			country={country}
			onAction={onAction}
		/>,
	];
}
SupportPageCountry.displayName = 'SupportPageCountry';
SupportPageCountry.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,
	country: PropTypes.string,
};
export default React.memo(SupportPageCountry);
