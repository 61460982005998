import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';
import { isPossibleToClick } from '@asteria/component-core/utils';

import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useCompanyVersion } from '../../components/CompanyVersion';
import LayoutContext, { MenuContext } from '../context';

import { MENU_ITEMS } from './constants';
import { useIsActive } from './hooks';

import './styles.scss';

const MenuItem = React.memo((props) => {
	const { id, items = [], action, path, level = 1 } = props;

	const { onAction } = React.useContext(LayoutContext);
	const { close } = React.useContext(MenuContext);

	const ref = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username =
		[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
		user?.username ||
		user?.id;

	const hasMultiCompanies = (user?.companies ?? []).length > 1;

	const style = React.useMemo(() => ({ '--level': level }), [level]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'logout') {
				localStorage.removeItem('wingsToken');
				localStorage.removeItem('wingsRefreshToken');

				const url = new URL('', window.location.origin);
				url.search = window.location.search;

				window.location.replace(url.toString());

				return;
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const onClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			if (items?.length) {
				setOpen((value) => !value);
				return;
			}

			close?.(event);

			if (action) {
				return handleAction(...action);
			}

			if (path) {
				return handleAction('go', path);
			}
		},
		[action, close, handleAction, items?.length, path],
	);

	const isActive = useIsActive();

	return (
		<>
			<Group
				className={cn('asteria-widget__layout-header__menu-item', {
					[`asteria-navigation--type-${id}`]: id,
					'asteria--state-active': isActive(path),
					'asteria--state-open': open,
				})}
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
				role="button"
				style={style}
				onClick={onClick}
				ref={ref}
			>
				<Title size="xs">
					{TranslationService.get(
						[
							'layout.header.navigation.item.label',
							`layout.header.navigation.item.${id}.label`,
							id === 'settings' && hasMultiCompanies
								? `layout.header.navigation.item.${id}.multi.label`
								: null,
						],
						undefined,
						{
							user: user,
							username: username,
							company: user?.company,
						},
					)}
				</Title>
				<Button size="sm" icon="chevron-right" />
			</Group>
			{open
				? items.map((object) => (
						<MenuItem
							key={object.id}
							{...object}
							level={level + 1}
						/>
				  ))
				: null}
		</>
	);
});

MenuItem.displayName = 'MenuItem';
MenuItem.propTypes = {
	id: PropTypes.string,
	path: PropTypes.string,
	action: PropTypes.arrayOf(PropTypes.string),
	items: PropTypes.arrayOf(PropTypes.shape({ ...MenuItem.propTypes })),
	level: PropTypes.number,
};

const Menu = React.memo(() => {
	const { state, close } = React.useContext(MenuContext);
	const apiVersion = useCompanyVersion();
	const user = useSelector(AppStore.selectors.user);

	const isDesktop = useMediaQuery('(min-width: 640px)');

	React.useEffect(() => {
		if (isDesktop) {
			close?.();
		}
	}, [close, isDesktop]);

	return (
		<div
			className={cn('asteria-widget__layout-header__menu', {
				'asteria--state-open': state,
			})}
		>
			<div className="asteria-widget__layout-header__menu-content">
				{MENU_ITEMS.filter(
					({ onValidate }) =>
						!onValidate ||
						onValidate({ user: user, apiVersion: apiVersion }),
				)
					.filter((object) => object?.id !== 'mobile-menu')
					.map((object) => (
						<MenuItem key={object?.id} {...object} />
					))}
			</div>
		</div>
	);
});

Menu.displayName = 'Menu';
Menu.propTypes = {};

export default Menu;
