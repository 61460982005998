import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import ConfirmationPage from './Confirmation';
import SettingsPage from './Settings';
import StartPage from './Start';
import SuccessPage from './Success';

import './styles.scss';

const InvoicesBatch = (props) => (
	<Routes>
		<Route index element={<StartPage {...props} />} />
		<Route path="settings" element={<SettingsPage {...props} />} />
		<Route path="confirmation" element={<ConfirmationPage {...props} />} />
		<Route path="success" element={<SuccessPage {...props} />} />
	</Routes>
);

InvoicesBatch.displayName = 'InvoicesBatch';

InvoicesBatch.propTypes = { className: PropTypes.string };

export default InvoicesBatch;
