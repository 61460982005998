import React from 'react';

import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import {
	AccountCard,
	CashflowCard,
	ClientPerformanceCard,
	ClientPerformanceExtra,
	ForecastActionsCard,
	ForecastActionsExtra,
	ForecastCard,
	ForecastExtra,
	ForecastStatusCard,
	ForecastStatusExtra,
	HealthCard,
	HealthExtra,
	IncomingCard,
	InvoicesCard,
	InvoicesExtra,
	OnboardingCard,
	OutgoingCard,
	ProfitCard,
	UnderConstructionCard,
} from '@asteria/component-card';
import FlexBox, { FlexItem } from '@asteria/component-flex-box';
import TimeSelector from '@asteria/component-time-selector';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import StreamlinedViewFeedback from './feedback';
import StreamlineTitle from './title';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<{ children: React.ReactNode, fit?: boolean }> } */
const CardWrapper = function CardWrapper(props) {
	const { children, fit } = props;

	if (fit) {
		return (
			<FlexItem {...props}>
				<div className="w-full">{children}</div>
			</FlexItem>
		);
	}

	return <FlexItem {...props} />;
};

CardWrapper.propTypes = { children: PropTypes.node, fit: PropTypes.bool };

function useRange(date) {
	if (typeof date === 'string') {
		return {
			startDate: formatISO(startOfMonth(parseDate(date)), {
				representation: 'date',
			}),
			endDate: formatISO(endOfMonth(parseDate(date)), {
				representation: 'date',
			}),
		};
	}

	if (date?.visible) {
		return {
			startDate: date?.startDate,
			endDate: date?.endDate,
		};
	}

	return { startDate: null, endDate: null };
}

/** @type { React.FC<Props> } */
const StreamlineView = React.memo(function StreamlineView(props) {
	const { className, onAction, onSubmit } = props;

	const [date, setDate] = React.useState(new Date().toISOString());
	const range = useRange(date);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				setDate(data?.date);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	return (
		<div className={cn('asteria-view__streamline', className)}>
			<StreamlineTitle
				onAction={handleAction}
				onSubmit={onSubmit}
				startDate={range.startDate}
				endDate={range.endDate}
			/>
			<StreamlinedViewFeedback onAction={onAction} />
			<TimeSelector onAction={handleAction} onSubmit={onSubmit} />

			<FlexBox direction="horizontal" breakpointWidth={1200}>
				<FlexBox
					direction="horizontal"
					breakpointWidth={1650}
					breakpointSize={2}
				>
					<FlexBox direction="vertical">
						<HealthCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						>
							{(args) => <HealthExtra {...args} />}
						</HealthCard>
						<ForecastActionsCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						>
							{(args) => <ForecastActionsExtra {...args} />}
						</ForecastActionsCard>
						<ClientPerformanceCard
							clientType="customer"
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						>
							{(args) => (
								<ClientPerformanceExtra
									{...args}
									clientType="customer"
								/>
							)}
						</ClientPerformanceCard>
						<AccountCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						/>
					</FlexBox>
					<FlexBox direction="vertical">
						<IncomingCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						/>
						<InvoicesCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						>
							{(args) => <InvoicesExtra {...args} />}
						</InvoicesCard>
						<ClientPerformanceCard
							clientType="supplier"
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						>
							{(args) => (
								<ClientPerformanceExtra
									clientType="supplier"
									{...args}
								/>
							)}
						</ClientPerformanceCard>
						<ProfitCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
						/>
						<UnderConstructionCard
							startDate={range.startDate}
							endDate={range.endDate}
							onAction={handleAction}
							onSubmit={onSubmit}
							wrapper={{
								as: CardWrapper,
								props: { animated: true, fit: true },
							}}
							modal
						/>
					</FlexBox>
				</FlexBox>

				<FlexBox direction="vertical">
					<OutgoingCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
					<OnboardingCard
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
					<UnderConstructionCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
						modal
					/>
					<ForecastStatusCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <ForecastStatusExtra {...args} />}
					</ForecastStatusCard>
					<ForecastCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <ForecastExtra {...args} />}
					</ForecastCard>
					<CashflowCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
				</FlexBox>

				{/*<FlexBox direction="vertical" breakpointWidth={1365}>
					<HealthCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <HealthExtra {...args} />}
					</HealthCard>
					<IncomingCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>

					<OutgoingCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
				</FlexBox>
				<FlexBox direction="horizontal">
					<ForecastActionsCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <ForecastActionsExtra {...args} />}
					</ForecastActionsCard>
					<InvoicesCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <InvoicesExtra {...args} />}
					</InvoicesCard>
					<OnboardingCard
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
				</FlexBox>
				<FlexBox direction="horizontal">
					<ClientPerformanceCard
						clientType="customer"
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => (
							<ClientPerformanceExtra
								{...args}
								clientType="customer"
							/>
						)}
					</ClientPerformanceCard>
					<ClientPerformanceCard
						clientType="supplier"
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => (
							<ClientPerformanceExtra
								clientType="supplier"
								{...args}
							/>
						)}
					</ClientPerformanceCard>
				</FlexBox>
				<FlexBox direction="horizontal">
					<AccountCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
					<ProfitCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
					<UnderConstructionCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
						modal
					/>
				</FlexBox>
				<FlexBox direction="horizontal">
					<CashflowCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					/>
					<ForecastCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <ForecastExtra {...args} />}
					</ForecastCard>

					<ForecastStatusCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
					>
						{(args) => <ForecastStatusExtra {...args} />}
					</ForecastStatusCard>
				</FlexBox>
				<FlexBox direction="horizontal">
					<UnderConstructionCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
						modal
					/>
					<UnderConstructionCard
						startDate={range.startDate}
						endDate={range.endDate}
						onAction={handleAction}
						onSubmit={onSubmit}
						wrapper={{
							as: CardWrapper,
							props: { animated: true, fit: true },
						}}
						modal
					/>
				</FlexBox>*/}
			</FlexBox>
		</div>
	);
});

StreamlineView.displayName = 'StreamlineView';

StreamlineView.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default StreamlineView;
