import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import { TableCell, TableHeader } from '@asteria/component-core/table';
import { stateClasses } from '@asteria/component-core/utils';

import { Checkbox } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function isFilterButtonActive(sorting, direction, key) {
	return sorting === key && direction === 'DESC';
}

const FilterCellOptions = React.memo((props) => {
	const { name, onAction } = props;

	const onInvoiceSellSelect = React.useCallback(
		(value) => () => onAction?.('invoice-set-sell', value),
		[onAction],
	);

	if (name === 'invoice-sell') {
		return (
			<Dropdown toggle={{ icon: 'cog', size: 'sm' }} size="sm" scroll>
				<DropdownItem onClick={onInvoiceSellSelect('SELL')}>
					{TranslationService.get([
						'invoice.sell.option1',
						'settings.invoice-sell.option.option1',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_1')}>
					{TranslationService.get([
						'invoice.sell.option2',
						'settings.invoice-sell.option.option2',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_2')}>
					{TranslationService.get([
						'invoice.sell.option3',
						'settings.invoice-sell.option.option3',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_3')}>
					{TranslationService.get([
						'invoice.sell.option4',
						'settings.invoice-sell.option.option4',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_4')}>
					{TranslationService.get([
						'invoice.sell.option5',
						'settings.invoice-sell.option.option5',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_5')}>
					{TranslationService.get([
						'invoice.sell.option6',
						'settings.invoice-sell.option.option6',
					])}
				</DropdownItem>
				<DropdownItem onClick={onInvoiceSellSelect('NOSELL_6')}>
					{TranslationService.get([
						'invoice.sell.option7',
						'settings.invoice-sell.option.option7',
					])}
				</DropdownItem>
			</Dropdown>
		);
	}

	return null;
});

FilterCellOptions.displayName = 'FilterCellOptions';
FilterCellOptions.propTypes = {
	name: PropTypes.string,
	onAction: PropTypes.func,
};

const FilterCell = React.memo((props) => {
	const {
		name,
		onAction,
		isActiveCell,
		sortable = true,
		isActiveButton,
		onSorting,

		selectable,
		selected,
		onSelect,
	} = props;

	const onClick = React.useCallback(
		() => onSorting?.(name),
		[name, onSorting],
	);

	const tooltip = React.useMemo(
		() =>
			sortable
				? {
						variant: 'alt',
						tooltip: TranslationService.get([
							'invoices.table.header.cell.tooltip',
							`invoices.table.header.cell.${name}.tooltip`,
						]),
				  }
				: null,
		[name, sortable],
	);

	if (name === 'custom-action') {
		return (
			<TableCell
				className={cn(
					{ [`asteria--cell-${name}`]: name },
					stateClasses({
						active: isActiveCell,
					}),
				)}
			/>
		);
	}

	if (name === 'selector') {
		return (
			<TableCell className="asteria--cell-selector">
				<Checkbox
					uncontrolled
					size="sm"
					value="ALL"
					onChange={onSelect}
					checked={selected}
					disabled={!selectable}
				/>
			</TableCell>
		);
	}

	return (
		<TableCell
			className={cn(
				{ [`asteria--cell-${name}`]: name },
				stateClasses({
					active: isActiveCell,
				}),
			)}
		>
			<Button
				variant="table-filter"
				size="sm"
				label={TranslationService.get([
					'invoices.table.header.cell',
					`invoices.table.header.cell.${name}`,
				])}
				active={isActiveButton}
				icon={sortable ? 'chevron-down' : null}
				iconActive={sortable ? 'chevron-up' : null}
				iconPosition={sortable ? 'last' : null}
				onClick={sortable ? onClick : null}
				data-sorting={name}
				tooltip={tooltip}
			>
				<FilterCellOptions name={name} onAction={onAction} />
			</Button>
		</TableCell>
	);
});

FilterCell.displayName = 'FilterCell';
FilterCell.propTypes = {
	name: PropTypes.string,

	onAction: PropTypes.func,

	isActiveCell: PropTypes.bool,
	isActiveButton: PropTypes.bool,

	sortable: PropTypes.bool,
	onSorting: PropTypes.func,

	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onSelect: PropTypes.func,
};

const Filters = React.memo((props) => {
	const { onAction, objects, fields, sorting, direction, onSorting } = props;

	const dispatch = useDispatch();

	const available = useSelector(
		(store) => {
			const selected = InvoiceStore.selectors.selected(store).reduce(
				(acc, object) => ({
					...acc,
					[object?._id ?? object?.id]: object,
				}),
				{},
			);

			return objects.filter(
				(object) => !selected[object?._id ?? object?.id],
			);
		},
		(a, b) => isEqual(a, b),
	);

	const hasSentFilters = useSelector((store) => {
		const sent = !!InvoiceStore.selectors.filters(store, {
			type: 'SERVICE:STATUS',
			value: 'SENT',
		}).length;

		const completed = !!InvoiceStore.selectors.filters(store, {
			type: 'SERVICE:STATUS',
			value: 'COMPLETED',
		}).length;

		return sent || completed;
	});

	const onSelect = React.useCallback(() => {
		dispatch(InvoiceStore.select(available));
	}, [available, dispatch]);

	return (
		<TableHeader>
			{(fields ?? []).map(({ name, sortable }) => (
				<FilterCell
					key={name}
					name={name}
					onAction={onAction}
					exists={!!objects.length}
					isActiveCell={sorting === name}
					isActiveButton={isFilterButtonActive(
						sorting,
						direction,
						name,
					)}
					onSorting={onSorting}
					sortable={sortable}
					selectable={
						name === 'selector' &&
						!hasSentFilters &&
						!!objects.length
					}
					selected={name === 'selector' && !available.length}
					onSelect={name === 'selector' && onSelect}
				/>
			))}

			<TableCell className="asteria--cell-details" />
		</TableHeader>
	);
});

Filters.displayName = 'Filters';

Filters.propTypes = {
	onAction: PropTypes.func,
	onSorting: PropTypes.func,
	objects: PropTypes.arrayOf(PropTypes.object),
	sorting: PropTypes.string,
	direction: PropTypes.oneOf(['ASC', 'DESC']),
	fields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	length: PropTypes.number,
};

export default Filters;
