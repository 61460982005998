import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import { Context as ContenterContext } from '@asteria/component-core/utils/useContenter';

import Basic from '@asteria/component-integrations/components/flows/basic';
import Code from '@asteria/component-integrations/components/flows/code';
import Configuration from '@asteria/component-integrations/components/flows/generic/configuration';
import Connecting from '@asteria/component-integrations/components/flows/generic/connecting';
import Select from '@asteria/component-integrations/components/flows/generic/select';
import Success from '@asteria/component-integrations/components/flows/generic/success';
import OAuth from '@asteria/component-integrations/components/flows/oauth';
import ListIntegrations from '@asteria/component-integrations/components/list';
import IntegrationContext, {
	Provider as IntegrationContextProvider,
} from '@asteria/component-integrations/context';

import { useSubscription } from '@asteria/utils-websocket/hooks';

import { AuthContext } from '../context';

import {
	DEFAULT_FIELDS,
	fetch as fetchIntegrations,
} from './funcs/integrations';

const IntegrationSteps = (props) => {
	const { onAction, integration: propIntegration } = props;

	const { currentStep, navigation } = useContext(IntegrationContext);
	const { accessToken } = useContext(AuthContext);
	const [flow, setFlow] = useState({});
	const [integration, setIntegration] = useState({});
	const timer = useRef(null);

	const [error, setError] = useState(null); // TODO: Handle GraphQL Errors

	useEffect(() => {
		if (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}, [error]);

	useEffect(() => {
		setIntegration(propIntegration);
	}, [propIntegration]);

	useEffect(() => {
		async function createAndStartPolling() {
			const response = await IntegrationGateway.integration
				.create(
					{
						fields: `ok error integration { ${DEFAULT_FIELDS} }`,
						input: integration,
					},
					{ token: accessToken },
				)
				.catch((err) => ({ ok: false, error: err }));

			if (!response.ok) {
				setError(response.error);
				return;
			}

			setIntegration({
				...integration,
				...response?.integration?.[0],
			});

			const id = response?.integration?.[0]?.id;

			const checkIntegration = async () => {
				await fetchIntegrations({
					token: accessToken,
					variables: { filters: { _id: id } },
				})
					.then((response) => {
						onIntegrationUpdate(response?.[0]);
					})
					.catch((err) => {
						setError(err);
					});

				timer.current = setTimeout(checkIntegration, 10000);
			};

			timer.current = setTimeout(checkIntegration, 10000);
		}

		if (currentStep !== 'connecting') {
			clearTimeout(timer?.current);
		}

		if (currentStep === 'connecting') {
			createAndStartPolling();
		}
	}, [currentStep]);

	const onIntegrationUpdate = useCallback(
		(data) => {
			const updatedIntegration = { ...integration, ...data };

			if (
				updatedIntegration?.disabled &&
				!updatedIntegration?.actions?.length
			) {
				navigation.abort();
				return;
			}

			if (integration) {
				setIntegration(updatedIntegration);
			}

			if (
				updatedIntegration?.config?.connected &&
				!updatedIntegration?.config?.errors?.length &&
				!updatedIntegration?.actions?.length
			) {
				navigation.go('success');
				return;
			}
		},
		[integration, currentStep, navigation],
	);

	const { data } = useSubscription({
		token: accessToken,
		query: `
			subscription {
				integrationUpdated {
					${DEFAULT_FIELDS}
				}
			}
		`,
	});

	useEffect(() => {
		if (currentStep === 'connecting' && data?.data?.integrationUpdated) {
			const { data: { integrationUpdated } = {} } = data;

			if (integrationUpdated.id === integration.id) {
				onIntegrationUpdate(integrationUpdated);
			}
		}
	}, [currentStep, data?.data?.integrationUpdated]);

	if (currentStep === 'connecting') {
		return (
			<Connecting
				integration={integration}
				setIntegration={setIntegration}
				onAction={onAction}
			/>
		);
	}

	if (currentStep === 'success') {
		return (
			<Success
				integration={integration}
				setIntegration={setIntegration}
				onAction={onAction}
			/>
		);
	}

	if (currentStep === 'flow' && flow) {
		if (flow.flow === 'generic') {
			return (
				<Configuration
					integration={integration}
					setIntegration={setIntegration}
					flow={flow}
					setFlow={setFlow}
				/>
			);
		}

		if (flow.flow === 'code') {
			return (
				<Code
					integration={integration}
					setIntegration={setIntegration}
					flow={flow}
					setFlow={setFlow}
				/>
			);
		}

		if (flow.flow === 'oAuth') {
			return (
				<OAuth
					integration={integration}
					setIntegration={setIntegration}
					flow={flow}
					setFlow={setFlow}
				/>
			);
		}

		if (flow.flow === 'basic') {
			return (
				<Basic
					integration={integration}
					setIntegration={setIntegration}
					flow={flow}
					setFlow={setFlow}
				/>
			);
		}
	}

	return (
		<Select
			integration={integration}
			setIntegration={setIntegration}
			flow={flow}
			setFlow={setFlow}
		/>
	);
};

IntegrationSteps.propTypes = {
	integration: PropTypes.object,
	setIntegration: PropTypes.func,
	flow: PropTypes.object,
	setFlow: PropTypes.func,
	onIntegrationUpdate: PropTypes.func,
};

const Integrations = (props) => {
	const {
		onClose,
		onAction,
		page: propsPage = 'list',
		type: propType,
	} = props;

	const dispatch = useDispatch();

	const { accessToken } = useContext(AuthContext);

	const [page, setPage] = useState(propsPage);
	const [integration, setIntegration] = useState({ type: propType });

	const [error, setError] = useState(null); // TODO: Handle GraphQL errors
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}, [error]);

	const refresh = useCallback(async () => {
		if (accessToken) {
			await fetchIntegrations({
				token: accessToken,
				dispatch: dispatch,
			}).catch((err) => {
				setError(err);
			});
		}
	}, [accessToken, dispatch]);

	const handleAction = useCallback(
		async (action, data) => {
			if (action === 'add') {
				setPage('add');
				setIntegration({ ...data });
			}
			if (accessToken && data?.id) {
				if (action === 'delete') {
					const response = await IntegrationGateway.integration
						.remove({ ids: data.id }, { token: accessToken })
						.catch((err) => ({ ok: false, error: err }));

					if (!response.ok) {
						setError(response.error);
						return;
					}

					await refresh();
				}

				if (action === 'enable') {
					const response = await IntegrationGateway.integration
						.enable({ ids: data.id }, { token: accessToken })
						.catch((err) => ({ ok: false, error: err }));

					if (!response.ok) {
						setError(response.error);
						return;
					}

					await refresh();
				}

				if (action === 'disable') {
					const response = await IntegrationGateway.integration
						.disable({ ids: data.id }, { token: accessToken })
						.catch((err) => ({ ok: false, error: err }));

					if (!response.ok) {
						setError(response.error);
						return;
					}

					await refresh();
				}

				// if (action === 'edit') {
				// }

				if (action === 'fetch') {
					const response = await IntegrationGateway.integration
						.importSingle(
							{ integrationId: data.id },
							{ token: accessToken },
						)
						.catch((err) => ({ ok: false, error: err }));

					if (!response.ok) {
						setError(response.error);
						return;
					}
				}
			}

			return onAction?.(action, data);
		},
		[accessToken, onAction, refresh],
	);

	const contenter = useMemo(() => {
		return {};
	}, []);

	const onNavigate = useCallback(
		async (action) => {
			if (action === 'abort' || action === 'done') {
				setIntegration({ type: integration.type });
				setPage('list');
			}

			if (action === 'abort' && integration?.id) {
				await IntegrationGateway.integration
					.remove({ ids: [integration.id] }, { token: accessToken })
					.catch((err) => ({ ok: false, error: err }));
			}
		},
		[accessToken, integration.id, integration.type],
	);

	useEffect(() => {
		setLoading(true);
		refresh().then(() => {
			setLoading(false);
		});
	}, [refresh]);

	useEffect(() => {
		refresh();
	}, [page]);

	if (page === 'add') {
		return (
			<ContenterContext.Provider value={contenter}>
				<IntegrationContextProvider onNavigate={onNavigate}>
					<IntegrationSteps
						integration={integration}
						onAction={handleAction}
					/>
				</IntegrationContextProvider>
			</ContenterContext.Provider>
		);
	}

	return (
		<ListIntegrations
			onClose={onClose}
			onAction={handleAction}
			loading={loading}
		/>
	);
};

Integrations.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	integrations: PropTypes.arrayOf(PropTypes.any),
	page: PropTypes.string,
};

export default Integrations;
