import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import GridContext from './GridContext';
import GridItem from './GridItem';

import './styles.scss';

const Grid = React.memo((props) => {
	const { className, children, cols = 2 } = props;

	const [{ hidden, removed, full, layout }, dispatch] = useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'SHOW':
					return {
						...state,
						hidden: state.hidden.filter(
							(id) => action.payload !== id,
						),
						removed: state.removed.filter(
							(id) => action.payload !== id,
						),
					};

				case 'HIDE':
					return {
						...state,
						hidden: state.hidden.concat(action.payload),
						full: state.full === action.payload ? null : state.full,
					};

				case 'REMOVE':
					return {
						...state,
						removed: state.removed.concat(action.payload),
						full: state.full === action.payload ? null : state.full,
					};

				case 'FULL_SCREEN':
					return {
						...state,
						full:
							state.full === action.payload
								? null
								: action.payload,
					};

				case 'LAYOUT':
					return {
						...state,
						layout: action.payload,
					};

				default:
					return state;
			}
		},
		{ hidden: [], removed: [], layout: { cols: cols }, full: null },
	);

	useEffect(() => {
		dispatch({ type: 'LAYOUT', payload: { cols: cols } });
	}, [cols]);

	const hide = useCallback(({ id, remove }) => {
		if (remove) {
			return dispatch({ type: 'REMOVE', payload: id });
		}

		return dispatch({ type: 'HIDE', payload: id });
	}, []);

	const show = useCallback((id) => {
		return dispatch({ type: 'SHOW', payload: id });
	}, []);

	const showFull = useCallback((id) => {
		return dispatch({ type: 'FULL_SCREEN', payload: id });
	}, []);

	const setLayout = useCallback(({ cols }) => {
		return dispatch({ type: 'LAYOUT', payload: { cols: cols } });
	}, []);

	const context = useMemo(
		() => ({
			hide: hide,
			show: show,
			showFull: showFull,
			setLayout: setLayout,
			hidden: hidden,
			removed: removed,
			fullItem: full,
		}),
		[hide, show, showFull, setLayout, hidden, removed, full],
	);

	const styles = useMemo(
		() => ({
			'--cols': layout?.cols,
		}),
		[layout.cols],
	);

	return (
		<GridContext.Provider value={context}>
			<div
				className={cn(
					'asteria-component__grid',
					{
						'asteria--state-fullscreen': full,
					},
					className,
				)}
				style={styles}
			>
				{children}
			</div>
		</GridContext.Provider>
	);
});

Grid.displayName = 'Grid';

Grid.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	cols: PropTypes.number,
};

export default Grid;
export { GridItem };
