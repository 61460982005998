import React from 'react';

import PropTypes from 'prop-types';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const InfoStep = (props) => {
	const { onAction } = React.useContext(LayoutContext);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(async () => {
		return onAction?.('go', `/integrations/erp/printer/os`);
	}, [onAction]);

	return <BaseStep {...props} step="info" onBack={onBack} onNext={onNext} />;
};

InfoStep.displayName = 'InfoStep';

InfoStep.propTypes = { className: PropTypes.string };

export default InfoStep;
