/* eslint-disable spellcheck/spell-checker */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

// import store from '@asteria/datalayer';

/*
import { useStore } from '@asteria/utils';
import DataLayerContext from '@asteria/services-datalayer/react/context';
import { Service as FeatureService } from '@asteria/component-featureflag';
import {
	disableFeature,
	enableFeature,
	updateFeature,
	addFeature,
	removeFeature,
	enableFeatures,
	disableFeatures,
} from '@asteria/services-datalayer/services/features/actions';
*/
import Drawer from './drawer';
import IconButton from './iconButton';

const Input = styled.input`
	display: block;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
	border-radius: 2px;
	padding: 4px;
	width: 100%;

	font-family: Source Code Pro;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 8px;
`;

const Textarea = styled.textarea`
	display: block;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
	border-radius: 2px;
	padding: 4px;
	width: 100%;

	font-family: Source Code Pro;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 8px;
`;

const Checkbox = styled(({ className, active, onClick }) => (
	<div
		role="button"
		tabIndex="-1"
		onClick={onClick}
		onKeyPress={onClick}
		className={cn(className, {
			'asteria-checked': active,
		})}
	>
		<svg
			width="12"
			height="9"
			viewBox="0 0 12 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M4.07573 8.7728L0.175729 4.8728C-0.0585762 4.63849 -0.0585762 4.25859 0.175729 4.02426L1.02424 3.17573C1.25854 2.9414 1.63846 2.9414 1.87277 3.17573L4.5 5.80294L10.1272 0.175729C10.3615 -0.0585762 10.7415 -0.0585762 10.9758 0.175729L11.8243 1.02426C12.0586 1.25857 12.0586 1.63846 11.8243 1.87279L4.92426 8.77282C4.68994 9.00713 4.31004 9.00713 4.07573 8.7728V8.7728Z" />
		</svg>
	</div>
))`
	border: 2px solid #3b3f51;
	box-sizing: border-box;
	border-radius: 3px;
	padding: 0px 3px;
	margin-right: 4px;
	cursor: pointer;
	svg {
		fill: #646772;
	}
	&.asteria-checked {
		background: #3b3f51;
		svg {
			fill: #69a9d7;
		}
	}
`;

const Feature = styled(({ className, feature, active, toggle, edit }) => (
	<li className={cn('asteria-features-item', className)}>
		<div
			onClick={() => toggle(feature)}
			onKeyPress={() => toggle(feature)}
			role="button"
			tabIndex="-1"
		>
			<Checkbox active={active} />
			<h3>
				{`${feature?.name || feature?.key} ${
					active ? `(${active.source})` : ''
				}`}
			</h3>
			<button
				type="button"
				className="edit-feature"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();

					edit(feature);
				}}
			>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g opacity="0.3">
						<path
							d="M6 0C2.68652 0 0 2.68749 0 6C0 9.31444 2.68652 12 6 12C9.31348 12 12 9.31444 12 6C12 2.68749 9.31348 0 6 0ZM6 2.66129C6.56119 2.66129 7.01613 3.11623 7.01613 3.67742C7.01613 4.23861 6.56119 4.69355 6 4.69355C5.43881 4.69355 4.98387 4.23861 4.98387 3.67742C4.98387 3.11623 5.43881 2.66129 6 2.66129ZM7.35484 8.80645C7.35484 8.96678 7.22485 9.09677 7.06452 9.09677H4.93548C4.77515 9.09677 4.64516 8.96678 4.64516 8.80645V8.22581C4.64516 8.06548 4.77515 7.93548 4.93548 7.93548H5.22581V6.3871H4.93548C4.77515 6.3871 4.64516 6.2571 4.64516 6.09677V5.51613C4.64516 5.3558 4.77515 5.22581 4.93548 5.22581H6.48387C6.6442 5.22581 6.77419 5.3558 6.77419 5.51613V7.93548H7.06452C7.22485 7.93548 7.35484 8.06548 7.35484 8.22581V8.80645Z"
							fill="white"
						/>
					</g>
				</svg>
			</button>
		</div>
	</li>
))`
	margin: 0;
	padding: 8px 0px;

	div {
		display: flex;
		align-items: center;
		padding-right: 3px;
		&:hover .edit-feature {
			opacity: 1;
		}
	}

	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		height: 40px;
		display: flex;
		align-items: center;

		&.edit-feature {
			opacity: 0;
			margin-right: -8px;
			&:hover {
				opacity: 1;
			}
		}

		&.asteria-remove {
			svg {
				fill: red;
			}
		}
	}

	${Checkbox} {
		height: 22px;
		width: 22px;
		margin-right: 10px;
	}

	input {
		border: 2px solid #3b3f51;
		border-radius: 3px;
		background: transparent;

		:checked {
			background: #404251;
		}
	}

	h3 {
		font-family: Source Code Pro;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;
		margin: 0;
		flex-grow: 1;
	}
`;

const ListToggler = styled(({ className, onClick, children }) => (
	<button className={cn(className)} type="button" onClick={onClick}>
		{children}
		<svg
			width="11"
			height="7"
			viewBox="0 0 11 7"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M6.02718 0.762888L10.2759 5.01156C10.5695 5.30522 10.5695 5.78007 10.2759 6.0706L9.56982 6.77663C9.27616 7.07029 8.80131 7.07029 8.51078 6.77663L5.49609 3.7682L2.48454 6.77976C2.19088 7.07341 1.71603 7.07341 1.42549 6.77976L0.716338 6.07373C0.42268 5.78007 0.42268 5.30522 0.716338 5.01468L4.96501 0.766011C5.25867 0.469229 5.73352 0.469229 6.02718 0.762888Z" />
		</svg>
	</button>
))`
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
	max-height: 40px;
	min-height: 40px;
	display: flex;
	align-items: center;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	font-size: 10px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.4);
	text-transform: uppercase;

	padding: 16px 12px 8px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	svg {
		transition: all 0.5s ease;
		transform: rotate(180deg);
		fill: #656871;
	}

	&.asteria-is-open {
		color: #6db2e2;
	}

	&.asteria-is-open svg {
		transform: rotate(0deg);
		fill: #fff;
	}
	&:hover {
		color: #6db2e2;
	}
	&:hover svg {
		fill: #fff;
	}
`;

const Spacer = styled.div`
	flex-grow: 1;
`;

const EditFeature = styled(({ className, item, edit }) => {
	/*
	const { dispatch } = useContext(DataLayerContext);
	const [isAdmin = false] = useStore(
		'store-auth',
		({ admin: { authorized = false } = {} }) => authorized,
	);
	*/
	const isAdmin = false;

	const [name, setName] = useState(item?.name || item?.key);
	const [description, setDescription] = useState(item?.description);
	useEffect(() => {
		setName(item?.name || item?.key);
		setDescription(item?.description);
	}, [item]);

	return (
		<Drawer className={cn(className, { 'asteria-open': item !== null })}>
			<IconButton
				onClick={() => edit(null)}
				position="top-right"
				iconOnly
			>
				<svg width="11" height="10" viewBox="0 0 11 10" fill="none">
					<path
						d="M8.21356 0.454132L5.48615 3.18154L2.75873 0.454132C2.42406 0.119456 1.88123 0.119456 1.54655 0.454132L0.94046 1.06022C0.605784 1.3949 0.605784 1.93773 0.94046 2.27241L3.66787 4.99982L0.94046 7.72723C0.605784 8.06191 0.605784 8.60474 0.94046 8.93941L1.54655 9.5455C1.88123 9.88018 2.42406 9.88018 2.75873 9.5455L5.48615 6.81809L8.21356 9.5455C8.54823 9.88018 9.09107 9.88018 9.42574 9.5455L10.0318 8.93941C10.3665 8.60474 10.3665 8.06191 10.0318 7.72723L7.30442 4.99982L10.0318 2.27241C10.3665 1.93773 10.3665 1.3949 10.0318 1.06022L9.42574 0.454132C9.09107 0.119456 8.54823 0.119456 8.21356 0.454132Z"
						fill="#656871"
					/>
				</svg>
			</IconButton>
			<div>
				<Input
					value={name}
					onChange={(e) => setName(e.target.value)}
					required
				/>
				<Textarea
					value={description}
					onChange={(e) => setName(e.target.value)}
				/>
				<p>{item?.key}</p>
				<Spacer />
				{item?._id && isAdmin ? (
					<button
						type="button"
						className="asteria-button asteria-remove-feature"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							if (item._id) {
								// dispatch(removeFeature(item));
							}
							edit(null);
						}}
					>
						Delete
					</button>
				) : null}

				{isAdmin ? (
					<button
						type="button"
						className="asteria-button asteria-save-feature"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							/*
							if (item._id) {
								dispatch(
									updateFeature({
										...item,
										name,
										description,
									}),
								);
							} else {
								dispatch(
									addFeature({ ...item, name, description }),
								);
							}
							*/
							edit(null);
						}}
					>
						Save
					</button>
				) : null}
			</div>
		</Drawer>
	);
})`
	position: absolute;
	left: 100%;
	width: 0px;
	height: 100vh;
	background-color: #f5f5f5;
	overflow: hidden;
	&.asteria-open {
		width: 100%;
	}

	> div {
		padding: 16px;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	button {
		&.asteria-button {
			cursor: pointer;
			border: none;
			background: #6db2e2;
			border-radius: 3px;
			margin-top: 4px;
			margin-bottom: 4px;
			padding: 8px;

			font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
				Helvetica, Arial, sans-serif, 'Apple Color Emoji',
				'Segoe UI Emoji', 'Segoe UI Symbol';
			font-style: normal;
			font-weight: 900;
			font-size: 12px;
			line-height: 14px;
			text-align: center;

			color: #ffffff;

			&.asteria-remove-feature {
				background: transparent;
				color: #64676f;
			}
		}
	}
`;

const FeaturesList = styled(({ className }) => {
	const [type, setType] = useState('PARTNER');
	const [target, setTarget] = useState(null);
	//  { dispatch } = useContext(DataLayerContext);
	const [showList, setShowList] = useState('');
	const [featureCode, setFeatureCode] = useState('');
	const [search, setSearch] = useState('');
	const [edit, setEdit] = useState(null);
	const availableFeatures = useMemo(() => [], []);
	const partner = false;
	const company = false;
	const user = false;
	// const isAdmin = false;
	const partners = [];
	const activeFeatures = useMemo(() => {}, []);
	/*
	const [availableFeatures = []] = useStore(
		'store-features',
		({ available = [] }) => available,
	);

	const [partner = false] = useStore(
		'store-auth',
		({ data: { partnerId = false } = {} }) => partnerId,
	);

	const [company = false] = useStore(
		'store-auth',
		({ data: { companyId = false } = {} }) => companyId,
	);

	const [user = false] = useStore(
		'store-auth',
		({ data: { id = false } = {} }) => id,
	);

	const [isAdmin = false] = useStore(
		'store-auth',
		({ admin: { authorized = false } = {} }) => authorized,
	);

	const [partners = []] = useStore(
		'store-auth',
		({ partners: list }) => list,
	);

	const [activeFeatures = {}] = useStore(
		'store-features',
		({ active = {} }) => active,
	);
	*/

	const sortedFeatures = useMemo(
		() => availableFeatures.sort((a, b) => a?.key?.localeCompare(b?.key)),
		[availableFeatures],
	);

	useEffect(() => {
		setFeatureCode(
			sortedFeatures
				.filter((feature) => activeFeatures[feature.key])
				.map(({ key }) => key)
				.join(','),
		);
	}, [activeFeatures, sortedFeatures]);

	const filteredFeatures = useMemo(() => {
		if (search) {
			return sortedFeatures.filter(({ key }) => {
				if (key && key.includes(search)) {
					return true;
				}

				return false;
			});
		}

		return sortedFeatures;
	}, [search, sortedFeatures]);

	const toggleFeature = useCallback(
		(feature) => {
			let entityId = target || partner;
			let targetType = type;
			if (
				activeFeatures[feature.key] &&
				activeFeatures[feature.key].source
			) {
				targetType = activeFeatures[feature.key].source;
			}

			if (targetType === 'COMPANY') {
				entityId = company;
			} else if (targetType === 'USER') {
				// eslint-disable-next-line no-unused-vars
				entityId = user;
			}

			/*
			if (activeFeatures[feature.key]) {
				dispatch(
					disableFeature(
						feature,
						{
							type: targetType,
							entityId,
						},
						isAdmin && feature._id,
					),
				);
			} else {
				dispatch(
					enableFeature(
						feature,
						{
							type,
							entityId,
						},
						isAdmin && feature._id,
					),
				);
			}
			*/
		},
		[target, partner, type, activeFeatures, company, user],
	);

	const applyFeatureCode = useCallback(
		async (codes) => {
			const featuresCodes = codes.split(',');
			let entityId = target || partner;
			const targetType = type;

			if (targetType === 'COMPANY') {
				entityId = company;
			} else if (targetType === 'USER') {
				// eslint-disable-next-line no-unused-vars
				entityId = user;
			}

			const eFeatures = featuresCodes.filter(
				(key) => !activeFeatures[key],
			);

			if (eFeatures.length > 0) {
				/*
				dispatch(
					enableFeatures(
						{ keys: eFeatures },
						{
							type,
							entityId,
						},
					),
				);
				*/
			}

			const dFeatures = Object.keys(activeFeatures)
				.filter(
					(key) =>
						!featuresCodes.includes(key) && activeFeatures[key]._id,
				)
				.map((key) => activeFeatures[key]._id);

			if (dFeatures.length > 0) {
				/*
				dispatch(
					disableFeatures(
						{ ids: dFeatures },
						{
							type: targetType,
							entityId,
						},
					),
				);
				*/
			}
		},
		[activeFeatures, company, partner, target, type, user],
	);

	const newFeatures = useMemo(
		() =>
			Object.values(FeatureService.detected)
				.filter(
					(detected) =>
						!!availableFeatures.find(
							(f) => f.key === detected.key,
						) === false,
				)
				.sort((a, b) => a?.key?.localeCompare(b?.key)),
		[availableFeatures],
	);

	return (
		<div className={cn(className)} style={{ height: 'calc(100% - 37px)' }}>
			<select value={type} onChange={(e) => setType(e.target.value)}>
				<option value="PARTNER">Partner</option>
				<option value="COMPANY">Company</option>
				<option value="USER">User</option>
			</select>
			{type === 'PARTNER' ? (
				<select
					value={target || partner}
					onChange={(e) => setTarget(e.target.value)}
				>
					{partners.map(({ id, name }) => (
						<option key={id} value={id}>
							{name}
						</option>
					))}
					<option value="COMPANY">Company</option>
					<option value="USER">User</option>
				</select>
			) : null}
			<input
				type="text"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				placeholder="Search feature"
			/>
			<EditFeature item={edit} edit={setEdit} />
			<div className="features-wrapper">
				<ListToggler
					onClick={() =>
						setShowList(
							showList === 'allFeatures' ? '' : 'allFeatures',
						)
					}
					className={cn({
						'asteria-is-open': showList === 'allFeatures',
					})}
				>
					All
				</ListToggler>
				<ul
					className={cn('scrollable', {
						'asteria-is-open': showList === 'allFeatures',
					})}
				>
					{filteredFeatures.map((feature) => (
						<Feature
							key={feature.key}
							feature={feature}
							active={activeFeatures[feature.key]}
							toggle={toggleFeature}
							edit={setEdit}
						/>
					))}
					<li>
						<textarea
							value={featureCode}
							onChange={(e) => setFeatureCode(e.target.value)}
						/>
						<button
							type="button"
							onClick={() => applyFeatureCode(featureCode)}
						>
							Apply Features
						</button>
					</li>
				</ul>
				<ListToggler
					onClick={() => setShowList(showList === 'new' ? '' : 'new')}
					className={cn({
						'asteria-is-open': showList === 'new',
					})}
				>
					New Features
				</ListToggler>
				<ul
					className={cn('asteria-features scrollable', {
						'asteria-is-open': showList === 'new',
					})}
				>
					{newFeatures.map((feature) => (
						<Feature
							key={feature.key}
							feature={feature}
							active={activeFeatures[feature.key]}
							toggle={toggleFeature}
							edit={setEdit}
						/>
					))}
				</ul>
				<ListToggler
					onClick={() =>
						setShowList(showList === 'widgets' ? '' : 'widgets')
					}
					className={cn({
						'asteria-is-open': showList === 'widgets',
					})}
				>
					Widgets
				</ListToggler>
				<ul
					className={cn('asteria-features scrollable', {
						'asteria-is-open': showList === 'widgets',
					})}
				>
					<li className="asteria-features-item">
						<div role="button">
							<div role="button" className="sc-cIShpX gKGtbM">
								<svg
									width="12"
									height="9"
									viewBox="0 0 12 9"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M4.07573 8.7728L0.175729 4.8728C-0.0585762 4.63849 -0.0585762 4.25859 0.175729 4.02426L1.02424 3.17573C1.25854 2.9414 1.63846 2.9414 1.87277 3.17573L4.5 5.80294L10.1272 0.175729C10.3615 -0.0585762 10.7415 -0.0585762 10.9758 0.175729L11.8243 1.02426C12.0586 1.25857 12.0586 1.63846 11.8243 1.87279L4.92426 8.77282C4.68994 9.00713 4.31004 9.00713 4.07573 8.7728V8.7728Z" />
								</svg>
							</div>
							<h3>wing-toolbar</h3>
							<button type="button" className="edit-feature">
								<svg
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g opacity="0.3">
										<path
											d="M6 0C2.68652 0 0 2.68749 0 6C0 9.31444 2.68652 12 6 12C9.31348 12 12 9.31444 12 6C12 2.68749 9.31348 0 6 0ZM6 2.66129C6.56119 2.66129 7.01613 3.11623 7.01613 3.67742C7.01613 4.23861 6.56119 4.69355 6 4.69355C5.43881 4.69355 4.98387 4.23861 4.98387 3.67742C4.98387 3.11623 5.43881 2.66129 6 2.66129ZM7.35484 8.80645C7.35484 8.96678 7.22485 9.09677 7.06452 9.09677H4.93548C4.77515 9.09677 4.64516 8.96678 4.64516 8.80645V8.22581C4.64516 8.06548 4.77515 7.93548 4.93548 7.93548H5.22581V6.3871H4.93548C4.77515 6.3871 4.64516 6.2571 4.64516 6.09677V5.51613C4.64516 5.3558 4.77515 5.22581 4.93548 5.22581H6.48387C6.6442 5.22581 6.77419 5.3558 6.77419 5.51613V7.93548H7.06452C7.22485 7.93548 7.35484 8.06548 7.35484 8.22581V8.80645Z"
											fill="white"
										/>
									</g>
								</svg>
							</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
})`
	.features-wrapper {
		margin: 0;
		padding: 0;
		display: flex;
		height: 100%;
		flex-direction: column;
		width: 100%;
	}
	ul {
		list-style: none;
		padding: 0;
		display: none;
		&.asteria-is-open {
			display: flex;
			flex-direction: column;
		}

		&.asteria-features {
		}

		&.scrollable {
			&.asteria-is-open {
				overflow: hidden auto;
				padding: 0 0 0 12px;
				margin: 0;
				width: 279px;
			}
			&::-webkit-scrollbar {
				background-color: transparent;
				width: 16px;
			}
			&::-webkit-scrollbar-track {
				background-color: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 16px;
				border: 5px solid #2a2d3d;
			}
			&::-webkit-scrollbar-button {
				display: none;
			}
		}

		.asteria-features-item {
			margin: 0;
			padding: 0px 0px;
			cursor: pointer;

			h3 {
				overflow: hidden;
				display: block;
				color: #ffffff;
				padding: 8px 0;
				display: block;
				min-width: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;

				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
					Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
					'Segoe UI Emoji', 'Segoe UI Symbol';
				font-size: 14px;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.6);
			}
			&:hover h3 {
				color: rgba(255, 255, 255, 1);
			}
		}
	}
`;

export default FeaturesList;
