import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip/chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import SearchContext from '../context';

import '../styles.scss';

const ChipServiceStatus = (props) => {
	const { status, badge } = props;

	const { onSubmit } = React.useContext(SearchContext);

	const dispatch = useDispatch();

	const { data: count } = useQuery({
		queryKey: ['invoices', 'search', 'status', { status: status }],
		queryFn: async () => {
			return onSubmit?.('invoices:available', {
				filters: { services: { status: status } },
				pageFilters: { first: 1 },
				fields: `_id`,
			});
		},
		select: (response) => response?.pageInfo?.count ?? 0,

		placeholderData: 0,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SERVICE:STATUS',
				value: status,
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'SERVICE:STATUS',
				value: status,
			}),
		);
	}, [dispatch, status]);

	if (!count) {
		return null;
	}

	return (
		<BadgeWrapper size="sm" icon={badge ? 'warning' : null}>
			<TooltipWrapper
				tooltip={TranslationService.get(
					[
						'invoices.search.quick.status.tooltip',
						`invoices.search.quick.status.${status.toLowerCase()}.tooltip`,
					].concat(
						active
							? [
									'invoices.search.quick.status.active.tooltip',
									`invoices.search.quick.status.${status.toLowerCase()}.active.tooltip`,
							  ]
							: [],
					),
					undefined,
					{ invoices: { length: count } },
				)}
			>
				<Chip
					size="sm"
					label={TranslationService.get(
						[
							'invoices.search.quick.status.label',
							`invoices.search.quick.status.${status.toLowerCase()}.label`,
						].concat(
							active
								? [
										'invoices.search.quick.status.active.label',
										`invoices.search.quick.status.${status.toLowerCase()}.active.label`,
								  ]
								: [],
						),
						undefined,
						{ invoices: { length: count } },
					)}
					onClick={onClick}
					active={active}
				/>
			</TooltipWrapper>
		</BadgeWrapper>
	);
};

ChipServiceStatus.displayName = 'ChipServiceStatus';
ChipServiceStatus.propTypes = {
	status: PropTypes.string,
	badge: PropTypes.bool,
};

export default ChipServiceStatus;
