import React, { useCallback, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Progress from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';
import useContenter from '@asteria/component-core/utils/useContenter';
import { FooterSection } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../../context';
import Step, { Content, Footer, Header } from '../../../step';
import Actions from '../actions';

import './connecting.scss';

function getRedirectURI(integration) {
	return integration?.config?.server?.urls?.REDIRECT?.[0]?.formattedHref;
}

const GenericConnecting = function GenericConnecting(props) {
	const { className, integration, setIntegration, onAction, onClose } = props;
	const { navigation } = useContext(NavigationContext);

	const [isOpen, setOpen] = useState(false);
	const [actions, setActions] = useState([]);

	const content = useContenter(`integrations.${integration.key}.connecting`);
	const step = `${integration.type}.oauth.info`;

	useEffect(() => {
		const URI = getRedirectURI(integration);

		const mode = integration?.config?.client?.mode;

		if (!URI || isOpen || mode == 'sandbox') {
			return;
		}

		setOpen(true);

		const win = window.open('', '_blank');

		if (!win) {
			setActions([{ action: 'browser.enable.popup', status: 'PENDING' }]);
			return;
		}

		win.location = URI;
		win.focus();
	}, [getRedirectURI(integration)]);

	useEffect(() => {
		const hasActions = integration?.actions?.some(
			({ status }) => status !== 'CANCELLED' && status !== 'PROCESSED',
		);

		if (integration?.status?.state === 'ERROR' && !hasActions) {
			navigation.back();
			onAction?.('remove', { ...integration });
			setIntegration?.({ type: integration.type });
		}
	}, [integration?.actions, integration?.status?.state, onAction]);

	useEffect(() => {
		if (integration?.actions?.length) {
			setActions(integration?.actions);
		}
	}, [integration?.actions]);

	const handleAction = useCallback(
		(action) => {
			const { action: type } = action;

			if (type === 'browser.enable.popup') {
				const URI = getRedirectURI(integration);

				const win = window.open('', '_blank');

				if (!win) {
					return;
				}

				win.location = URI;
				win.focus();

				setActions(integration?.actions);
			} else {
				onAction?.('checkAction', action);
			}
		},
		[onAction, getRedirectURI(integration), integration?.actions],
	);

	const onAbort = useCallback(() => {
		onAction?.('delete', { ...integration });
		navigation?.abort?.();
	}, [integration, onAction, navigation.abort]);

	const onBack = useCallback(() => {
		onAction?.('delete', { ...integration });
		navigation?.back?.();
	}, [integration, onAction, navigation.back]);

	const isConnected = integration?.config?.connected || false;

	return (
		<Step
			className={cn(
				'asteria-component__integration-generic__connecting',
				{
					[`asteria-component__integration-generic__connecting--type-${integration.type}`]:
						integration.type,
				},
				className,
			)}
			step={step}
		>
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration.type}.add.title`,
					],
					undefined,
					{ integration: integration },
				)}
			</Header>
			<Content step={step}>
				<div className="asteria-component__integration-step__content__inner">
					<div className="asteria-component__connecting__wrapper">
						<div className="asteria-component__connecting">
							{(!actions || actions.length === 0) && (
								<>
									<Text>
										{TranslationService.get(
											[
												`integrations.connecting`,
												`integrations.${integration.type}.connecting`,
												`integrations.${integration.type}.${integration.key}.connecting`,
											],
											undefined,
											{ integration: integration },
										)}
									</Text>
									<Progress progress={-1} />
								</>
							)}
							<Contenter content={content} />
							<Actions
								integration={integration}
								onAction={handleAction}
								actions={actions}
							/>
						</div>
					</div>
				</div>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${integration.key}.abort`}
						variant="tertiary"
						label={TranslationService.get('action.abort')}
						onClick={onAbort}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${integration.key}.back`}
						variant="secondary"
						label={TranslationService.get('action.back')}
						onClick={onBack}
					/>
					{integration?.status?.state !== 'ERROR' && isConnected ? (
						<Button
							variant="primary"
							label={TranslationService.get('action.next')}
							onClick={navigation.next}
						/>
					) : null}
				</FooterSection>
			</Footer>
		</Step>
	);
};

GenericConnecting.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	onAction: PropTypes.func,
	setIntegration: PropTypes.func,
};

GenericConnecting.defaultProps = {};

export default GenericConnecting;
