import React from 'react';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import Actions from '../../../components/Actions';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const VariantInitial = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const actions = React.useMemo(
		() => [{ id: 'connection', variant: 'integration', type: 'erp' }],
		[],
	);

	return [
		<div key="content" className="asteria-page__wrapper">
			<TextGroup>
				<Title size="page-title">
					{TranslationService.get([
						`page.invoices.title`,
						`page.invoices.initial.title`,
					])}
				</Title>
				<Text size="lg">
					{TranslationService.get([
						`page.invoices.content`,
						`page.invoices.initial.content`,
					])}
				</Text>
			</TextGroup>
		</div>,
		<Actions
			key="actions"
			actions={actions}
			onAction={onAction}
			onSubmit={onSubmit}
		/>,
	];
};

VariantInitial.displayName = 'VariantInitial';

VariantInitial.propTypes = {};

export default VariantInitial;
