import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

function VismaAdministrationStep(props) {
	const { onAction } = React.useContext(LayoutContext);

	const onAbort = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() =>
			onAction?.('go', '/integrations/erp/visma.administration/software'),
		[onAction],
	);

	return (
		<BaseStep
			{...props}
			step="installation-visma"
			onAbort={onAbort}
			onNext={onNext}
		/>
	);
}

function SoftwareStep(props) {
	const { onAction } = React.useContext(LayoutContext);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() => onAction?.('go', '/integrations/erp/visma.administration/plugin'),
		[onAction],
	);

	return (
		<BaseStep
			{...props}
			step="installation-software"
			onBack={onBack}
			onNext={onNext}
		/>
	);
}

function PluginStep(props) {
	const { onAction } = React.useContext(LayoutContext);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() => onAction?.('go', '/integrations/erp/visma.administration/setup'),
		[onAction],
	);

	return (
		<BaseStep
			{...props}
			step="installation-plugin"
			onBack={onBack}
			onNext={onNext}
		/>
	);
}

const InstallationStep = (props) => {
	return (
		<Routes>
			<Route index element={<VismaAdministrationStep {...props} />} />
			<Route path="software" element={<SoftwareStep {...props} />} />
			<Route path="plugin" element={<PluginStep {...props} />} />
		</Routes>
	);
};

InstallationStep.displayName = 'InstallationStep';

InstallationStep.propTypes = { className: PropTypes.string };

export default InstallationStep;
