import React from 'react';

import PropTypes from 'prop-types';

import useContenter from '@asteria/component-core/utils/useContenter';
import { Content } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

const StepContent = (props) => {
	const { className, step, children, ...contentProps } = props;
	const preContent = useContenter(`integrations.step.${step}.preContent`);
	const postContent = useContenter(`integrations.step.${step}.postContent`);

	return (
		<Content className={cn(className)} {...contentProps}>
			{preContent ? (
				<Contenter
					content={preContent}
					className="asteria-component__integration-step__pre-content"
				/>
			) : null}

			<div className="asteria-component__integration-step__content">
				{children}
			</div>

			{postContent ? (
				<Contenter
					content={postContent}
					className="asteria-component__integration-step__post-content"
				/>
			) : null}
		</Content>
	);
};

StepContent.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	children: PropTypes.node,
};

export default StepContent;
