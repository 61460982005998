import { compareDesc, isAfter, isBefore, isSameDay, parseISO } from 'date-fns';
import { get, merge } from 'lodash-es';

export function formatStatistics(objects) {
	const total = objects.reduce((acc, object) => {
		if (object?.isCreditInvoice) {
			return acc - (object?.sums?.original?.total ?? 0);
		} else {
			return acc + (object?.sums?.original?.total ?? 0);
		}
	}, 0);

	const currency = objects?.[0]?.sums?.original?.currency;

	const count = objects.length;

	const average = count ? total / count : 0;

	return {
		count: count,
		total: total,
		currency: currency,
		average: average,
		details: Object.values(
			objects.reduce((acc, object) => {
				const currency = object?.sums?.original?.currency;
				const total = object?.sums?.original?.total;

				if (acc[currency] === undefined) {
					acc[currency] = {
						count: 0,
						total: 0,
						currency: currency,
						average: 0,
					};
				}

				acc[currency].count += 1;
				acc[currency].total = object?.isCreditInvoice
					? acc[currency].total - total
					: acc[currency].total + total;
				acc[currency].average =
					acc[currency].total / acc[currency].count;

				return acc;
			}, {}),
		).map((object, index, array) => ({
			...object,
			first: index === 0,
			last: index === array.length - 1,
		})),
	};
}

export function applyFilters({
	objects: $objects,
	search,
	filters: $filters,
	selected: $selected = [],
	ignore: $ignore = [],
}) {
	let objects = $objects;
	const ignore = [].concat($ignore).filter(Boolean).concat('SERVICE:STATUS');

	const selected = $selected.reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object?.id]: object }),
		{},
	);

	if (search) {
		objects = objects.filter((object) => {
			const fields = [
				'client.name',
				'meta.invoiceNumber',
				'meta.clientNumber',
				'meta.message',
				'sums.original.total',
				'sums.original.currency',
			];

			for (const key of fields) {
				const value = get(object, key);

				if (
					value
						?.toString?.()
						?.toLowerCase?.()
						?.includes?.(search?.toLowerCase?.())
				) {
					return true;
				}
			}

			return false;
		});

		return objects;
	}

	if ($filters?.length) {
		const filters = $filters.reduce(
			(acc, object) => {
				const type = object?.type;
				const selected = type?.startsWith?.('SELECTED');
				const key = selected ? 'selected' : 'available';

				if (ignore.includes(type)) {
					return acc;
				}

				acc[key].exists = true;

				if (!acc[key].data[type]) {
					acc[key].data[type] = [];
				}

				acc[key].data[type].push(object);

				return acc;
			},
			{
				available: { exists: false, data: {} },
				selected: { exists: false, data: {} },
			},
		);

		if (filters.selected.exists) {
			objects = objects
				.filter((object) => !!selected[object?._id ?? object?.id])
				.filter((object) =>
					Object.values(filters.selected.data).every((filters) =>
						filters.some((value) => {
							if (value?.type === 'SELECTED:CURRENCY') {
								const currency =
									object?.sums?.original?.currency;

								return currency === value?.value;
							}

							return false;
						}),
					),
				);
		}

		if (filters.available.exists) {
			objects = objects.filter((object) =>
				Object.values(filters.available.data).every((filters) =>
					filters.some((value) => {
						if (value?.type === 'CLIENT') {
							return object?.clientId === value?.value;
						}

						if (value?.type === 'CURRENCY') {
							const currency = object?.sums?.original?.currency;

							return currency === value?.value;
						}

						if (value?.type === 'AMOUNT') {
							const total = object?.sums?.original?.total;

							const from = value?.value?.from;
							const to = value?.value?.to;

							return total >= from && total <= to;
						}

						if (value?.type === 'DATE:SENT') {
							const date = object?.dates?.invoiceSent
								? parseISO(object?.dates?.invoiceSent)
								: null;

							const startDate = value?.value?.startDate
								? parseISO(value?.value?.startDate)
								: null;

							const endDate = value?.value?.endDate
								? parseISO(value?.value?.endDate)
								: null;

							if (!date) {
								return false;
							}

							if (startDate && endDate) {
								return (
									(isSameDay(date, startDate) ||
										isAfter(date, startDate)) &&
									(isSameDay(date, endDate) ||
										isBefore(date, endDate))
								);
							}

							if (startDate) {
								return isSameDay(date, startDate);
							}

							if (endDate) {
								return isSameDay(date, endDate);
							}
						}

						if (value?.type === 'TYPE') {
							return object?.type === value?.value;
						}

						return false;
					}),
				),
			);
		}
	}

	return objects;
}

/**
 * @template TService
 * @param { TService[] } services
 * @param { string } invoiceId
 * @returns { (TService & { invoice: unknown })[] }
 */
function fmtServices(services, invoiceId) {
	return (services ?? []).map((service) => {
		const invoice = (service?.invoices ?? []).find(
			(service) => service?.invoiceId === invoiceId,
		);

		return { ...service, invoice: invoice };
	});
}

function getCountryCode({ country, candidates }) {
	if (!country || !candidates) {
		return country;
	}

	const code = candidates[country.toLowerCase()];

	return code ? code : 'SE';
}

export function format({ object, serviceId, changes = false, countries }) {
	const ID = object?._id ?? object?.id;

	return [
		(object) => {
			if (!object) {
				return object;
			}

			return {
				...object,
				services: fmtServices(object?.services ?? [], ID),
				modifications: fmtServices(object?.modifications ?? [], ID).map(
					(service) => ({
						...service,
						invoice: service?.invoice?.data,
					}),
				),
			};
		},
		(object) => {
			if (!object) {
				return object;
			}

			const lastService = []
				.concat(object?.services ?? [])
				.sort((a, b) => {
					if (!a?.createdAt && !b?.createdAt) {
						return 0;
					}

					if (!a?.createdAt) {
						return -1;
					}

					if (!b?.createdAt) {
						return 1;
					}

					return compareDesc(
						new Date(a.createdAt),
						new Date(b.createdAt),
					);
				})[0];

			return { ...object, lastService: lastService };
		},

		changes
			? (object) => {
					if (!object) {
						return object;
					}

					const modification = (object?.modifications ?? []).find(
						(service) => service?.serviceId === serviceId,
					);

					const service = merge({}, modification ?? {});

					if (service?.invoice) {
						service.invoice.isCreditInvoice =
							service?.invoice?.type === 'credit';
					}

					return merge({}, object, service?.invoice ?? {});
			  }
			: null,
		countries
			? (object) => {
					if (!object) {
						return object;
					}

					const contact = merge({}, object?.contact ?? {});

					['billing', 'general', 'shipping'].forEach((key) => {
						if (contact?.[key]?.country) {
							contact[key].country = getCountryCode({
								country: contact?.[key]?.country,
								candidates: countries,
							});
						}
					});

					return { ...object, contact: contact };
			  }
			: null,
	]
		.filter(Boolean)
		.reduce((acc, fn) => fn(acc), object);
}

export function getId(object) {
	return object?._id ?? object?.id;
}
