import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useFormValues } from '@asteria/component-form';
import Input from '@asteria/component-form/input';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const InputReset = React.memo((props) => {
	const { className, name, ...rest } = props;
	const { setValue } = useFormContext();

	const value = useFormValues({
		name: name,
	});

	const handleClick = React.useCallback(() => {
		setValue(name, '');
	}, [name, setValue]);

	return (
		<Input
			className={cn('asteria-component__input-reset', className)}
			name={name}
			{...rest}
			postfix={
				value ? (
					<Button icon="close" size="xs" onClick={handleClick} />
				) : null
			}
		/>
	);
});

InputReset.displayName = 'InputReset';

InputReset.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
};

export default InputReset;
