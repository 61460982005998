import React from 'react';

import PropTypes from 'prop-types';

import LF from './lf/header';
// import PayEx from './payex/header';
import Swedbank from './swedbank/header';

const Header = (props) => {
	const { partner, fullscreen, theme } = props;

	if (theme === 'lf') {
		return <LF partner={partner} fullscreen={fullscreen} />;
	}

	if (theme === 'Swedbank') {
		return <Swedbank />;
	}

	// eslint-disable-next-line spellcheck/spell-checker
	if (theme === 'payex') {
		// return <PayEx />;
	}

	return null;
};

Header.displayName = 'Header';

Header.propTypes = {
	className: PropTypes.string,
	partner: PropTypes.object,
	fullscreen: PropTypes.shape({
		state: PropTypes.bool,
		onChange: PropTypes.func,
	}),
	theme: PropTypes.string,
};

export default Header;
