import React, { useCallback, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../context';
import Step, { Content, Footer, Header } from '../../step';
import Guide from '../guide';

import './index.scss';

const BasicInfo = function BasicInfo(props) {
	const { className, flow, integration, setIntegration, onClose } = props;
	const { navigation } = useContext(NavigationContext);
	const [showGuide, setShowGuide] = useState(false);

	const step = `${integration.type}.basic`;

	const onSubmit = useCallback(
		(form) => {
			setIntegration(form);
			navigation.next();
		},
		[navigation, setIntegration],
	);

	return (
		<Form onSubmit={onSubmit} defaultValues={integration}>
			<Step
				className={cn(
					'asteria-component__integration-basic__info',
					{
						[`asteria-component__integration-basic--type-${integration.type}`]:
							integration.type,
					},
					className,
				)}
				step={step}
			>
				{flow?.steps?.info?.guide && (
					<Guide
						open={showGuide}
						steps={flow?.steps?.info?.guide?.steps}
						onClose={() => setShowGuide(false)}
					/>
				)}
				<Header onClose={onClose}>
					{TranslationService.get(
						[
							`integrations.add.title`,
							`integrations.${integration.type}.add.title`,
						],
						undefined,
						{ integration: integration },
					)}
				</Header>
				<Content step={step} scroll>
					<TextGroup>
						<Title>{flow?.name}</Title>
						<Text>
							{TranslationService.get(
								[
									`integrations.add.content`,
									`integrations.${integration.type}.add.content`,
									`integrations.${integration.type}.${integration.key}.add.content`,
								],
								undefined,
								{ integration: integration },
							)}
						</Text>
						<Contenter content={flow?.steps?.info?.content} />
					</TextGroup>

					<FormWrapper>
						<Content>
							<Input
								name="config.client.API-User"
								label={TranslationService.get('label.username')}
								required
								direction="vertical"
							/>
							<Input
								name="config.client.API-Key"
								label={TranslationService.get('label.password')}
								type="password"
								required
								direction="vertical"
							/>
						</Content>
					</FormWrapper>

					{flow?.steps?.info?.guide && (
						<Button
							className="asteria-component__integration-step__help"
							variant="assist"
							label={TranslationService.get(
								[
									`integrations.add.help`,
									`integrations.${integration.type}.add.help`,
									`integrations.${integration.type}.${integration.key}.add.help`,
								],
								undefined,
								{ integration: integration },
							)}
							// icon="triangle-right"
							// iconPosition="last"
							onClick={() => setShowGuide(true)}
						/>
					)}
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get('action.abort')}
							onClick={navigation.abort}
						/>
						<Button
							variant="secondary"
							label={TranslationService.get('action.back')}
							onClick={navigation.back}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get('action.continue')}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Step>
		</Form>
	);
};

BasicInfo.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	flow: PropTypes.object,
};

BasicInfo.defaultProps = {
	className: null,
	type: null,
};

export default BasicInfo;
