import { IntegrationGateway } from '@asteria/backend-utils-services';

export const DEFAULT_FIELDS = `
    id
    key
    name: key
    type
    disabled
    lastSync
    status {
        state
        progress
        entities
    }
    config {
        connected
        server
        client
    }
    actions {
        _id
        action
        status
        data
    }
`;

/**
 * @param { { token: string, variables?: object, fields?: string } } options
 */
export const fetch = async (options) => {
	const { token, fields = DEFAULT_FIELDS, variables = {} } = options;

	try {
		return IntegrationGateway.integration.fetch(
			{ fields: fields, ...variables },
			{ token: token },
		);
	} catch (e) {
		return null;
	}
};
