import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import FAQDetails from './Details';
import FAQModal from './Modal';
import { SectionPropTypes } from './PropTypes';
import FAQQuestion from './Question';
import FAQSection from './Section';
import { useFAQ } from './hooks';

import './styles.scss';

const FAQ = (props) => {
	const { className, sections = [], onAction } = props;

	const [modal, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'OPEN':
					return { open: true, ...action?.payload };

				case 'CLOSE':
					return { ...state, open: false };
			}
		},
		{ open: false },
	);

	const handleOpen = React.useCallback((data) => {
		dispatch({ type: 'OPEN', payload: data });
	}, []);

	const handleClose = React.useCallback(() => {
		dispatch({ type: 'CLOSE' });
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'faq:details') {
				if (data?.modal) {
					return handleOpen(data);
				}
			}

			return onAction?.(action, data);
		},
		[handleOpen, onAction],
	);

	if (!sections?.length) {
		return null;
	}

	return (
		<>
			<FAQModal {...modal} onClose={handleClose} />
			<div className={cn('asteria-component__faq', className)}>
				{sections.map((section, index) => (
					<FAQSection
						key={index}
						{...section}
						onAction={handleAction}
					/>
				))}
			</div>
		</>
	);
};

FAQ.displayName = 'FAQ';

FAQ.propTypes = {
	className: PropTypes.string,
	sections: PropTypes.arrayOf(PropTypes.shape({ ...SectionPropTypes })),
	onAction: PropTypes.func,
};

export default FAQ;
export { FAQQuestion, FAQSection, FAQDetails, useFAQ };
