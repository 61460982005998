/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import styled from 'styled-components';

import { cn } from '@asteria/utils-funcs/classes';

const Drawer = styled(({ className, children }) => (
	<div className={cn('asteria-widget-toolbar', className)}>{children}</div>
))`
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 1000;
	background-color: #292d3e;
	width: 300px;
	height: 100vh;
	display: flex;
`;

export default Drawer;
