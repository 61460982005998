import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const SelectedConfirm = (props) => {
	const { onClose, onAction, onSubmit, next } = props;

	const config = useConfig(['modals.invoice.selected.confirm'], {
		deep: true,
	});

	const selected = useSelector(InvoiceStore.selectors.selected, (a, b) =>
		isEqual(a, b),
	);

	const dispatch = useDispatch();

	const onConfirm = React.useCallback(
		(event) => {
			onClose?.(event);

			dispatch(InvoiceStore.select('RESET'));

			return onAction?.('go', next);
		},
		[onClose, dispatch, onAction, next],
	);

	const contenterData = React.useMemo(
		() => ({ selected: selected }),
		[selected],
	);

	const contenterActions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get(
					['invoice.modal.selected.confirm.title'],
					undefined,
					{ selected: selected },
				)}
			</Header>
			<Content scroll>
				<Contenter
					content={config?.content}
					data={contenterData}
					actions={contenterActions}
				/>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'invoice.modal.selected.confirm.action.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<Button
					variant="primary"
					label={TranslationService.get([
						'button.remove',
						'action.remove',
						'invoice.modal.selected.confirm.action.remove',
					])}
					onClick={onConfirm}
				/>
			</Footer>
		</Wrapper>
	);
};

SelectedConfirm.displayName = 'SelectedConfirm';

SelectedConfirm.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	next: PropTypes.string,
};

const SelectedConfirmModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-selected-confirm',
			)}
			scroll
			size="sm"
		>
			<SelectedConfirm {...props} />
		</Modal>
	);
};

SelectedConfirmModal.displayName = 'SelectedConfirmModal';

SelectedConfirmModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	name: PropTypes.string,
};

export default SelectedConfirmModal;
