import { useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { LanguageService } from '@asteria/backend-utils-services';

import {
	setTranslations,
	setTranslationsLoaded,
} from '@asteria/datalayer/stores/language';

import { TranslationService } from '@asteria/language';

import { AuthContext } from '../context';

const DEFAULT_FIELDS = `translations`;

LanguageService.translation.extend({
	getQuery: (fields = DEFAULT_FIELDS) => `
    query GetTranslations(
        $code: String!
        $themeId: ObjectId!
        $partnerId: ObjectId
    ) {
        translation(code: $code, themeId: $themeId, partnerId: $partnerId) {
            ${fields}
        }
    }
  `,
	key: `language`,
	loggerMethod: `services.languageService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.languageService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const Languages = (props) => {
	const { children } = props;
	const dispatch = useDispatch();
	const language = useSelector((store) => store?.language?.language);
	const translations = useSelector((store) => store?.language?.translations);
	const loaded = useSelector((store) => store?.language?.loaded);

	const { accessToken, partnerId, themeId } = useContext(AuthContext);

	useEffect(() => {
		if (!accessToken || !partnerId || !themeId || !language) {
			return;
		}

		LanguageService.translation.extension
			.language(
				{
					useTokenFromContext: false,
					code: language,
					partnerId: partnerId,
					themeId: themeId,
				},
				{ token: accessToken },
			)
			.then((response) => {
				dispatch(setTranslations(response?.translations));
				dispatch(setTranslationsLoaded(true));
			})
			.catch(() => {});
	}, [accessToken, language]);

	if (!loaded || translations === null) {
		return null;
	}

	TranslationService.setTranslations(translations);
	TranslationService.code = language;

	return children;
};

Languages.propTypes = {
	children: PropTypes.node,
};

export default Languages;
