import React from 'react';

import PropTypes from 'prop-types';

import useConfig from '@asteria/utils-hooks/useConfig';

import Guides from '../../components/Guides';
import Support from '../../components/Support';

import Countries from './Countries';

import './styles.scss';

function SupportPageDefault(props) {
	const { countries, onAction, country } = props;

	const guides = useConfig('pages.common.guides', { deep: true });

	return [
		<Countries
			key="countries"
			country={country}
			countries={countries}
			onAction={onAction}
		/>,
		<Support key="support" hideSupport hideTitle onAction={onAction} />,
		<Guides key="guides" guides={guides} onAction={onAction} />,
	];
}

SupportPageDefault.displayName = 'SupportPageDefault';
SupportPageDefault.propTypes = {
	countries: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,
	country: PropTypes.string,
};

export default React.memo(SupportPageDefault);
