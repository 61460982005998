import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Guide from '@asteria/component-integrations-v2/components/Guide';
import {
	CustomIntegration,
	getRedirectURI,
	useActions,
	useAnalytics,
} from '@asteria/component-integrations-v2/flows/Connecting';
import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import OnboardingError from './Error';
import Help from './Help';
import OnboardingSuccess from './Success';
import Context from './context';
import { usePlaceholder } from './hooks';

import './styles.scss';

const Action = React.memo((props) => {
	const { action, integration, onAction } = props;

	const { action: type, data, status } = action || {};

	const [isGuideShown, setGuideShown] = React.useState(false);

	const handleGuideOpen = React.useCallback(() => {
		setGuideShown(true);
	}, []);

	const handleGuideClose = React.useCallback(() => {
		setGuideShown(false);
	}, []);

	const title = TranslationService.get(
		[
			`integrations.actions.${type}`,
			`integrations.${integration?.type}.actions.${type}`,
			`integrations.${integration?.type}.${integration?.key}.actions.${type}`,
		],
		undefined,
		{ integration: integration },
	);

	const description = TranslationService.get(
		[
			`integrations.actions.${type}.message`,
			`integrations.${integration?.type}.actions.${type}.message`,
			`integrations.${integration?.type}.${integration?.key}.actions.${type}.message`,
			data?.message,
		],
		data?.message,
		{ message: data?.message },
	);

	const config = useConfig([
		`integration.actions.${type}`,
		`integration.actions.${integration?.key}.${type}`,
	]);

	const GuideProps = React.useMemo(
		() => ({ integration: { type: type, key: integration?.key } }),
		[integration?.key, type],
	);

	const handleClick = React.useCallback(
		() => onAction?.(action),
		[action, onAction],
	);

	return (
		<>
			{config?.guide ? (
				<Guide
					open={isGuideShown}
					steps={config?.guide?.steps}
					onClose={handleGuideClose}
					{...GuideProps}
				/>
			) : null}
			<div className="asteria-component__linear-onboarding__actions__action">
				<TextGroup>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="space-between"
					>
						{title ? <Title size="xs">{title}</Title> : null}
						<Group
							direction="horizontal"
							verticalAlign="center"
							className={cn(
								'asteria-component__linear-onboarding__actions__action__icons',
								{
									[`asteria--status-${status}`]: status,
								},
							)}
						>
							{status === 'PROCESSED' ? (
								<Button icon="check" />
							) : null}
							{status === 'PENDING' ? (
								config?.guide ? (
									<Button
										icon="help"
										onClick={handleGuideOpen}
										tooltip={TranslationService.get(
											[
												'onboarding.linear.actions.help.tooltip',
												`onboarding.linear.${integration?.type}.actions.help.tooltip`,
												`onboarding.linear.${integration?.key}.actions.help.tooltip`,
												`onboarding.linear.${integration?.type}.${integration?.key}.actions.help.tooltip`,
											],
											undefined,
											{
												action: action,
												integration: integration,
											},
										)}
									/>
								) : null
							) : null}
							{status === 'PROGRESS' ? <Button loading /> : null}
							{status === 'FAILED' ? (
								<Button icon="warning" />
							) : null}
						</Group>
					</Group>
					{description ? <Text>{description}</Text> : null}
				</TextGroup>
				{status === 'PENDING' ? (
					<div>
						<Button
							variant="secondary"
							label={TranslationService.get([
								'action.done',
								'integrations.actions.done',
								'onboarding.linear.actions.done',
							])}
							size="sm"
							onClick={handleClick}
						/>
					</div>
				) : null}
			</div>
		</>
	);
});

Action.displayName = 'Action';
Action.propTypes = {
	action: PropTypes.shape({
		type: PropTypes.string,
		data: PropTypes.object,
		status: PropTypes.oneOf(['PROCESSED', 'PENDING', 'PROGRESS', 'FAILED']),
	}),
	integration: PropTypes.object,
	onAction: PropTypes.func,
};

const Actions = (props) => {
	const { actions, integration, onAction } = props;

	if (!actions?.length) {
		return null;
	}

	const type = integration?.type;
	const key = integration?.key;

	const title = TranslationService.get(
		[
			'onboarding.linear.actions.alert.title',
			`onboarding.linear.${type}.actions.alert.title`,
			`onboarding.linear.${key}.actions.alert.title`,
			`onboarding.linear.${type}.${key}.actions.alert.title`,
		],
		undefined,
		{ integration: integration },
	);

	const content = TranslationService.get(
		[
			'onboarding.linear.actions.alert.content',
			`onboarding.linear.${type}.actions.alert.content`,
			`onboarding.linear.${key}.actions.alert.content`,
			`onboarding.linear.${type}.${key}.actions.alert.content`,
		],
		undefined,
		{ integration: integration },
	);

	return (
		<div className="asteria-component__linear-onboarding__actions-wrapper">
			{title || content ? (
				<Alert title={title} level="error">
					{content ? <Text>{content}</Text> : null}
				</Alert>
			) : null}
			<div className="asteria-component__linear-onboarding__actions">
				{actions.map((action, index) => (
					<Action
						key={index}
						action={action}
						integration={integration}
						onAction={onAction}
					/>
				))}
			</div>
		</div>
	);
};

Actions.displayName = 'Actions';
Actions.propTypes = {
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			data: PropTypes.object,
			status: PropTypes.oneOf([
				'PROCESSED',
				'PENDING',
				'PROGRESS',
				'FAILED',
			]),
		}),
	),
	integration: PropTypes.object,
	onAction: PropTypes.func,
};

const OnboardingConnecting = React.memo((props) => {
	const { className } = props;

	const { onAction, onBack, onAbort } = React.useContext(Context);

	const _id = useSelector(IntegrationStore.selectors.navigation._id);

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, _id),
		(a, b) => isEqual(a, b),
	);

	const [actions, setActions] = useActions(integration);

	const analyticsFlow = useAnalytics(integration);

	const flow = useFlow(integration?.type, integration?.key);
	const content = flow?.steps?.connecting?.content;

	const handleAbort = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onAbort?.(event);
		},
		[analyticsFlow, onAbort],
	);

	const handleBack = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onBack(event);
		},
		[analyticsFlow, onBack],
	);

	const handleAction = React.useCallback(
		(action) => {
			const { action: type } = action;

			if (type === 'browser.enable.popup') {
				const URI = getRedirectURI(integration);

				const win = window.open('', '_blank');

				if (!win) {
					return;
				}

				win.location = URI;
				win.focus();

				setActions(integration?.actions);

				return;
			}

			return onAction?.('integrations:action', action);
		},
		[integration, onAction, setActions],
	);

	const Placeholder = usePlaceholder(
		integration?.type,
		integration?.key,
		'connecting',
	);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__connecting',
				{ 'asteria--variant-split': Placeholder },
				className,
			)}
		>
			<div className="asteria-component__linear-onboarding__content">
				<Wrapper scroll>
					<Header>
						{TranslationService.get(
							[
								`integrations.add.title`,
								`integrations.${integration?.type}.add.title`,
								`integrations.${integration?.key}.add.title`,
								`integrations.${integration?.type}.${integration?.key}.add.title`,
								`integrations.connecting.title`,
								`integrations.${integration?.type}.connecting.title`,
								`integrations.${integration?.key}.connecting.title`,
								`integrations.${integration?.type}.${integration?.key}.connecting.title`,
							],
							undefined,
							{ integration: integration },
						)}
					</Header>
					<Content scroll>
						{actions?.length ? (
							<Actions
								integration={integration}
								onAction={handleAction}
								actions={actions}
							/>
						) : (
							<>
								<Contenter
									content={content}
									data={{ integration: integration }}
								/>

								{flow?.flow === 'custom' ? (
									<div className="asteria-component__onboarding-connecting__token-wrapper">
										<CustomIntegration
											integration={integration}
										/>
									</div>
								) : null}
							</>
						)}
					</Content>
					<Footer>
						<FooterSection>
							<Button
								analyticsKey={`integrations.${integration?.key}.abort`}
								variant="tertiary"
								label={TranslationService.get('action.abort')}
								onClick={handleAbort}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								analyticsKey={`integrations.${integration?.key}.back`}
								variant="primary"
								label={TranslationService.get('action.back')}
								onClick={handleBack}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
				<Help />
			</div>
			{Placeholder}
		</div>
	);
});

OnboardingConnecting.displayName = 'OnboardingConnecting';

OnboardingConnecting.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
};

const OnboardingGateway = React.memo((props) => {
	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, _id),
		(a, b) => isEqual(a, b),
	);

	const status = integration?.status?.state;
	const isConnected = integration?.config?.connected ?? false;
	const errors = integration?.config?.errors ?? [];

	if (status !== 'ERROR' && isConnected) {
		return <OnboardingSuccess {...props} />;
	}

	if (status === 'ERROR' || errors?.length) {
		return <OnboardingError {...props} />;
	}

	return <OnboardingConnecting {...props} />;
});

OnboardingGateway.displayName = 'OnboardingGateway';

OnboardingGateway.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
};

export default OnboardingGateway;
