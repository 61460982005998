import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TableCell } from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const HeaderCell = (props) => {
	const { field, sort, onAction } = props;

	const { name, sortable } = field;

	const onSortClick = React.useCallback(
		() => onAction?.('table:filter', name),
		[name, onAction],
	);

	return (
		<TableCell key={name} className={`asteria--type-${name}-filter`}>
			{sortable ? (
				<Button
					variant="table-filter"
					onClick={onSortClick}
					active={sort.field === name}
					icon="chevron-down"
					iconActive={
						sort.direction === 'ASC' ? 'chevron-down' : 'chevron-up'
					}
					iconPosition="last"
					label={TranslationService.get(
						`page.invoices.pending.table.${name}.header`,
					)}
				/>
			) : (
				<Text>
					{TranslationService.get(
						`page.invoices.pending.table.${name}.header`,
					)}
				</Text>
			)}
		</TableCell>
	);
};

HeaderCell.displayName = 'HeaderCell';

HeaderCell.propTypes = {
	field: PropTypes.object,
	sort: PropTypes.object,
	onAction: PropTypes.func,
};

export default HeaderCell;
