/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import PropTypes from 'prop-types';

import { SupportService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { useGenericProperties } from '@asteria/component-feedback';
import FeedbackModal from '@asteria/component-feedback/FormModal';
import FeedbackSuccessModal from '@asteria/component-feedback/SuccessModal';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Footer = (props) => {
	const { className } = props;

	const handleFeedbackSubmit = React.useCallback(
		(data) =>
			SupportService.feedback
				.add({ input: data }, { token: localStorage.wingsToken })
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.warn(err);
				}),
		[],
	);

	const {
		loading,
		state,
		onClose: onFeedbackClose,
		onOpen: onFeedbackOpen,
		onSubmit: onFeedbackSubmit,
		rating: FeedbackRating,
	} = useGenericProperties({ onSubmit: handleFeedbackSubmit });

	return [
		<FeedbackModal
			key="feedback-modal"
			open={state === 'OPEN'}
			type="generic"
			onClose={onFeedbackClose}
			onSubmit={onFeedbackSubmit}
			hideContactUs
			loading={loading}
			rating={FeedbackRating}
		/>,
		<FeedbackSuccessModal
			key="feedback-success-modal"
			open={state === 'DONE'}
			type="generic"
			onClose={onFeedbackClose}
		/>,
		<div
			key="footer"
			className={cn(
				'wings-footer',
				'lf-footer',
				'asteria-component__layout__footer',
				className,
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
			>
				<Button
					variant="secondary"
					label="Tyck till"
					icon="heart"
					onClick={onFeedbackOpen}
				/>
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="right"
					className="asteria--type-navigation"
				>
					<div className="wings-footer__link">
						<Text size="sm">Om personuppgifter</Text>
					</div>
					<div className="wings-footer__link">
						<Text size="sm">Cookies</Text>
					</div>
					<div className="wings-footer__link">
						<Text size="sm">Internetvillkor</Text>
					</div>
					<div className="wings-footer__link">
						<Text size="sm">Säkerhet</Text>
					</div>
					<div className="wings-footer__link">
						<Text size="sm">Kontakta oss</Text>
					</div>
					<div className="wings-footer__logo" />
				</Group>
			</Group>
		</div>,
	];
};

Footer.displayName = 'Footer';

Footer.propTypes = { className: PropTypes.string };

export default Footer;
