import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Option = (props) => {
	const { className } = props;

	return (
		<div
			className={cn(
				'asteria-component__form-v2__select-option',
				className,
			)}
		>
			Hello
		</div>
	);
};

Option.displayName = 'Option';

Option.propTypes = { className: PropTypes.string };

export default Option;
