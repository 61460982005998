import React from 'react';

import { loremIpsum } from 'lorem-ipsum';

import { Text } from '@asteria/component-core/typography';

import '../styles.scss';

const Reference = () => {
	return <Text>{loremIpsum({ count: 14 })}</Text>;
};

Reference.displayName = 'Reference';

Reference.propTypes = {};

export default Reference;
