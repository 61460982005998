import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';
import { Content } from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import { Wrapper as FormWrapper } from '@asteria/component-form';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Billing from './tab/billing';
import Buyer from './tab/buyer';
import Contact from './tab/contact';
import Delivery from './tab/delivery';
import Details from './tab/details';
import Reference from './tab/reference';
import Rows from './tab/rows';

import './styles.scss';

const NavigationItem = (props) => {
	const { name, active, feature, onClick } = props;

	const handleClick = React.useCallback(() => {
		onClick?.(name);
	}, [name, onClick]);

	const isEnabled = useFeature(feature);

	if (feature && !isEnabled) {
		return null;
	}

	return (
		<Chip
			size="sm"
			active={active}
			label={TranslationService.get([
				`invoice.edit.tab.${name}`,
				`invoice.edit.navigation.${name}`,
			])}
			onClick={handleClick}
		/>
	);
};

NavigationItem.displayName = 'NavigationItem';

NavigationItem.propTypes = {
	active: PropTypes.bool,
	name: PropTypes.string,
	feature: PropTypes.string,
	onClick: PropTypes.func,
};

const TabsContentWrapper = (props) => {
	const { name, children } = props;

	return (
		<div
			className={cn(
				'asteria-component__invoice-edit-tab-content-wrapper',
				`asteria--type-${name}`,
			)}
		>
			<Title size="xxs">
				{TranslationService.get([
					`invoice.edit.tab.${name}`,
					`invoice.edit.tab.${name}.title`,
				])}
			</Title>
			<FormWrapper className="asteria-component__invoice-edit-tab-content">
				<Content>{children}</Content>
			</FormWrapper>
		</div>
	);
};

TabsContentWrapper.displayName = 'TabsContentWrapper';

TabsContentWrapper.propTypes = {
	name: PropTypes.string,
	children: PropTypes.node,
};

const Tabs = {
	details: { component: Details, feature: 'invoice-edit-details' },
	delivery: { component: Delivery, feature: 'invoice-edit-delivery' },
	billing: { component: Billing, feature: 'invoice-edit-billing' },
	contact: { component: Contact, feature: 'invoice-edit-contact' },
	buyer: { component: Buyer, feature: 'invoice-edit-buyer' },
	ref: { component: Reference, feature: 'invoice-edit-reference' },
	rows: { component: Rows, feature: 'invoice-edit-rows' },
};

const InvoiceEditTabs = (props) => {
	const { className } = props;

	const [activeTab, setActiveTab] = React.useState('delivery');

	const handleClick = React.useCallback((name) => {
		setActiveTab(name);
	}, []);

	const TabContent = Tabs[activeTab].component;

	return (
		<div className={cn('asteria-component__invoice-edit-tabs', className)}>
			<Group
				direction="horizontal"
				flex
				className={cn(
					'asteria-component__invoice-edit-tabs-navigation',
				)}
			>
				{Object.keys(Tabs).map((tab) => (
					<NavigationItem
						key={tab}
						name={tab}
						feature={Tabs?.[tab]?.feature}
						active={tab === activeTab}
						onClick={handleClick}
					/>
				))}
			</Group>
			<TabsContentWrapper name={activeTab}>
				<TabContent />
			</TabsContentWrapper>
		</div>
	);
};

InvoiceEditTabs.displayName = 'InvoiceEditTabs';

InvoiceEditTabs.propTypes = {
	className: PropTypes.string,
};

export default InvoiceEditTabs;
