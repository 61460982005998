import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

// import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const Placeholder = () => {
	const hasPrinters = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [{ key: 'printer' }],
			}).length,
	);

	const layouts = useSelector(
		(store) =>
			InvoiceStore.selectors.layouts(store, {
				status: ['PENDING', 'LOADING'],
			}),
		(a, b) => isEqual(a, b),
	);

	if (hasPrinters && layouts.length) {
		return (
			<div className="asteria-page__placeholder">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					<Icon icon="document" />
					<Title size="sm" align="center">
						{TranslationService.get(
							[
								'invoices.table.empty.title',
								`invoices.table.empty.printer.title`,
							],
							undefined,
							{ layouts: layouts },
						)}
					</Title>
				</Group>
				<Text align="center">
					{TranslationService.get(
						[
							'invoices.table.empty.content',
							`invoices.table.empty.printer.content`,
						],
						undefined,
						{ layouts: layouts },
					)}
				</Text>
				<Text align="center">
					{TranslationService.get(
						[
							'invoices.table.empty.content.1',
							`invoices.table.empty.printer.content.1`,
						],
						undefined,
						{ layouts: layouts },
					)}
				</Text>
			</div>
		);
	}

	return (
		<div className="asteria-page__placeholder">
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="center"
			>
				<Icon icon="document" />
				<Title size="sm" align="center">
					{TranslationService.get([
						'page.invoices.placeholder.title',
						'page.invoices.empty.placeholder.title',
					])}
				</Title>
			</Group>
			<Text align="center">
				{TranslationService.get([
					'page.invoices.placeholder.content',
					'page.invoices.empty.placeholder.content',
				])}
			</Text>
			{/* <Button
				variant="link"
				label={TranslationService.get([
					'page.invoices.placeholder.link',
					'page.invoices.empty.placeholder.link',
				])}
			/> */}
		</div>
	);
};

const VariantEmpty = () => {
	return (
		<div className="asteria-page__wrapper">
			<TextGroup>
				<Title size="page-title">
					{TranslationService.get([
						`page.invoices.title`,
						`page.invoices.empty.title`,
					])}
				</Title>
				<Text size="lg">
					{TranslationService.get([
						`page.invoices.content`,
						`page.invoices.empty.content`,
					])}
				</Text>
			</TextGroup>
			<Placeholder />
		</div>
	);
};

VariantEmpty.displayName = 'VariantEmpty';

VariantEmpty.propTypes = {};

export default VariantEmpty;
