import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Tour from '@asteria/component-tour';

import * as TourStore from '@asteria/datalayer/stores/tour';

import useConfig from '@asteria/utils-hooks/useConfig';

function useTour(name) {
	const config = useConfig(['widget.tour'])?.[name];

	return React.useMemo(() => config, [config]);
}

const TourLogic = (props) => {
	const { onAction } = props;

	const current = useSelector(TourStore.selectors.current);
	const zIndex = useSelector(TourStore.selectors.zIndex);

	const onClose = React.useCallback(() => onAction?.('tour:end'), [onAction]);

	const steps = useTour(current);

	if (!steps?.length) {
		return null;
	}

	return (
		<Tour
			isOpen={!!steps?.length}
			onClose={onClose}
			onAction={onAction}
			steps={steps}
			zIndex={zIndex}
			rootSelector=".asteria-widget__layout"
		/>
	);
};

TourLogic.displayName = 'TourLogic';

TourLogic.propTypes = { onAction: PropTypes.func };

export default TourLogic;
