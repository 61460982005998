import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const FAQDetails = (props) => {
	const { className, content } = props;

	if (!content?.length) {
		return null;
	}

	return (
		<Contenter
			className={cn('asteria-component__faq-details', className)}
			content={content}
		/>
	);
};

FAQDetails.displayName = 'FAQDetails';

FAQDetails.propTypes = {
	className: PropTypes.string,
	content: PropTypes.arrayOf(PropTypes.object),
};

export default FAQDetails;
