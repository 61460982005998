import AsteriaCore from '@asteria/core';

import { InvoiceService } from '@asteria/backend-utils-services';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { withPagination } from '@asteria/utils-funcs/request';

InvoiceService.service.extend({
	getQuery: () => `
		mutation AddInvoiceLayoutMessage($id: ID!, $message: String!) {
			addInvoiceLayoutMessage(id: $id, input: { message: $message }) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `addInvoiceLayoutMessage`,
	loggerMethod: `services.InvoiceService.addInvoiceLayoutMessage`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.addInvoiceLayoutMessage`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

InvoiceService.service.extend({
	getQuery: () => `
		mutation UpdateInvoiceLayoutMessages(
			$layoutId: ID!
			$input: [UpdateInvoiceLayoutMessageInputType!]
		) {
			updateInvoiceLayoutMessages(layoutId: $layoutId, input: $input) {
				ok
				data {
					id
				}
			}
		}
	`,
	key: `updateInvoiceLayoutMessages`,
	loggerMethod: `services.InvoiceService.updateInvoiceLayoutMessages`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.updateInvoiceLayoutMessages`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

InvoiceService.service.extend({
	getQuery: () => `
		query Countries {
			countries
		}
	`,
	key: `invoiceCountries`,
	loggerMethod: `services.InvoiceService.invoiceCountries`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.InvoiceService.invoiceCountries`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export const FIELDS = `
id
_id
companyId
clientId
clientType
active
canceled
type

meta {
	invoiceNumber
	clientNumber
	message
	source
}

dates {
	invoiceSent
	invoiceDue
	invoicePaid
}

sums {
	original {
		tax
		total
		currency
	}
}

contact {
	general {
		name
		email
		street
		street2
		city
		zipcode
		country
		phone
		mobile
	}

	shipping {
		name
		email
		street
		street2
		city
		zipcode
		country
		phone
		mobile
	}

	billing {
		name
		email
		street
		street2
		city
		zipcode
		country
		phone
		mobile
	}
}

isCreditInvoice

invoiceLayout
invoiceLayoutDetails {
	_id
	pdfUri
}

services(filters: { status: ["DISCARD", "SENT", "COMPLETED", "ERROR"] }) {
	id
	serviceId
	status
	createdAt
	updatedAt
	invoices {
		invoiceId
		status
		errors
	}
}

modifications: services(filters: { status: ["MODIFIED"] }) {
	id
	serviceId
	status
	createdAt
	updatedAt
	invoices {
		invoiceId
		data
		status
		errors
	}
}
`;

export const SERVICE_FIELDS = `
id
serviceId
status
invoices {
	invoiceId
	data
	status
	errors
}
data
`;

export async function details({ accessToken, serviceId, options }) {
	return InvoiceService.invoice
		.fetchOne(
			{
				id: options?.id,
				fields: options?.fields ?? FIELDS,
			},
			{ token: accessToken },
		)
		.then((node) =>
			InvoiceStore.utils.format({
				object: node,
				serviceId: serviceId,
				changes: options?.changes,
				countries: options?.countries,
			}),
		);
}

export async function available({ accessToken, dispatch, serviceId, options }) {
	let fn = InvoiceService.invoice.fetch;

	if (options?.withPagination) {
		fn = withPagination(fn);
	}

	const response = await fn(
		{
			fields: `
				pageInfo {
					count
					hasNextPage
					endCursor
				}
				edges {
					node {
						${options?.fields ?? FIELDS}
					}
				}
			`,
			filters: {
				type: ['invoice', 'credit'],
				clientType: 'CUSTOMER',
				...options?.filters,
			},
			pageFilters: options?.pageFilters,
			isBulk: options?.bulk,
		},
		{ token: accessToken },
	).then((response) => {
		if (options?.withPagination) {
			return response.map((node) =>
				InvoiceStore.utils.format({
					object: node,
					serviceId: serviceId,
					changes: options?.changes,
				}),
			);
		}

		return {
			...response,
			edges: (response?.edges ?? []).map(({ node }) => ({
				node: InvoiceStore.utils.format({
					object: node,
					serviceId: serviceId,
					changes: options?.changes,
				}),
			})),
		};
	});

	if (options?.useDispatch) {
		dispatch?.(
			InvoiceStore.setUnpaid(
				Array.isArray(response)
					? response
					: (response?.edges ?? []).map(({ node }) => node),
			),
		);
	}

	return response;
}

export async function refresh({ accessToken, timestamp }, ctx) {
	const ids = await InvoiceService.invoice
		.fetch(
			{
				fields: `edges { node { _id } }`,
				filters: {
					// paidStatus: ['UNPAID', 'OVERDUE'],
					type: ['invoice', 'credit'],
					clientType: 'CUSTOMER',
					updatedAt: { gte: timestamp },
				},
				isBulk: true,
				pageFilters: { first: 0 },
			},
			{ token: accessToken },
		)
		.then((response) =>
			(response?.edges ?? []).map(({ node }) => node?._id),
		);

	if (ids.length) {
		await ctx?.queryClient?.invalidateQueries?.({
			predicate: (query) =>
				[].concat(query.queryKey).some((key) => key === 'invoices'),
		});
	}
}

export async function completeBatch({ accessToken, id, serviceId, dispatch }) {
	const response = await InvoiceService.service
		.update(
			{ id: id, input: { status: 'COMPLETED' }, isBulk: true },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await batches({ accessToken, dispatch, serviceId });
}

export async function retryBatch({ accessToken, id, serviceId, dispatch }) {
	const response = await InvoiceService.service
		.update(
			{ id: id, input: { status: 'SENT' }, isBulk: true },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await batches({ accessToken, dispatch, serviceId });
}

export async function approve({
	accessToken,
	invoices,
	serviceId,
	dispatch,
	status = 'SENT',
}) {
	const ids = invoices.map(({ invoiceId }) => invoiceId);

	// First clear any old batches from the invoices

	await InvoiceService.service
		.removeInvoices(
			{ serviceId: serviceId, invoices: ids, status: 'DISCARD' },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	const response = await InvoiceService.service
		.create(
			{
				serviceId: serviceId,
				input: { status: status, invoices: invoices },
				fields: `
					ok
					error
					errors
					data {
						${SERVICE_FIELDS}
					}
					services {
						${SERVICE_FIELDS}
					}
				`,
			},
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err, errors: [err] }));

	if (!response.ok) {
		const err = new Error(response?.error?.message ?? response?.error);

		err.errors = response?.errors;

		if (typeof response?.error === 'object') {
			Object.assign(err, response?.error);
		}

		throw err;
	}
	await batches({ accessToken, dispatch, serviceId });

	const IDs = (response?.services ?? []).map(({ id }) => id);

	if (IDs.length > 1) {
		return IDs;
	}

	return IDs[0];
}

export async function discard({ accessToken, ids, serviceId }) {
	const response = await InvoiceService.service.create(
		{
			serviceId: serviceId,
			input: {
				status: 'DISCARD',
				invoices: ids.map((id) => ({ invoiceId: id })),
			},
			fields: `ok error`,
		},
		{ token: accessToken },
	);

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}
}

export async function batches({ accessToken, serviceId, dispatch }) {
	const response = await withPagination(InvoiceService.service.fetch)(
		{
			pageFilters: { first: 1_000 },
			filters: {
				serviceIds: serviceId,
				status: ['SENT', 'COMPLETED', 'ERROR'],
			},
			isBulk: true,
			fields: `
				pageInfo {
					count
					startCursor
					endCursor
					hasNextPage
				}
				edges {
					node {
						id
						serviceId
						status
						invoices {
							invoiceId
						}
						data
						createdAt
						updatedAt
					}
				}
			`,
		},
		{ token: accessToken },
	);

	dispatch?.(InvoiceStore.setBatches(response.reverse()));

	return response;
}

export async function removeBatch({ accessToken, id, serviceId, dispatch }) {
	const response = await InvoiceService.service
		.update(
			{ id: id, input: { status: 'REMOVED' }, isBulk: true },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	await batches({ accessToken, dispatch, serviceId });

	return id;
}

export async function remove({ accessToken, id, dispatch, serviceId }) {
	const response = await InvoiceService.invoice.remove(
		{ ids: id },
		{ token: accessToken },
	);

	await batches({ accessToken, dispatch, serviceId });

	return response;
}

export async function addService({ accessToken, serviceId, input }) {
	const response = await InvoiceService.service
		.create(
			{ serviceId: serviceId, input: input, fields: `ok error` },
			{ token: accessToken },
		)
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}
}

export async function updateService({ accessToken, id, input }) {
	const response = await InvoiceService.service
		.update({ id: id, input: input }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}
}

export async function invoiceCountries(options) {
	const { accessToken, dispatch } = options;

	const response = await InvoiceService.service.extension
		.invoiceCountries({}, { token: accessToken })
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.warn(err);

			return [];
		});

	dispatch?.(InvoiceStore.setCountries(response));

	return response;
}
