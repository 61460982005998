import React from 'react';

import PropTypes from 'prop-types';

import { useCompanyVersion, useCompanyVersionQuery } from './hooks';

const CompanyVersion = React.memo((props) => {
	const { eq, neq, gt, gte, lt, lte, invert, children } = props;

	const show = useCompanyVersionQuery({ eq, neq, gt, gte, lt, lte, invert });

	if (!show) {
		return null;
	}

	return children;
});

CompanyVersion.displayName = 'CompanyVersion';

CompanyVersion.propTypes = {
	eq: PropTypes.number,
	neq: PropTypes.number,
	gt: PropTypes.number,
	gte: PropTypes.number,
	lt: PropTypes.number,
	lte: PropTypes.number,
	invert: PropTypes.bool,
	children: PropTypes.node,
};

export default CompanyVersion;
export { useCompanyVersionQuery, useCompanyVersion };
