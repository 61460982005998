import React from 'react';

import { useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { compareDesc, isAfter, parseISO } from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { useImportantActions } from '../../components/Actions/hooks';
import { useCompanyVersionQuery } from '../../components/CompanyVersion';

function useAvailableActions({ onSubmit }) {
	const hasConnectedIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			}).length > 0,
	);

	const hasPrinterIntegration = useSelector((store) =>
		IntegrationStore.selectors
			.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			})
			.some((object) => object?.key === 'printer'),
	);

	const hasErrorIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				status: 'ERROR',
			}).length > 0,
	);

	const hasBatches = useSelector(
		(store) => InvoiceStore.selectors.batches(store).length > 0,
	);

	const [{ data: hasUnpaidInvoices }] = useQueries({
		queries: [
			{
				queryKey: ['invoices', 'unpaid'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: null } },
						pageFilters: { first: 1 },
						fields: `_id`,
					}),
				select: (response) => !!response?.pageInfo?.count,

				placeholderData: false,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
		],
	});

	return React.useMemo(() => {
		let actions = [];

		if (!hasConnectedIntegration && !hasErrorIntegration) {
			actions.push({
				id: 'connection',
				variant: 'integration',
				type: 'erp',
			});
		}

		if (!hasBatches) {
			if (hasPrinterIntegration && !hasUnpaidInvoices) {
				actions = actions.concat([
					{
						id: 'layouts',
						variant: 'link',
						path: '/layouts',
						disabled: !hasConnectedIntegration,
					},
				]);
			}

			actions = actions.concat([
				{
					id: 'invoices',
					variant: 'link',
					path: '/invoices',
					disabled:
						!hasConnectedIntegration ||
						(hasPrinterIntegration && !hasUnpaidInvoices),
				},
				{
					id: 'review',
					variant: 'link',
					path: 'https://google.com',
					external: true,
					disabled: true,
				},
			]);
		}

		return actions;
	}, [
		hasBatches,
		hasConnectedIntegration,
		hasErrorIntegration,
		hasUnpaidInvoices,
		hasPrinterIntegration,
	]);
}

function useRecommendedActions({ onSubmit }) {
	const importantActions = useImportantActions();
	const hasCompanyVersionTwo = useCompanyVersionQuery({ gte: 2 });
	const reportTimestamp = useSelector(
		InvoiceStore.selectors.reports.timestamp,
	);

	const hasErrorIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				status: 'ERROR',
			}).length > 0,
	);

	const hasBatches = useSelector(
		(store) => InvoiceStore.selectors.batches(store).length > 0,
	);

	const [
		{ data: hasUnpaidInvoices },
		{ data: errorInvoice },
		{ data: hasUnreadLayoutMessage },
		{ data: lastReport },
	] = useQueries({
		queries: [
			{
				queryKey: ['invoices', 'unpaid'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: null } },
						pageFilters: { first: 1 },
						fields: `_id`,
					}),
				select: (response) => !!response?.pageInfo?.count,

				placeholderData: false,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['invoices', 'error'],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: { services: { status: 'ERROR' } },
						pageFilters: { first: 1 },
					}),
				select: (response) => ({
					count: response?.pageInfo?.count ?? 0,
					node: response?.edges?.[0]?.node ?? null,
				}),

				placeholderData: null,

				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				retry: false,
			},
			{
				queryKey: ['navigation', 'layouts', 'unread', 'count'],
				queryFn: async () => {
					const response = await onSubmit?.('invoice-layout:unread');

					return !!response?.[0];
				},
				placeholderData: false,
				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,

				retry: false,
			},
			{
				queryKey: ['reports', 'last'],
				queryFn: async () => {
					const response = await onSubmit?.('reports:list');

					return (
						[]
							.concat(response)
							.filter(Boolean)
							.sort(({ createdAt: a }, { createdAt: b }) =>
								compareDesc(parseISO(a), parseISO(b)),
							)
							.find((object) =>
								isAfter(
									parseISO(object?.createdAt),
									parseISO(reportTimestamp),
								),
							) ?? null
					);
				},
				placeholderData: false,
				// refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,
				enabled: !!reportTimestamp,

				retry: false,
			},
		],
	});

	const deliveryClients = useSelector(
		(store) =>
			AppStore.selectors.clients(store, {
				filters: [{ type: 'DELIVERY:MISSING' }],
			}).length,
	);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	return React.useMemo(() => {
		let actions = [...importantActions];

		if (hasErrorIntegration) {
			actions.push({
				id: 'connection',
				variant: 'integration',
				type: 'erp',
			});
		}

		if (hasBatches && hasUnpaidInvoices) {
			actions.push({
				id: 'invoices',
				variant: 'link',
				path: '/invoices',
			});
		}

		if (deliveryClients && companyService === 'INVOICE') {
			actions.push({
				id: 'action-client-list',
				variant: 'link',
				data: { count: deliveryClients },
			});
		}

		if (hasUnreadLayoutMessage) {
			actions.push({
				id: 'action-layouts-unread',
				variant: 'link',
				path: '/pendingInvoices',
			});
		}

		if (lastReport && hasCompanyVersionTwo) {
			actions.push({
				id: 'action-reports',
				variant: 'link',
				path: '/reports',
				data: { report: lastReport },
			});
		}

		if (errorInvoice?.count && hasCompanyVersionTwo) {
			const serviceInvoice = errorInvoice?.node?.lastService?.invoice;

			actions.push({
				id: 'action-invoice-error',
				variant: 'link',
				data: {
					count: errorInvoice?.count,
					last: errorInvoice?.node,
					errors: serviceInvoice?.errors,
				},
			});
		}

		return actions;
	}, [
		companyService,
		deliveryClients,
		errorInvoice?.count,
		errorInvoice?.node,
		hasBatches,
		hasCompanyVersionTwo,
		hasErrorIntegration,
		hasUnpaidInvoices,
		hasUnreadLayoutMessage,
		importantActions,
		lastReport,
	]);
}

export function useActions({ onSubmit }) {
	const available = useAvailableActions({ onSubmit });
	const recommended = useRecommendedActions({ onSubmit });

	return React.useMemo(
		() => ({ available: available, recommended: recommended }),
		[available, recommended],
	);
}
