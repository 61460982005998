import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Select, { Option } from '@asteria/component-form/select';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import NavigationContext from '../../../../context';
import Step, { Content, Footer, Header } from '../../../step';
import MissingIntegration from '../missing';

import './index.scss';

const SelectIntegration = function SelectIntegration(props) {
	const {
		className,
		flow,
		integration,
		setFlow,
		setIntegration,
		onClose,
		onNextClose,
		selectDescription,
		onAction,
		nextBtnLabel = TranslationService.get('action.next'),
		prevBtnLabel = TranslationService.get('action.abort'),
	} = props;

	const [showMissing, setShowMissing] = useState(false);
	const { navigation = {} } = useContext(NavigationContext);

	const availableIntegrations = useConfig(`integrations`);

	const selectableIntegrations = useMemo(() => {
		return (
			availableIntegrations?.filter(
				({ type: integrationType }) =>
					integration.type === integrationType || !integration.type,
			) || []
		);
	}, [availableIntegrations, integration.type]);

	const step = integration.type ? `${integration.type}.select` : 'all.select';

	const onChange = useCallback(
		({ value }) => {
			const flow = selectableIntegrations.find(
				({ key }) => key === value,
			);
			setFlow(flow);
			setIntegration({ key: flow?.key, type: flow?.type });
		},
		[selectableIntegrations, setFlow, setIntegration],
	);

	const onNext = useCallback(
		(form) => {
			onNextClose?.();
			if (!form?.integrationFlow) {
				// TODO: Show error
				return;
			}

			onChange({ value: form?.integrationFlow });

			navigation.next();
		},
		[navigation, onChange, onNextClose],
	);

	useEffect(() => {
		if (!flow?.key) {
			onChange({ value: selectableIntegrations?.[0]?.key });
		}
	}, [flow, selectableIntegrations]);

	const defaultValue = useMemo(() => {
		return flow?.key || selectableIntegrations?.[0]?.key || null;
	}, [flow, selectableIntegrations]);

	return (
		<Form
			onSubmit={onNext}
			defaultValues={{ integrationFlow: defaultValue }}
		>
			<Step
				className={cn(
					'asteria-component__integration-select',
					{
						[`asteria-component__integration-select--type-${integration.type}`]:
							integration.type,
					},
					className,
				)}
				step={step}
			>
				<MissingIntegration
					open={showMissing}
					type={integration.type}
					onClose={() => setShowMissing(false)}
					onAction={onAction}
				/>
				<Header onClose={onClose}>
					{TranslationService.get(
						[
							`integrations.add.title`,
							`integrations.${integration.type}.add.title`,
						],
						undefined,
						{ integration: integration },
					)}
				</Header>
				<Content step={step} scroll>
					{selectDescription}
					<Text>
						{TranslationService.get(
							[
								`integrations.select.label`,
								`integrations.${integration.type}.select.label`,
							],
							undefined,
							{ integration: integration },
						)}
					</Text>

					<FormWrapper>
						<Content>
							<Select
								name="integrationFlow"
								icon="triangle-down"
								iconActive="triangle-up"
								onChange={onChange}
							>
								{selectableIntegrations.map((item) => (
									<Option key={item.key} value={item.key}>
										{item.name}
									</Option>
								))}
							</Select>
						</Content>
					</FormWrapper>

					<Button
						analyticsKey="integrations.select.missing"
						className="asteria-component__integration-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.missing`,
								`integrations.${integration.type}.missing`,
							],
							undefined,
							{ integration: integration },
						)}
						// icon="triangle-right"
						// iconPosition="last"
						onClick={() => setShowMissing(true)}
						type="button"
					/>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							analyticsKey="integrations.select.abort"
							variant="tertiary"
							label={prevBtnLabel}
							onClick={navigation.abort}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							analyticsKey="integrations.select.next"
							variant="primary"
							label={nextBtnLabel}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Step>
		</Form>
	);
};

SelectIntegration.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	setIntegration: PropTypes.func,
	flow: PropTypes.object,
	setFlow: PropTypes.func,
	onClose: PropTypes.func,
	selectDescription: PropTypes.node,
	nextBtnLabel: PropTypes.string,
	prevBtnLabel: PropTypes.string,
};

SelectIntegration.defaultProps = {
	className: null,
	type: null,
};

export default SelectIntegration;
