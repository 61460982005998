import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import List, { ListCell, ListItem } from '@asteria/component-list';
import Modal from '@asteria/component-modal';
import { MessageBoxToggle } from '@asteria/component-support/MessageBox';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useInvoice } from '../hooks';

import './styles.scss';

const InvoiceDetailsModalSection = (props) => {
	const { invoice, fields, type } = props;

	return (
		<div
			className={cn('asteria-component__invoice-modal__section', {
				[`asteria--type-${type}`]: type,
			})}
		>
			<Title size="xxs">
				{TranslationService.get(
					[
						'invoice.modal.details.section.title',
						`invoice.modal.details.section.${type}.title`,
						`invoice.modal.details.${invoice?.type}.section.title`,
						`invoice.modal.details.${invoice?.type}.section.${type}.title`,
					],
					undefined,
					{ invoice: invoice },
				)}
			</Title>
			<List size="lg">
				{fields.map(({ key, value }) => (
					<ListItem key={key}>
						<ListCell>
							<Text size="sm">
								{TranslationService.get(
									[
										'invoice.modal.details.section.item.label',
										`invoice.modal.details.section.item.${key}.label`,
										`invoice.modal.details.${invoice?.type}.section.item.label`,
										`invoice.modal.details.${invoice?.type}.section.item.${key}.label`,
									],
									undefined,
									{ invoice: invoice },
								)}
							</Text>
						</ListCell>
						<ListCell>
							{value ? (
								typeof value === 'object' &&
								React.isValidElement(value) ? (
									value
								) : (
									<Text size="sm">{value}</Text>
								)
							) : (
								<Text size="sm">
									{TranslationService.get(
										[
											'invoice.modal.details.section.item.value',
											`invoice.modal.details.section.item.${key}.value`,
											`invoice.modal.details.${invoice?.type}.section.item.value`,
											`invoice.modal.details.${invoice?.type}.section.item.${key}.value`,
										],
										undefined,
										{ invoice: invoice },
									)}
								</Text>
							)}
						</ListCell>
					</ListItem>
				))}
			</List>
		</div>
	);
};

InvoiceDetailsModalSection.displayName = 'InvoiceDetailsModalSection';
InvoiceDetailsModalSection.propTypes = {
	invoice: PropTypes.object,
	onAction: PropTypes.func,
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		}),
	),
	type: PropTypes.string,
};

const RemoveButton = React.memo((props) => {
	const { onSubmit, id } = props;

	const onRemoveSubmit = React.useCallback(
		() => onSubmit?.('invoice:remove', id),
		[id, onSubmit],
	);

	const toggle = React.useMemo(
		() => ({
			label: TranslationService.get(['invoice.modal.details.remove']),
			iconTooltip: TranslationService.get([
				'invoice.modal.details.remove.tooltip',
			]),
			variant: 'link',
			icon: 'help',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="invoice.modal.details.remove.message-box"
			scrollIntoView
		>
			{({ onClose }) => [
				<Content key="content">
					<Text>
						{TranslationService.get([
							'invoice.modal.details.remove.confirm',
						])}
					</Text>
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
							])}
							onClick={onRemoveSubmit}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveButton.displayName = 'RemoveButton';
RemoveButton.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	id: PropTypes.string,
};

const InvoiceDetailsModalContent = (props) => {
	const { onClose, onAction, onSubmit, _id } = props;

	const dispatch = useDispatch();

	const invoice = useInvoice({ id: _id, onSubmit, onAction });

	const clientId = invoice?.clientId;
	const clientName = useSelector(
		(store) => AppStore.selectors.client(store, clientId)?.name,
		(a, b) => isEqual(a, b),
	);

	const isExcluded = (invoice?.services ?? []).some(
		({ status }) => status === 'DISCARD',
	);

	const isSent = (invoice?.services ?? []).some(
		({ status }) => status === 'SENT',
	);

	const isCompleted = (invoice?.services ?? []).some(
		({ status }) => status === 'COMPLETED',
	);

	const onClientClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientOverview,
				data: { _id: clientId },
			}),
		);
	}, [clientId, dispatch]);

	const handleSubmit = React.useCallback(
		(action, data) => {
			if (action === 'invoice:remove') {
				onClose?.();
			}

			return onSubmit?.(action, data);
		},
		[onClose, onSubmit],
	);

	const onEditClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.InvoiceEdit,
				data: { id: _id },
			}),
		);
	}, [_id, dispatch]);

	const detailsFields = React.useMemo(
		() => [
			{ key: 'number' },
			{ key: 'dates.sent' },
			{ key: 'dates.due' },
			{
				key: 'currency',
				value: (
					<Chip
						flag={invoice?.sums?.original?.currency}
						size="sm"
						variant="simple"
						label={invoice?.sums?.original?.currency}
					/>
				),
			},
			{
				key: 'client',
				value: (
					<Button
						variant="link"
						size="sm"
						label={clientName}
						onClick={onClientClick}
					/>
				),
			},
			{ key: 'message' },
		],
		[clientName, invoice?.sums?.original?.currency, onClientClick],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						'invoice.modal.details.title',
						`invoice.modal.details.${invoice?.type}.title`,
					],
					undefined,
					{ invoice: invoice },
				)}
			</Header>
			<Content scroll>
				<TextGroup>
					<Text size="sm">
						{TranslationService.get(
							[
								'invoice.modal.details.placeholder.before',
								`invoice.modal.details.${invoice?.type}.placeholder.before`,
							],
							undefined,
							{ invoice: invoice },
						)}
					</Text>
					<Title>
						{TranslationService.get(
							[
								'invoice.modal.details.placeholder.title',
								`invoice.modal.details.${invoice?.type}.placeholder.title`,
							],
							undefined,
							{ invoice: invoice },
						)}
					</Title>
					<Text size="sm">
						{TranslationService.get(
							[
								'invoice.modal.details.placeholder.after',
								`invoice.modal.details.${invoice?.type}.placeholder.after`,
							],
							undefined,
							{ invoice: invoice },
						)}
					</Text>

					{invoice?.invoiceLayoutDetails?.pdfUri ? (
						<Button
							variant="link"
							label={TranslationService.get(
								[
									'invoice.modal.details.placeholder.invoice.pdf.label',
								],
								undefined,
								{ invoice: invoice },
							)}
							icon="external"
							iconSize="md"
							href={TranslationService.get(
								'page.invoices.pending.table.open',
								undefined,
								{ uri: invoice?.invoiceLayoutDetails?.pdfUri },
							)}
							target="__blank"
						/>
					) : null}
				</TextGroup>
				<div className="asteria-component__invoice-modal__content">
					<InvoiceDetailsModalSection
						type="details"
						invoice={invoice}
						fields={detailsFields}
						onAction={onAction}
					/>
				</div>

				{isExcluded ? (
					<RemoveButton
						onSubmit={handleSubmit}
						onAction={onAction}
						id={_id}
					/>
				) : null}
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'invoice.modal.details.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<FeatureFlag feature="invoice-details-edit">
					{!(isSent || isCompleted) ? (
						<FooterSection position="last">
							<Button
								variant="secondary"
								label={TranslationService.get([
									'button.edit',
									'action.edit',
									'invoice.modal.details.edit',
								])}
								onClick={onEditClick}
							/>
						</FooterSection>
					) : null}
				</FeatureFlag>
			</Footer>
		</Wrapper>
	);
};

InvoiceDetailsModalContent.displayName = 'InvoiceDetailsModalContent';
InvoiceDetailsModalContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	_id: PropTypes.string,
};

const InvoiceDetailsModal = (props) => {
	const { className, open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-details',
				className,
			)}
			scroll
			size="sm"
		>
			<InvoiceDetailsModalContent {...props} />
		</Modal>
	);
};

InvoiceDetailsModal.displayName = 'InvoiceDetailsModal';

InvoiceDetailsModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InvoiceDetailsModal;
