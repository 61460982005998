import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const ActionDialog = (props) => {
	const { onClose, className, onAction, action, text } = props;

	const closeDialog = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const onClick = useCallback(() => {
		closeDialog?.();
		onAction?.(action?.action, action?.data);
	}, [closeDialog, onAction, action]);

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'xs' }),
		[],
	);

	return (
		<Dialog
			className={cn('asteria-component__action-dialog', className)}
			onEnd={closeDialog}
			placement="bottom"
		>
			<Wrapper>
				<Header onClose={closeDialog} postfix={HeaderPostfix}>
					<Title type="title" size="sm">
						{TranslationService.get([`${text}.title`])}
					</Title>
				</Header>
				<Content>
					<Text>{TranslationService.get([`${text}.text`])}</Text>
					<Button
						variant="primary"
						size="sm"
						label={TranslationService.get([`${text}.button`])}
						onClick={onClick}
					/>
				</Content>
			</Wrapper>
		</Dialog>
	);
};

ActionDialog.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	action: PropTypes.any,
	text: PropTypes.string,
};

export default ActionDialog;
