import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Icon from '@asteria/component-core/icon';
import withAnalytics from '@asteria/component-core/utils/withAnalytics';

import { cn } from '@asteria/utils-funcs/classes';

import InputWrapper from '../generic/InputWrapper';
import { withLabel } from '../generic/Label';
import withRegister from '../withRegister';

import './styles.scss';

const Switch = React.forwardRef((props, ref) => {
	const {
		className,
		size,
		active,
		disabled,
		prefix,
		postfix,
		error,
		icon,
		iconActive = icon,
		onChange,
		...input
	} = props;

	delete input.setValueAs;
	delete input.label;
	delete input.uncontrolled;
	delete input.defaultValue;
	delete input.multiplier;

	const [isActive, setActive] = useState(input.checked ?? active ?? false);

	useEffect(() => {
		setActive(active);
	}, [active]);

	const handleChange = useCallback(
		(event) => {
			setActive(event.target.checked);

			return onChange?.(event);
		},
		[onChange],
	);

	return (
		<label
			className={cn(
				'asteria-component__form-v2__switch-wrapper',
				className,
			)}
		>
			<InputWrapper
				active={isActive}
				disabled={disabled}
				error={error}
				prefix={prefix}
				size={size}
				postfix={postfix}
				className="asteria-component__form-v2__switch"
			>
				<input
					className="asteria-component__form-v2__switch__input"
					type="checkbox"
					ref={ref}
					onChange={handleChange}
					disabled={disabled}
					{...input}
					checked={isActive}
				/>
				<div
					className={cn(
						'asteria-component__form-v2__switch__thumb__wrapper',
					)}
				>
					<div
						className={cn(
							'asteria-component__form-v2__switch__thumb',
							{
								'asteria--state-has-icon':
									(icon && !isActive) ||
									(iconActive && isActive),
							},
						)}
					>
						<Icon
							icon={isActive ? iconActive : icon}
							size={size === 'sm' ? 'xs' : 'sm'}
						/>
					</div>
				</div>
				<div
					className={cn('asteria-component__form-v2__switch__track')}
				/>
			</InputWrapper>
		</label>
	);
});

Switch.displayName = 'Switch';

Switch.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	prefix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	postfix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	icon: PropTypes.string,
	iconActive: PropTypes.string,
	size: SizeProp(),
	onChange: PropTypes.func,
};

export default withRegister(
	withAnalytics(withLabel(Switch, { className: 'asteria--state-w-fit' }), {
		event: 'form.switch',
		methods: [
			{
				key: 'onChange',
				format: (event, { analyticsKey }) => ({
					name: event?.target?.name,
					checked: event?.target?.checked,
					analyticsKey: analyticsKey,
				}),
			},
			'onBlur',
			'onFocus',
		],
	}),
);
