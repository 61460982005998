import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SidebarCollapse = React.memo((props) => {
	const { active, label, icon, children } = props;

	const [open, setOpen] = React.useState(true);

	const toggle = React.useCallback(() => {
		setOpen((value) => !value);
	}, []);

	return (
		<div
			className={cn('wings-sidebar__collapse', {
				'asteria--state-active': active,
			})}
		>
			<div
				className={cn('wings-sidebar__collapse-button', {
					'asteria--state-open': open,
					'asteria--state-active': active,
				})}
				onClick={toggle}
			>
				<div className="wings-sidebar__item__prefix">
					<Icon icon={icon} />
				</div>
				<div className="wings-sidebar__item__label">
					<Text size="sm">{label}</Text>
				</div>
				<div className="wings-sidebar__item__postfix">
					<Icon size="sm" icon="triangle-down" />
				</div>
			</div>
			{open ? (
				<div className="wings-sidebar__collapse-content">
					{children}
				</div>
			) : null}
		</div>
	);
});

SidebarCollapse.displayName = 'SidebarCollapse';
SidebarCollapse.propTypes = {
	active: PropTypes.bool,
	label: PropTypes.string,
	icon: PropTypes.string,
	children: PropTypes.node,
};

const Placeholder = React.memo(() => (
	<div className="wings-sidebar__placeholder" />
));
Placeholder.displayName = 'Placeholder';

const Spacer = React.memo(() => <div className="wings-sidebar__spacer" />);
Spacer.displayName = 'Spacer';

const SidebarButton = React.memo((props) => {
	const { active, placeholder, icon, onClick, label } = props;

	return (
		<div
			className={cn('wings-sidebar__item', {
				'asteria--state-active': active,
				'asteria--state-no-icon': !(placeholder || icon),
			})}
			onClick={onClick}
		>
			{placeholder || icon ? (
				<div className="wings-sidebar__item__icon">
					{placeholder ? <Placeholder /> : <Icon icon={icon} />}
				</div>
			) : null}
			<div className="wings-sidebar__item__label">
				{placeholder ? (
					<Placeholder />
				) : typeof label === 'string' ? (
					<Text size="sm">{label}</Text>
				) : (
					label
				)}
			</div>
		</div>
	);
});

SidebarButton.displayName = 'SidebarButton';
SidebarButton.propTypes = {
	active: PropTypes.bool,
	placeholder: PropTypes.bool,
	icon: PropTypes.string,
	onClick: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Sidebar = (props) => {
	const { className, navigation } = props;

	const onRedirect = React.useCallback(
		(path) => {
			return () => {
				navigation?.navigate?.(path);
			};
		},
		[navigation],
	);

	const onOpen = React.useCallback(
		(path) => {
			return () => {
				navigation?.open?.(path);
			};
		},
		[navigation],
	);

	return (
		<div
			className={cn(
				'wings-sidebar',
				'swedbank-sidebar',
				'asteria-component__layout__sidebar',
				className,
			)}
		>
			<SidebarButton
				label="Hem"
				onClick={onRedirect('/swedbank/home')}
				icon="framework-home"
			/>
			<SidebarButton placeholder />

			<SidebarButton
				label={TranslationService.get(['widget.navigation.streamline'])}
				onClick={onRedirect('/streamline')}
				icon="trends"
			/>
			<SidebarCollapse
				// eslint-disable-next-line spellcheck/spell-checker
				label="Företagskollen"
				icon="cashflow"
				active
			>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.cashflow',
					])}
					onClick={onRedirect('/')}
					active={navigation?.current === '/'}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.onboarding',
					])}
					onClick={onRedirect('/onboarding')}
					active={navigation?.current === '/onboarding'}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.forecaster',
						'sidebar.navigation.forecaster',
					])}
					onClick={onRedirect('/forecaster')}
					active={navigation?.current?.includes?.('/forecaster')}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.financial',
					])}
					onClick={onRedirect('/financial')}
					active={navigation?.current?.includes?.('/financial')}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.statement',
					])}
					onClick={onOpen('/statement')}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.overview',
					])}
					onClick={onOpen('/overview')}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.accounts',
					])}
					onClick={onOpen('/accounts')}
				/>
				<SidebarButton
					label={TranslationService.get([
						'widget.navigation.settings',
					])}
					onClick={onOpen('/settings')}
				/>
			</SidebarCollapse>
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<SidebarButton placeholder />
			<Spacer />
			<SidebarButton
				// eslint-disable-next-line spellcheck/spell-checker
				label="Logga ut"
				icon="framework-logout"
				onClick={() => {
					localStorage.removeItem('wingsToken');
					localStorage.removeItem('wingsRefreshToken');
					location.reload();
				}}
			/>
		</div>
	);
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
	className: PropTypes.string,
	navigation: PropTypes.shape({
		current: PropTypes.string,
		navigate: PropTypes.func,
		open: PropTypes.func,
	}),
};

export default Sidebar;
