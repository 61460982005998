import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';
import { stateClasses, statusClasses } from '@asteria/component-core/utils';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Step = React.memo((props) => {
	const { id, completed, active, disabled, index, onAction } = props;

	const onClick = React.useCallback(
		() => onAction?.('stepper:click', id),
		[id, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__stepper-step',
				statusClasses({ success: completed }),
				stateClasses({ active: active, disabled: disabled }),
			)}
			onClick={onClick}
		>
			<div className="asteria-component__stepper-step__icon">
				{completed ? (
					<Icon icon="check" />
				) : (
					<Title size="xs">{index}</Title>
				)}
			</div>

			<Text className="asteria-component__stepper-step__content">
				{TranslationService.get([
					'stepper.step.content',
					`stepper.step.${id}.content`,
				])}
			</Text>
		</div>
	);
});

Step.displayName = 'Step';
Step.propTypes = {
	id: PropTypes.string,
	index: PropTypes.number,
	completed: PropTypes.bool,
	active: PropTypes.bool,
	disabled: PropTypes.bool,

	onAction: PropTypes.func,
};

function useSteps({ onSubmit }) {
	const { pathname } = useLocation();

	const hasIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			}).length,
	);

	const hasBatches = useSelector(
		(store) => !!InvoiceStore.selectors.batches(store).length,
	);

	const hasCompletedBatches = useSelector((store) =>
		InvoiceStore.selectors
			.batches(store)
			.some((object) => object?.status === 'COMPLETED'),
	);

	const { data: hasInvoices } = useQuery({
		queryKey: ['invoices', 'exist'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				pageFilters: { first: 1 },
				fields: `_id`,
			}),
		select: (response) => !!response?.pageInfo?.count,

		placeholderData: false,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		retry: false,
	});

	const hasPrinterIntegration = useSelector((store) =>
		IntegrationStore.selectors
			.integrations(store, {
				type: 'erp',
				filters: [
					{ status: 'IDLE' },
					{ status: 'IMPORTING', connected: true },
				],
			})
			.some((object) => object?.key === 'printer'),
	);

	return React.useMemo(() => {
		const steps = [
			{
				id: 'connection',

				active: pathname.includes('/integrations'),
				disabled: false,
				completed: hasIntegrations || hasBatches || hasCompletedBatches,
			},
		];

		if (hasPrinterIntegration) {
			steps.push({
				id: 'layouts',
				active: pathname.includes('/layouts'),
				disabled: false,
				completed: hasInvoices || hasBatches || hasCompletedBatches,
			});
		}

		return [].concat(steps).concat([
			{
				id: 'invoices',

				active: ['/invoices', '/invoices/review'].includes(pathname),
				disabled:
					!hasIntegrations || (hasPrinterIntegration && !hasInvoices),
				completed: hasBatches || hasCompletedBatches,
			},
			{
				id: 'review',

				active: pathname.includes('/invoices/batch'),
				disabled: !hasIntegrations || !hasBatches,
				completed: hasCompletedBatches,
			},
		]);
	}, [
		hasBatches,
		hasCompletedBatches,
		hasIntegrations,
		hasInvoices,
		hasPrinterIntegration,
		pathname,
	]);
}

const Stepper = React.memo((props) => {
	const { className, onAction, onSubmit, forceShow } = props;

	const steps = useSteps({ onSubmit });

	const isCompleted = steps.every(({ completed }) => completed);

	if (isCompleted && !forceShow) {
		return null;
	}

	return (
		<div className={cn('asteria-component__stepper', className)}>
			{steps.map((step, index) => (
				<Step
					key={step?.id}
					{...step}
					index={index + 1}
					onAction={onAction}
				/>
			))}
		</div>
	);
});

Stepper.displayName = 'Stepper';

Stepper.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	onAction: PropTypes.func,
	forceShow: PropTypes.bool,
	onSubmit: PropTypes.func,
};

export default Stepper;
