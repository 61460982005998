import { BankService } from '@asteria/backend-utils-services';

const DefaultFields = `
id
name
active
identifiers {
	number
}
sums {
	original {
		currency
		total
	}
	display {
		currency
		total
		credit
	}
}
`;

export const fetch = async (options) => {
	const { fields, accessToken } = options;

	const accountFields = fields || DefaultFields;
	try {
		const data = await BankService.bankAccount
			.fetch(
				{
					fields: `
						edges {
							node {
								${accountFields}
							}
						}
					`,
					pageFilters: { first: 0, orderField: 'name' },
				},
				{ token: accessToken },
			)
			.then(({ edges = [] }) => edges?.map(({ node }) => node) || []);

		return data;
	} catch (e) {
		return null;
	}
};

export const update = async (options) => {
	const { accessToken, input } = options;

	try {
		await BankService.bankAccount.update(
			{ input: input },
			{ token: accessToken },
		);
	} catch (e) {
		// Do nothing
	}

	return fetch(options);
};
