import React from 'react';

const GridContext = React.createContext({
	hide: () => null,
	show: () => null,
	showFull: () => null,
	setLayout: () => null,
	hidden: [],
	removed: [],
	fullItem: null,
});

export default GridContext;
