import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import DatepickerContext from '../../context';

import DatepickerContentCalendarContent from './Content';
import DatepickerContentCalendarHeader from './Header';

import './styles.scss';

const DatepickerContentCalendar = (props) => {
	const { className } = props;

	const { type } = React.useContext(DatepickerContext);

	return (
		<div
			className={cn(
				'asteria-component__form-v2__datepicker-content__calendar',
				{ [`asteria-datepicker--type-${type}`]: type },
				className,
			)}
		>
			{type === 'day' ? <DatepickerContentCalendarHeader /> : null}
			<DatepickerContentCalendarContent />
		</div>
	);
};

DatepickerContentCalendar.displayName = 'DatepickerContentCalendar';

DatepickerContentCalendar.propTypes = { className: PropTypes.string };

export default DatepickerContentCalendar;
