import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const DatepickerContentCalendarCell = React.memo((props) => {
	const { className, date, type } = props;

	return (
		<div
			className={cn(
				'asteria-component__form-v2__datepicker-content__calendar-cell',
				{ [`asteria-datepicker--type-${type}`]: type },
				className,
			)}
		>
			<Text size="sm" align="center">
				{TranslationService.get(
					[
						'datepicker.calendar.cell',
						`datepicker.calendar.${type}.cell`,
					],
					undefined,
					{ date: date },
				)}
			</Text>
		</div>
	);
});

DatepickerContentCalendarCell.displayName = 'DatepickerContentCalendarCell';
DatepickerContentCalendarCell.propTypes = {
	className: PropTypes.string,
	date: PropTypes.instanceOf(Date),
	type: PropTypes.string,
};

export default DatepickerContentCalendarCell;
