import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Label = React.memo((props) => {
	const {
		className,
		children,
		label,
		position,
		verticalAlign,
		horizontalAlign,
		size,
		tooltip,
	} = props;

	return (
		<div
			className={cn(
				'asteria-component__form-v2__label',
				{
					[`asteria--position-${position}`]: position,
					[`asteria--align-vertical-${verticalAlign}`]: verticalAlign,
					[`asteria--align-horizontal-${horizontalAlign}`]:
						horizontalAlign,
				},
				className,
			)}
		>
			<div className="asteria-component__form-v2__label-inner">
				{React.isValidElement(label) ? (
					label
				) : (
					<Text size={size}>{label}</Text>
				)}
				{tooltip ? (
					<TooltipWrapper tooltip={tooltip}>
						<Icon
							icon="help-filled"
							className="asteria-component__form-v2__label-tooltip"
						/>
					</TooltipWrapper>
				) : null}
			</div>
			{children}
		</div>
	);
});

Label.displayName = 'Label';

Label.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
	verticalAlign: PropTypes.string,
	horizontalAlign: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	size: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

/**
 * @template T
 * @param { T } Component
 * @param {{ className?: string }} [options]
 * @returns { T }
 */
function withLabel(Component, options = {}) {
	const Wrapper = React.memo(
		React.forwardRef((props, ref) => {
			const { label, size } = props;

			if (label) {
				const args =
					typeof label === 'object' ? label : { label: label };

				return (
					<Label
						{...args}
						size={args?.size ?? size}
						className={cn(args?.className, options?.className)}
					>
						<Component {...props} label={null} ref={ref} />
					</Label>
				);
			}

			return <Component {...props} ref={ref} />;
		}),
	);

	Wrapper.displayName = Component.displayName;

	Wrapper.propTypes = {
		...Component.propTypes,
		label: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				className: PropTypes.string,
				position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
				verticalAlign: PropTypes.string,
				horizontalAlign: PropTypes.string,
				label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
				size: PropTypes.string,
				tooltip: PropTypes.oneOfType([
					PropTypes.node,
					PropTypes.string,
				]),
			}),
		]),
	};

	Wrapper.defaultProps = Component.defaultProps;
	Wrapper.__docgenInfo = Component.__docgenInfo;

	return Wrapper;
}

export default Label;
export { withLabel };
