import React, { useCallback } from 'react';

import { useDispatch, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';

import { setNavigationRoute } from '@asteria/datalayer/stores/navigate';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import cardImg from './assets/Group631.png';

import './welcome.scss';

const Welcome = function Welcome(props) {
	const {
		className,
		image,
		cardTitle,
		btnLabel,
		description,
		descriptionDetail,
		subDescription,
	} = props;

	const dispatch = useDispatch();
	const store = useStore();

	const handleOnClick = useCallback(() => {
		const currentRoute = store.getState()?.navigate?.route;

		dispatch(
			setNavigationRoute(
				currentRoute.includes('success')
					? '/welcomeForm/notification/error'
					: '/welcome/welcomeForm',
			),
		);
	}, [dispatch, store]);

	return (
		<div className={cn('asteria-component__welcome', className)}>
			<div className="asteria-component__welcome__card">
				<div className="asteria-component__welcome__card__image__container">
					<img
						src={image || cardImg}
						alt="welcome-laptop"
						className="asteria-component__welcome__card__image__container_img"
					/>
				</div>
				<div className="asteria-component__welcome__card__text__container">
					<Title size="xl">{cardTitle}</Title>
					<Text
						size="lg"
						className="asteria-component__welcome__card__text__container_noBottom"
					>
						{description}
					</Text>
					<Text size="lg">{descriptionDetail}</Text>
					<Text size="lg">{subDescription}</Text>
					<Button
						label={btnLabel}
						variant="secondary"
						className="asteria-component__welcome__card__text__container_button"
						onClick={handleOnClick}
					/>
				</div>
			</div>
		</div>
	);
};

Welcome.propTypes = {
	className: PropTypes.string,
	heading: PropTypes.string,
	image: PropTypes.string,
	cardTitle: PropTypes.string,
	btnLabel: PropTypes.string,
	description: PropTypes.string,
	descriptionDetail: PropTypes.string,
	subDescription: PropTypes.string,
};

Welcome.defaultProps = {
	className: null,
	image: cardImg,
	cardTitle: TranslationService.get('welcome.card.title'),
	btnLabel: TranslationService.get('welcome.card.btnLabel'),
	description: TranslationService.get('welcome.card.description'),
	descriptionDetail: TranslationService.get('welcome.card.descriptionDetail'),
	subDescription: TranslationService.get('welcome.card.subDescription'),
};

export default Welcome;
