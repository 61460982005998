import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const TrayStep = (props) => {
	const { onAction } = React.useContext(LayoutContext);

	const { id } = useParams();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() =>
			onAction?.(
				'go',
				`/integrations/erp/visma.administration/${id}/success`,
			),
		[id, onAction],
	);

	const data = React.useMemo(
		() => ({ integration: integration }),
		[integration],
	);

	return (
		<BaseStep
			{...props}
			step="tray"
			onBack={onBack}
			onNext={onNext}
			data={data}
		/>
	);
};

TrayStep.displayName = 'TrayStep';

TrayStep.propTypes = { className: PropTypes.string };

export default TrayStep;
