/* eslint-disable spellcheck/spell-checker */
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import Progress from '@asteria/component-core/progress';
import { Text, Title, UnorderedList } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Select from '@asteria/component-integrations/components/flows/generic/select';
import Modal from '@asteria/component-modal';
import { Provider } from '@asteria/component-tools/featureflag/context';

import { TranslationService } from '@asteria/language';

import StatementWrapper from '../../components/statement';

import LoginPlaceHolder from './assets/Login_placeholder.png';
import image2 from './assets/Union.png';
import error from './assets/error.png';
import image from './assets/sc.png';
import success from './assets/success.png';
import swedbank from './assets/swedbank.png';
import DataLayer from './components/datalayer';
import Features from './components/features';
import Theme from './components/theme';
import { AuthContext } from './context';

import './index.scss';

// Vendor Path Components
const CreditsLanding = (props) => {
	const {
		title,
		actions,
		description,
		loginTitle,
		showCircle,
		showCross,
		handleLoginOpen,
	} = props;
	return (
		<Wrapper className={`asteria-component__credits__wrapper`}>
			<Header>
				{title ? (
					<Title type="title" size="base">
						{title}
					</Title>
				) : null}
			</Header>
			{showCross && (
				<div
					className="asteria-component__shared_data_login"
					onClick={handleLoginOpen}
				>
					<Text size="lg">{loginTitle}</Text>
					<Icon icon="chevron-right" size="sm" />
				</div>
			)}
			<Content className="asteria-component__wrapper__content__background__center">
				{showCircle && (
					<div className="asteria-component__content__flex-center">
						<div className="asteria-component__wrapper__circle-content">
							<Title size="xl">{title}</Title>
						</div>
					</div>
				)}
				{/* Below screen is removed due to reuirement change by Carl Bodlund */}
				{/* {showCross && (
					<div className="asteria-component__content__flex-center">
						<div className="asteria-component__wrapper__square-content close">
						</div>
					</div>
				)} */}
				<Text
					size="lg"
					className={
						!showCircle &&
						'asteria-component__wrapper__content__text__w-80'
					}
				>
					{description}
				</Text>
			</Content>
			<Footer className="asteria-component__credits-footer">
				{actions &&
					actions.map((button, index) => (
						<Button
							key={index}
							variant={button?.variant}
							label={button?.label}
							size={button?.size}
							icon={button?.icon}
							iconPosition={button?.iconPosition}
							onClick={button?.action}
						/>
					))}
			</Footer>
		</Wrapper>
	);
};
const SignAgreement = ({ handleOpen }) => {
	return (
		<div>
			<div
				className={`asteria-component__credits__wrapper`}
				onClick={handleOpen}
			>
				<div>
					<img src={image} alt="Bild4" width="100%" />
				</div>
			</div>
		</div>
	);
};
const ConfirmBookkeeping = ({ handleOpen }) => {
	return (
		<div>
			<div
				className={`asteria-component__credits__wrapper`}
				onClick={handleOpen}
			>
				<div>
					<img
						src={LoginPlaceHolder}
						alt="LoinPlaceHolder"
						width="100%"
					/>
				</div>
			</div>
		</div>
	);
};
const ConnectAgreement = ({ handleClose, onClose }) => {
	const [flow, setFlow] = useState({});
	const [integration, setIntegration] = useState({});
	const closeAgreement = useCallback(() => {
		handleClose();
	}, []);
	return (
		<div>
			<Select
				integration={integration}
				setIntegration={setIntegration}
				flow={flow}
				setFlow={setFlow}
				onClose={onClose ?? handleClose}
				onNextClose={closeAgreement}
				nextBtnLabel={TranslationService.get('action.continue')}
				prevBtnLabel={TranslationService.get(
					'action.integration.cancel',
				)}
				selectDescription={
					<Content className="asteria-component__wrapper-content_no_top_bottom-padding">
						<Text>
							{TranslationService.get('account.list.heading')}
						</Text>
						<UnorderedList
							items={[
								{
									icon: 'bullet',
									type: 'text',
									value: TranslationService.get(
										'account.list.text.1',
									),
								},
								{
									icon: 'bullet',
									type: 'text',
									value: TranslationService.get(
										'account.list.text.2',
									),
								},
								{
									icon: 'bullet',
									type: 'text',
									value: TranslationService.get(
										'account.list.text.3',
									),
								},
							]}
						/>
					</Content>
				}
			/>
		</div>
	);
};
const BookkeepingDownloading = (props) => {
	const {
		title,
		actions,
		loaderTitle,
		alertTile,
		downloadedActions,
		isDownloading,
		onClose,
	} = props;
	return (
		<Wrapper>
			<Header onClose={onClose}>
				{title ? (
					<Title type="title" size="base">
						{title}
					</Title>
				) : null}
			</Header>
			{isDownloading ? (
				<>
					<Content className="asteria-component__wrapper__content__background__center asteria-component__credits__bookkeeping">
						<Text>{loaderTitle}</Text>
						<Progress progress={-1} />
					</Content>
					<Footer>
						{actions &&
							actions.map((button, index) => (
								<Button
									key={index}
									variant={button?.variant}
									label={button?.label}
									size={button?.size}
									icon={button?.icon}
									iconPosition={button?.iconPosition}
									onClick={button?.action}
								/>
							))}
					</Footer>
				</>
			) : (
				<>
					<Content>
						<Alert
							level="success"
							type="feedback"
							icon="errorCircle"
							iconPosition="first"
						>
							<Text
								type="title"
								className="asteria-component__head-alert"
							>
								{alertTile}
							</Text>
							<div className="asteria-component__text__divider"></div>
							<Text className="asteria-component__main-text-bookkeeping">
								Foljande Limit ar godkand
							</Text>
							<Text
								type="text"
								size="base"
								className="asteria-component__sub-text-bookkeeping"
							>
								1 500 000
							</Text>
						</Alert>
					</Content>
					<Footer>
						{downloadedActions &&
							downloadedActions.map((button, index) => (
								<Button
									key={index}
									variant={button?.variant}
									label={button?.label}
									size={button?.size}
									icon={button?.icon}
									iconPosition={button?.iconPosition}
									onClick={button?.action}
								/>
							))}
					</Footer>
				</>
			)}
		</Wrapper>
	);
};
// Customer Path Components
const SignAgreementCustomerPath = (props) => {
	const { handleOpen, openAgreement } = props;
	const getImage = useCallback(() => {
		if (openAgreement?.signAgreementCustomerPathSuccess) {
			return success;
		} else if (openAgreement?.signAgreementCustomerPathError) {
			return error;
		} else {
			return swedbank;
		}
	}, [openAgreement]);
	return (
		<div className="asteria-component__customer__path-signagreement">
			<div
				className={`asteria-component__credits__wrapper`}
				onClick={handleOpen}
			>
				<div>
					<img src={getImage()} alt="Bild4" width="100%" />
				</div>
			</div>
		</div>
		// <div className="asteria-component__customer__path-signagreement">
		// 	<Wrapper>
		// 		<Header>
		// 			{title ? (
		// 				<Title type="title" size="base">
		// 					{title}
		// 				</Title>
		// 			) : null}
		// 		</Header>
		// 		<Content>
		// 			<Text>
		// 				En rådgivare ringer upp dig och hjälper dig att hitta
		// 				rätt finansiering och att göra en ansökan direkt på
		// 				telefon.
		// 			</Text>
		// 			<Title
		// 				type="title"
		// 				size="base"
		// 				className="asteria-component__customer__path__content__title"
		// 			>
		// 				Företagets behov
		// 			</Title>
		// 		</Content>
		// 		<Footer>
		// 			{actions &&
		// 				actions?.map((button, index) => (
		// 					<Button
		// 						key={index}
		// 						variant={button?.variant}
		// 						label={button?.label}
		// 						size={button?.size}
		// 						icon={button?.icon}
		// 						iconPosition={button?.iconPosition}
		// 						onClick={button?.action}
		// 					/>
		// 				))}
		// 		</Footer>
		// 	</Wrapper>
		// </div>
	);
};
const ConfirmDelete = (props) => {
	const { title, onClose, contentText, actions } = props;
	return (
		<Wrapper className="asteria-component__wrapper__confirm_delete">
			<Header onClose={onClose}>
				{title ? (
					<Title type="title" size="base">
						{title}
					</Title>
				) : null}
			</Header>
			<Content className="asteria-component__content-border">
				<Text size="lg">{contentText}</Text>
			</Content>
			<Footer>
				{actions &&
					actions.map((button, index) => (
						<Button
							key={index}
							variant={button?.variant}
							label={button?.label}
							size={button?.size}
							icon={button?.icon}
							iconPosition={button?.iconPosition}
							onClick={button?.action}
						/>
					))}
			</Footer>
		</Wrapper>
	);
};
const DeletedSuccessfully = (props) => {
	const { title, actions, alertTile, onClose } = props;
	return (
		<Wrapper className="asteria-component__wrapper__confirm_delete">
			<Header onClose={onClose}>
				{title ? (
					<Title type="title" size="base">
						{title}
					</Title>
				) : null}
			</Header>
			<Content>
				<Alert
					level="success"
					type="feedback"
					icon="errorCircle"
					iconPosition="first"
				>
					<Text
						type="title"
						className="asteria-component__head-alert"
					>
						{alertTile}
					</Text>
					<div className="asteria-component__text__divider"></div>
					<div className="asteria-component__alert_image">
						<img src={image2} />
					</div>
				</Alert>
			</Content>
			<Footer>
				{actions &&
					actions.map((button, index) => (
						<Button
							key={index}
							variant={button?.variant}
							label={button?.label}
							size={button?.size}
							icon={button?.icon}
							iconPosition={button?.iconPosition}
							onClick={button?.action}
						/>
					))}
			</Footer>
		</Wrapper>
	);
};

const Wrappers = () => {
	const [openAgreement, setOpenAgreement] = useState({
		creditsLanding: true, // Make Web responsive
		login: false,
		signAgreementCustomerPath: false,
		// deleted: true,
	});
	const handleLoginOpen = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			creditsLanding: false,
			login: true,
		});
	}, [openAgreement?.creditsLanding, openAgreement?.login]);
	const handleOpenSignAgreement = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			creditsLanding: false,
			signAgreement: true,
		});
	}, [openAgreement?.signAgreement]);
	const handleModalOpenSignAgreement = useCallback(() => {
		setOpenAgreement({
			// ...openAgreement,
			// creditsLanding: false,
			modalSignAgreement: true,
		});
	}, [openAgreement?.modalSignAgreement]);
	const handleCloseSignAgreement = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			signAgreement: false,
			connectAgreement: true,
		});
	}, [openAgreement?.signAgreement, openAgreement?.connectAgreement]);
	const handleModalCloseSignAgreement = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			modalSignAgreement: false,
			modalConnectAgreement: true,
		});
	}, [
		openAgreement?.modalSignAgreement,
		openAgreement?.modalConnectAgreement,
	]);

	const handleConnectAgreement = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			connectAgreement: false,
			bookkeeping: true,
			isDownloading: true,
		});
	}, [
		openAgreement?.connectAgreement,
		openAgreement?.bookkeeping,
		openAgreement?.isDownloading,
	]);
	const handleModalConnectAgreement = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			modalConnectAgreement: false,
			modalBookkeeping: true,
			modalIsDownloading: true,
		});
	}, [
		openAgreement?.modalConnectAgreement,
		openAgreement?.modalBookkeeping,
		openAgreement?.modalIsDownloading,
	]);

	const handleOpenDataShared = useCallback(() => {
		setOpenAgreement({
			deleteData: false,
			creditsLanding: false,
			confirmDelete: true,
		});
	}, [
		openAgreement?.deleteData,
		openAgreement?.dataShared,
		openAgreement?.confirmDelete,
	]);

	const downloadingComplete = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			isDownloading: false,
			bookkeeping: false,
			statement: true,
		});
	}, [openAgreement?.isDownloading]);
	const modalDownloadingComplete = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			modalIsDownloading: false,
			modalBookkeeping: false,
			modalStatement: true,
		});
	}, [openAgreement?.isDownloading]);

	const downloadingCompleteSuccess = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			bookkeeping: true,
			statement: false,
		});
	}, [openAgreement?.isDownloading]);
	const modalDownloadingCompleteSuccess = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			modalBookkeeping: true,
			modalStatement: false,
		});
	}, [openAgreement?.bookkeeping, openAgreement?.modalStatement]);
	const downloadingCompleteReject = useCallback(() => {
		setOpenAgreement({
			creditsLanding: true,
		});
	}, [openAgreement?.isDownloading]);

	const handleBookkeepingDownloading = useCallback(() => {
		setOpenAgreement({
			...openAgreement,
			creditsLanding: false,
			bookkeeping: false,
			isDownloading: false,
			deleteData: true,
			confirmDelete: false,
		});
	}, [
		openAgreement?.creditsLanding,
		openAgreement?.bookkeeping,
		openAgreement?.deleteData,
	]);
	const handleModalBookkeepingDownloading = useCallback(() => {
		setOpenAgreement({
			modalBookkeeping: false,
			signAgreementCustomerPath: true,
			signAgreementCustomerPathError: true,
		});
		setTimeout(() => {
			setOpenAgreement({
				signAgreementCustomerPath: true,
				signAgreementCustomerPathError: false,
				signAgreementCustomerPathSuccess: true,
			});
		}, 5000);
	}, [openAgreement?.modalBookkeeping]);
	const handleStatementOpen = useCallback(() => {
		setOpenAgreement({
			statement: true,
		});
	}, [openAgreement?.statement]);
	// const handleModalStatementOpen = useCallback(() => {
	// 	setOpenAgreement({
	// 		modalStatement: true,
	// 	});
	// }, [openAgreement?.modalStatement]);

	const handleConfirmDeleteData = useCallback(() => {
		setOpenAgreement({
			confirmDelete: false,
			deleted: true,
		});
	}, [openAgreement?.confirmDelete]);
	const handleOpenLanding = useCallback(() => {
		setOpenAgreement({
			creditsLanding: true,
		});
	}, [openAgreement?.confirmDelete]);

	let args = {
		integrations: [{ type: 'erp' }],
		data: {
			accountingResults: {
				years: ['2019', '2020', '2021'],
				rows: [
					{
						label: 'accounting.results.deposit',
						values: null,
						rows: [
							{
								label: 'accounting.results.deposit.revenue',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.results.deposit.purchases',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.results.deposit.faction',
						values: null,
						rows: null,
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.results.expenses',
						values: null,
						rows: [
							{
								label: 'accounting.results.expenses.misc_expenses',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.results.withdraw.employees',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.results.withdraw.depreciation',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.financial_results',
						values: null,
						rows: [
							{
								label: 'accounting.results.financial_net',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.results_before_disp',
						values: null,
						rows: [
							{
								label: 'accounting.results.extra_netto',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.results',
						values: null,
						rows: [
							{
								label: 'accounting.results.statement',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.results.taxes',
								values: [0, 0, 0],
								total: null,
								badges: [false, false, true],
								rows: null,
							},
						],
						total: null,
						badges: true,
					},
				],
				total: [0, 0, 0],
				currency: 'SEK',
				badges: [false, false, false],
			},
			accountingBalanceSheet: {
				years: ['2019', '2020', '2021'],
				rows: [
					{
						label: 'accounting.balance.assets',
						values: null,
						rows: [
							{
								label: 'accounting.balance.assets.assets',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.buildings',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.machines',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.financial',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.warehouse',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.customers',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.receivables',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.prepaid',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.placements',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.assets.account',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
					{
						label: 'accounting.balance.debt',
						values: null,
						rows: [
							{
								label: 'accounting.balance.debt.capital_taxation',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.capital_none_taxation',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.provisions',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.longterm',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.suppliers',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.tax_debt',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.vat',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.employee_taxes',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.misc',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.balance.debt.prepaid',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: [0, 0, 0],
						badges: null,
					},
				],
				currency: 'SEK',
				badges: null,
			},
			accountingWorkingCapital: {
				years: ['2019', '2020', '2021'],
				rows: [
					{
						label: 'accounting.capital',
						values: null,
						rows: [
							{
								label: 'accounting.capital.storage',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.capital.customer_credit',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.capital.misc_receivables',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.capital.supplier_credit',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.capital.invoice_credit_debt',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.capital.misc_credit',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: null,
						badges: null,
					},
				],
				total: null,
				currency: 'SEK',
				badges: null,
			},
			accountingMisc: {
				years: ['2019', '2020', '2021'],
				rows: [
					{
						label: 'accounting.misc',
						values: null,
						rows: [
							{
								label: 'accounting.misc.net_investments',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.misc.dividend',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.misc.rights_issue',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.misc.amortizations',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
							{
								label: 'accounting.misc.loans',
								values: [0, 0, 0],
								total: null,
								badges: null,
								rows: null,
							},
						],
						total: null,
						badges: null,
					},
				],
				total: null,
				currency: 'SEK',
				badges: null,
			},
		},
	};

	return (
		<>
			{openAgreement?.creditsLanding && (
				<CreditsLanding
					title={TranslationService.get(
						'credits.mobile.landing.title',
					)}
					loginTitle={TranslationService.get(
						'credits.mobile.landing.login.title',
					)}
					showCircle={true}
					showCross={true}
					handleLoginOpen={handleLoginOpen}
					openAgreement={openAgreement}
					actions={[
						{
							icon: 'triangle-right',
							label: TranslationService.get('aside.button.label'),
							size: 'medium',
							variant: 'link',
							action: handleOpenSignAgreement,
						},
					]}
					description={TranslationService.get(
						'credits.mobile.landing.description',
					)}
				/>
			)}
			{openAgreement?.bookkeeping && (
				<BookkeepingDownloading
					isDownloading={!!openAgreement.isDownloading}
					title={TranslationService.get(
						'credits.mobile.landing.bookkeeping.downloading.title',
					)}
					loaderTitle={TranslationService.get(
						'credits.mobile.landing.bookkeeping.downloading.loader.title',
					)}
					actions={[
						{
							label: TranslationService.get('action.close'),
							variant: 'default',
						},
						{
							label: TranslationService.get('action.next'),
							variant: 'secondary',
							action: downloadingComplete,
						},
					]}
					alertTile={TranslationService.get(
						'credits.mobile.landing.bookkeeping.downloading.alert.title',
					)}
					downloadedActions={[
						{},
						{
							label: TranslationService.get('action.clear'),
							variant: 'primary',
							action: handleBookkeepingDownloading,
						},
					]}
				/>
			)}
			{openAgreement?.signAgreement && (
				<SignAgreement handleOpen={handleCloseSignAgreement} />
			)}
			{openAgreement?.connectAgreement && (
				<ConnectAgreement handleClose={handleConnectAgreement} />
			)}
			{openAgreement?.deleteData && (
				<CreditsLanding
					title={TranslationService.get(
						'credits.mobile.landing.title',
					)}
					showCircle={false}
					actions={[
						{
							icon: 'triangle-right',
							label: TranslationService.get(
								'credits.mobile.landing.delete.date.button.title',
							),
							size: 'medium',
							variant: 'link',
							action: handleOpenDataShared,
						},
					]}
					description={TranslationService.get(
						'credits.mobile.landing.delete.date.description',
					)}
				/>
			)}
			{openAgreement?.confirmDelete && (
				<ConfirmDelete
					title={TranslationService.get(
						'credits.mobile.landing.delete.data.header.title',
					)}
					onClose={false}
					actions={[
						{
							label: TranslationService.get('action.abort'),
							size: 'medium',
							variant: 'default',
							action: handleBookkeepingDownloading,
						},
						{
							label: TranslationService.get(
								'credits.mobile.landing.delete.data.header.title',
							),
							size: 'medium',
							variant: 'primary',
							action: handleConfirmDeleteData,
						},
					]}
					contentText={TranslationService.get(
						'credits.mobile.landing.delete.data.content.title',
					)}
				/>
			)}

			{openAgreement.deleted && (
				<DeletedSuccessfully
					alertTile={TranslationService.get(
						'credits.mobile.landing.bookkeeping.delete.complete.title',
					)}
					actions={[
						{},
						{
							label: TranslationService.get('action.clear'),
							variant: 'primary',
							action: handleOpenLanding,
						},
					]}
				/>
			)}
			{/* Component below is no longer displayed or conditionally rendered on request of Carl */}
			{openAgreement?.dataShared && (
				<CreditsLanding
					title={TranslationService.get(
						'credits.mobile.landing.title',
					)}
					showCross={true}
					actions={[
						{
							icon: 'triangle-right',
							// iconPosition: 'last',
							label: TranslationService.get('aside.button.label'),
							size: 'medium',
							variant: 'link',
							action: handleOpenSignAgreement,
						},
					]}
					description={TranslationService.get(
						'credits.mobile.landing.description',
					)}
				/>
			)}
			{openAgreement?.login && (
				<ConfirmBookkeeping handleOpen={handleStatementOpen} />
			)}
			{openAgreement.statement && (
				<Provider
					features={{
						'credit-application': { version: 1 },
						'credit-application-teaser': { version: 1 },
					}}
				>
					<Wrapper>
						<Content>
							<StatementWrapper
								{...args}
								hideDetailCard={true}
								showError={true}
							/>
						</Content>
						<Footer>
							<Button
								variant="tertiary"
								label={TranslationService.get('action.abort')}
								onClick={downloadingCompleteReject}
							/>
							<Button
								variant="primary"
								label={TranslationService.get('action.share')}
								onClick={downloadingCompleteSuccess}
							/>
						</Footer>
					</Wrapper>
				</Provider>
			)}
			{openAgreement.modalStatement && (
				<Modal size="md">
					<Provider
						features={{
							'credit-application': { version: 1 },
							'credit-application-teaser': { version: 1 },
						}}
					>
						<Wrapper>
							<Content>
								<StatementWrapper
									{...args}
									hideDetailCard={true}
									showError={false}
									showHeader
									onClose={modalDownloadingCompleteSuccess}
								/>
							</Content>
							<Footer>
								<Button
									variant="tertiary"
									label={TranslationService.get(
										'action.abort',
									)}
									onClick={modalDownloadingCompleteSuccess}
								/>
								<Button
									variant="primary"
									label={TranslationService.get(
										'action.share',
									)}
									onClick={modalDownloadingCompleteSuccess}
								/>
							</Footer>
						</Wrapper>
					</Provider>
				</Modal>
			)}
			{openAgreement.signAgreementCustomerPath && (
				<SignAgreementCustomerPath
					handleOpen={handleModalOpenSignAgreement}
					openAgreement={openAgreement}
				/>
			)}
			{openAgreement?.modalSignAgreement && (
				<Modal size="sm">
					<SignAgreement handleOpen={handleModalCloseSignAgreement} />
				</Modal>
			)}
			{openAgreement?.modalConnectAgreement && (
				<Modal size="sm">
					<ConnectAgreement
						handleClose={handleModalConnectAgreement}
						onClose={true}
					/>
				</Modal>
			)}
			{openAgreement?.modalBookkeeping && (
				<Modal size="sm">
					<div>
						<BookkeepingDownloading
							onClose={true}
							isDownloading={openAgreement.modalIsDownloading}
							title={
								openAgreement.modalIsDownloading
									? TranslationService.get(
											'credits.mobile.landing.bookkeeping.downloading.title',
									  )
									: `Godkand Limit`
							}
							loaderTitle={TranslationService.get(
								'credits.mobile.landing.bookkeeping.downloading.loader.title',
							)}
							actions={[
								{
									label: TranslationService.get(
										'action.close',
									),
									variant: 'default',
								},
								{
									label: TranslationService.get(
										'action.next',
									),
									variant: 'secondary',
									action: modalDownloadingComplete,
								},
							]}
							alertTile={
								`Limit` ||
								TranslationService.get(
									'credits.mobile.landing.bookkeeping.downloading.alert.title',
								)
							}
							downloadedActions={[
								{},
								{
									label: TranslationService.get(
										'action.clear',
									),
									variant: 'primary',
									action: handleModalBookkeepingDownloading,
								},
							]}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};

const Widget = (props) => {
	const [partner, setPartner] = useState(null);
	const { accessToken, partnerId, language, loader = true } = props;

	useEffect(() => {
		const fetch = async () => {
			const response = await PartnerService.partner.fetchOne(
				{ id: partnerId, fields: 'name settings { themeId }' },
				{ token: accessToken },
			);

			setPartner(response);
		};

		fetch();
	}, [partnerId]);

	if (!partner) {
		return null;
	}

	return (
		<AuthContext.Provider
			value={{
				accessToken: accessToken,
				partnerId: partnerId,
				themeId: partner?.settings?.themeId,
				language: language,
			}}
		>
			<DataLayer loader={loader}>
				<Theme themeId={partner?.settings?.themeId}>
					<Features>
						<Wrappers />
					</Features>
				</Theme>
			</DataLayer>
		</AuthContext.Provider>
	);
};

Widget.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	language: PropTypes.string,
};

export default Widget;
