import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Modal from '@asteria/component-modal';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Actions from '../../Actions';
import { BatchRemoveButton } from '../BatchRemoveModal';
import ReviewTable from '../Table/ReviewTable';

import './styles.scss';

const BatchErrorDetails = (props) => {
	const { batch } = props;

	const code = batch?.data?.errors?.[0]?.code ?? batch?.data?.error?.code;

	const customerNo =
		batch?.data?.errors?.[0]?.customerNo ?? batch?.data?.error?.customerNo;

	const sellerOrderID =
		batch?.data?.errors?.[0]?.sellerOrderID ??
		batch?.data?.error?.sellerOrderID;

	return [
		<Text key="batch-error-title">
			{TranslationService.get(
				[
					`page.invoices.review.modal.error`,
					`page.invoices.review.modal.error.${code}`,
				]
					.concat(
						customerNo
							? [
									`page.invoices.review.modal.error.customer`,
									`page.invoices.review.modal.error.${customerNo}`,
									`page.invoices.review.modal.error.${code}.${customerNo}`,
							  ]
							: [],
					)
					.concat(
						sellerOrderID
							? [
									`page.invoices.review.modal.error.invoice`,
									`page.invoices.review.modal.error.${sellerOrderID}`,
									`page.invoices.review.modal.error.${code}.${sellerOrderID}`,
							  ]
							: [],
					)
					.concat(
						customerNo && sellerOrderID
							? [
									`page.invoices.review.modal.error.customer.invoice`,
									`page.invoices.review.modal.error.${customerNo}.${sellerOrderID}`,
									`page.invoices.review.modal.error.${code}.${customerNo}.${sellerOrderID}`,
							  ]
							: [],
					),
				undefined,
				{ batch: batch },
			)}
		</Text>,
		...[]
			.concat(batch?.data?.error)
			.concat(batch?.data?.errors)
			.filter(Boolean)
			.map((error) => (
				<Alert
					key={error?.code}
					level="error"
					icon="warning"
					iconPosition="last"
				>
					<Text>
						{TranslationService.get(
							[
								`page.invoices.review.modal.error.alert.content`,
								`page.invoices.review.modal.error.alert.${error?.code}.content`,
							]
								.concat(
									error?.customerNo
										? [
												`page.invoices.review.modal.error.alert.customer.content`,
												`page.invoices.review.modal.error.alert.${error?.customerNo}.content`,
												`page.invoices.review.modal.error.alert.${error?.code}.${error?.customerNo}.content`,
										  ]
										: [],
								)
								.concat(
									error?.sellerOrderID
										? [
												`page.invoices.review.modal.error.alert.invoice.content`,
												`page.invoices.review.modal.error.alert.${error?.sellerOrderID}.content`,
												`page.invoices.review.modal.error.alert.${error?.code}.${error?.sellerOrderID}.content`,
										  ]
										: [],
								)
								.concat(
									error?.customerNo && error?.sellerOrderID
										? [
												`page.invoices.review.modal.error.alert.customer.invoice.content`,
												`page.invoices.review.modal.error.alert.${error?.customerNo}.${error?.sellerOrderID}.content`,
												`page.invoices.review.modal.error.alert.${error?.code}.${error?.customerNo}.${error?.sellerOrderID}.content`,
										  ]
										: [],
								),
							'{{ error.message }}',
							{ error: error, batch: batch },
						)}
					</Text>
				</Alert>
			)),
	];
};

BatchErrorDetails.displayName = 'BatchErrorDetails';
BatchErrorDetails.propTypes = { batch: PropTypes.object };

const ReviewModalContent = React.memo((props) => {
	const { onClose, onAction, onSubmit, id } = props;

	const dispatch = useDispatch();

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, id),
		(a, b) => isEqual(a, b),
	);

	const status = batch?.status;

	const { data: invoices, isFetching } = useQuery({
		queryKey: ['batch', id, 'invoices'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				filters: {
					ids: (batch?.invoices ?? []).map(
						({ invoiceId }) => invoiceId,
					),
				},
				pageFilters: { first: 0 },
			}),

		select: (response) => (response?.edges ?? []).map(({ node }) => node),

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,

		placeholderData: [],
	});

	const onContinue = React.useCallback(
		(event) => {
			onClose?.(event);
			return onAction?.('go', `/invoices/batch/${id}`);
		},
		[id, onAction, onClose],
	);

	const onRetry = React.useCallback(
		async (event) => {
			onClose?.(event);
			await onAction?.('invoices:batch:remove', { id: batch?.id });
			dispatch(InvoiceStore.select([]));
			dispatch(InvoiceStore.select(invoices));
			return onAction?.('go', '/invoices/review');
		},
		[batch?.id, dispatch, invoices, onAction, onClose],
	);

	const actions = React.useMemo(
		() => [
			{
				id: 'batch',
				variant: 'link',
				path: `/invoices/batch/${id}`,
			},
		],
		[id],
	);

	const fields = React.useMemo(
		() => [
			{ name: 'invoice-number', tooltip: true },
			{ name: 'client-number', tooltip: true },
			{ name: 'client-name' },
			// { name: 'message' },
			{ name: 'invoice-date-sent' },
			{ name: 'invoice-date-due' },
			{ name: 'currency' },
			{ name: 'total' },
			{ name: 'tax' },
		],
		[],
	);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'invoices:batch:remove') {
				const response = await onAction?.(action, data);

				onClose?.();

				return response;
			}

			return onAction?.(action, data);
		},
		[onAction, onClose],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						'invoice.history.row.title',
						`invoice.history.row.${status?.toLowerCase?.()}.title`,
					],
					undefined,
					batch,
				)}
			</Header>
			<Content scroll>
				{status === 'SENT' ? (
					<Actions
						onAction={onAction}
						onSubmit={onSubmit}
						actions={actions}
					/>
				) : null}

				<div className="asteria-page__placeholder">
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
					>
						<Icon icon="document" />
						<Title size="sm" align="center">
							{TranslationService.get(
								[
									'page.invoices.placeholder.title',
									'page.invoices.review.placeholder.title',
									`page.invoices.review.modal.placeholder.title`,
									`page.invoices.review.modal.${status?.toLowerCase?.()}.placeholder.title`,
								],
								null,
								batch,
							)}
						</Title>
					</Group>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.subtext',
								'page.invoices.review.placeholder.subtext',
								`page.invoices.review.modal.placeholder.subtext`,
								`page.invoices.review.modal.${status?.toLowerCase?.()}.placeholder.subtext`,
							],
							null,
							batch,
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								'page.invoices.placeholder.content',
								'page.invoices.review.placeholder.content',
								`page.invoices.review.modal.placeholder.content`,
								`page.invoices.review.modal.${status?.toLowerCase?.()}.placeholder.content`,
							],
							null,
							batch,
						)}
					</Text>
					{/* <Button
							variant="link"
							label={TranslationService.get(
								[
									'page.invoices.placeholder.link',
									'page.invoices.review.placeholder.link',
									`page.invoices.review.modal.placeholder.link`,
									`page.invoices.review.modal.${status?.toLowerCase?.()}.placeholder.link`,
								],
								null,
								batch,
							)}
						/> */}
				</div>
				<div className="asteria-page__invoices_review__content">
					<Title size="xxs" align="left">
						{TranslationService.get(
							[
								'page.invoices.review.title',
								'page.invoices.review.modal.title',
								`page.invoices.review.modal.${status?.toLowerCase?.()}.title`,
							],
							null,
							batch,
						)}
					</Title>

					{status === 'ERROR' ? (
						<Group className="asteria-page__invoices-review__batch-errors">
							<BatchErrorDetails batch={batch} />
						</Group>
					) : null}

					<ReviewTable
						invoices={invoices}
						onAction={onAction}
						fields={fields}
						loading={isFetching}
					/>
				</div>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'invoice.modal.review.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					{status === 'ERROR' ? (
						<BatchRemoveButton
							id={id}
							onAction={handleAction}
							onSubmit={onSubmit}
							toggle={{
								variant: 'secondary',
								label: TranslationService.get([
									'button.remove',
									'action.remove',
									'invoice.modal.review.remove',
								]),
							}}
						/>
					) : null}

					{status === 'ERROR' ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.retry',
								'action.retry',
								'invoice.modal.review.retry',
							])}
							onClick={onRetry}
						/>
					) : null}
					{status !== 'ERROR' ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.continue',
								'action.continue',
								'invoice.modal.review.continue',
							])}
							onClick={onContinue}
						/>
					) : null}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ReviewModalContent.displayName = 'ReviewModalContent';
ReviewModalContent.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	invoices: PropTypes.arrayOf(PropTypes.object),
	id: PropTypes.string,
};

const ReviewModal = React.memo((props) => {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-review',
			)}
			size="lg"
			scroll
		>
			<ReviewModalContent {...props} />
		</Modal>
	);
});

ReviewModal.displayName = 'ReviewModal';
ReviewModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	invoices: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	id: PropTypes.string,
};

export default ReviewModal;
