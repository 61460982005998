import React from 'react';

import { Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

import CountrySelect from '../inputs/country';

import '../styles.scss';

const Billing = () => {
	return (
		<>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.name.label',
					'invoice.edit.field.contact.billing.name.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.name"
				disabled
			/>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.street.label',
					'invoice.edit.field.contact.billing.street.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.street"
				disabled
			/>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.street2.label',
					'invoice.edit.field.contact.billing.street2.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.street2"
				disabled
			/>
			<Input
				className="asteria--type-zip"
				label={TranslationService.get([
					'invoice.edit.field.contact.zipcode.label',
					'invoice.edit.field.contact.billing.zipcode.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.zipcode"
				disabled
			/>
			<Input
				className="asteria--type-city"
				label={TranslationService.get([
					'invoice.edit.field.contact.city.label',
					'invoice.edit.field.contact.billing.city.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.city"
				disabled
			/>
			<CountrySelect
				className="asteria--type-country"
				label={TranslationService.get([
					'invoice.edit.field.contact.country.label',
					'invoice.edit.field.contact.billing.country.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.billing.country"
				disabled
			/>
		</>
	);
};

Billing.displayName = 'Billing';

Billing.propTypes = {};

export default Billing;
