import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Currency = React.memo((props) => {
	const { currency } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const object = useSelector(
		(store) =>
			(InvoiceStore.selectors.statistics(store)?.details ?? []).find(
				(object) => object?.currency === currency,
			),
		(a, b) => isEqual(a, b),
	);

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SELECTED:CURRENCY',
				value: currency,
			}).length,
	);

	const onDismiss = React.useCallback(() => {
		const objects = InvoiceStore.selectors.selected(store.getState());
		const active = InvoiceStore.selectors.filters(store.getState(), {
			type: 'SELECTED:CURRENCY',
			value: currency,
		}).length;

		dispatch(
			InvoiceStore.select(
				objects.filter(
					(value) => value?.sums?.original?.currency === currency,
				),
			),
		);

		if (active) {
			dispatch(
				InvoiceStore.filter({
					type: 'SELECTED:CURRENCY',
					value: currency,
				}),
			);
		}
	}, [dispatch, currency, store]);

	const onClick = React.useCallback(() => {
		const filters = InvoiceStore.selectors
			.filters(store.getState())
			.filter(
				({ type }) =>
					!type.startsWith('SELECTED') && !type.startsWith('SERVICE'),
			);

		dispatch(
			InvoiceStore.filter(
				[{ type: 'SELECTED:CURRENCY', value: currency }].concat(
					filters,
				),
			),
		);
	}, [dispatch, currency, store]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				['invoices.actionbar.currency.chip.label'],
				undefined,
				object,
			)}
			dismiss
			onDismiss={onDismiss}
			onClick={onClick}
			active={active}
		/>
	);
});

Currency.displayName = 'Currency';
Currency.propTypes = {
	currency: PropTypes.string,
};

const Currencies = React.memo((props) => {
	const { className } = props;

	const dispatch = useDispatch();

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SELECTED:CURRENCY',
			}).length,
	);

	const onSelectAll = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'SELECTED:CURRENCY',
				value: null,
			}),
		);
	}, [dispatch]);

	const currencies = useSelector(
		(store) =>
			(InvoiceStore.selectors.statistics(store)?.details ?? [])
				.sort((a, b) => b.total - a.total)
				.map((object) => object?.currency),
		(a, b) => isEqual(a, b),
	);

	return (
		<Group
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
			className={cn(
				'asteria-component__invoice-actionbar__currencies',
				className,
			)}
		>
			<Chip
				size="sm"
				label={TranslationService.get([
					'invoices.actionbar.currency.chip.label',
					'invoices.actionbar.currency.chip.ALL.label',
				])}
				active={!active}
				onClick={onSelectAll}
			/>

			{currencies.map((currency) => (
				<Currency key={currency} currency={currency} />
			))}
		</Group>
	);
});

Currencies.displayName = 'Currencies';

Currencies.propTypes = {
	className: PropTypes.string,
};

export default Currencies;
