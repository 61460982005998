import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Support = React.memo((props) => {
	return null;

	// eslint-disable-next-line no-unreachable
	const {
		className,
		onAction,
		hideFAQ,
		hideSupport,
		hideTitle = true,
	} = props;

	const hasFAQFeature = useFeature('freja-faq');

	const handleFAQClick = React.useCallback(
		() => onAction?.('go', '/faq'),
		[onAction],
	);

	const handleSupportClick = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	if ((hideFAQ || !hasFAQFeature) && hideSupport) {
		return null;
	}

	return (
		<div className={cn('asteria-component__support-box', className)}>
			{!hideTitle ? (
				<Title size="page-title">
					{TranslationService.get(['support.box.title'])}
				</Title>
			) : null}
			<Group direction="horizontal">
				{!hideFAQ && hasFAQFeature ? (
					<Button
						variant="secondary"
						size="lg"
						label={TranslationService.get(['support.box.faq.link'])}
						icon="support-arrow"
						iconPosition="last"
						onClick={handleFAQClick}
					/>
				) : null}
				{!hideSupport ? (
					<Button
						variant="primary"
						size="lg"
						label={TranslationService.get([
							'support.box.support.link',
						])}
						icon="support-arrow"
						iconPosition="last"
						onClick={handleSupportClick}
					/>
				) : null}
			</Group>
		</div>
	);
});

Support.displayName = 'Support';

Support.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	hideFAQ: PropTypes.bool,
	hideSupport: PropTypes.bool,
	hideTitle: PropTypes.bool,
};

export default Support;
