/* eslint-disable spellcheck/spell-checker */
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { cn } from '@asteria/utils-funcs/classes';

import Drawer from './drawer';

/*
import { useStore } from '@asteria/utils';
import DataLayerContext from '@asteria/services-datalayer/react/context';
import {
	loginAdmin,
	logoutAdmin,
	fetchPartners,
} from '@asteria/services-datalayer/services/auth/actions';
import {
	fetchAllFeatures,
	fetchEnabledFeatures,
} from '@asteria/services-datalayer/services/features/actions';
*/
import FeaturesList from './featuresList';
import IconButton from './iconButton';

const ToolbarToggle = styled(({ className, isOpen, setOpen }) => (
	<div className={cn('asteria-widget-toolbar', className)}>
		<button
			type="button"
			className="asteria-toolbar-button"
			onClick={() => setOpen(!isOpen)}
		>
			<svg width="16" height="14" viewBox="0 0 16 14" fill="none">
				<path
					d="M0.571429 2.57143H15.4286C15.7442 2.57143 16 2.31561 16 2V0.571429C16 0.255821 15.7442 0 15.4286 0H0.571429C0.255821 0 0 0.255821 0 0.571429V2C0 2.31561 0.255821 2.57143 0.571429 2.57143ZM0.571429 8.28571H15.4286C15.7442 8.28571 16 8.02989 16 7.71429V6.28571C16 5.97011 15.7442 5.71429 15.4286 5.71429H0.571429C0.255821 5.71429 0 5.97011 0 6.28571V7.71429C0 8.02989 0.255821 8.28571 0.571429 8.28571ZM0.571429 14H15.4286C15.7442 14 16 13.7442 16 13.4286V12C16 11.6844 15.7442 11.4286 15.4286 11.4286H0.571429C0.255821 11.4286 0 11.6844 0 12V13.4286C0 13.7442 0.255821 14 0.571429 14Z"
					fill="white"
				/>
			</svg>
		</button>
	</div>
))`
	position: fixed;
	left: 16px;
	top: 16px;
	z-index: 1000;

	button {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		border: none;
		background-color: #292d3e;
		cursor: pointer;
		:hover {
			background-color: #656874;
		}
	}
`;

const Content = styled.div`
	position: relative;
	margin: 0px 0 48px 0;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Input = styled.input`
	display: block;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.17);
	border-radius: 2px;
	padding: 4px;
	width: 100%;

	font-family: Source Code Pro;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 8px;
`;

const Button = styled.button`
	background: #6db2e2;
	border: none;
	border-radius: 3px;
	width: 100%;
	height: 24px;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	font-weight: 900;
	font-size: 12px;
	line-height: 14px;
	text-align: center;

	cursor: pointer;

	color: #ffffff;
`;

const SectionTitle = styled.p`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	color: rgba(255, 255, 255, 0.4);
	font-size: 10px;
	font-weight: 400;
	padding: 16px 12px 8px;
	margin: 0;
`;

const FeaturesPage = ({ setPage }) => (
	<>
		<Content>
			<SectionTitle>Features</SectionTitle>
			<FeaturesList />
		</Content>
		<IconButton
			onClick={() => setPage('settings')}
			position="bottom-right"
			iconOnly
		>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.4318 9.13226L15.8065 9.92577C15.9635 10.0164 16.0364 10.2038 15.9826 10.3769C15.6255 11.5266 15.0159 12.5653 14.2185 13.4281C14.1588 13.4926 14.0789 13.535 13.992 13.5482C13.905 13.5615 13.8162 13.5447 13.74 13.5008L12.3663 12.7074C11.7884 13.2026 11.1239 13.5868 10.4064 13.8406V15.4271C10.4064 15.5151 10.3764 15.6005 10.3215 15.6692C10.2665 15.7379 10.1898 15.7858 10.1039 15.8051C8.97629 16.0583 7.76523 16.0714 6.58071 15.8053C6.40362 15.7656 6.27739 15.6087 6.27739 15.4272V13.8406C5.55986 13.5868 4.89538 13.2026 4.31743 12.7074L2.94376 13.5008C2.86759 13.5447 2.77873 13.5615 2.69179 13.5482C2.60485 13.535 2.52498 13.4926 2.46531 13.4281C1.66795 12.5653 1.05824 11.5266 0.701149 10.3769C0.647375 10.2038 0.720245 10.0164 0.877245 9.92577L2.25198 9.13226C2.1139 8.38374 2.1139 7.61629 2.25198 6.86778L0.877277 6.07426C0.720278 5.98365 0.647407 5.79626 0.701181 5.62314C1.05828 4.4734 1.66795 3.43469 2.46534 2.57198C2.52501 2.50738 2.60488 2.465 2.69182 2.45179C2.77877 2.43857 2.86762 2.45532 2.94379 2.49927L4.31746 3.29266C4.89543 2.79745 5.5599 2.41326 6.27743 2.15943V0.57289C6.27742 0.484898 6.30738 0.399527 6.36235 0.330825C6.41733 0.262122 6.49406 0.214181 6.57991 0.19489C7.70752 -0.0583347 8.91858 -0.0714315 10.1031 0.194665C10.2802 0.234439 10.4064 0.391309 10.4064 0.572825V2.1594C11.124 2.41321 11.7884 2.7974 12.3664 3.29262L13.7401 2.49924C13.8162 2.45529 13.9051 2.43854 13.992 2.45175C14.079 2.46497 14.1588 2.50735 14.2185 2.57195C15.0159 3.43466 15.6256 4.47336 15.9827 5.6231C16.0364 5.7962 15.9636 5.98359 15.8066 6.07423L14.4318 6.86774C14.5699 7.61627 14.5699 8.38373 14.4318 9.13226ZM10.9225 8C10.9225 6.57704 9.76487 5.41936 8.3419 5.41936C6.91894 5.41936 5.76127 6.57704 5.76127 8C5.76127 9.42296 6.91894 10.5806 8.3419 10.5806C9.76487 10.5806 10.9225 9.42296 10.9225 8Z"
					fill="white"
				/>
			</svg>
		</IconButton>
	</>
);

FeaturesPage.propTypes = {
	setPage: PropTypes.func,
};

// eslint-disable-next-line no-unused-vars
const createNotification = (dispatch, apiService, notifications) => {
	apiService
		.query(
			`
			mutation Notification($input: [NotificationCreateInput!]!) {
				createNotification(input: $input) {
					ok
				}
			}
		`,
			{ input: notifications },
			{ reqAuth: true, admin: true },
		)
		.subscribe(() => {});
};

const SettingsPage = ({ setPage }) => {
	/*
    const { dispatch, lookup } = useContext(DataLayerContext);
	const [
		{ errors = [], loading = false, authorized = false } = {},
	] = useStore('store-auth', store => store?.admin || {});
	const [company = false] = useStore(
		'store-auth',
		({ data: { companyId = false } = {} }) => companyId,
	);
    */

	const authorized = false;
	const loading = false;

	const [credentials, setCredentials] = useState({});
	const login = (e) => {
		if (e) {
			e.preventDefault();
		}
		// dispatch(loginAdmin(credentials));
	};

	const logout = () => {
		// dispatch(logoutAdmin());
	};

	const triggerForecastNotifications = useCallback(() => {
		// const apiService = lookup('service-api');
		// createNotification(dispatch, apiService, notifications);
	}, []);

	return (
		<>
			<Content>
				<h2>Login</h2>
				{authorized ? (
					<>
						<h3>You are logged in as ******* </h3>

						<Button type="button" onClick={logout}>
							LOG OUT
						</Button>

						<h3>Trigger Notifications</h3>
						<ul>
							<button
								type="button"
								onClick={triggerForecastNotifications}
							>
								Trigger DEMO / Example Currency Notification
							</button>
						</ul>
					</>
				) : (
					<form onSubmit={login}>
						<Input
							type="text"
							placeholder="Username"
							onChange={(e) =>
								setCredentials({
									...credentials,
									username: e.target.value,
								})
							}
							required
						/>
						<Input
							type="password"
							placeholder="Password"
							onChange={(e) =>
								setCredentials({
									...credentials,
									password: e.target.value,
								})
							}
							required
						/>
						<Button type="submit" disabled={loading}>
							LOG IN
						</Button>
					</form>
				)}
			</Content>
			<IconButton
				onClick={() => setPage('features')}
				position="bottom-left"
			>
				<svg
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.506 7.48118L12.506 6.31799L2.94472 6.31799L5.66295 3.59975L4.838 2.7748L0.713208 6.89959L4.838 11.0244L5.66295 10.1994L2.94472 7.48118L12.506 7.48118Z"
						fill="#68AAD8"
					/>
				</svg>
				BACK
			</IconButton>
		</>
	);
};

SettingsPage.propTypes = {
	setPage: PropTypes.func,
};

const Pages = {
	features: FeaturesPage,
	settings: SettingsPage,
};

const Toolbar = styled(({ className }) => {
	// const { dispatch } = useContext(DataLayerContext);
	const [page, setPage] = useState('features');
	const [isOpen, setOpen] = useState(false);
	/*
	const [{ authorized = false } = {}] = useStore(
		'store-auth',
		store => store?.admin || {},
	);
	*/

	useEffect(() => {
		if (isOpen) {
			// dispatch(fetchAllFeatures());
			// dispatch(fetchEnabledFeatures());
			// dispatch(fetchPartners());
		}
	}, [isOpen]);

	if (!isOpen) {
		return <ToolbarToggle isOpen={isOpen} setOpen={setOpen} />;
	}

	const Page = Pages[page];

	return (
		<Drawer className={cn(className)}>
			<IconButton
				onClick={() => setOpen(false)}
				position="top-right"
				iconOnly
			>
				<svg width="11" height="10" viewBox="0 0 11 10" fill="none">
					<path
						d="M8.21356 0.454132L5.48615 3.18154L2.75873 0.454132C2.42406 0.119456 1.88123 0.119456 1.54655 0.454132L0.94046 1.06022C0.605784 1.3949 0.605784 1.93773 0.94046 2.27241L3.66787 4.99982L0.94046 7.72723C0.605784 8.06191 0.605784 8.60474 0.94046 8.93941L1.54655 9.5455C1.88123 9.88018 2.42406 9.88018 2.75873 9.5455L5.48615 6.81809L8.21356 9.5455C8.54823 9.88018 9.09107 9.88018 9.42574 9.5455L10.0318 8.93941C10.3665 8.60474 10.3665 8.06191 10.0318 7.72723L7.30442 4.99982L10.0318 2.27241C10.3665 1.93773 10.3665 1.3949 10.0318 1.06022L9.42574 0.454132C9.09107 0.119456 8.54823 0.119456 8.21356 0.454132Z"
						fill="#656871"
					/>
				</svg>
			</IconButton>
			<Page setPage={setPage} />
		</Drawer>
	);
})`
	h2 {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
			'Segoe UI Symbol';
		font-size: 12px;
		color: #64676f;
		padding: 17px 0px;
		margin: 0;
	}
`;

export default Toolbar;
