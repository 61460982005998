import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Range = (props) => {
	const { className } = props;

	return (
		<div className={cn('asteria-component__form-v2__range', className)}>
			Hello
		</div>
	);
};

Range.displayName = 'Range';

Range.propTypes = { className: PropTypes.string };

export default Range;
