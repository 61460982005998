import React from 'react';

import { TranslationService } from '@asteria/language';

import { useCompanyVersionQuery } from '../CompanyVersion';

export const useClient = (client) => {
	const hasCompanyVersionTwo = useCompanyVersionQuery({ gte: 2 });

	return React.useMemo(() => {
		const service = client?.service?.client;

		return [
			{
				label: TranslationService.get(
					['client.overview.details.name'],
					undefined,
					{ client: client },
				),
				value: service?.name ?? client?.name,
				name: 'name',
				required: true,
				disabled: hasCompanyVersionTwo,
			},
			hasCompanyVersionTwo
				? {
						label: TranslationService.get(
							['client.overview.details.info.type'],
							undefined,
							{ client: client },
						),
						value: service?.info?.type ?? client?.info?.type,
						name: 'info.type',
						type: 'select',
						options: [
							{
								value: null,
								label: TranslationService.get(
									[
										'client.overview.details.info.type.option.label',
										'client.overview.details.info.type.option.empty.label',
									],
									undefined,
									{ client: client },
								),
							},
							{
								value: 'COMPANY',
								label: TranslationService.get(
									[
										'client.overview.details.info.type.option.label',
										'client.overview.details.info.type.option.company.label',
									],
									undefined,
									{ client: client },
								),
							},
							{
								value: 'PRIVATE',
								label: TranslationService.get(
									[
										'client.overview.details.info.type.option.label',
										'client.overview.details.info.type.option.private.label',
									],
									undefined,
									{ client: client },
								),
							},
						],
						format: (value) =>
							TranslationService.get(
								[
									'client.overview.details.info.type.option.label',
									`client.overview.details.info.type.option.${value?.toLowerCase?.()}.label`,
								],
								undefined,
								{ client: client },
							),
				  }
				: null,
			hasCompanyVersionTwo
				? {
						label: TranslationService.get(
							['client.overview.details.info.protected'],
							undefined,
							{ client: client },
						),
						value:
							service?.info?.protected ?? client?.info?.protected,
						name: 'info.protected',
						type: 'switch',
						postfix: TranslationService.get(
							'client.overview.details.info.protected.value',
						),
						format: (value) => {
							if (value) {
								return TranslationService.get(
									[
										'client.overview.details.info.protected.value',
										'client.overview.details.info.protected.value.true',
									],
									undefined,
									{ client: client },
								);
							}

							return TranslationService.get(
								[
									'client.overview.details.info.protected.value',
									'client.overview.details.info.protected.value.false',
								],
								undefined,
								{ client: client },
							);
						},
						alwaysVisible: true,
						useVisibility: (form) => {
							const type = form?.service?.client?.info?.type;

							return type === 'PRIVATE';
						},
				  }
				: null,
			{
				label: TranslationService.get(
					['client.overview.details.info.orgNumber'],
					undefined,
					{ client: client },
				),
				useLabel: (form) => {
					const type =
						form?.service?.client?.info?.type?.toLowerCase?.();

					return TranslationService.get(
						[
							'client.overview.details.info.orgNumber',
							type
								? `client.overview.details.info.orgNumber.${type}`
								: null,
						],
						undefined,
						{ client: client },
					);
				},
				value: service?.info?.orgNumber ?? client?.info?.orgNumber,
				name: 'info.orgNumber',
				disabled: !!client?.info?.orgNumber,
				required: false,
			},
			{
				label: TranslationService.get(
					['client.overview.details.meta.clientNumber'],
					undefined,
					{ client: client },
				),
				value:
					service?.meta?.clientNumber ?? client?.meta?.clientNumber,
				name: 'meta.clientNumber',
				required: true,
				disabled: true,
			},

			/*
			{
				label: TranslationService.get(
					[
						'client.overview.details.info.name',
					],
					undefined,
					{ client: client },
				),

				fields: [
					{
						value:
							service?.contact?.general?.name ??
							client?.contact?.general?.name,
						name: 'contact.general.name',
					},
					{
						value:
							service?.contact?.billing?.name ??
							client?.contact?.billing?.name,
						name: 'contact.billing.name',
					},
					{
						value:
							service?.contact?.shipping?.name ??
							client?.contact?.shipping?.name,
						name: 'contact.shipping.name',
					},
				],
			},

			 */

			{
				label: TranslationService.get(
					['client.overview.details.info.email'],
					undefined,
					{ client: client },
				),

				key: 'contact.email',

				fields: [
					{
						value:
							service?.contact?.general?.email ??
							client?.contact?.general?.email,
						name: 'contact.general.email',
					},
					{
						value:
							service?.contact?.billing?.email ??
							client?.contact?.billing?.email,
						name: 'contact.billing.email',
					},
					{
						value:
							service?.contact?.shipping?.email ??
							client?.contact?.shipping?.email,
						name: 'contact.shipping.email',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.info.address'],
					undefined,
					{ client: client },
				),

				key: 'contact.street',

				fields: [
					{
						value:
							service?.contact?.general?.street ??
							client?.contact?.general?.street,
						name: 'contact.general.street',
					},
					{
						value:
							service?.contact?.billing?.street ??
							client?.contact?.billing?.street,
						name: 'contact.billing.street',
					},
					{
						value:
							service?.contact?.shipping?.street ??
							client?.contact?.shipping?.street,
						name: 'contact.shipping.street',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.info.street2'],
					undefined,
					{ client: client },
				),

				key: 'contact.street2',

				fields: [
					{
						value:
							service?.contact?.general?.street2 ??
							client?.contact?.general?.street2,
						name: 'contact.general.street2',
					},
					{
						value:
							service?.contact?.billing?.street2 ??
							client?.contact?.billing?.street2,
						name: 'contact.billing.street2',
					},
					{
						value:
							service?.contact?.shipping?.street2 ??
							client?.contact?.shipping?.street2,
						name: 'contact.shipping.street2',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.info.city'],
					undefined,
					{ client: client },
				),

				key: 'contact.city',

				fields: [
					{
						value:
							service?.contact?.general?.city ??
							client?.contact?.general?.city,
						name: 'contact.general.city',
					},
					{
						value:
							service?.contact?.billing?.city ??
							client?.contact?.billing?.city,
						name: 'contact.billing.city',
					},
					{
						value:
							service?.contact?.shipping?.city ??
							client?.contact?.shipping?.city,
						name: 'contact.shipping.city',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.info.zip.code'],
					undefined,
					{ client: client },
				),

				key: 'contact.zipcode',

				fields: [
					{
						value:
							service?.contact?.general?.zipcode ??
							client?.contact?.general?.zipcode,
						name: 'contact.general.zipcode',
					},
					{
						value:
							service?.contact?.billing?.zipcode ??
							client?.contact?.billing?.zipcode,
						name: 'contact.billing.zipcode',
					},
					{
						value:
							service?.contact?.shipping?.zipcode ??
							client?.contact?.shipping?.zipcode,
						name: 'contact.shipping.zipcode',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.info.country'],
					undefined,
					{ client: client },
				),

				key: 'contact.country',

				fields: [
					{
						value:
							service?.contact?.general?.country ??
							client?.contact?.general?.country,
						name: 'contact.general.country',
					},
					{
						value:
							service?.contact?.billing?.country ??
							client?.contact?.billing?.country,
						name: 'contact.billing.country',
					},
					{
						value:
							service?.contact?.shipping?.country ??
							client?.contact?.shipping?.country,
						name: 'contact.shipping.country',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.phone'],
					undefined,
					{ client: client },
				),

				key: 'contact.phone',

				fields: [
					{
						value:
							service?.contact?.general?.phone ??
							client?.contact?.general?.phone,
						name: 'contact.general.phone',
					},
					{
						value:
							service?.contact?.billing?.phone ??
							client?.contact?.billing?.phone,
						name: 'contact.billing.phone',
					},
					{
						value:
							service?.contact?.shipping?.phone ??
							client?.contact?.shipping?.phone,
						name: 'contact.shipping.phone',
					},
				],
			},
			{
				label: TranslationService.get(
					['client.overview.details.mobile'],
					undefined,
					{ client: client },
				),

				key: 'contact.mobile',

				fields: [
					{
						value:
							service?.contact?.general?.mobile ??
							client?.contact?.general?.mobile,
						name: 'contact.general.mobile',
					},
					{
						value:
							service?.contact?.billing?.mobile ??
							client?.contact?.billing?.mobile,
						name: 'contact.billing.mobile',
					},
					{
						value:
							service?.contact?.shipping?.mobile ??
							client?.contact?.shipping?.mobile,
						name: 'contact.shipping.mobile',
					},
				],
			},
		].filter(Boolean);
	}, [client, hasCompanyVersionTwo]);
};
