import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-core/tabs';
import { Title } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../../components/Actions';
import Guides from '../../../components/Guides';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const VPrintPage = (props) => {
	const { className } = props;

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = useRef(null);
	const guides = useConfig('pages.common.guides', { deep: true });

	const guide = (guides ?? []).find((object) => object?.id === 'v-print');

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const handleBack = React.useCallback(
		() => onAction?.('go', -1),
		[onAction],
	);

	const data = React.useMemo(() => ({ user: user }), [user]);

	return (
		<div
			ref={ref}
			className={cn(
				'asteria-page',
				'asteria-page__details',
				'asteria-page__guide-details',
				'asteria--type-vprint',
				className,
			)}
		>
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<div className="asteria-page__wrapper-content">
					<Group
						className="asteria-page__navigation"
						direction="horizontal"
						horizontalAlign="left"
						verticalAlign="center"
					>
						<Button icon="chevron-left" onClick={handleBack} />
						<Title size="page-title">
							{TranslationService.get([
								`guide.box.title`,
								`guide.box.v-print.title`,
								'page.guide.details.title',
								`page.guide.details.v-print.title`,
							])}
						</Title>
					</Group>

					<Contenter content={guide?.content?.common} data={data} />

					<Tabs active="windows">
						<TabsNavigation variant="fluid">
							<Button
								tab="windows"
								label={TranslationService.get([
									'guide.v-print.tab.windows',
								])}
								variant="tab"
							/>
							<Button
								tab="osx"
								label={TranslationService.get([
									'guide.v-print.tab.osx',
								])}
								variant="tab"
							/>
						</TabsNavigation>
						<TabsContent>
							<div tab="windows">
								<Contenter
									content={guide?.content?.windows}
									data={data}
								/>
							</div>
							<div tab="osx">
								<Contenter
									content={guide?.content?.osx}
									data={data}
								/>
							</div>
						</TabsContent>
					</Tabs>
				</div>

				<Guides guides={guides} onAction={onAction} />
			</div>

			<Support onAction={onAction} />
		</div>
	);
};

VPrintPage.displayName = 'VPrintPage';

VPrintPage.propTypes = { className: PropTypes.string };

export default VPrintPage;
