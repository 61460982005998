import React from 'react';

import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const ErrorBadge = React.memo((props) => {
	const { batch, index } = props;

	const length = (batch?.data?.errors ?? [])?.length;
	const code = batch?.data?.errors?.[0]?.code ?? batch?.data?.error?.code;

	const customerNo =
		batch?.data?.errors?.[0]?.customerNo ?? batch?.data?.error?.customerNo;

	const sellerOrderID =
		batch?.data?.errors?.[0]?.sellerOrderID ??
		batch?.data?.error?.sellerOrderID;

	return (
		<Badge
			size="sm"
			icon={length > 1 ? null : 'warning'}
			tooltip={TranslationService.get(
				[
					`invoice.history.row.badge.tooltip`,
					`invoice.history.row.${code}.badge.tooltip`,
				]
					.concat(
						customerNo
							? [
									`invoice.history.row.customer.badge.tooltip`,
									`invoice.history.row.${customerNo}.badge.tooltip`,
									`invoice.history.row.${code}.${customerNo}.badge.tooltip`,
							  ]
							: [],
					)
					.concat(
						sellerOrderID
							? [
									`invoice.history.row.invoice.badge.tooltip`,
									`invoice.history.row.${sellerOrderID}.badge.tooltip`,
									`invoice.history.row.${code}.${sellerOrderID}.badge.tooltip`,
							  ]
							: [],
					)
					.concat(
						customerNo && sellerOrderID
							? [
									`invoice.history.row.customer.invoice.badge.tooltip`,
									`invoice.history.row.${customerNo}.${sellerOrderID}.badge.tooltip`,
									`invoice.history.row.${code}.${customerNo}.${sellerOrderID}.badge.tooltip`,
							  ]
							: [],
					),
				'{{ batch.data.errors.0.message }}',
				{ batch: { ...batch, index: index } },
			)}
		>
			{length > 1 ? length : null}
		</Badge>
	);
});

ErrorBadge.displayName = 'ErrorBadge';
ErrorBadge.propTypes = { batch: PropTypes.object, index: PropTypes.string };

export default ErrorBadge;
