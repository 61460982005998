import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip/chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const ChipDate = (props) => {
	const { startDate, endDate } = props;

	const dispatch = useDispatch();
	const { resetField } = useFormContext();

	const onDismiss = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'DATE:SENT',
				value: { startDate: startDate, endDate: endDate },
			}),
		);

		resetField('date');
	}, [dispatch, endDate, resetField, startDate]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				[
					startDate || startDate === endDate
						? 'invoices.search.quick.date.start.label'
						: null,
					endDate ? 'invoices.search.quick.date.end.label' : null,
					startDate && endDate && startDate !== endDate
						? 'invoices.search.quick.date.label'
						: null,
				],
				undefined,
				{ startDate: startDate, endDate: endDate },
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
};

ChipDate.displayName = 'ChipDate';
ChipDate.propTypes = { startDate: PropTypes.string, endDate: PropTypes.string };

const ChipDateWrapper = () => {
	const dates = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'DATE:SENT' }),
		(a, b) => isEqual(a, b),
	);

	return dates.map((object, index) => (
		<ChipDate
			key={index}
			startDate={object?.value?.startDate}
			endDate={object?.value?.endDate}
		/>
	));
};

ChipDateWrapper.displayName = 'ChipDateWrapper';
ChipDateWrapper.propTypes = {};

export default ChipDate;
export { ChipDateWrapper };
