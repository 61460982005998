import { UserService } from '@asteria/backend-utils-services';

import { setFeedback } from '@asteria/datalayer/stores/app';

const fields = `
ok
error
user {
    id
    settings {
        layout
        flags
        combineGraph
        compressList
        erpReminder
        hasOnboarded
        hasReadAdjustable
        listColumns
    }
    feedback
}
`;

export default async ({ accessToken, id, feedback, dispatch }) => {
	try {
		const { user } = await UserService.user.update(
			{ fields: fields, id: id, input: { feedback: feedback } },
			{ token: accessToken },
		);
		dispatch(setFeedback(user.feedback));
	} catch (e) {
		// Do nothing
	}
};
