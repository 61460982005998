import { useCallback, useContext } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import LayoutContext, { MenuContext } from '../context';

export function useIsActive() {
	const { pathname } = useLocation();

	return useCallback(
		(path) => {
			if (path === '/') {
				return !(
					pathname.includes('integrations') ||
					pathname.includes('settings') ||
					pathname.includes('faq') ||
					pathname.includes('support') ||
					pathname.includes('invoices') ||
					pathname.includes('reports') ||
					pathname.includes('pendingInvoices')
				);
			}

			return pathname.includes(path);
		},
		[pathname],
	);
}

export function useClick() {
	const { onAction } = useContext(LayoutContext);
	const { open, close, toggle } = useContext(MenuContext);

	const dispatch = useDispatch();

	const handleAction = useCallback(
		(action, data) => {
			if (action === 'logout') {
				localStorage.removeItem('wingsToken');
				localStorage.removeItem('wingsRefreshToken');

				const url = new URL('', window.location.origin);
				url.search = window.location.search;

				window.location.replace(url.toString());

				return;
			}

			if (action === 'modal:customers:open') {
				dispatch(
					ModalStore.open({
						type: 'CLIENT_LIST',
						skipValidation: true,
					}),
				);
			}

			if (action === 'menu:open') {
				return open?.();
			}

			if (action === 'menu:close') {
				return close?.();
			}

			if (action === 'menu:toggle') {
				return toggle?.();
			}

			return onAction?.(action, data);
		},
		[close, dispatch, onAction, open, toggle],
	);

	return useCallback(
		({ path, action }) => {
			if (path) {
				return handleAction('go', path);
			}

			if (action) {
				return handleAction(...action);
			}
		},
		[handleAction],
	);
}
