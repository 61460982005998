import React, { Component } from 'react';

import { TranslationService } from '@asteria/language';

const ERROR_ICON_STYLE = {
	flexGrow: 1,
	flexShrink: 1,
	flexBasis: 'auto',
	// height: '100%',
	width: '100%',
	fill: 'rgba(255,255,255,255)',
	height: '16px',
};

const ERROR_ICON_WRAPPER_STYLE = {
	display: 'flex',
	cursor: 'pointer',
	alignItems: 'center',
	justifyContent: 'center',
	width: '40px',
	height: '32px',
	borderRadius: '0px 200px 200px 0px',
	backgroundColor: 'rgba(197,19,28,1)',
	flexShrink: 0,
	flexGrow: 0,
};

const ERROR_STYLE = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingRight: '1rem',
	paddingLeft: '0px',
	lineHeight: '1.3em',
	backgroundColor: 'rgba(249,222,211,0.1)',
	paddingTop: '4px',
	paddingBottom: '0px',
};

const ERROR_TEXT_STYLE = {
	display: 'block',
	flexGrow: 1,
	fontFamily: 'Arial, Helvetica, sans-serif',
	color: 'rgba(34,34,34,1)',
	padding: '10px',
	textAlign: 'left',
};

const ERROR_WRAPPER_STYLE = {
	overflow: 'hidden',
	transform: 'scaleY(1)',
	transformOrigin: 'top',
	transition: 'opacity 0.25s ease-in-out,transform 0.25s ease-in-out',
	minHeight: '48px',
	backgroundColor: 'rgba(249,222,211,1)',
	border: '1px solid #e59393',
	borderTop: '0.25rem solid #e59393',
	padding: '0px',
	width: '100%',
	boxShadow: '0px 0px 0px rgba(0,0,0,0)',
	borderTopColor: 'rgba(197,19,28,1)',
	borderTopWidth: '2px',
	borderRightWidth: '0px',
	borderLeftWidth: '0px',
	borderBottomWidth: '0px',
};

function CrashError() {
	return (
		<div style={ERROR_WRAPPER_STYLE}>
			<div style={ERROR_STYLE}>
				<div
					className="asteria-icon-wrapper asteria-info"
					style={ERROR_ICON_WRAPPER_STYLE}
				>
					<svg
						className="asteria-icon-svg"
						width="16px"
						height="16px"
						viewBox="0 0 16 16"
						version="1.1"
						xmlns=""
						name="Icon"
						type="error"
						preserveAspectRatio="xMidYMid"
						style={ERROR_ICON_STYLE}
					>
						<g
							id="Page-1"
							stroke="none"
							strokeWidth="1"
							fillRule="evenodd"
						>
							<g id="Message/Information-2-Copy">
								<g id="Message/Information">
									<path
										d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M7.37919976,12 L8.80159976,12 L8.80159976,6.3552 L7.37919976,6.3552 L7.37919976,12 Z M8.92479976,4.5968 C8.92479976,4.36533218 8.84266724,4.16746749 8.67839976,4.0032 C8.51413227,3.83893251 8.31626758,3.7568 8.08479976,3.7568 C7.85333193,3.7568 7.65733389,3.83893251 7.49679976,4.0032 C7.33626562,4.16746749 7.25599976,4.36533218 7.25599976,4.5968 C7.25599976,4.82826782 7.33626562,5.02426586 7.49679976,5.1848 C7.65733389,5.34533414 7.85333193,5.4256 8.08479976,5.4256 C8.31626758,5.4256 8.51413227,5.34533414 8.67839976,5.1848 C8.84266724,5.02426586 8.92479976,4.82826782 8.92479976,4.5968 Z"
										id="Mask"
										transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) "
									/>
								</g>
							</g>
						</g>
					</svg>
				</div>
				<div
					className="asteria-text"
					name="Text"
					dir="auto"
					style={ERROR_TEXT_STYLE}
				>
					{TranslationService.get(
						'error',
						// eslint-disable-next-line spellcheck/spell-checker
						'Ett oväntat fel har inträffat och därför kan ditt kassaflöde inte visas. Var vänlig försök igen eller kontakta support.',
					)}
				</div>
			</div>
		</div>
	);
}

class ErrorWrapper extends Component {
	state = { hasError: false };

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	render() {
		const { hasError = false } = this.state;
		const { children } = this.props;

		if (hasError) {
			// You can render any custom fallback UI
			return <CrashError />;
		}

		return children;
	}
}

export default ErrorWrapper;
