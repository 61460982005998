import { AuthService, UserService } from '@asteria/backend-utils-services';

import { setUser } from '@asteria/datalayer/stores/app';

const fields = `
ok
error
user {
    settings {
        layout
        flags
        combineGraph
        compressList
        erpReminder
        hasOnboarded
        hasReadAdjustable
        listColumns
    }
}
`;

export default async ({ accessToken, id, settings, dispatch }) => {
	try {
		await UserService.user.update(
			{ fields: fields, id: id, input: { settings: settings } },
			{ token: accessToken },
		);

		const me = await AuthService.auth.me(
			{
				fields: `
                    id
                    settings {
                        layout
                        flags
                        compressList
                        listColumns
                        hasReadAdjustable
                    }
                    feedback
            `,
			},
			{ token: accessToken },
		);

		dispatch(setUser(me));
	} catch (e) {
		// Do nothing
	}
};
