import React, { useRef } from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../../components/Actions';
import Guides from '../../../components/Guides';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import VPrintPage from './VPrint';

import './styles.scss';

const GuideDetailsPage = (props) => {
	const { className } = props;

	const { id } = useParams();
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = useRef(null);
	const guides = useConfig('pages.common.guides', { deep: true });

	const guide = (guides ?? []).find((object) => object?.id === id);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, [id]);

	const handleBack = React.useCallback(
		() => onAction?.('go', -1),
		[onAction],
	);

	return (
		<div
			ref={ref}
			className={cn(
				'asteria-page',
				'asteria-page__details',
				'asteria-page__guide-details',
				className,
			)}
		>
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<div className="asteria-page__wrapper-content">
					<Group
						className="asteria-page__navigation"
						direction="horizontal"
						horizontalAlign="left"
						verticalAlign="center"
					>
						<Button icon="chevron-left" onClick={handleBack} />
						<Title size="page-title">
							{TranslationService.get([
								`guide.box.title`,
								`guide.box.${id}.title`,
								'page.guide.details.title',
								`page.guide.details.${id}.title`,
							])}
						</Title>
					</Group>

					<Contenter content={guide?.content} />
				</div>

				<Guides guides={guides} onAction={onAction} />
			</div>

			<Support onAction={onAction} />
		</div>
	);
};

GuideDetailsPage.displayName = 'GuideDetailsPage';

GuideDetailsPage.propTypes = { className: PropTypes.string };

export default GuideDetailsPage;
export { VPrintPage };
