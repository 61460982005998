import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Aside = (props) => {
	const { className, actions = [] } = props;

	return (
		<div className={cn(className, 'asteria-component__splash')}>
			<Wrapper>
				{props?.title ? (
					<Header>
						<Title type="title" size="base">
							{props?.title}
						</Title>
					</Header>
				) : null}

				<Content scroll={props?.scroll}>
					<Text size="lg">{props?.description}</Text>
					<Text size="lg">{props?.subDescription}</Text>
				</Content>
				<Footer>
					<FooterSection>
						{actions &&
							actions.map((button, index) => (
								<Button
									key={index}
									variant={button?.variant}
									label={button?.label}
									size={button?.size}
									icon={button?.icon}
									iconPosition={button?.iconPosition}
									onClick={button?.action}
									className="asteria-component__splash__underline__label"
								/>
							))}
					</FooterSection>
				</Footer>
			</Wrapper>
		</div>
	);
};

Aside.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	subDescription: PropTypes.string,
	scroll: PropTypes.bool,
	actions: PropTypes.arrayOf(PropTypes.shape({})),
};

Aside.defaultProps = {
	className: null,
};

Aside.displayName = 'Aside';

export default Aside;
