import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	isPossibleToClick,
	sizeClasses,
	stateClasses,
	statusClasses,
} from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';
import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import Error from '../Error';

import './styles.scss';

const Element = (props) => {
	const { icon, size, active, type, data } = props;

	const inputRef = data?.ref;

	const onClick = data?.onClick;
	const children =
		data?.children ??
		(React.isValidElement(data) || typeof data !== 'object')
			? data
			: null;

	const ref = React.useRef(null);

	const $ref = useCombinedRefs(inputRef, ref);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onClick?.(event);
		},
		[ref, onClick],
	);

	return (
		<div
			className={cn(
				'asteria-component__form-v2__input-wrapper__element',
				{ [`asteria--type-${type}`]: type },
				stateClasses({ onClick: onClick }),
			)}
			onClick={handleClick}
			ref={$ref}
		>
			{children ? (
				React.isValidElement(children) ? (
					children
				) : (
					<Text size={size}>{children}</Text>
				)
			) : null}
			{icon ? (
				<Button
					icon={icon?.icon ?? icon}
					iconActive={icon?.iconActive}
					onClick={icon?.onClick}
					size={icon?.size ?? size}
					active={active}
				/>
			) : null}
		</div>
	);
};

Element.displayName = 'Element';

Element.propTypes = {
	type: PropTypes.oneOf(['prefix', 'postfix']),
	data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			icon: PropTypes.string,
			size: PropTypes.string,
			onClick: PropTypes.string,
		}),
	]),
	size: PropTypes.string,
	active: PropTypes.bool,
};

const HelpText = (props) => {
	const { helpText, size, value } = props;

	if (!helpText) {
		return null;
	}

	let result = helpText;

	if (typeof result === 'function') {
		result = result(value);
	}

	return (
		<div className="asteria-component__form-v2__input-wrapper__help">
			{React.isValidElement(result) ? (
				result
			) : (
				<Text size={size}>{result}</Text>
			)}
		</div>
	);
};

HelpText.displayName = 'HelpText';
HelpText.propTypes = {
	helpText: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node,
		PropTypes.string,
	]),
	size: PropTypes.string,
	value: PropTypes.any,
};

const InputWrapper = React.forwardRef((props, ref) => {
	const {
		className,
		active,
		children,
		prefix,
		postfix,
		icon,
		size,
		error,
		helpText,
		value,
		name,
		visibleValue,
		hideVisibleOnFocus,
		onClick,
	} = props;

	const [isInputFocused, setFocus] = React.useState(false);

	const handleInputFocus = React.useCallback(() => {
		setFocus(true);
	}, []);
	const handleInputBlur = React.useCallback(() => {
		setFocus(false);
	}, []);

	const wrapperRef = React.useRef(null);

	const $ref = useCombinedRefs(ref, wrapperRef);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, wrapperRef.current)) {
				return;
			}

			return onClick?.(event);
		},
		[wrapperRef, onClick],
	);

	return (
		<div
			className={cn(
				'asteria-component__form-v2__input-wrapper',
				{
					'asteria--has-prefix': prefix,
					'asteria--has-postfix': postfix,
					'asteria--has-helpText': helpText,
				},
				sizeClasses({ size: size }),
				stateClasses(props),
				statusClasses({
					info: error?.type === 'info',
					success: error?.type === 'success',
					error: [
						'minLength',
						'maxLength',
						'required',
						'pattern',
						'validate',
						'error',
					].includes(error?.type),
					warning: error?.type === 'warning',
				}),
				className,
			)}
			ref={$ref}
			onClick={handleClick}
		>
			<HelpText helpText={helpText} value={value} size={size} />
			<div
				className="asteria-component__form-v2__input-wrapper__inner"
				onFocus={handleInputFocus}
				onBlur={handleInputBlur}
			>
				{prefix || (icon && icon?.position === 'first') ? (
					<Element
						data={prefix}
						size={prefix?.size ?? size}
						icon={prefix?.icon ?? icon}
						type="prefix"
						active={active}
					/>
				) : null}
				<div
					className={cn(
						'asteria-component__form-v2__input-wrapper__input',
						{
							'asteria--has-visible':
								visibleValue &&
								(!hideVisibleOnFocus || !isInputFocused),
						},
					)}
					data-visible={visibleValue}
				>
					{children}
				</div>
				{postfix || (icon && icon?.position !== 'first') ? (
					<Element
						data={postfix}
						size={postfix?.size ?? size}
						icon={postfix?.icon ?? icon}
						type="postfix"
						active={active}
					/>
				) : null}
			</div>
			<Error name={name} error={error} />
		</div>
	);
});

InputWrapper.displayName = 'InputWrapper';

InputWrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	postfix: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			icon: PropTypes.string,
			size: PropTypes.string,
			onClick: PropTypes.string,
		}),
	]),
	size: PropTypes.string,

	active: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),

	helpText: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node,
		PropTypes.string,
	]),
	value: PropTypes.any,
	name: PropTypes.string,
	visibleValue: PropTypes.string,
	hideVisibleOnFocus: PropTypes.bool,
	onClick: PropTypes.func,
};

export default InputWrapper;
