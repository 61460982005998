import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';

import Alert from '@asteria/component-alert';
import Modal from '@asteria/component-modal';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ClientDetailsInfo from './ClientDetailsInfo';

const SelectedClientWrapper = (props) => {
	const { className, _id, onClose } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	const dispatch = useDispatch();

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const hasEditFeature = useFeature('client-details-update');

	const handleEdit = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: _id },
			}),
		);
	}, [_id, dispatch]);

	const handleBack = React.useCallback(
		(event) => {
			handleClose(event);

			dispatch(
				ModalStore.open({ type: 'CLIENT_LIST', skipValidation: true }),
			);
		},
		[dispatch, handleClose],
	);

	const client = useSelector(
		(store) => AppStore.selectors.client(store, _id),
		(a, b) => isEqual(a, b),
	);

	const clientProtected =
		client?.service?.client?.info?.protected ?? client?.info?.protected;

	return (
		<Wrapper
			className={cn(
				'asteria-component__client',
				'asteria-component__invoices__client',
			)}
			scroll
		>
			<Header onClose={handleClose} onBack={handleBack}>
				{TranslationService.get(
					[
						'client.overview.title.details',
						`client.overview.title.details.${client?.type}`,
					],
					'client.overview.title.details',
					{ client: client },
				)}
			</Header>
			<Content scroll>
				<div
					className={cn(
						'asteria-component__client-overview',
						'asteria-component__client-overview__details',
						className,
					)}
				>
					{clientProtected ? (
						<Alert
							level="info"
							title={TranslationService.get(
								[
									'client.overview.details.alert.title',
									'client.overview.details.protected.alert.title',
								],
								undefined,
								{ client: client },
							)}
						>
							<Translation
								translationKey={[
									'client.overview.details.alert.content',
									'client.overview.details.protected.alert.content',
								]}
								data={{ client: client }}
								Component={Text}
							/>
						</Alert>
					) : null}

					<div className="asteria-component__client-overview__details__wrapper">
						<ClientDetailsInfo client={client} />
					</div>
				</div>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get('button.cancel')}
						onClick={onClose}
					/>
				</FooterSection>
				{companyService === 'INVOICE' || hasEditFeature ? (
					<FooterSection position="last">
						<Button
							variant="secondary"
							label={TranslationService.get('button.edit')}
							onClick={handleEdit}
						/>
					</FooterSection>
				) : null}
			</Footer>
		</Wrapper>
	);
};

SelectedClientWrapper.propTypes = {
	className: PropTypes.string,
	_id: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

const ClientOverviewWrapper = (props) => {
	const { className, open = true, onClose, _id, onAction } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	return (
		<Modal open={open} onClose={handleClose} size="sm">
			<SelectedClientWrapper
				_id={_id}
				className={className}
				onAction={onAction}
				onClose={onClose}
			/>
		</Modal>
	);
};

ClientOverviewWrapper.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	_id: PropTypes.string,
	onAction: PropTypes.func,
};

ClientOverviewWrapper.defaultProps = {};

export default ClientOverviewWrapper;
