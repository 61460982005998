import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Error = (props) => {
	const { className, name, error } = props;

	if (!error) {
		return null;
	}

	return (
		<Alert
			className={cn('asteria-component__form-v2__error', className)}
			level={error?.type ?? 'warning'}
		>
			<Text>
				{TranslationService.get(
					[
						`form.error.label`,
						`form.error.label.${error?.type}`,
						`form.${name}.error.label`,
						`form.${name}.error.label.${error?.type}`,
					],
					error?.message || error?.type,
					error,
				)}
			</Text>
		</Alert>
	);
};

Error.displayName = 'Error';

Error.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
};

export default Error;
