import { ClientService } from '@asteria/backend-utils-services';

import { setClients, updateClients } from '@asteria/datalayer/stores/app';

import { withPagination } from '@asteria/utils-funcs/request';

export const FIELDS = `
id
_id
name
createdAt
updatedAt
type
active
meta {
    source
    clientNumber
}

info {
    termsOfPayment
    vatNumber
    orgNumber
    creditLimit
}
contact {
    general {
        name
        email
        street
        street2
        city
        zipcode
        country
        phone
        mobile
    }
    billing {
        name
        email
        street
        street2
        city
        zipcode
        country
        phone
        mobile
    }
}
services {
    id
    status
		serviceId
    clients {
        clientId
        data
    }
}
statistics {
    invoices {
        count
        total
    }
}
`;

export const PAGINATION_FIELDS = `
    pageInfo {
        count
        startCursor
        endCursor
        hasNextPage
    }
    edges {
        cursor
        node {
            ${FIELDS}
        }
    }
`;

/**
 * @param { { accessToken: string, dataloader: DataLoader, dispatch?: unknown } } options
 */
export async function fetch(options) {
	const { accessToken, dataloader, dispatch, ids } = options;

	const objects = await withPagination(ClientService.client.fetch)(
		{
			isBulk: true,
			pageFilters: { first: 1_000 },
			filters: { type: 'CUSTOMER', ids: ids, active: true },
			fields: PAGINATION_FIELDS,
			dataloader: dataloader,
		},
		{ token: accessToken },
	);

	if (ids?.length) {
		dispatch?.(updateClients(objects));
	} else {
		dispatch?.(setClients(objects));
	}

	return objects;
}

export async function refresh({
	accessToken,
	dataloader,
	dispatch,
	timestamp,
}) {
	const ids = await ClientService.client
		.fetch(
			{
				isBulk: true,
				pageFilters: { first: 0 },
				filters: {
					type: 'CUSTOMER',
					active: true,
					updatedAt: { gte: timestamp },
				},
				fields: `edges { node { _id } }`,
				dataloader: dataloader,
			},
			{ token: accessToken },
		)
		.then((response) =>
			(response?.edges ?? []).map(({ node }) => node?._id),
		);

	if (ids.length) {
		return fetch({ accessToken, dataloader, dispatch, ids: ids });
	}
}

export async function addService({ accessToken, serviceId, input, dispatch }) {
	const response = await ClientService.service
		.create({ serviceId: serviceId, input: input }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ accessToken: accessToken, dispatch: dispatch });
}

export async function updateService({ accessToken, id, input, dispatch }) {
	const response = await ClientService.service
		.update({ id: id, input: input }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ accessToken: accessToken, dispatch: dispatch });
}

export async function removeService({ accessToken, id, dispatch }) {
	const response = await ClientService.service
		.remove({ id: id }, { token: accessToken })
		.catch((err) => ({ ok: false, error: err }));

	if (!response.ok) {
		// eslint-disable-next-line no-console
		console.warn(response.error);
	}

	return fetch({ accessToken: accessToken, dispatch: dispatch });
}
