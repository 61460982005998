import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

export function useChat() {
	const language = TranslationService.code;
	const supportedChatLanguages = useConfig('widget.support.chatLanguages', {
		deep: true,
	});

	return supportedChatLanguages?.includes(language);
}
