import React from 'react';

import styled from 'styled-components';

import { cn } from '@asteria/utils-funcs/classes';

const IconButton = styled(
	({ className, position, onClick, children, iconOnly }) => (
		<button
			className={cn(className, `asteria-toolbar-button-${position}`, {
				'asteria-toolbar-button-icon-only': iconOnly,
			})}
			type="button"
			onClick={onClick}
		>
			{children}
		</button>
	),
)`
	position: absolute;
	border: none;
	background-color: transparent;
	cursor: pointer;
	height: 40px;
	display: flex;
	align-items: center;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	font-size: 12px;
	font-weight: 500;
	color: #6db2e2;
	z-index: 2;

	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 5px;
	}

	&.asteria-toolbar-button-icon-only {
		width: 35px;
		border-radius: 50%;

		svg {
			margin-right: 0px;
		}
	}

	&.asteria-toolbar-button-top-right {
		right: 0px;
		top: 5px;
	}

	&.asteria-toolbar-button-top-left {
		left: 5px;
		top: 5px;
	}

	&.asteria-toolbar-button-bottom-right {
		right: 5px;
		bottom: 5px;
	}

	&.asteria-toolbar-button-bottom-left {
		left: 5px;
		bottom: 5px;
	}
`;

export default IconButton;
