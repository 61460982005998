import React from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const NotFound = (props) => {
	const { className } = props;

	return (
		<div
			className={cn('asteria-page', 'asteria-page__not-found', className)}
		>
			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get(['page.not-found.title'])}
					</Title>
					<Text size="lg">
						{TranslationService.get(['page.not-found.content'])}
					</Text>
				</TextGroup>
			</div>
		</div>
	);
};

NotFound.displayName = 'NotFound';

NotFound.propTypes = { className: PropTypes.string };

export default NotFound;
