import React from 'react';

import DatepickerContext from '../../../context';

import Day from './Day';
import Month from './Month';
import Week from './Week';
import Year from './Year';

import '../styles.scss';

const Content = React.memo((props) => {
	const { type } = React.useContext(DatepickerContext);

	if (type === 'day') {
		return <Day {...props} />;
	}

	if (type === 'week') {
		return <Week {...props} />;
	}

	if (type === 'month') {
		return <Month {...props} />;
	}

	if (type === 'year') {
		return <Year {...props} />;
	}

	return null;
});

Content.displayName = 'Content';
Content.propTypes = {};

export default Content;
