import React from 'react';

import { Route, Routes } from 'react-router-dom';

import InvoicesBatch from './batch';
import Invoices from './list';
import InvoicesReview from './review';

const InvoicesRouter = () => {
	return (
		<Routes>
			<Route path="/" element={<Invoices />} />
			<Route path="/review" element={<InvoicesReview />} />
			<Route path="/batch/:id/*" element={<InvoicesBatch />} />
		</Routes>
	);
};

InvoicesRouter.displayName = 'InvoicesRouter';

export default InvoicesRouter;
