import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import Actions from '../../components/Actions';
import Guides from '../../components/Guides';
import Stepper from '../../components/Stepper';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import IntroductionFeedback from './Feedback';
import { useActions } from './hooks';

import './styles.scss';

const IntroductionPage = (props) => {
	const { className } = props;

	const dispatch = useDispatch();

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = React.useRef(null);

	const guides = useConfig('pages.common.guides', { deep: true });

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'action-client-list') {
				dispatch(
					ModalStore.open({
						type: 'CLIENT_LIST',
						skipValidation: true,
					}),
				);
			}

			if (action === 'action-invoice-error') {
				dispatch(
					InvoiceStore.filter({
						type: 'SERVICE:STATUS',
						value: 'ERROR',
					}),
				);

				onAction?.('go', '/invoices');
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	// const onLinkClick = React.useCallback(
	// 	() => handleAction?.('go', '/guide/support'),
	// 	[handleAction],
	// );

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const { available, recommended } = useActions({ onSubmit });

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__introduction',
				className,
			)}
			ref={ref}
		>
			<IntroductionFeedback />
			<Title size="page-title">
				{TranslationService.get(['page.introduction.title'])}
			</Title>
			{/* <div
				className={cn(
					'asteria-page__wrapper',
					'asteria--type-content',
				)}
			>
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get(['page.introduction.title'])}
					</Title>
					<Text size="lg">
						{TranslationService.get(['page.introduction.content'])}
					</Text>
				</TextGroup>
				<Button
					variant="link"
					label={TranslationService.get(['page.introduction.link'])}
					onClick={onLinkClick}
				/>
			</div> */}
			{available.length || recommended.length
				? [
						<Text key="separator" size="lg" separator>
							{TranslationService.get([
								'page.introduction.separator',

								available.length
									? 'page.introduction.separator.required'
									: null,

								recommended.length
									? 'page.introduction.separator.recommended'
									: null,

								available.length && recommended.length
									? 'page.introduction.separator.both'
									: null,
							])}
						</Text>,
						<Stepper
							key="stepper"
							onAction={onAction}
							onSubmit={onSubmit}
						/>,
						<Actions
							key="actions"
							actions={available.concat(recommended)}
							onAction={handleAction}
							onSubmit={onSubmit}
						/>,
				  ]
				: [
						<Text key="separator" size="lg" separator>
							{TranslationService.get([
								'page.introduction.separator',
								'page.introduction.separator.recommended',
								'page.introduction.separator.done',
							])}
						</Text>,
						<div
							key="placeholder"
							className="asteria-page__placeholder"
						>
							<Group
								direction="horizontal"
								verticalAlign="center"
								horizontalAlign="center"
							>
								<Icon icon="check" />
								<Title size="sm" align="center">
									{TranslationService.get([
										'page.introduction.placeholder.title',
										'page.introduction.placeholder.done.title',
									])}
								</Title>
							</Group>
							<Text align="center">
								{TranslationService.get([
									'page.introduction.placeholder.content',
									'page.introduction.placeholder.done.content',
								])}
							</Text>
						</div>,
				  ]}

			<div
				className={cn('asteria-page__wrapper', 'asteria--type-guides')}
			>
				<Guides guides={guides} onAction={handleAction} />
			</div>
			<Support onAction={handleAction} />
		</div>
	);
};

IntroductionPage.displayName = 'IntroductionPage';

IntroductionPage.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default IntroductionPage;
