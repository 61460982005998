import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import { PAGES } from './constants';

import './styles.scss';

function SupportHeader(props) {
	const { page, country, showBack, onBack, response } = props;

	const title = (
		<Title size="page-title">
			{TranslationService.get([
				'page.support.title',
				page === PAGES.COUNTRY ? `page.support.${country}.title` : null,
				page !== PAGES.DEFAULT ? `page.support.details.title` : null,
				page === PAGES.COUNTRY
					? `page.support.details.${country}.title`
					: null,
				page === PAGES.SUCCESS && !response?.error
					? `page.support.success.title`
					: null,
				page === PAGES.SUCCESS && response?.error
					? `page.support.error.title`
					: null,
			])}
		</Title>
	);

	const content = (
		<Text size="lg">
			{TranslationService.get([
				'page.support.content',
				page === PAGES.COUNTRY
					? `page.support.${country}.content`
					: null,
				page !== PAGES.DEFAULT ? `page.support.details.content` : null,
				page === PAGES.COUNTRY
					? `page.support.details.${country}.content`
					: null,
				page === PAGES.SUCCESS && !response?.error
					? `page.support.success.content`
					: null,
				page === PAGES.SUCCESS && response?.error
					? `page.support.error.content`
					: null,
			])}
		</Text>
	);

	return (
		<TextGroup>
			{page === PAGES.DEFAULT
				? [title, content]
				: [
						<Group
							key="support-navigation"
							className="asteria-page__navigation"
							direction="horizontal"
							horizontalAlign="left"
							verticalAlign="center"
						>
							{showBack ? (
								<Button icon="chevron-left" onClick={onBack} />
							) : null}
							{title}
						</Group>,
						content,
				  ]}
		</TextGroup>
	);
}

SupportHeader.displayName = 'SupportHeader';
SupportHeader.propTypes = {
	page: PropTypes.string,
	country: PropTypes.string,
	showBack: PropTypes.bool,
	onBack: PropTypes.func,
	response: PropTypes.object,
};

export default React.memo(SupportHeader);
