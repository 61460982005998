import React from 'react';
import { useContext, useEffect, useState } from 'react';

import { createGlobalStyle } from 'styled-components';

import { AdminService } from '@asteria/backend-utils-services';

import { AuthContext } from '../context';

const ThemeGlobalStyle = createGlobalStyle`
	${({ style }) => style}
`;

const Theme = ({ themeId, children }) => {
	const { accessToken } = useContext(AuthContext);
	const [style, setStyle] = useState(null);

	useEffect(() => {
		AdminService.theme
			.fetchOne({ fields: 'styles', id: themeId }, { accessToken })
			.then((theme) => {
				setStyle(theme?.styles?.global?.css || '');
			})
			.catch(() => {});
	}, [accessToken, themeId]);

	if (style === null) {
		return null;
	}

	return (
		<>
			<ThemeGlobalStyle style={style} />
			{children}
		</>
	);
};

export default Theme;
