import React from 'react';

import PropTypes from 'prop-types';

import Form from '@asteria/component-form';
import Input from '@asteria/component-form/input';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const OnboardingSearch = (props) => {
	const { className, onChange } = props;

	return (
		<div className={cn('asteria-component__onboarding-search', className)}>
			<Form>
				<Input
					name="search-value"
					onChange={onChange}
					icon="magnifier"
					iconSize="md"
					placeholder={TranslationService.get([
						'onboarding.grid.search.placeholder',
					])}
				/>
			</Form>
		</div>
	);
};

OnboardingSearch.displayName = 'OnboardingSearch';

OnboardingSearch.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
};

export default OnboardingSearch;
