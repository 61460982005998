import React from 'react';

import {
	addWeeks,
	eachWeekOfInterval,
	endOfMonth,
	startOfMonth,
	subWeeks,
} from 'date-fns';

import { WEEKS_BEFORE, WEEKS_TOTAL } from '../../constants';
import { useDateFNSLocale } from '../../hooks';
import Cell from '../Cell';

import '../styles.scss';

const Week = React.memo(() => {
	const locale = useDateFNSLocale();

	const dates = React.useMemo(() => {
		let dates = eachWeekOfInterval(
			{ start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
			{ locale: locale },
		);

		const startDate = dates[0];
		const endDate = dates.slice(-1)[0];

		return []
			.concat(
				new Array(WEEKS_BEFORE)
					.fill()
					.map((_, index) => subWeeks(startDate, index + 1))
					.reverse(),
			)
			.concat(dates)
			.concat(
				new Array(WEEKS_TOTAL - (dates.length + WEEKS_TOTAL))
					.fill()
					.map((_, index) => addWeeks(endDate, index + 1)),
			);
	}, [locale]);

	return (
		<div className="asteria-component__form-v2__datepicker-content__calendar-content">
			{dates.map((date) => (
				<Cell key={date} date={date} type="week" />
			))}
		</div>
	);
});

Week.displayName = 'Week';
Week.propTypes = {};

export default Week;
