import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../context';
import Step, { Content, Footer, Header } from '../../step';
import Guide from '../guide';

import './index.scss';

const OAuth2Info = function OAuth2Info(props) {
	const { className, integration, flow, onClose } = props;
	const { navigation } = useContext(NavigationContext);
	const [showGuide, setShowGuide] = useState(false);

	const step = `${integration.type}.oauth.info`;

	return (
		<Step
			className={cn(
				'asteria-component__integration-oauth__info',
				{
					[`asteria-component__integration-oauth--type-${integration.type}`]:
						integration.type,
				},
				className,
			)}
			step={step}
			onClose={onClose}
		>
			{flow?.steps?.info?.guide && (
				<Guide
					open={showGuide}
					steps={flow?.steps?.info?.guide?.steps}
					onClose={() => setShowGuide(false)}
				/>
			)}
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration.type}.add.title`,
					],
					undefined,
					{ integration: integration },
				)}
			</Header>
			<Content step={step} scroll>
				<div className="asteria-component__integration-step__content__inner">
					<Title>{flow?.name}</Title>
					<Contenter content={flow?.steps?.info?.content} />
				</div>
				{flow?.steps?.info?.guide && (
					<Button
						analyticsKey={`integrations.${integration.key}.help`}
						className="asteria-component__integration-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.add.help`,
								`integrations.${integration.type}.add.help`,
								`integrations.${integration.type}.${integration.key}.add.help`,
							],
							undefined,
							{ integration: integration },
						)}
						// icon="arrow-triangle-right"
						// iconPosition="last"
						onClick={() => setShowGuide(true)}
					/>
				)}
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${integration.key}.abort`}
						variant="tertiary"
						label={TranslationService.get('action.abort')}
						onClick={navigation.abort}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${integration.key}.back`}
						variant="secondary"
						label={TranslationService.get('action.back')}
						onClick={navigation.back}
					/>
					<Button
						analyticsKey={`integrations.${integration.key}.next`}
						variant="primary"
						label={TranslationService.get(
							[
								'action.next',
								'integrations.action.next',
								`integrations.action.next.${integration.key}`,
							],
							undefined,
							{ integration: integration },
						)}
						onClick={navigation.next}
					/>
					<FeatureFlag feature="integrations-testing" invert>
						<Button
							analyticsKey={`integrations.${integration.key}.skip`}
							variant="primary"
							label={TranslationService.get(
								[
									'action.skip',
									'integrations.action.skip',
									`integrations.action.skip.${integration.key}`,
								],
								undefined,
								{ integration: integration },
							)}
							onClick={() => navigation.go('success')}
						/>
					</FeatureFlag>
				</FooterSection>
			</Footer>
		</Step>
	);
};

OAuth2Info.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	flow: PropTypes.object,
};

OAuth2Info.defaultProps = {
	className: null,
	type: null,
};

export default OAuth2Info;
