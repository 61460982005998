import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Accordion from '@asteria/component-core/accordion';
import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import Typography, { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import List, { ListHeader, ListItem } from '@asteria/component-list';
import Prefix from '@asteria/component-prefix';
import Sidepane from '@asteria/component-sidepane';

import {
	clearInvoices,
	removeInvociedIds,
	setSideDrawerOpen,
} from '@asteria/datalayer/stores/navigate';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const selectors = {
	isOpen: createSelector(
		(state) => state.navigate.sideDrawerOpen,
		(value) => value ?? false,
	),
	transaction: createSelector(
		(store) => store?.transactions?.items,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	invoicedIds: createSelector(
		(state) => state.navigate.invoicedIds,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const SideDrawer = (props) => {
	const { headerTitle } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const isOpen = useSelector(selectors.isOpen);
	const transaction = useSelector(selectors.transaction);
	const invoicedIds = useSelector(selectors.invoicedIds);

	const invoices = invoicedIds
		?.map((item) => transaction?.[item]?.links)
		.filter((i) => i);

	const closeSideBar = useCallback(() => {
		dispatch(clearInvoices());
		dispatch(setSideDrawerOpen(false));
	}, [dispatch]);

	const [showFaqItem, setShowFaqItem] = useState(false);
	const removeItemFromCart = useCallback(
		(e, index) => {
			const invoicedIds = selectors.invoicedIds(store.getState());

			e.stopPropagation();
			dispatch(removeInvociedIds(invoicedIds[index]));
		},
		[store, dispatch],
	);

	return (
		<Sidepane open={isOpen} onClose={closeSideBar}>
			<Wrapper>
				<Header
					onClose={closeSideBar}
					className="asteria-component__sidepane_wrapper_header"
				>
					{headerTitle}
				</Header>
				<List>
					<ListHeader>
						<ListItem>
							<Title>
								{TranslationService.get(
									'side.drawer.content.title',
								)}
							</Title>
						</ListItem>
					</ListHeader>
				</List>
				<Content className="asteria-component__sidepane_wrapper_content">
					<Accordion>
						{invoices?.map(([{ invoiceDetails }], i) => (
							<div
								key={i}
								className={cn(
									'asteria-component__accordion-item',
									`asteria-component__accordion-item-${i}`,
									{
										'asteria--state-active':
											showFaqItem === i,
									},
								)}
							>
								<button
									className="asteria-component__button asteria--size-medium asteria-component__button--icon-chevron-right asteria-component__button--icon-position-first asteria-component__accordion-content__vertical-align asteria-component__accordion-item__button__width__full"
									type="button"
									onClick={() =>
										setShowFaqItem(
											showFaqItem === i ? false : i,
										)
									}
								>
									<div className="asteria-component__flex__between__100">
										<div>
											<p
												className="asteria-component__typography asteria-component__text__reject asteria-component-accordion-button-heading"
												component="button-text"
											>
												{transaction[invoicedIds[i]]
													?.description ||
													invoiceDetails?.client
														?.name}
											</p>
											<p
												className="asteria-component__typography asteria-component__text__reject asteria-component__typography__sub-text"
												component="button-text"
											>
												{invoiceDetails?.client?.name}
											</p>
										</div>
										<div className="asteria-component__d-flex-center">
											<p
												className="asteria-component__typography asteria-component__text__reject asteria-component-accordion-button-heading"
												component="button-text"
											>
												{transaction?.[
													invoicedIds[i]
												]?.sums?.display?.total?.toFixed?.(
													2,
												)}
											</p>
											<Button
												icon="remove"
												size="md"
												onClick={(e) =>
													removeItemFromCart(e, i)
												}
											/>
										</div>
									</div>

									<div className="asteria-utils-position-first asteria-component__icon asteria-component__icon--chevron-right asteria--size-medium asteria-component__accordion-content__no-margin">
										<Icon
											className={cn(
												'asteria-component__alert--asteria-component-icon',
												'asteria-component__alert--asteria-component-no-margin',
											)}
											icon={
												showFaqItem === i
													? 'chevron-down'
													: 'chevron-right'
											}
										/>
									</div>
								</button>
								<div className="asteria-component__accordion-item__content">
									<List>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.invoice.heading.name',
														)}
													</p>
													<Text
														size="md"
														className="asteria-component__text__total asteria-component__shorten_text"
													>
														{
															invoiceDetails?.meta
																?.invoiceNumber
														}
													</Text>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.customer.heading.name',
														)}
													</p>
													<Button
														variant="link"
														label={
															invoiceDetails
																?.client?.name
														}
														size="md"
														className="asteria-component__splash__underline__label"
													/>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.payment.heading.name',
														)}
													</p>
													<Text
														size="md"
														className="asteria-component__text__total"
													>
														{
															invoiceDetails?.dates?.invoiceDue?.split?.(
																'T',
															)?.[0]
														}
													</Text>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.category.heading.name',
														)}
													</p>
													<div className="asteria-component__d-flex-row-center">
														<Prefix
															className="asteria-component__chip__prefix asteria-component__icon-mr-5"
															colors={
																('invoices-customer-forecast',
																'forecast',
																'invoices-customer')
															}
															tabIndex="0"
														/>
														<Typography
															type="text"
															size="default"
														>
															{TranslationService.get(
																[
																	`tags.${transaction?.[
																		invoicedIds?.[
																			i
																		]
																	]?.meta?.tags[0]?.name?.replace(
																		'$',
																		'',
																	)}`,
																],
															)}
														</Typography>
													</div>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.status.heading.name',
														)}
													</p>
													<Typography
														type="text"
														size={'default'}
														className="asteria-component__text__total"
													>
														{
															invoiceDetails?.info
																?.status
														}
													</Typography>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.cost.heading.name',
														)}
													</p>
													<Text
														size="md"
														className="asteria-component__text__total"
													>
														{
															invoiceDetails?.sums
																?.display?.rate
														}
													</Text>
												</div>
											</ListItem>
										</ListHeader>
										<ListHeader>
											<ListItem className="asteria-component__accordion-item__button__width__full">
												<div className="asteria-component__accordion-item__content__list">
													<p className="asteria-component__typography asteria-component__text__reject">
														{TranslationService.get(
															'side.drawer.content.detail.amount.heading.name',
														)}
													</p>
													<Text
														size="md"
														className="asteria-component__text__total"
													>
														{transaction[
															invoicedIds[i]
														]?.sums?.display?.total.toFixed(
															2,
														)}
													</Text>
												</div>
											</ListItem>
										</ListHeader>
									</List>
								</div>
							</div>
						))}
					</Accordion>
				</Content>
				<Footer className="asteria-component__wrapper-footer-border-top">
					<div className="asteria-component__accordion-item__button__width__full">
						<div className="asteria-component__flex__between__100 asteria-component__accordion__footer__bottom__padding">
							<div>
								<p className="asteria-component__typography asteria-component__text__reject asteria-component-accordion-button-heading">
									{TranslationService.get(
										'side.drawer.content.detail.amount.total.heading',
									)}
								</p>
								<Text>
									{TranslationService.get(
										'side.drawer.content.detail.amount.cost.heading',
										'Cost',
									)}
								</Text>
							</div>
							<div>
								<p className="asteria-component__typography asteria-component__text__reject asteria-component__text__total">
									{invoicedIds
										?.map((item) => transaction[item])
										?.filter((i) => i)
										?.reduce(
											(prev, { sums }) =>
												prev + sums?.display?.total,
											0,
										)
										.toFixed(2)}{' '}
									SEK
								</p>
								<Text className="asteria-factoring-cost">
									{invoicedIds
										?.map((item) => transaction[item])
										?.filter((i) => i)
										?.reduce(
											(prev, { sums }) =>
												prev +
												sums?.display?.total * 0.015,
											0,
										)
										.toFixed(2)}{' '}
									SEK
								</Text>
								<Text>
									{TranslationService.get(
										'side.drawer.content.detail.amount.total.info',
										// eslint-disable-next-line spellcheck/spell-checker
										'{{clients}} kunder, {{invoices}} fakturor',
										{
											clients: [
												...new Set(
													invoices?.map(
														([
															{ invoiceDetails },
														]) =>
															invoiceDetails
																?.client?.name,
													),
												),
											]?.length,
											invoices: invoices?.length,
										},
									)}
								</Text>
							</div>
						</div>
						<div className="asteria-component__flex__between__100">
							<Button
								variant="tertiary"
								label={TranslationService.get('button.cancel')}
								onClick={closeSideBar}
							/>
							<Button
								variant="primary"
								label={TranslationService.get('button.send')}
								onClick={closeSideBar}
							/>
						</div>
					</div>
				</Footer>
			</Wrapper>
		</Sidepane>
	);
};

SideDrawer.propTypes = {
	className: PropTypes.string,
	headerTitle: PropTypes.string,
};

SideDrawer.defaultProps = {
	className: null,
};

SideDrawer.displayName = 'SideDrawer';

export default SideDrawer;
