import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { FAQSection, useFAQ } from '@asteria/component-faq';
import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useCompanyVersionQuery } from '../../../components/CompanyVersion';
import Stepper from '../../../components/Stepper';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const BatchStartPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const params = useParams();
	const location = useLocation();

	const id = location?.state?.id ?? params?.id;

	const config = useConfig(['pages.invoices.batch.form.start'], {
		deep: true,
	});

	const faqSections = useConfig('pages.faq.sections', { deep: true }) ?? [];

	const faqSection = useFAQ(faqSections, { name: config?.faq });

	const hasCompanyVersionTwo = useCompanyVersionQuery({ gte: 2 });

	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank ?? 'swedbank';
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.() ?? 'swedbank';
		}

		return bank?.value?.toLowerCase?.() ?? 'swedbank';
	});

	const onCancel = React.useCallback(() => {
		if (location?.state?.from) {
			if (location?.state?.from === '/invoices/review') {
				return onAction?.('go', '/invoices');
			}

			return onAction?.('go', -1);
		}

		return onAction?.('go', '/');
	}, [location?.state?.from, onAction]);

	const onContinue = React.useCallback(() => {
		window
			.open(
				TranslationService.get(
					[
						'invoice.batch.form.start.continue.href',
						`invoice.batch.form.start.continue.${bank}.href`,
					],
					'https://google.com',
				),
				'_blank',
			)
			.focus();

		if (hasCompanyVersionTwo) {
			if (Array.isArray(id)) {
				return onAction?.('go', `/invoices/batch/multi/success`);
			}

			return onAction?.('go', {
				path: `/invoices/batch/${id}/success`,
				state: { id: id },
			});
		}

		if (Array.isArray(id)) {
			return onAction?.('go', {
				path: `/invoices/batch/multi/confirmation`,
				state: { id: id },
			});
		}

		return onAction?.('go', `/invoices/batch/${id}/confirmation`);
	}, [bank, hasCompanyVersionTwo, id, onAction]);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices-batch',
				'asteria--variant-start',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<div
				className={cn('asteria-page__wrapper', {
					'asteria--variant-split': config?.placeholder,
				})}
			>
				<div
					className={cn(
						'asteria-page__wrapper-section',
						'asteria--type-content',
					)}
				>
					<Wrapper scroll>
						<Header>
							<Title size="lg">
								{TranslationService.get([
									'invoice.batch.form.title',
									'invoice.batch.form.start.title',
								])}
							</Title>
						</Header>
						<Content scroll>
							<Contenter
								content={config?.content}
								data={{ bank }}
							/>
						</Content>
						<Footer>
							<FooterSection position="first">
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.cancel',
										'action.cancel',
										'invoice.batch.form.cancel',
										'invoice.batch.form.start.cancel',
									])}
									onClick={onCancel}
								/>
							</FooterSection>
							<FooterSection position="last">
								<Button
									variant="primary"
									label={TranslationService.get([
										'button.continue',
										'action.continue',
										'invoice.batch.form.continue',
										'invoice.batch.form.start.continue',
										`invoice.batch.form.start.continue.${bank}`,
									])}
									onClick={onContinue}
								/>
							</FooterSection>
						</Footer>
					</Wrapper>
				</div>

				{config?.placeholder ? (
					<div
						className={cn(
							'asteria-page__wrapper-section',
							'asteria--type-placeholder',
						)}
					>
						{config?.placeholder?.type === 'image' ? (
							<div
								className="asteria-component__placeholder-image"
								style={{
									backgroundImage: `url(${config?.placeholder.src})`,
								}}
							/>
						) : null}
						{config?.placeholder?.type === 'contenter' ? (
							<Contenter content={config?.placeholder?.content} />
						) : null}
					</div>
				) : null}
			</div>

			{config?.faq && faqSection ? (
				<FAQSection
					{...faqSection}
					title={TranslationService.get([
						'faq.content.title',
						`faq.content.title.${faqSection?.name}`,
						'invoice.batch.form.faq',
						'invoice.batch.form.start.faq',
						`invoice.batch.form.faq.${faqSection?.name}`,
						`invoice.batch.form.start.faq.${faqSection?.name}`,
					])}
					onAction={onAction}
				/>
			) : null}

			<Support onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
};

BatchStartPage.displayName = 'BatchStartPage';

BatchStartPage.propTypes = { className: PropTypes.string };

export default BatchStartPage;
