export function useFAQ(sections, { name, id }) {
	if (name !== undefined) {
		const section = (sections ?? []).find(
			(object) => `${object?.name}` === `${name}`,
		);

		if (id !== undefined) {
			return (section?.questions ?? []).find(
				(object) => `${object?.id}` === `${id}`,
			);
		}

		return section;
	}

	return sections;
}
