import React from 'react';

import PropTypes from 'prop-types';

import Accordion, { AccordionItem } from '@asteria/component-core/accordion';

import Aside from '@asteria/component-aside';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const FormRejected = (props) => {
	const { className } = props;
	return (
		<div
			className={cn(
				'asteria-component__application__container',
				className,
			)}
		>
			<div className="asteria-component__accordion_width-60">
				<div className="asteria-component__wrapper asteria-component__feedback__prompt asteria-component__feedback__prompt__width-100">
					<div className="asteria-component__wrapper-header asteria-component__wrapper-header--border-bottom">
						<h4 className="asteria-component__typography asteria-component__title asteria-component__title--size-title-3">
							{TranslationService.get(
								'form.rejected.QA.section.heading',
							)}
						</h4>
					</div>
					<div className="asteria-component__wrapper-content asteria-component__wrapper-content__no-padding">
						<div className="asteria-component__accordion">
							{[1, 2, 3].map((item, i) => (
								<Accordion key={i + 1}>
									<AccordionItem
										label={TranslationService.get(
											`form.rejected.question.${item}`,
										)}
									>
										{TranslationService.get(
											`form.rejected.answer.1`,
										)}
									</AccordionItem>
								</Accordion>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="asteria-component__aside_width-35">
				<Aside
					actions={[
						{
							icon: 'triangle-right',
							iconPosition: 'last',
							label: TranslationService.get('aside.button.label'),
							size: 'medium',
							variant: 'link',
						},
					]}
					description={TranslationService.get('aside.description')}
					subDescription={TranslationService.get(
						'aside.subDescription',
					)}
					title={TranslationService.get('aside.title')}
				/>
			</div>
		</div>
	);
};

FormRejected.propTypes = {
	className: PropTypes.string,
};

FormRejected.defaultProps = {
	className: null,
};

FormRejected.displayName = 'FormRejected';

export default FormRejected;
