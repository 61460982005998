import React from 'react';

import {
	addMonths,
	addYears,
	endOfMonth,
	endOfWeek,
	endOfYear,
	isSameDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subMonths,
	subYears,
} from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import DatepickerContext from '../context';

import { useDateFNSLocale } from './hooks';

import './styles.scss';

const DatepickerContentNavigationItem = (props) => {
	const { type, increase, decrease } = props;

	const { date: ctxDate, onDateChange } = React.useContext(DatepickerContext);

	const locale = useDateFNSLocale();

	const date = React.useMemo(() => ctxDate ?? new Date(), [ctxDate]);

	const onIncrease = React.useCallback(
		() => onDateChange?.(increase?.(date, 1)),
		[date, increase, onDateChange],
	);

	const onDecrease = React.useCallback(
		() => onDateChange?.(decrease?.(date, 1)),
		[date, decrease, onDateChange],
	);

	const { startDate, endDate } = React.useMemo(() => {
		if (type === 'day') {
			let startDate = startOfMonth(date);
			let endDate = endOfMonth(date);

			if (
				isSameDay(startDate, startOfWeek(startDate, { locale: locale }))
			) {
				startDate = startOfWeek(startDate, { locale: locale });
			}

			if (isSameDay(endDate, startOfWeek(endDate, { locale: locale }))) {
				endDate = endOfWeek(endDate, { locale: locale });
			}

			return { startDate: startDate, endDate: endDate };
		}

		if (type === 'week') {
			let startDate = null;
			let endDate = null;

			return { startDate: startDate, endDate: endDate };
		}

		if (type === 'month') {
			const startDate = startOfYear(date);
			const endDate = endOfYear(date);

			return { startDate: startDate, endDate: endDate };
		}

		if (type === 'year') {
			const startDate = subYears(date, 5);
			const endDate = addYears(date, 6);

			return { startDate: startDate, endDate: endDate };
		}

		return {};
	}, [date, locale, type]);

	return (
		<div className="asteria-component__form-v2__datepicker-content__navigation-item">
			<Button size="sm" icon="triangle-left" onClick={onDecrease} />
			<Text size="sm">
				{TranslationService.get(
					[
						`datepicker.navigation.item`,
						`datepicker.navigation.item.${type}`,
					],
					undefined,
					{ date: date, startDate: startDate, endDate: endDate },
				)}
			</Text>
			<Button size="sm" icon="triangle-right" onClick={onIncrease} />
		</div>
	);
};

DatepickerContentNavigationItem.displayName = 'DatepickerContentNavigationItem';
DatepickerContentNavigationItem.propTypes = {
	type: PropTypes.string,
	increase: PropTypes.func,
	decrease: PropTypes.func,
};

const DatepickerContentNavigation = () => {
	const { type } = React.useContext(DatepickerContext);

	return (
		<div className="asteria-component__form-v2__datepicker-content__navigation">
			{type === 'day'
				? [
						<DatepickerContentNavigationItem
							increase={addYears}
							decrease={subYears}
							type="day.year"
							key="year"
						/>,
						<DatepickerContentNavigationItem
							increase={addMonths}
							decrease={subMonths}
							type="day.month"
							key="month"
						/>,
				  ]
				: null}
			{type === 'week' ? (
				<DatepickerContentNavigationItem
					increase={addMonths}
					decrease={subMonths}
					type="week"
				/>
			) : null}
			{type === 'month' ? (
				<DatepickerContentNavigationItem
					increase={addYears}
					decrease={subYears}
					type="month"
				/>
			) : null}
			{type === 'year' ? (
				<DatepickerContentNavigationItem
					increase={addYears}
					decrease={subYears}
					type="year"
				/>
			) : null}
		</div>
	);
};

DatepickerContentNavigation.displayName = 'DatepickerContentNavigation';

export default React.memo(DatepickerContentNavigation);
