import React from 'react';

import { eachDayOfInterval, endOfWeek, startOfWeek } from 'date-fns';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import { useDateFNSLocale } from '../hooks';

import './styles.scss';

const DatepickerContentCalendarHeader = React.memo(() => {
	const locale = useDateFNSLocale();

	const dates = React.useMemo(
		() =>
			eachDayOfInterval({
				start: startOfWeek(new Date(), { locale: locale }),
				end: endOfWeek(new Date(), { locale: locale }),
			}),
		[locale],
	);

	return (
		<div className="asteria-component__form-v2__datepicker-content__calendar-header">
			{dates.map((date) => (
				<Text key={date} align="center" size="sm">
					{TranslationService.get(
						['datepicker.content.header.day'],
						undefined,
						{ date: date },
					)}
				</Text>
			))}
		</div>
	);
});

DatepickerContentCalendarHeader.displayName = 'DatepickerContentCalendarHeader';
DatepickerContentCalendarHeader.propTypes = {};

export default DatepickerContentCalendarHeader;
