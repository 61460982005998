import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import { cn } from '@asteria/utils-funcs/classes';

import CompanyVersion from '../../../CompanyVersion';
import Cell from '../Cell';

import { ChipAmountWrapper } from './Amount';
import { ChipClientWrapper } from './Client';
import { ChipCurrencyWrapper } from './Currency';
import { ChipDateWrapper } from './Date';
import ChipServiceStatus from './ServiceStatus';
import ChipType from './Type';
import { useVisibility } from './hooks';

import '../styles.scss';

const QuickFilters = React.memo((props) => {
	const { className } = props;

	const isVisible = useVisibility();

	if (!isVisible) {
		return null;
	}

	return (
		<Cell className="asteria--type-quick-filters">
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="left"
				className={cn(
					'asteria-component__invoice-search__quick-filters',
					className,
				)}
			>
				<ChipClientWrapper />
				<ChipCurrencyWrapper />
				<ChipDateWrapper />
				<ChipServiceStatus status="SENT" />
				<CompanyVersion gte={2}>
					<ChipServiceStatus status="ERROR" badge />
				</CompanyVersion>
				<ChipServiceStatus status="COMPLETED" />
				<ChipServiceStatus status="DISCARD" />
				<ChipType type="credit" />
				{/* <ChipType type="invoice" onAction={onAction}
							onSubmit={onSubmit} /> */}
				<ChipAmountWrapper />
			</Group>
		</Cell>
	);
});

QuickFilters.displayName = 'QuickFilters';

QuickFilters.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default QuickFilters;
export { useVisibility };
