import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import OnboardingConfiguration from './Configuration';
import OnboardingConnecting from './Connecting';
import OnboardingSelect from './Select';
import Context from './context';

import './styles.scss';

const Onboarding = React.memo((props) => {
	const { className, type, name, onClose, onSubmit, onAction } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const _id = useSelector(IntegrationStore.selectors.navigation._id);

	const handleAbort = React.useCallback(
		async (event, options) => {
			const skipRedirect = options?.skipRedirect;

			const _id = IntegrationStore.selectors.navigation._id(
				store.getState(),
			);

			if (_id) {
				const integration = IntegrationStore.selectors.integration(
					store.getState(),
					_id,
				);

				if (!integration?.config?.connected) {
					await onSubmit?.('integrations:delete', integration);
				}
			}

			if (!skipRedirect) {
				return onClose?.(event);
			}
		},
		[onClose, onSubmit, store],
	);

	const handleBack = React.useCallback(async () => {
		const _id = IntegrationStore.selectors.navigation._id(store.getState());

		if (_id) {
			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				_id,
			);

			await onSubmit?.('integrations:delete', integration);

			dispatch(
				IntegrationStore.navigation.back(
					JSON.parse(JSON.stringify(integration)),
				),
			);
		}
	}, [dispatch, onSubmit, store]);

	const handleClose = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	const ctx = React.useMemo(
		() => ({
			onAbort: handleAbort,
			onBack: handleBack,
			onClose: handleClose,
			onSubmit: onSubmit,
			onAction: onAction,
		}),
		[handleAbort, handleBack, handleClose, onAction, onSubmit],
	);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__wrapper',
				className,
			)}
		>
			<Context.Provider value={ctx}>
				{_id ? (
					<OnboardingConnecting type={type} />
				) : key ? (
					<OnboardingConfiguration type={type} name={name} />
				) : (
					<OnboardingSelect type={type} />
				)}
			</Context.Provider>
		</div>
	);
});

Onboarding.displayName = 'Onboarding';

Onboarding.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,

	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Onboarding;
