import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import IntegrationConfiguration from '@asteria/component-integrations-v2/flows/Configuration';
import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import Help from './Help';
import Context from './context';
import { usePlaceholder } from './hooks';

import './styles.scss';

const OnboardingConfiguration = React.memo((props) => {
	const { className, type, name } = props;

	const { onClose, onSubmit, onAction } = React.useContext(Context);

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const ConfigurationHeader = React.useMemo(() => ({ hideClose: true }), []);
	const ConfigurationFooter = React.useMemo(() => ({ hideBack: true }), []);

	const flow = useFlow(type, key);

	const content = flow?.steps?.['configuration']?.content;

	const Placeholder = usePlaceholder(type, key, 'configuration');

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__configuration',
				{ 'asteria--variant-split': Placeholder },
				className,
			)}
		>
			<div className="asteria-component__linear-onboarding__content">
				<IntegrationConfiguration
					type={type}
					header={ConfigurationHeader}
					footer={ConfigurationFooter}
					onClose={onClose}
					onSubmit={onSubmit}
					onAction={onAction}
					name={name}
					content={content ? <Contenter content={content} /> : null}
				/>
				<Help />
			</div>
			{Placeholder}
		</div>
	);
});

OnboardingConfiguration.displayName = 'OnboardingConfiguration';

OnboardingConfiguration.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
};

export default OnboardingConfiguration;
