import { PAGES } from './constants';

export function getCountry(countries) {
	if (countries?.length) {
		if (countries?.length === 1) {
			return countries?.[0]?.id;
		}

		return null;
	}

	return 'sv';
}

export const initialState = (countries) => ({
	page: PAGES.COUNTRY,
	country: getCountry(countries),
	response: null,
});
