import React, { useCallback, useRef } from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import { FAQSection, useFAQ } from '@asteria/component-faq';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../../components/Actions';
import Guides from '../../../components/Guides';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const FAQSectionPage = (props) => {
	const { className } = props;
	const { name } = useParams();

	const ref = useRef(null);

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const sectionsConfig = useConfig('pages.faq.sections', { deep: true });
	const guideConfig = useConfig('pages.common.guides', { deep: true });

	const section = useFAQ(sectionsConfig, { name: name });

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, [name]);

	const handleBack = useCallback(() => onAction?.('go', -1), [onAction]);

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<div
			ref={ref}
			className={cn(
				'asteria-page',
				'asteria-page__details',
				'asteria-page__faq-section',
				className,
			)}
		>
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<Group
					className="asteria-page__navigation"
					direction="horizontal"
					horizontalAlign="left"
					verticalAlign="center"
				>
					<Button icon="chevron-left" onClick={handleBack} />
					<Title size="page-title">
						{TranslationService.get([
							'faq.content.title',
							`faq.content.title.${name}`,
							'page.faq.section.title',
							`page.faq.section.${name}.title`,
						])}
					</Title>
				</Group>

				<Contenter content={section?.content} actions={actions} />
				<Contenter content={section?.details} actions={actions} />

				<FAQSection {...section} count={null} onAction={onAction} />

				<Guides guides={guideConfig} onAction={onAction} />
			</div>

			<Support hideFAQ onAction={onAction} />
		</div>
	);
};

FAQSectionPage.displayName = 'FAQSectionPage';

FAQSectionPage.propTypes = { className: PropTypes.string };

export default FAQSectionPage;
