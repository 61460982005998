import React, { useCallback, useState } from 'react';

import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Spinner from '@asteria/component-core/spinner';
import { Text, TextGroup } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { ListItem } from '@asteria/component-list';
import Prefix from '@asteria/component-prefix';
import { MessageBox } from '@asteria/component-support';

import { TranslationService } from '@asteria/language';

import './list.scss';

const selectors = {
	integration: createSelector(
		(store) => store?.integrations?.items ?? [],
		(_, ID) => ID,
		(objects, id) =>
			objects.find((object) => {
				const ID = object?._id ?? object.id;

				return ID === id;
			}),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Integration = (props) => {
	const { id, onAction } = props;

	const store = useStore();

	const integration = useSelector((store) =>
		selectors.integration(store, id),
	);

	const [isDelete, setDeleteStatus] = useState(false);

	const toggleDeleteStatus = useCallback(() => {
		setDeleteStatus((isDelete) => !isDelete);
	}, []);

	const onDelete = useCallback(() => {
		const integration = selectors.integration(store.getState(), id);

		onAction?.('delete', integration);
		toggleDeleteStatus();
	}, [store, id, onAction, toggleDeleteStatus]);

	const onToggle = useCallback(() => {
		const integration = selectors.integration(store.getState(), id);

		if (integration.disabled) {
			onAction?.('enable', integration);
		} else {
			onAction?.('disable', integration);
		}
	}, [store, id, onAction]);

	/*
	const onEdit = useCallback(() => {
		onAction?.('edit', props);
	}, [id]);
	*/

	const onFetch = useCallback(() => {
		const integration = selectors.integration(store.getState(), id);
		return onAction?.('fetch', integration);
	}, [store, id, onAction]);

	const state = integration?.status?.state?.toLowerCase?.();

	const PropsWithState = { ...integration, state: state };

	return (
		<>
			<ListItem>
				{!integration?.config?.connected ? (
					<Prefix
						colors="not-connected"
						className="not-connected"
						tooltip={
							<Text>
								{TranslationService.get(
									[
										'integrations.list.item.not.connected.tooltip',
										`integrations.list.item.${integration?.name}.not.connected.tooltip`,
									],
									undefined,
									PropsWithState,
								)}
							</Text>
						}
					/>
				) : integration?.disabled ? (
					<Prefix
						colors="disabled"
						className="disabled"
						tooltip={
							<Text>
								{TranslationService.get(
									[
										'integrations.list.item.disabled.tooltip',
										`integrations.list.item.${integration?.name}.disabled.tooltip`,
									],
									undefined,
									PropsWithState,
								)}
							</Text>
						}
					/>
				) : (
					<Prefix
						colors={`status-${state}`}
						className={`status-${state}`}
						tooltip={
							<Text>
								{TranslationService.get(
									[
										'integrations.list.item.tooltip',
										`integrations.list.item.${integration?.name}.tooltip`,
									],
									undefined,
									PropsWithState,
								)}
							</Text>
						}
					/>
				)}

				<TextGroup className="asteria-component__integration-list__integration-description">
					<Text size="sm">
						{TranslationService.get(
							[
								'integrations.list.item',
								`integrations.list.item.${integration?.name}`,
							],
							undefined,
							PropsWithState,
						)}
					</Text>
					{integration?.lastSync ? (
						<Text size="xs">
							{TranslationService.get(
								[
									'integrations.list.item.lastSync',
									`integrations.list.item.${integration?.name}.lastSync`,
								],
								integration?.lastSync,
								PropsWithState,
							)}
						</Text>
					) : null}
				</TextGroup>
				{(state === 'importing' || state === 'initiating') && (
					<Spinner />
				)}
				<Dropdown
					toggle={{
						icon: 'more',
						iconActive: 'more',
					}}
					title={TranslationService.get([
						'integrations.actions.title',
						'integrations.actions.dropdown.title',
					])}
				>
					<DropdownItem onClick={toggleDeleteStatus}>
						{TranslationService.get(
							[
								'integrations.list.action.delete',
								`integrations.list.action.${integration?.name}.delete`,
							],
							undefined,
							PropsWithState,
						)}
					</DropdownItem>
					<DropdownItem onClick={onToggle}>
						{integration?.disabled
							? TranslationService.get(
									[
										'integrations.list.action.enable',
										`integrations.list.action.${integration?.name}.enable`,
									],
									undefined,
									PropsWithState,
							  )
							: TranslationService.get(
									[
										'integrations.list.action.disable',
										`integrations.list.action.${integration?.name}.disable`,
									],
									undefined,
									PropsWithState,
							  )}
					</DropdownItem>
					{!integration?.disabled ? (
						<DropdownItem onClick={onFetch}>
							{TranslationService.get(
								[
									'integrations.list.action.fetch',
									`integrations.list.action.${integration?.name}.fetch`,
								],
								undefined,
								PropsWithState,
							)}
						</DropdownItem>
					) : null}
					{/*<DropdownItem onClick={onEdit}>
						{TranslationService.get(
							[
								'integrations.list.action.edit',
								`integrations.list.action.${key}.edit`,
							],
							undefined,
							PropsWithState,
						)}
						</DropdownItem>*/}
				</Dropdown>
			</ListItem>
			{isDelete ? (
				<MessageBox
					variant="help"
					className="asteria-component__integration-list__integration-delete-message-box"
				>
					<Content>
						<Text>
							{TranslationService.get(
								[
									'integrations.list.item.delete',
									`integrations.list.item.${integration?.name}.delete`,
								],
								undefined,
								PropsWithState,
							)}
						</Text>
					</Content>
					<Footer>
						<FooterSection>
							<Button
								variant="tertiary"
								size="sm"
								onClick={toggleDeleteStatus}
								label={TranslationService.get([
									'action.abort',
									'action.cancel',
								])}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="primary"
								size="sm"
								onClick={onDelete}
								label={TranslationService.get([
									'action.submit',
									'action.remove',
								])}
							/>
						</FooterSection>
					</Footer>
				</MessageBox>
			) : null}
		</>
	);
};

Integration.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	key: PropTypes.string,
	status: PropTypes.shape({
		state: PropTypes.string,
	}),
	lastSync: PropTypes.string,
	onAction: PropTypes.func,

	disabled: PropTypes.bool,

	config: PropTypes.shape({
		connected: PropTypes.bool,
	}),
};

export default Integration;
