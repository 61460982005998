import React, { useCallback, useState } from 'react';

import { useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Form from '@asteria/component-form/form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import PageContent from './content';

import '../styles.scss';

const PageForm = (props) => {
	const { onAction } = props;

	const store = useStore();

	const invoiceSell = useSelector(
		(store) =>
			AppStore.selectors.company(store)?.service?.data?.['invoice-sell'],
	);

	const values = useSelector(
		(store) =>
			Object.fromEntries(
				InvoiceStore.selectors
					.selected(store)
					.map((object) => [
						object?._id ?? object?.id,
						{ factoringOperation: invoiceSell ?? 'SELL' },
					]),
			),
		(a, b) => isEqual(a, b),
	);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const onFormSubmit = useCallback(
		async (data) => {
			setLoading(true);

			try {
				const id = await onAction?.('invoices:approve', data);

				setLoading(false);

				const lending = AppStore.selectors.company(store.getState())
					?.service?.data?.lending;

				if (!id) {
					return onAction?.('go', '/invoices');
				}

				if (Array.isArray(id)) {
					if (
						['not-receivable', 'Inte fakturabelåning'].includes(
							lending,
						)
					) {
						return onAction?.('go', {
							path: `/invoices/batch/multi/success`,
							state: { id: id },
						});
					}

					return onAction?.('go', {
						path: `/invoices/batch/multi`,
						state: { id: id },
					});
				}

				if (
					['not-receivable', 'Inte fakturabelåning'].includes(lending)
				) {
					return onAction?.('go', `/invoices/batch/${id}/success`);
				}

				return onAction?.('go', `/invoices/batch/${id}`);
			} catch (e) {
				setError(e);
			} finally {
				setLoading(false);
			}
		},
		[onAction, store],
	);

	return (
		<Form values={values} onSubmit={onFormSubmit}>
			<PageContent
				{...props}
				onFormSubmit={onFormSubmit}
				loading={loading}
				error={error}
			/>
		</Form>
	);
};

PageForm.displayName = 'PageForm';

PageForm.propTypes = { onAction: PropTypes.func };

export default PageForm;
