import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip/chip';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import SearchContext from '../context';

import '../styles.scss';

const ChipType = (props) => {
	const { type } = props;

	const { onSubmit } = React.useContext(SearchContext);

	const dispatch = useDispatch();

	const statuses = useSelector(
		(store) =>
			InvoiceStore.selectors
				.filters(store, {
					type: 'SERVICE:STATUS',
				})
				.map(({ value }) => value),
		(a, b) => isEqual(a, b),
	);

	const { data: count } = useQuery({
		queryKey: [
			'invoices',
			'search',
			'type',
			{ type: type },
			{ statuses: statuses },
		],
		queryFn: async () => {
			return onSubmit?.('invoices:available', {
				filters: {
					type: type,
					services: { status: statuses.length ? statuses : null },
				},
				pageFilters: { first: 1 },
				fields: `_id`,
			});
		},
		select: (response) => response?.pageInfo?.count ?? 0,

		placeholderData: 0,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const active = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'TYPE',
				value: type,
			}).length,
	);

	const onClick = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'TYPE',
				value: type,
			}),
		);
	}, [dispatch, type]);

	if (!count) {
		return null;
	}

	return (
		<TooltipWrapper
			tooltip={TranslationService.get(
				[
					'invoices.search.quick.type.tooltip',
					`invoices.search.quick.type.${type.toLowerCase()}.tooltip`,
				].concat(
					active
						? [
								'invoices.search.quick.type.active.tooltip',
								`invoices.search.quick.type.${type.toLowerCase()}.active.tooltip`,
						  ]
						: [],
				),
				undefined,
				{ invoices: { length: count } },
			)}
		>
			<Chip
				size="sm"
				label={TranslationService.get(
					[
						'invoices.search.quick.type.label',
						`invoices.search.quick.type.${type.toLowerCase()}.label`,
					].concat(
						active
							? [
									'invoices.search.quick.type.active.label',
									`invoices.search.quick.type.${type.toLowerCase()}.active.label`,
							  ]
							: [],
					),
					undefined,
					{ invoices: { length: count } },
				)}
				onClick={onClick}
				active={active}
			/>
		</TooltipWrapper>
	);
};

ChipType.displayName = 'ChipType';
ChipType.propTypes = { type: PropTypes.string };

export default ChipType;
