import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import Currency from '@asteria/component-currency';

import { setFilters } from '@asteria/datalayer/stores/app';

import { AuthContext } from '../context';

import { fetch as fetchAccounts } from './funcs/accounts';
import fetchCurrencies from './funcs/currencies';

const CurrencyLogic = (props) => {
	const { onClose } = props;
	const dispatch = useDispatch();
	const { accessToken } = useContext(AuthContext);
	const [currencies, setCurrencies] = useState(null);
	const [accounts, setAccounts] = useState(null);

	const filters = useSelector((store) => store?.app?.filters || null);

	useEffect(() => {
		fetchCurrencies({ accessToken }).then((data) => {
			setCurrencies(
				data?.sort((a, b) => a?.currency?.localeCompare(b?.currency)),
			);
		});

		fetchAccounts({ accessToken }).then((data) => {
			setAccounts(data);
		});
	}, []);

	const onAction = useCallback((action, value) => {
		if (action === 'toggleCurrency') {
			dispatch(
				setFilters([
					{
						id: value.currency,
						type: 'currency',
						item: {
							code: value.currency,
						},
					},
				]),
			);
		}
	}, []);

	const start = useMemo(() => format(new Date(), 'yyyy-MM-dd'));
	const impact = useMemo(
		() =>
			currencies?.reduce(
				(acc, item) =>
					acc +
					Math.abs(
						(item?.deposit?.display?.total || 0) -
							(item?.withdraw?.display?.total || 0),
					),
				0,
			) || 0,
		[currencies],
	);
	const hasRiskAlert = useMemo(() => false);

	const onGetHelp = useCallback(() => {
		onClose?.();
	}, [onClose]);

	if (currencies === null || accounts === null) {
		return null;
	}

	return (
		<Currency
			currencies={currencies}
			accounts={accounts}
			onClose={onClose}
			impact={impact}
			start={start}
			hasRiskAlert={hasRiskAlert}
			onAction={onAction}
			filters={filters}
			onGetHelp={onGetHelp}
		/>
	);
};

CurrencyLogic.propTypes = {
	onClose: PropTypes.func,
};

export default CurrencyLogic;
