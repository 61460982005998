import React from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { ImportantActions } from '../../components/Actions';
import OnboardingGrid from '../../components/Onboarding/Grid';
import Stepper from '../../components/Stepper';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import './styles.scss';

const IntegrationsPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__integrations',
				className,
			)}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />
			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get(
							'page.onboarding.selection.title',
						)}
					</Title>
					<Text size="lg">
						{TranslationService.get(
							'page.onboarding.selection.content',
						)}
					</Text>
				</TextGroup>
				<OnboardingGrid onAction={onAction} onSubmit={onSubmit} />
			</div>
			<Support onAction={onAction} />
		</div>
	);
};

IntegrationsPage.displayName = 'IntegrationsPage';

IntegrationsPage.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default IntegrationsPage;
