/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Guide from './Guide';
import { GuidePropTypes } from './PropTypes';

import './styles.scss';

const Guides = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { className, guides = [], onAction } = props;

	return null;

	// if (!guides?.length) {
	// 	return null;
	// }

	// return (
	// 	<div className={cn('asteria-component__guides', className)}>
	// 		{guides.map((guide, index) => (
	// 			<Guide
	// 				key={guide?.id ?? index}
	// 				{...guide}
	// 				onAction={onAction}
	// 			/>
	// 		))}
	// 	</div>
	// );
};

Guides.displayName = 'Guides';

Guides.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	guides: PropTypes.arrayOf(PropTypes.shape({ ...GuidePropTypes })),
};

export default Guides;
export { Guide };
