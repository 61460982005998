import React from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { FAQSection, useFAQ } from '@asteria/component-faq';
import { useFlow } from '@asteria/component-integrations-v2/hooks';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../components/Actions';
import Onboarding from '../../components/Onboarding/Connection';
import Stepper from '../../components/Stepper';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import PrinterPage from './Printer';
import VismaAdministrationPage from './VismaAdministration';

import './styles.scss';

const ConnectionPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const dispatch = useDispatch();

	const { type, key } = useParams();

	const ref = React.useRef(null);

	const flow = useFlow(type, key);
	const faqSections = useConfig('pages.faq.sections', { deep: true }) ?? [];

	const faqSection = useFAQ(faqSections, { name: flow?.faq });

	React.useEffect(() => {
		dispatch(IntegrationStore.navigation.go({ key: key }));
	}, [dispatch, key]);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, [key]);

	const handleClose = React.useCallback(
		() => onAction?.('go', -1),
		[onAction],
	);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__connection',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />
			<div className="asteria-page__wrapper">
				<Onboarding
					type={type}
					name={key}
					onClose={handleClose}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
			</div>
			{flow?.faq && faqSection ? (
				<FAQSection
					{...faqSection}
					title={TranslationService.get([
						'faq.content.title',
						`faq.content.title.${faqSection?.name}`,
						'page.connection.faq',
						`page.connection.faq.${faqSection?.name}`,
						`page.connection.faq.${type}`,
						`page.connection.faq.${key}`,
						`page.connection.faq.${type}.${key}`,
					])}
					onAction={onAction}
				/>
			) : null}
			<Support onAction={onAction} />
		</div>
	);
};

ConnectionPage.displayName = 'ConnectionPage';

ConnectionPage.propTypes = { className: PropTypes.string };

export default ConnectionPage;
export { VismaAdministrationPage, PrinterPage };
