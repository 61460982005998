import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Option, Select } from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const CountrySelect = (props) => {
	const codes = useSelector(InvoiceStore.selectors.countryCodes);

	const search = React.useMemo(
		() => ({
			toggle: true,
			placeholder: TranslationService.get(
				'dropdown.search.input.country.placeholder',
			),
			empty: TranslationService.get(
				'dropdown.search.input.country.empty',
			),
		}),
		[],
	);

	return (
		<Select {...props} scroll search={search}>
			{Object.keys(codes).map((item) => (
				<Option key={item} value={item}>
					{TranslationService.get(`country.${item}`)}
				</Option>
			))}
		</Select>
	);
};

CountrySelect.displayName = 'CountrySelect';

CountrySelect.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
};

export default CountrySelect;
