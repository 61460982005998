import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';

import { SupportService } from '@asteria/backend-utils-services';

import Support from '@asteria/component-support';

import { AuthContext } from '../context';

const SupportLogic = (props) => {
	const { onClose } = props;
	const { accessToken } = useContext(AuthContext);

	const onSend = useCallback(
		async (form) => {
			const data = await SupportService.issue.reportIssues(
				{
					fields: 'reportId',
					input: form,
				},
				{ token: accessToken },
			);

			// eslint-disable-next-line spellcheck/spell-checker
			return data?.[0]?.reportId || 'Okänt ärende nummer';
		},
		[accessToken],
	);

	return <Support onClose={onClose} onSend={onSend} />;
};

SupportLogic.propTypes = {
	onClose: PropTypes.func,
};

export default SupportLogic;
