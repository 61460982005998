import React from 'react';

import { useDispatch } from 'react-redux';

import { IntegrationGateway } from '@asteria/backend-utils-services';

import { useSubscription } from '@asteria/utils-websocket/hooks';

import { AuthContext } from '../context';
import {
	DEFAULT_FIELDS as DEFAULT_INTEGRATION_FIELDS,
	addCustomERP,
	fetch as fetchIntegrations,
} from '../logic/funcs/integrations';

const useIntegrationRequest = (props) => {
	const { onAction, callback } = props;

	const dispatch = useDispatch();

	const { accessToken } = React.useContext(AuthContext);

	const handleIntegrationRequest = React.useCallback(
		async (action, data) => {
			if (action === 'integrations:missing') {
				return addCustomERP({ token: accessToken, variables: data });
			}

			if (action === 'integrations:list') {
				return fetchIntegrations({
					token: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'integrations:delete') {
				const response = await IntegrationGateway.integration
					.remove({ ids: data.id }, { token: accessToken })
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(action, response.error);
					return;
				}

				return handleIntegrationRequest?.('integrations:list');
			}

			if (action === 'integrations:enable') {
				const response = await IntegrationGateway.integration
					.enable({ ids: data.id }, { token: accessToken })
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(action, response.error);
					return;
				}

				return handleIntegrationRequest?.('integrations:list');
			}

			if (action === 'integrations:disable') {
				const response = await IntegrationGateway.integration
					.disable({ ids: data.id }, { token: accessToken })
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(action, response.error);
					return;
				}

				return handleIntegrationRequest?.('integrations:list');
			}

			if (action === 'integrations:import') {
				const response = await IntegrationGateway.integration
					.importSingle(
						{ integrationId: data.id },
						{ token: accessToken },
					)
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(action, response.error);
					return;
				}

				return handleIntegrationRequest?.('integrations:list');
			}

			if (action === 'integrations:create') {
				const response = await IntegrationGateway.integration
					.create(
						{
							fields: `ok error integration { ${DEFAULT_INTEGRATION_FIELDS} }`,
							input: data,
						},
						{ token: accessToken },
					)
					.catch((err) => ({ ok: false, error: err }));

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.warn(action, response.error);
					return;
				}

				await handleIntegrationRequest?.('integrations:list');

				return response?.integration?.[0];
			}
		},
		[accessToken, dispatch],
	);

	const handleIntegrationStatus = React.useCallback(
		(response) => {
			handleIntegrationRequest?.('integrations:list');

			const integration = response?.data?.integrationUpdated;

			if (integration) {
				onAction?.('askForFeedback', {
					feedbackKey: `integration-updated-${integration._id}`,
				});

				callback?.('integration.status', {
					integrationId: integration._id ?? integration.id,
					status: integration?.status?.state || 'IDLE',
				});
			}
		},
		[callback, handleIntegrationRequest, onAction],
	);

	useSubscription({
		token: accessToken,
		query: `
			subscription IntegrationUpdated {
				integrationUpdated {
					${DEFAULT_INTEGRATION_FIELDS}
				}
			}
		`,
		onNext: handleIntegrationStatus,
	});

	return handleIntegrationRequest;
};

export default useIntegrationRequest;
