import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Form = React.forwardRef((props, ref) => {
	const {
		className,
		children,

		analyticsKey,
		onSubmit,

		mode,
		reValidateMode,
		defaultValues,
		values,
		resetOptions,
		criteriaMode,
		shouldFocusError,
		delayError,
		shouldUseNativeValidation,
		shouldUnregister,

		...form
	} = props;

	const methods = useForm({
		mode: mode,
		reValidateMode: reValidateMode,
		defaultValues: defaultValues,
		values: values,
		resetOptions: resetOptions,
		criteriaMode: criteriaMode,
		shouldFocusError: shouldFocusError,
		delayError: delayError,
		shouldUseNativeValidation: shouldUseNativeValidation,
		shouldUnregister: shouldUnregister,
	});

	const onAnalyticsSubmit = React.useCallback(
		(data) => {
			Analytics.event('form.submit', {
				form: data,
				className: className,
				analyticsKey: analyticsKey || className,
			});

			return onSubmit?.(data);
		},
		[onSubmit, className, analyticsKey],
	);

	const onAnalyticsError = React.useCallback(
		(errors) => {
			const hasError = Object.keys(errors ?? {}).length;

			if (!hasError) {
				return;
			}

			Analytics.event('form.error', {
				errors: errors,
				className: className,
				analyticsKey: analyticsKey || className,
			});
		},
		[analyticsKey, className],
	);

	const handleSubmit = React.useCallback(
		async (event) => {
			event.stopPropagation();

			const data = await methods.handleSubmit(
				onAnalyticsSubmit,
				onAnalyticsError,
			)(event);

			return data;
		},
		[methods, onAnalyticsError, onAnalyticsSubmit],
	);

	return (
		<FormProvider {...methods}>
			<form
				className={cn('asteria-component__form-v2', className)}
				onSubmit={handleSubmit}
				ref={ref}
				{...form}
			>
				{children}
			</form>
		</FormProvider>
	);
});

Form.displayName = 'Form';

Form.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,

	analyticsKey: PropTypes.string,
	onSubmit: PropTypes.func,

	/**
	 * https://react-hook-form.com/docs/useform#mode
	 *
	 * Validation strategy before submitting behaviour.
	 */
	mode: PropTypes.oneOf([
		'onChange',
		'onBlur',
		'onSubmit',
		'onTouched',
		'all',
	]),

	/**
	 * https://react-hook-form.com/docs/useform#reValidateMode
	 *
	 * Validation strategy after submitting behaviour.
	 */
	reValidateMode: PropTypes.oneOf(['onChange', 'onBlur', 'onSubmit']),

	/**
	 * https://react-hook-form.com/docs/useform#defaultValues
	 *
	 * Default values for the form.
	 */
	defaultValues: PropTypes.object,

	/**
	 * https://react-hook-form.com/docs/useform#values
	 *
	 * Reactive values to update the form values.
	 */
	values: PropTypes.object,

	/**
	 * https://react-hook-form.com/docs/useform#resetOptions
	 *
	 * Option to reset form state update while updating new form values.
	 */
	resetOptions: PropTypes.shape({
		/**
		 * An optional object to reset form values, and it's recommended to provide the entire defaultValues when supplied.
		 */
		values: PropTypes.object,
		/**
		 * All errors will remain. This will not guarantee with further user actions.
		 */
		keepErrors: PropTypes.bool,
		/**
		 * DirtyFields form state will remain, and isDirty will temporarily remain as the current state until further user's action.
		 */
		keepDirty: PropTypes.bool,
		/**
		 * DirtyFields and isDirty will remained, and only none dirty fields will be updated to the latest rest value. [Check out the example](https://codesandbox.io/s/react-keepdirtyvalues-o8to91).
		 *
		 */
		keepDirtyValues: PropTypes.bool,
		/**
		 * Form input values will be unchanged.
		 */
		keepValues: PropTypes.bool,
		/**
		 * Keep the same defaultValues which are initialized via useForm.
		 */
		keepDefaultValues: PropTypes.bool,
		/**
		 * isSubmitted state will be unchanged.
		 */
		keepIsSubmitted: PropTypes.bool,
		/**
		 * isTouched state will be unchanged.
		 */
		keepTouched: PropTypes.bool,
		/**
		 * isValid will temporarily persist as the current state until additional user actions.
		 */
		keepIsValid: PropTypes.bool,
		/**
		 * submitCount state will be unchanged.
		 */
		keepSubmitCount: PropTypes.bool,
	}),

	/**
	 * https://react-hook-form.com/docs/useform#criteriaMode
	 *
	 * Display all validation errors or one at a time.
	 */
	criteriaMode: PropTypes.oneOf(['firstError', 'all']),

	/**
	 * https://react-hook-form.com/docs/useform#shouldFocusError
	 *
	 * Enable or disable built-in focus management.
	 */
	shouldFocusError: PropTypes.bool,

	/**
	 * https://react-hook-form.com/docs/useform#delayError
	 *
	 * Delay error from appearing instantly.
	 */
	delayError: PropTypes.number,

	/**
	 * https://react-hook-form.com/docs/useform#shouldUseNativeValidation
	 *
	 * Use browser built-in form constraint API.
	 */
	shouldUseNativeValidation: PropTypes.bool,

	/**
	 * https://react-hook-form.com/docs/useform#shouldUnregister
	 *
	 * Enable and disable input unregister after unmount.
	 */
	shouldUnregister: PropTypes.bool,
};

export default React.memo(Form);
