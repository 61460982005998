import React from 'react';

import PropTypes from 'prop-types';

import IntegrationSelect from '@asteria/component-integrations-v2/flows/Select';

import { cn } from '@asteria/utils-funcs/classes';

import Context from './context';

import './styles.scss';

const OnboardingSelect = React.memo((props) => {
	const { className, type } = props;

	const { onClose } = React.useContext(Context);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__select',
				className,
			)}
		>
			<div className="asteria-component__linear-onboarding__content">
				<IntegrationSelect type={type} onClose={onClose} />
			</div>
		</div>
	);
});

OnboardingSelect.displayName = 'OnboardingSelect';

OnboardingSelect.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
};

export default OnboardingSelect;
