import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import Spinner from '@asteria/component-core/spinner';
import { Text } from '@asteria/component-core/typography';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function getSelectedInvoices(state) {
	return InvoiceStore.selectors
		.selected(state)
		.filter((object) =>
			(object?.services ?? []).some(
				(service) => service?.status === 'DISCARD',
			),
		);
}

const RemoveButton = React.memo((props) => {
	const { className, onSubmit } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const hasActiveFilters = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'SERVICE:STATUS',
				value: 'DISCARD',
			}).length,
	);
	const selected = useSelector(getSelectedInvoices, (a, b) => isEqual(a, b));

	const [loading, setLoading] = React.useState(false);

	const onRemove = React.useCallback(async () => {
		const selected = getSelectedInvoices(store.getState()).map(
			(object) => object?._id ?? object?.id,
		);

		setLoading(true);

		try {
			// await new Promise((resolve) => setTimeout(resolve, 5_000));
			await onSubmit?.('invoice:remove', selected);
		} catch (err) {
			//
		}

		const response = await onSubmit?.('invoices:available', {
			pageFilters: { first: 1 },
			filters: { services: { status: 'DISCARD' } },
			fields: `_id`,
		});

		if (!response?.pageFilters?.count) {
			dispatch(
				InvoiceStore.filter({
					type: 'SERVICE:STATUS',
					value: 'DISCARD',
				}),
			);
		}

		setLoading(false);
	}, [dispatch, onSubmit, store]);

	if (!(selected.length && hasActiveFilters)) {
		return null;
	}

	return (
		<Group
			className={cn(
				'asteria-component__invoice-table-button',
				'asteria--variant-remove',
				{ 'asteria--state-loading': loading },
				className,
			)}
			onClick={onRemove}
		>
			{loading ? <Spinner empty /> : <Icon icon="bin" />}
			<Text>
				{TranslationService.get(
					['freja.invoice-table.remove-button.label'],
					undefined,
					{ selected: selected },
				)}
			</Text>
		</Group>
	);
});

RemoveButton.displayName = 'RemoveButton';

RemoveButton.propTypes = {
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

export default RemoveButton;
