import React from 'react';

import PropTypes from 'prop-types';

import LF from './lf/sidebar';
import Swedbank from './swedbank/sidebar';

const Sidebar = (props) => {
	const { partner, fullscreen, theme, navigation } = props;

	if (theme === 'lf') {
		return (
			<LF
				partner={partner}
				fullscreen={fullscreen}
				navigation={navigation}
			/>
		);
	}

	if (theme === 'Swedbank') {
		return <Swedbank navigation={navigation} />;
	}

	return null;
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
	className: PropTypes.string,
	partner: PropTypes.object,
	fullscreen: PropTypes.shape({
		state: PropTypes.bool,
		onChange: PropTypes.func,
	}),
	theme: PropTypes.string,
	navigation: PropTypes.shape({
		current: PropTypes.string,
		navigate: PropTypes.func,
		open: PropTypes.func,
	}),
};

export default Sidebar;
