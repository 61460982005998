import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip/chip';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const ChipClient = (props) => {
	const { value } = props;

	const client = useSelector(
		(store) => AppStore.selectors.client(store, value),
		(a, b) => isEqual(a, b),
	);

	const dispatch = useDispatch();

	const onDismiss = React.useCallback(() => {
		dispatch(
			InvoiceStore.filter({
				type: 'CLIENT',
				value: value,
			}),
		);
	}, [dispatch, value]);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				['invoices.search.quick.client.label'],
				undefined,
				client,
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
};

ChipClient.displayName = 'ChipClient';
ChipClient.propTypes = { value: PropTypes.string };

const ChipClientWrapper = () => {
	const clients = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CLIENT' }),
		(a, b) => isEqual(a, b),
	);

	return clients.map((object, index) => (
		<ChipClient key={index} value={object?.value} />
	));
};

ChipClientWrapper.displayName = 'ChipClientWrapper';
ChipClientWrapper.propTypes = {};

export default ChipClient;
export { ChipClientWrapper };
