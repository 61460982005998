import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Datepicker from '@asteria/component-form/datepicker';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { cn } from '@asteria/utils-funcs/classes';

import Cell from './Cell';

import './styles.scss';

const DateSelector = React.memo((props) => {
	const { className } = props;

	const dispatch = useDispatch();

	const onChange = React.useCallback(
		({ value }) => {
			dispatch(
				InvoiceStore.filter([
					{ type: 'DATE:SENT', value: null },
					{ type: 'DATE:SENT', value: value },
				]),
			);
		},
		[dispatch],
	);

	return (
		<Cell className="asteria--type-date">
			<div
				className={cn(
					'asteria-component__invoice-search__date',
					className,
				)}
			>
				<Datepicker
					name="date"
					variant="range"
					onChange={onChange}
					skipVisibleValue
				/>
			</div>
		</Cell>
	);
});

DateSelector.displayName = 'DateSelector';

DateSelector.propTypes = { className: PropTypes.string };

export default DateSelector;
