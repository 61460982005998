import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Wrapper = (props) => {
	const { className } = props;

	return (
		<div className={cn('asteria-component__form-v2__wrapper', className)}>
			Hello
		</div>
	);
};

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = { className: PropTypes.string };

export default Wrapper;
