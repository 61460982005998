import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

const NavigationActions = (props) => {
	const { onAction } = props;

	const integrations = useSelector((store) => store?.integrations?.items);

	const hasErp = useMemo(() => {
		return !!Object.values(integrations || {})?.find(
			({ type }) => type === 'erp',
		);
	}, [integrations]);

	if (hasErp) {
		return null;
	}

	return (
		<FeatureFlag feature="header-action-erp">
			<Button
				variant="secondary"
				size="sm"
				className="asteria-component__button--add-erp"
				tooltipClassName="asteria-component__tooltip__add-erp"
				tooltip={TranslationService.get(
					'header.action.add.erp.tooltip',
					// eslint-disable-next-line spellcheck/spell-checker
					'Koppla på ditt bokföringssystem för att se dina fakturor, mer historik och på så sätt få en bättre överblick för framtiden med Företagskollen.',
				)}
				label={TranslationService.get(
					'header.action.add.erp',
					// eslint-disable-next-line spellcheck/spell-checker
					'Lägg till bokföringssystem',
				)}
				onClick={() => onAction?.('go', '/integrations/add/erp')}
			/>
		</FeatureFlag>
	);
};

NavigationActions.propTypes = {
	onAction: PropTypes.func,
};

export default NavigationActions;
