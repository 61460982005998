import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

export function useInvoice({ id, onSubmit }) {
	const countries = useSelector(
		(store) => store?.invoices?.country?.candidates,
		(a, b) => isEqual(a, b),
	);

	const { data } = useQuery({
		queryKey: ['invoices', id],
		queryFn: async () =>
			onSubmit?.('invoices:details', { id, changes: true, countries }),

		placeholderData: null,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!id,
	});

	return data;
}
