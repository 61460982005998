import React from 'react';

import { useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useSubscription } from '@asteria/utils-websocket/hooks';

function useInvoiceUpdate({ accessToken, onFetch }) {
	const timestampRef = React.useRef(new Date().toISOString());

	const [{ refetch: refetchInvoices }, { refetch: refetchClients }] =
		useQueries({
			queries: [
				{
					queryKey: ['updater:invoices'],
					queryFn: async () => {
						await onFetch?.('invoices:refresh', {
							timestamp: timestampRef.current,
						});

						timestampRef.current = new Date().toISOString();

						return timestampRef.current;
					},

					refetchOnMount: false,
					refetchOnReconnect: false,
					refetchOnWindowFocus: false,

					refetchInterval: 30_000,
				},
				{
					queryKey: ['updater:clients'],
					queryFn: async () => {
						await onFetch?.('clients:refresh', {
							timestamp: timestampRef.current,
						});

						timestampRef.current = new Date().toISOString();

						return timestampRef.current;
					},

					refetchOnMount: false,
					refetchOnReconnect: false,
					refetchOnWindowFocus: false,

					refetchInterval: 30_000,
				},
			],
		});

	const refresh = React.useCallback(
		() => Promise.allSettled([refetchInvoices(), refetchClients()]),
		[refetchClients, refetchInvoices],
	);

	useSubscription({
		query: `
			subscription InvoiceUpdated($filters: [InvoiceFiltersInput!]) {
				invoiceUpdated(filters: $filters) {
					_id
				}
			}
		`,
		token: accessToken,
		onNext: React.useMemo(
			() => AsteriaCore.utils.throttle(refresh, 10_000),
			[refresh],
		),
	});
}

function useInvoiceLayoutUpdate({ accessToken, onFetch }) {
	const [{ refetch }] = useQueries({
		queries: [
			{
				queryKey: ['updater:invoice-layouts'],
				queryFn: async () =>
					onFetch?.('invoice-layout:list', {
						filters: { status: ['PENDING', 'LOADING'] },
					}),

				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				refetchInterval: 30_000,
			},
		],
	});

	useSubscription({
		query: `
			subscription InvoiceLayoutUpdated(
				$filters: [InvoiceLayoutFiltersInput!]
			) {
				invoiceLayoutUpdated(filters: $filters) {
					_id
				}
			}
		`,
		token: accessToken,
		onNext: React.useMemo(
			() => AsteriaCore.utils.throttle(refetch, 5_000),
			[refetch],
		),
	});
}

function useBatchesUpdate({ accessToken, onFetch }) {
	const [{ refetch }] = useQueries({
		queries: [
			{
				queryKey: ['updater:batches'],
				queryFn: async () => onFetch?.('batches:list'),

				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				refetchInterval: 30_000,
			},
		],
	});

	useSubscription({
		query: `
			subscription InvoiceServiceUpdated(
				$filters: [InvoiceServiceFiltersInput!]
			) {
				invoiceServiceUpdated(filters: $filters) {
					_id
				}
			}
		`,
		token: accessToken,
		onNext: React.useMemo(
			() => AsteriaCore.utils.throttle(refetch, 5_000),
			[refetch],
		),
	});
}

function useIntegrationUpdate({ accessToken, onFetch }) {
	const [{ refetch }] = useQueries({
		queries: [
			{
				queryKey: ['updater:integrations'],
				queryFn: async () => onFetch?.('integrations:list'),

				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				refetchInterval: 30_000,
			},
		],
	});

	useSubscription({
		query: `
			subscription integrationUpdated($filters: [IntegrationFilters!]) {
				integrationUpdated(filters: $filters) {
					_id
				}
			}
		`,
		token: accessToken,
		onNext: React.useMemo(
			() => AsteriaCore.utils.throttle(refetch, 5_000),
			[refetch],
		),
	});
}

const Updater = React.memo((props) => {
	const { onFetch } = props;

	const accessToken = useSelector(AppStore.selectors.accessToken);

	useInvoiceUpdate({ accessToken: accessToken, onFetch: onFetch });
	useInvoiceLayoutUpdate({ accessToken: accessToken, onFetch: onFetch });
	useBatchesUpdate({ accessToken: accessToken, onFetch: onFetch });
	useIntegrationUpdate({ accessToken: accessToken, onFetch: onFetch });

	return null;
});

Updater.displayName = 'Updater';
Updater.propTypes = { onFetch: PropTypes.func };

export default Updater;
