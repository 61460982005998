import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import LayoutContext from '../../../layout/context';

import './styles.scss';

const IntroductionFeedback = () => {
	const { onAction } = React.useContext(LayoutContext);

	const hasBatches = useSelector(
		(store) => !!InvoiceStore.selectors.batches(store).length,
	);

	const [isFeedbackSent, setFeedbackState] = React.useState(false);

	const handleHideFeedback = React.useCallback(() => {
		setFeedbackState(true);
	}, []);

	const onSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	if (isFeedbackSent || !hasBatches) {
		return null;
	}

	return (
		<Feedback
			className="asteria-view__introduction__feedback"
			onAction={onAction}
			feedbackKey="introduction-feedback"
			description={TranslationService.get(
				'introduction.feedback.description',
			)}
			minText={TranslationService.get('introduction.feedback.min')}
			maxText={TranslationService.get('introduction.feedback.max')}
			values={[1, 2, 3, 4, 5]}
			size="lg"
			validate
			type="introduction"
			onSupportRequest={onSupportRequest}
			onEnd={handleHideFeedback}
			nodismiss
		/>
	);
};

IntroductionFeedback.displayName = 'IntroductionFeedback';

IntroductionFeedback.propTypes = { className: PropTypes.string };

export default IntroductionFeedback;
