import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { isPossibleToClick } from '@asteria/component-core/utils';

import Checkbox from '@asteria/component-form/checkbox';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

import Cell from './Cell';
import { useClients } from './hooks';

import './styles.scss';

const Client = React.memo((props) => {
	const { object, onChange } = props;

	const ref = React.useRef(null);
	const dispatch = useDispatch();

	const selected = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, {
				type: 'CLIENT',
				value: object?._id,
			}).length,
	);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onChange?.({ value: object?._id ?? object.id });
		},
		[object?._id, object.id, onChange],
	);

	const onClientDetailsClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientOverview,
				data: { _id: object?._id ?? object?.id },
			}),
		);
	}, [dispatch, object?._id, object?.id]);

	return (
		<DropdownItem
			onClick={handleClick}
			prefix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Checkbox
						uncontrolled
						checked={selected}
						onChange={handleClick}
					/>
				</Group>
			}
			postfix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Button
						icon="chevron-right"
						size="sm"
						onClick={onClientDetailsClick}
					/>
				</Group>
			}
			ref={ref}
		>
			{object?.name}
		</DropdownItem>
	);
});

Client.displayName = 'Client';
Client.propTypes = { object: PropTypes.object, onChange: PropTypes.func };

const Clients = React.memo(() => {
	const dispatch = useDispatch();

	const clients = useClients();

	const filters = useSelector(
		(store) => InvoiceStore.selectors.filters(store, { type: 'CLIENT' }),
		(a, b) => isEqual(a, b),
	);

	const handleChange = React.useCallback(
		(event) => {
			dispatch(
				InvoiceStore.filter({ type: 'CLIENT', value: event.value }),
			);
		},
		[dispatch],
	);

	const openClientsModal = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: 'CLIENT_LIST',
				skipValidation: true,
			}),
		);
	}, [dispatch]);

	const ToggleProps = React.useMemo(
		() => ({
			variant: 'select',
			label: TranslationService.get(
				[
					'invoices.search.clients.placeholder',
					filters.length
						? 'invoices.search.clients.value.placeholder'
						: null,
				],
				undefined,
				{ filters: filters },
			),
			icon: 'chevron-down',
			iconPosition: 'last',
			className: 'asteria-component__invoice-search__clients',
		}),
		[filters],
	);

	if (clients.length < 2) {
		return null;
	}

	return (
		<Cell className="asteria--type-clients">
			<Dropdown
				toggle={ToggleProps}
				title={TranslationService.get([
					'invoices.search.clients.placeholder',
				])}
				className="asteria-component__invoice-search__clients-dropdown"
				offset={-1}
				scroll
			>
				{clients.map((object) => (
					<Client
						key={object?._id ?? object?.id}
						object={object}
						onChange={handleChange}
					/>
				))}
				<DropdownItem
					className="asteria--variant-link"
					onClick={openClientsModal}
				>
					<Button
						variant="link"
						label={TranslationService.get(
							'dropdown.clients.item.link.label',
						)}
					/>
				</DropdownItem>
			</Dropdown>
		</Cell>
	);
});

Clients.displayName = 'ClientSelect';
Clients.propTypes = {};

export default Clients;
