/* eslint-disable spellcheck/spell-checker */
import React, { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import Typography, { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';
import Stepper from '@asteria/component-stepper';
import Steps, { Step } from '@asteria/component-steps';
import WelcomeForm from '@asteria/component-welcomeform';

import { setNavigationRoute } from '@asteria/datalayer/stores/navigate';

import { TranslationService } from '@asteria/language';

import './index.scss';

const StepperForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const dispatch = useDispatch();
	let steps = [
		'Anslut bokforingssystem',
		'ForetagsInformation',
		'Huvudman/Agarstruktur',
		'PEP',
		'KYC-steg X, Y, Z',
		'Ladda upp dokument',
		'Bekrafta',
	];
	return (
		<div className="asteria-component__form__container">
			<Steps
				label="Text"
				onEnd={useCallback(
					() =>
						dispatch(
							setNavigationRoute(
								'/welcomeForm/notification/success',
							),
						),
					[],
				)}
				onStepChange={(step) =>
					step === 2 ? setActiveStep(6) : setActiveStep(step)
				}
				nextBtnVariant="secondary"
				nextBtnLabel="action.move on"
				doneBtnLabel="action.continue"
				backBtnLabel="action.abort"
				hideBackBtnOnLast={steps.length - 1 === activeStep}
				dividerBeforeFooter={activeStep === 1}
			>
				<Step>
					<Typography>Step 1</Typography>
				</Step>
				<Step>
					<WelcomeForm />
				</Step>
				<Step>
					<Alert
						level="success"
						type="feedback"
						icon="check"
						iconPosition="first"
						title={TranslationService.get(
							'welcomeForm.step.7.alert.title',
						)}
					>
						<Text className="asteria-component__main-text">
							{TranslationService.get(
								'welcomeForm.step.7.alert.maintext',
							)}
						</Text>
						<Text
							type="text"
							size="base"
							className="asteria-component__sub-text"
						>
							{TranslationService.get(
								'welcomeForm.step.7.alert.subtext',
							)}
						</Text>
					</Alert>
				</Step>
			</Steps>
			<div className="asteria-component__stepper-container">
				<Stepper steps={steps} activeStep={activeStep + 1} />
			</div>
		</div>
	);
};
export default StepperForm;
