import React from 'react';

import PropTypes from 'prop-types';

import { TableCell } from '@asteria/component-core/table';
import Tooltip from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const Cell = React.memo((props) => {
	const { type, object, children, tooltip, loading } = props;

	return (
		<TableCell className={`asteria--cell-${type}`}>
			{loading ? (
				<span className="w-full h-2 bg-black/10 animate-pulse rounded-full" />
			) : (
				children ?? (
					<Tooltip
						variant="alt"
						label={
							tooltip
								? TranslationService.get(
										[
											`invoices.table.row.cell.${type}`,
											`invoices.table.row.cell.${type}.tooltip`,
										],
										undefined,
										object,
								  )
								: null
						}
					>
						<Text size="sm">
							{TranslationService.get(
								[`invoices.table.row.cell.${type}`],
								undefined,
								object,
							)}
						</Text>
					</Tooltip>
				)
			)}
		</TableCell>
	);
});

Cell.displayName = 'Cell';
Cell.propTypes = {
	type: PropTypes.string,
	object: PropTypes.object,
	tooltip: PropTypes.bool,
	children: PropTypes.node,
	loading: PropTypes.bool,
};

export default Cell;
