import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import {
	IntegrationSuccessFeedback,
	useAnalytics,
} from '@asteria/component-integrations-v2/flows/Success';
import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Help from './Help';
import Context from './context';
import { usePlaceholder } from './hooks';

import './styles.scss';

const OnboardingSuccess = React.memo((props) => {
	const { className, type: $type } = props;

	const _id = useSelector(IntegrationStore.selectors.navigation._id);

	const { onAction } = React.useContext(Context);

	const location = useLocation();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, _id),
		(a, b) => isEqual(a, b),
	);

	const hasIntegrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
			}).length > 1,
	);

	const analyticsFlow = useAnalytics(integration);

	const type = integration?.type ?? $type;
	const key = integration?.key;

	const Placeholder = usePlaceholder(type, key, 'success');

	const flow = useFlow(type, key);
	const content = flow?.steps?.success?.content;
	const alertContent = flow?.steps?.success?.alertContent;

	const handleClose = React.useCallback(() => {
		Analytics.endFlow(analyticsFlow);

		if (hasIntegrations) {
			const from = location?.state?.from;

			if (from && !from.includes('/integrations')) {
				return onAction?.('go', from);
			}

			return onAction?.('go', '/integrations');
		}

		return onAction?.('go', '/');
	}, [analyticsFlow, hasIntegrations, location?.state?.from, onAction]);

	return (
		<div
			className={cn(
				'asteria-component__linear-onboarding',
				'asteria-component__linear-onboarding__success',
				{ 'asteria--variant-split': Placeholder },
				className,
			)}
		>
			<div className="asteria-component__linear-onboarding__content">
				<Wrapper scroll>
					<Header>
						{TranslationService.get(
							[
								`integrations.add.title`,
								`integrations.${type}.add.title`,
								`integrations.${key}.add.title`,
								`integrations.${type}.${key}.add.title`,
								`integrations.success.title`,
								`integrations.${type}.success.title`,
								`integrations.${key}.success.title`,
								`integrations.${type}.${key}.success.title`,
							],
							undefined,
							{ integration: integration },
						)}
					</Header>
					<Content scroll>
						<Alert
							level="success"
							icon="check"
							title={TranslationService.get(
								[
									`integrations.success.title`,
									`integrations.${type}.success.title`,
									`integrations.${type}.${key}.success.title`,
								],
								undefined,
								{ integration: integration },
							)}
						>
							<Text size="lg">
								{TranslationService.get(
									[
										`integrations.success.content`,
										`integrations.${type}.success.content`,
										`integrations.${type}.${key}.success.content`,
									],
									undefined,
									{ integration: integration },
								)}
							</Text>
							<Contenter content={alertContent} />
						</Alert>
						<Contenter content={content} />
						<IntegrationSuccessFeedback
							type={type}
							onAction={onAction}
						/>
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								analyticsKey={`integrations.${key}.done`}
								variant="primary"
								label={TranslationService.get([
									'button.done',
									'action.done',
									'integrations.success.action.done',
								])}
								onClick={handleClose}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
				<Help />
			</div>
			{Placeholder}
		</div>
	);
});

OnboardingSuccess.displayName = 'OnboardingSuccess';

OnboardingSuccess.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
};

export default OnboardingSuccess;
