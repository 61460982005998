import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { IntegrationSuccessFeedback } from '@asteria/component-integrations-v2/flows/Success';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const SuccessStep = (props) => {
	const { onAction } = React.useContext(LayoutContext);

	const location = useLocation();

	const type = 'erp';
	const key = 'printer';

	const integrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { type: 'erp' }),
		(a, b) => isEqual(a, b),
	);

	const onDone = React.useCallback(() => {
		if (integrations?.length > 1) {
			const from = location?.state?.from;

			if (from && !from.includes('/integrations')) {
				return onAction?.('go', from);
			}

			return onAction?.('go', '/integrations');
		}

		return onAction?.('go', '/');
	}, [integrations?.length, location?.state?.from, onAction]);

	return (
		<BaseStep {...props} step="success" onDone={onDone}>
			<IntegrationSuccessFeedback
				type={type}
				name={key}
				onAction={onAction}
			/>
		</BaseStep>
	);
};

SuccessStep.displayName = 'SuccessStep';

SuccessStep.propTypes = {
	className: PropTypes.string,
};

export default SuccessStep;
