import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Help from '../../../components/Onboarding/Connection/Help';
import Context from '../../../components/Onboarding/Connection/context';
import { usePlaceholder } from '../../../components/Onboarding/Connection/hooks';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const BaseStep = (props) => {
	const {
		className,
		step,
		onAbort,
		onBack,
		onNext,
		onDone,
		onAction: $onAction,
		children,
		loading,
		data,
	} = props;

	const { onAction } = React.useContext(LayoutContext);

	const type = 'erp';
	const key = 'visma.administration';

	const flow = useFlow(type, key);
	const Placeholder = usePlaceholder(type, key, step);

	const handleAction = React.useCallback(
		(action, data) => {
			if ($onAction) {
				return $onAction?.(action, data);
			}

			return onAction?.(action, data);
		},
		[$onAction, onAction],
	);

	const contenterActions = React.useMemo(
		() => ({
			onAbort: onAbort,
			onBack: onBack,
			onNext: onNext,
			onAction: handleAction,
		}),
		[handleAction, onAbort, onBack, onNext],
	);

	const ctx = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	return (
		<Context.Provider value={ctx}>
			<div
				className={cn(
					'asteria-component__linear-onboarding',
					{ [`asteria-component__linear-onboarding__${step}`]: step },
					'asteria-component__linear-onboarding-custom',
					{
						[`asteria-component__linear-onboarding-custom__${step}`]:
							step,
					},
					{ 'asteria--variant-split': Placeholder },
					className,
				)}
			>
				<div className="asteria-component__linear-onboarding__content">
					<Wrapper scroll>
						<Header>
							{TranslationService.get([
								`integrations.add.title`,
								`integrations.${type}.add.title`,
								`integrations.${key}.add.title`,
								`integrations.${type}.${key}.add.title`,
								`integrations.${step}.title`,
								`integrations.${type}.${step}.title`,
								`integrations.${key}.${step}.title`,
								`integrations.${type}.${key}.${step}.title`,
							])}
						</Header>
						<Content scroll>
							<div className="asteria-component__onboarding-step__content">
								<div className="asteria-component__onboarding-step__content__inner">
									<Contenter
										content={flow?.steps?.[step]?.content}
										actions={contenterActions}
										data={data}
									/>
									{children}
								</div>
							</div>
						</Content>
						<Footer>
							{onBack || onAbort ? (
								<FooterSection>
									{onBack ? (
										<Button
											analyticsKey={`integrations.${key}.back`}
											variant="tertiary"
											label={TranslationService.get([
												'button.back',
												'action.back',
												`integrations.add.action.back`,
												`integrations.${type}.add.action.back`,
												`integrations.${key}.add.action.back`,
												`integrations.${type}.${key}.add.action.back`,
												`integrations.${step}.action.back`,
												`integrations.${type}.${step}.action.back`,
												`integrations.${key}.${step}.action.back`,
												`integrations.${type}.${key}.${step}.action.back`,
											])}
											onClick={onBack}
										/>
									) : (
										<Button
											analyticsKey={`integrations.${key}.abort`}
											variant="tertiary"
											label={TranslationService.get([
												'button.abort',
												'action.abort',
												`integrations.add.action.abort`,
												`integrations.${type}.add.action.abort`,
												`integrations.${key}.add.action.abort`,
												`integrations.${type}.${key}.add.action.abort`,
												`integrations.${step}.action.abort`,
												`integrations.${type}.${step}.action.abort`,
												`integrations.${key}.${step}.action.abort`,
												`integrations.${type}.${key}.${step}.action.abort`,
											])}
											onClick={onAbort}
										/>
									)}
								</FooterSection>
							) : null}
							{onNext || onDone ? (
								<FooterSection position="last">
									{onDone ? (
										<Button
											analyticsKey={`integrations.${key}.done`}
											variant="primary"
											label={TranslationService.get([
												'button.done',
												'action.done',
												`integrations.add.action.done`,
												`integrations.${type}.add.action.done`,
												`integrations.${key}.add.action.done`,
												`integrations.${type}.${key}.add.action.done`,
												`integrations.${step}.action.done`,
												`integrations.${type}.${step}.action.done`,
												`integrations.${key}.${step}.action.done`,
												`integrations.${type}.${key}.${step}.action.done`,
											])}
											onClick={onDone}
											loading={loading}
											disabled={loading}
										/>
									) : (
										<Button
											analyticsKey={`integrations.${key}.continue`}
											variant="primary"
											label={TranslationService.get([
												'button.continue',
												'action.continue',
												`integrations.add.action.continue`,
												`integrations.${type}.add.action.continue`,
												`integrations.${key}.add.action.continue`,
												`integrations.${type}.${key}.add.action.continue`,
												`integrations.${step}.action.continue`,
												`integrations.${type}.${step}.action.continue`,
												`integrations.${key}.${step}.action.continue`,
												`integrations.${type}.${key}.${step}.action.continue`,
											])}
											onClick={onNext}
											loading={loading}
											disabled={loading}
										/>
									)}
								</FooterSection>
							) : null}
						</Footer>
					</Wrapper>
					<Help />
				</div>
				{Placeholder}
			</div>
		</Context.Provider>
	);
};

BaseStep.displayName = 'BaseStep';

BaseStep.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	onAbort: PropTypes.func,
	onBack: PropTypes.func,
	onNext: PropTypes.func,
	onDone: PropTypes.func,
	onAction: PropTypes.func,

	loading: PropTypes.bool,

	children: PropTypes.node,
	data: PropTypes.any,
};

export default BaseStep;
