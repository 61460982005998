import React from 'react';

import { useDispatch, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import { PartnerService } from '@asteria/backend-utils-services';

import ModalCollection from '@asteria/component-modal-collection';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { cn } from '@asteria/utils-funcs/classes';

import DataLayer from './components/datalayer';
import Features from './components/features';
import Theme from './components/theme';
import { AuthContext } from './context';
import useModalHandlers from './hooks/useModalHandlers';
import updateFeedback from './logic/funcs/updateFeedback';
import IntegrationDialog from './logic/integrations';

const Wrapper = (props) => {
	const { callback, history, showListing, onClose, showProgress } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const [path, setPath] = React.useState('/');

	const { accessToken } = React.useContext(AuthContext);

	React.useEffect(() => {
		let listenHistory = false;
		if (history) {
			listenHistory = history.listen(
				({ location: { pathname } = {} }) => {
					setPath(pathname);
				},
			);

			const {
				location: { pathname },
			} = history;

			setPath(pathname);
		}

		return () => {
			if (listenHistory) {
				listenHistory();
			}
		};
	}, [history]);

	React.useEffect(() => {
		dispatch(ModalStore.close());

		if (path === '/integrations') {
			if (showListing) {
				dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationList,
					}),
				);
			}
		}

		if (path === '/integrations/add') {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
				}),
			);
		}

		if (path === '/integrations/add/erp') {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: 'erp' },
				}),
			);
		}

		if (path === '/integrations/add/bank') {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: 'bank' },
				}),
			);
		}
	}, [dispatch, path, showListing]);

	const onAction = React.useCallback(
		(action, data) => {
			if (action === 'updateFeedback') {
				const state = store.getState();

				updateFeedback({
					accessToken: accessToken,
					id: state?.app?.user?.id,
					feedback: { [data.feedbackKey]: data },
					dispatch: dispatch,
				});
			}
		},
		[accessToken, dispatch, store],
	);

	const {
		onAction: handleModalAction,
		onClose: handleModalClose,
		onSubmit: handleModalSubmit,
	} = useModalHandlers({
		onClose: onClose,
		onAction: onAction,
		callback: callback,
	});

	return (
		<div className={cn('asteria-wrapper')}>
			{showProgress ? <IntegrationDialog /> : null}
			<ModalCollection
				onAction={handleModalAction}
				onClose={handleModalClose}
				onSubmit={handleModalSubmit}
			/>
		</div>
	);
};

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = {
	callback: PropTypes.func,
	onClose: PropTypes.func,

	history: PropTypes.any,

	showListing: PropTypes.bool,
	showProgress: PropTypes.bool,
};

const Widget = (props) => {
	const [partner, setPartner] = React.useState(null);

	const {
		accessToken,
		partnerId,
		language,
		history,
		callback,
		loader = true,
		onClose = () => {},
		showListing = true,
		showProgress = true,
	} = props;

	React.useEffect(() => {
		const fetch = async () => {
			const response = await PartnerService.partner.fetchOne(
				{ id: partnerId, fields: 'name settings { themeId }' },
				{ token: accessToken },
			);

			setPartner(response);
		};

		fetch();
	}, [accessToken, partnerId]);

	const ctx = React.useMemo(
		() => ({
			accessToken: accessToken,
			partnerId: partnerId,
			themeId: partner?.settings?.themeId,
			language: language,
		}),
		[accessToken, language, partner?.settings?.themeId, partnerId],
	);

	if (!partner) {
		return null;
	}

	return (
		<AuthContext.Provider value={ctx}>
			<DataLayer loader={loader}>
				<Theme themeId={partner?.settings?.themeId}>
					<Features>
						<Wrapper
							history={history}
							callback={callback}
							onClose={onClose}
							showListing={showListing}
							showProgress={showProgress}
						/>
					</Features>
				</Theme>
			</DataLayer>
		</AuthContext.Provider>
	);
};

Widget.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	language: PropTypes.string,
	history: PropTypes.any,
	callback: PropTypes.func,
	loader: PropTypes.bool,
	onClose: PropTypes.func,
	showListing: PropTypes.bool,
	showProgress: PropTypes.bool,
};

export default Widget;
