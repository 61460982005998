import React from 'react';

import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function Country(props) {
	const { onAction, id, active, content } = props;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<div
			className={cn(
				'asteria-page__support-country',
				{ [`asteria--country-${id}`]: id },
				stateClasses({ active: active }),
			)}
		>
			<Contenter content={content} actions={actions} />
		</div>
	);
}

Country.displayName = 'Country';
Country.propTypes = {
	onAction: PropTypes.func,
	id: PropTypes.string,
	active: PropTypes.bool,
	content: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(Country);
