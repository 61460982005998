import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const ActionSplash = (props) => {
	const { onClose, className, onAction, action, text } = props;

	const closeDialog = useCallback(() => {
		onClose?.();
	}, [onClose]);

	const onClick = useCallback(() => {
		closeDialog?.();
		if (action?.action !== 'close') {
			onAction?.(action?.action, action?.data);
		}
	}, [closeDialog, onAction, action]);

	return (
		<Modal
			className={cn('asteria-component__modal__splash', className)}
			onEnd={closeDialog}
			placement="bottom"
			size="sm"
		>
			<Wrapper>
				<Header onClose={closeDialog}>
					<Title type="title" size="lg">
						{TranslationService.get([`${text}.title`])}
					</Title>
				</Header>
				<Content>
					<div className="asteria-component__splash">
						<Wrapper>
							{TranslationService.hasKey(`${text}.title2`) && (
								<Header>
									<Title type="title" size="base">
										{TranslationService.get([
											`${text}.title2`,
										])}
									</Title>
								</Header>
							)}
							<Content>
								{TranslationService.hasKey(
									`${text}.text.intro`,
								) && (
									<Text>
										{TranslationService.get([
											`${text}.text.intro`,
										])}
									</Text>
								)}
								<Text>
									{TranslationService.get([`${text}.text`])}
								</Text>
							</Content>
							{action?.action && (
								<Footer>
									<Button
										variant="secondary"
										size="sm"
										label={TranslationService.get([
											`${text}.button`,
										])}
										onClick={onClick}
									/>
								</Footer>
							)}
						</Wrapper>
					</div>
				</Content>
			</Wrapper>
		</Modal>
	);
};

ActionSplash.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.open,
	onAction: PropTypes.func,
	action: PropTypes.any,
	text: PropTypes.string,
};

export default ActionSplash;
