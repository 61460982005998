import React, { useCallback, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import useContenter from '@asteria/component-core/utils/useContenter';
import { FooterSection } from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';
import Feedback from '@asteria/component-feedback';
import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../../context';
import Step, { Content, Footer, Header } from '../../../step';

import './success.scss';

const GenericSuccess = function GenericSuccess(props) {
	const { className, integration, onAction, onClose } = props;
	const { navigation } = useContext(NavigationContext);
	const [showFeedback, setShowFeedback] = useState(true);

	const content = useContenter(`integrations.${integration.key}.success`);
	const step = `${integration.type}.oauth.info`;

	const onFeedbackAction = useCallback(
		(action, data) => {
			if (data.dismissed) {
				setShowFeedback(false);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const onFeedbackSupportRequest = useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	return (
		<Step
			className={cn(
				'asteria-component__integration-generic__success',
				{
					[`asteria-component__integration-generic__success--type-${integration.type}`]:
						integration.type,
				},
				className,
			)}
			step={step}
		>
			<Header onClose={onClose}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration.type}.add.title`,
					],
					undefined,
					{ integration: integration },
				)}
			</Header>
			<Content step={step}>
				<CompleteScreen
					level="success"
					icon="check"
					iconPosition="first"
					title={TranslationService.get(
						[
							`integrations.success.title`,
							`integrations.${integration.type}.success.title`,
							`integrations.${integration.type}.${integration.key}.success.title`,
						],
						undefined,
						{ integration: integration },
					)}
				>
					<Title type="title" size="xs" align="center">
						{TranslationService.get(
							[
								`integrations.success.subtitle`,
								`integrations.${integration.type}.success.subtitle`,
								`integrations.${integration.type}.${integration.key}.success.subtitle`,
							],
							undefined,
							{ integration: integration },
						)}
					</Title>
					<Text
						type="text"
						weight="bold"
						className="asteria-component__alert--highlight"
						align="center"
					>
						{TranslationService.get(
							[
								`integrations.success.integration`,
								`integrations.${integration.type}.success.integration`,
								`integrations.${integration.type}.${integration.key}.success.integration`,
							],
							'{{ integration.name | cap }}',
							{ integration: integration },
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								`integrations.success.content`,
								`integrations.${integration.type}.success.content`,
								`integrations.${integration.type}.${integration.key}.success.content`,
							],
							undefined,
							{ integration: integration },
						)}
					</Text>
					<Contenter content={content} />
				</CompleteScreen>
				<FeatureFlag feature="feedback">
					{showFeedback ? (
						<Feedback
							description={TranslationService.get(
								'feedback.text',
							)}
							feedbackKey={`add-${integration.type}`}
							maxText={TranslationService.get(
								'feedback.rating.max',
							)}
							minText={TranslationService.get(
								'feedback.rating.min',
							)}
							values={[1, 2, 3, 4, 5]}
							size="lg"
							title=""
							onAction={onFeedbackAction}
							onSupportRequest={onFeedbackSupportRequest}
							onEnd={() => setShowFeedback(false)}
						/>
					) : null}
				</FeatureFlag>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${integration.key}.done`}
						variant="primary"
						label={TranslationService.get('action.done')}
						onClick={navigation.done}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

GenericSuccess.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	onAction: PropTypes.func,
};

GenericSuccess.defaultProps = {
	className: null,
	type: null,
};

export default GenericSuccess;
