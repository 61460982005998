import { useCallback, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { merge } from 'lodash-es';

import AsteriaCore from '@asteria/core';

/**
 * @template T
 * @param {{ name: string | string }} [options]
 * @returns { T }
 */
export function useFormValues(options = {}) {
	const { name } = options;

	const { getValues } = useFormContext();

	const dynamicValues = useWatch({ name: name });
	const staticValues = getValues(name);

	if (typeof dynamicValues === 'object' || staticValues === 'object') {
		return merge(dynamicValues, staticValues);
	}

	return dynamicValues || staticValues;
}

export function useChange({ debounce, onChange, isRawEvent }) {
	const throttleOnChange = useMemo(() => {
		if (debounce && onChange) {
			return AsteriaCore.utils.throttle(onChange, debounce);
		}

		return null;
	}, [onChange, debounce]);

	return useCallback(
		(event) => {
			if (!onChange) {
				return;
			}

			if (!debounce || isRawEvent) {
				return onChange(event);
			}

			throttleOnChange(event);
		},
		[debounce, isRawEvent, onChange, throttleOnChange],
	);
}
