import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import RemoveModal from './RemoveModal';

import './styles.scss';

const Actions = React.memo((props) => {
	const { object, onAction, size, onSubmit } = props;

	const dispatch = useDispatch();

	const [isRemoveModalOpen, setRemoveModalState] = React.useState(false);

	const handleRemoveModalOpen = React.useCallback(() => {
		setRemoveModalState(true);
	}, []);

	const handleRemoveModalClose = React.useCallback(() => {
		setRemoveModalState(false);
	}, []);

	const ToggleProps = React.useMemo(
		() => ({ icon: 'cog', size: size }),
		[size],
	);

	const handleRemove = React.useCallback(
		(event) => handleRemoveModalOpen(event),
		[handleRemoveModalOpen],
	);

	const handleEdit = React.useCallback(() => {
		dispatch(
			IntegrationStore.navigation.go({
				key: object?.key,
				form: {
					$id: object?._id ?? object?.id,
					config: { client: object?.config?.client },
				},
			}),
		);

		return onAction?.('connection:open', {
			type: object?.type,
			key: object?.key,
		});
	}, [
		dispatch,
		object?._id,
		object?.config?.client,
		object?.id,
		object?.key,
		object?.type,
		onAction,
	]);

	const handleToggle = React.useCallback(() => {
		if (object?.disabled) {
			return onSubmit?.('integrations:enable', object);
		}

		return onSubmit?.('integrations:disable', object);
	}, [object, onSubmit]);

	const handleFetch = React.useCallback(
		() => onSubmit?.('integrations:import', object),
		[object, onSubmit],
	);

	return (
		<>
			<RemoveModal
				open={isRemoveModalOpen}
				onClose={handleRemoveModalClose}
				onSubmit={onSubmit}
				object={object}
			/>
			<Dropdown
				toggle={ToggleProps}
				size={size}
				title={TranslationService.get([
					'integrations.actions.title',
					'integrations.actions.dropdown.title',
				])}
			>
				<DropdownItem
					onClick={handleRemove}
					analyticsKey="integration.options.item.delete"
				>
					{TranslationService.get(
						[
							'integrations.list.action.delete',
							`integrations.list.action.${object?.name}.delete`,
						],
						undefined,
						object,
					)}
				</DropdownItem>
				<FeatureFlag feature="integrations-edit">
					<DropdownItem
						onClick={handleEdit}
						analyticsKey="integration.options.item.edit"
						size={size}
					>
						{TranslationService.get(
							[
								'integrations.list.action.edit',
								`integrations.list.action.${object?.name}.edit`,
							],
							undefined,
							object,
						)}
					</DropdownItem>
				</FeatureFlag>
				<DropdownItem
					onClick={handleToggle}
					analyticsKey="integration.options.item.toggle"
				>
					{object?.disabled
						? TranslationService.get(
								[
									'integrations.list.action.enable',
									`integrations.list.action.${object?.name}.enable`,
								],
								undefined,
								object,
						  )
						: TranslationService.get(
								[
									'integrations.list.action.disable',
									`integrations.list.action.${object?.name}.disable`,
								],
								undefined,
								object,
						  )}
				</DropdownItem>
				{!['printer', 'visma.administration'].includes(object?.key) ? (
					<DropdownItem
						onClick={handleFetch}
						analyticsKey="integration.options.item.fetch"
					>
						{TranslationService.get(
							[
								'integrations.list.action.fetch',
								`integrations.list.action.${object?.name}.fetch`,
							],
							undefined,
							object,
						)}
					</DropdownItem>
				) : null}
			</Dropdown>
		</>
	);
});

Actions.displayName = 'Actions';
Actions.propTypes = {
	object: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	size: PropTypes.string,
};

export default Actions;
