import React from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { FAQSection, useFAQ } from '@asteria/component-faq';
import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import Stepper from '../../../components/Stepper';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const BatchSettingsPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);
	const { id } = useParams();

	const hasFAQFeature = useFeature('freja-faq');

	const config = useConfig(['pages.invoices.batch.form.settings'], {
		deep: true,
	});

	const faqSections = useConfig('pages.faq.sections', { deep: true }) ?? [];

	const faqSection = useFAQ(faqSections, { name: config?.faq });

	const handleFAQClick = React.useCallback(
		() => onAction?.('go', '/faq'),
		[onAction],
	);

	const handleSupportClick = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	const onCancel = React.useCallback(() => onAction?.('go', '/'), [onAction]);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onContinue = React.useCallback(
		() => onAction?.('go', `/invoices/batch/${id}/confirmation`),
		[id, onAction],
	);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices-batch',
				'asteria--variant-settings',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<div
				className={cn('asteria-page__wrapper', {
					'asteria--variant-split': config?.placeholder,
				})}
			>
				<div
					className={cn(
						'asteria-page__wrapper-section',
						'asteria--type-content',
					)}
				>
					<Wrapper scroll>
						<Header>
							<Title size="lg">
								{TranslationService.get([
									'invoice.batch.form.title',
									'invoice.batch.form.settings.title',
								])}
							</Title>
						</Header>
						<Content scroll>
							<Contenter content={config?.content} />
						</Content>
						<Footer>
							<FooterSection position="first">
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.cancel',
										'action.cancel',
										'invoice.batch.form.cancel',
										'invoice.batch.form.settings.cancel',
									])}
									onClick={onCancel}
								/>
							</FooterSection>
							<FooterSection position="last">
								<Button
									variant="secondary"
									label={TranslationService.get([
										'button.back',
										'action.back',
										'invoice.batch.form.back',
										'invoice.batch.form.settings.back',
									])}
									onClick={onBack}
								/>
								<Button
									variant="primary"
									label={TranslationService.get([
										'button.continue',
										'action.continue',
										'invoice.batch.form.continue',
										'invoice.batch.form.settings.continue',
									])}
									onClick={onContinue}
								/>
							</FooterSection>
						</Footer>
					</Wrapper>
					<div className="asteria-component__invoice-batch__help">
						<Text size="lg" separator>
							{TranslationService.get([
								'onboarding.linear.help.title',
								'invoice.batch.form.help.title',
								'invoice.batch.form.settings.help.title',
							])}
						</Text>
						<Group verticalAlign="center" horizontalAlign="center">
							{hasFAQFeature ? (
								<Button
									variant="link"
									size="lg"
									label={TranslationService.get([
										'onboarding.linear.help.faq',
										'invoice.batch.form.help.faq',
										'invoice.batch.form.settings.help.faq',
									])}
									onClick={handleFAQClick}
								/>
							) : null}

							<Button
								variant="link"
								size="lg"
								label={TranslationService.get([
									'onboarding.linear.help.support',
									'invoice.batch.form.help.support',
									'invoice.batch.form.settings.help.support',
								])}
								onClick={handleSupportClick}
							/>
						</Group>
					</div>
				</div>

				{config?.placeholder ? (
					<div
						className={cn(
							'asteria-page__wrapper-section',
							'asteria--type-placeholder',
						)}
					>
						{config?.placeholder?.type === 'image' ? (
							<div
								className="asteria-component__placeholder-image"
								style={{
									backgroundImage: `url(${config?.placeholder.src})`,
								}}
							/>
						) : null}
						{config?.placeholder?.type === 'contenter' ? (
							<Contenter content={config?.placeholder?.content} />
						) : null}
					</div>
				) : null}
			</div>

			{config?.faq && faqSection ? (
				<FAQSection
					{...faqSection}
					title={TranslationService.get([
						'faq.content.title',
						`faq.content.title.${faqSection?.name}`,
						'invoice.batch.form.faq',
						'invoice.batch.form.settings.faq',
						`invoice.batch.form.faq.${faqSection?.name}`,
						`invoice.batch.form.settings.faq.${faqSection?.name}`,
					])}
					onAction={onAction}
				/>
			) : null}

			<Support onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
};

BatchSettingsPage.displayName = 'BatchSettingsPage';

BatchSettingsPage.propTypes = { className: PropTypes.string };

export default BatchSettingsPage;
