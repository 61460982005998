import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import { TableCell, TableRow } from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import Checkbox from '@asteria/component-form/checkbox';
import Select, { Option } from '@asteria/component-form/select';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useCompanyVersion } from '../../CompanyVersion';

import Cell from './Cell';

import './styles.scss';

const InvoiceCellBase = React.memo((props) => {
	const { name, object, tooltip, loading } = props;

	return (
		<Cell type={name} object={object} tooltip={tooltip} loading={loading} />
	);
});

InvoiceCellBase.displayName = 'InvoiceCellBase';
InvoiceCellBase.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

function useSelectable(object) {
	const version = useCompanyVersion();

	const services = object?.services ?? [];

	let response = !services.length;

	if (version >= 2) {
		response ||=
			['DISCARD'].includes(object?.lastService?.status) ||
			['ERROR'].includes(object?.lastService?.invoice?.status);
	} else {
		response ||= ['DISCARD', 'ERROR'].includes(object?.lastService?.status);
	}

	return React.useMemo(() => response, [response]);
}

const InvoiceCellSelector = React.memo((props) => {
	const { object, onAction, loading } = props;

	const selected = useSelector((store) =>
		InvoiceStore.selectors.isSelected(store, object?._id ?? object.id),
	);

	const selectable = useSelectable(object);

	const handleSelect = React.useCallback(
		() => onAction?.('invoices:select', object),
		[object, onAction],
	);

	return (
		<TableCell className="asteria--cell-selector" loading={loading}>
			<Checkbox
				uncontrolled
				size="sm"
				onChange={handleSelect}
				checked={selected}
				value={object?._id ?? object.id}
				disabled={!selectable}
			/>
		</TableCell>
	);
});

InvoiceCellSelector.displayName = 'InvoiceCellSelector';
InvoiceCellSelector.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellInvoiceNumber = React.memo((props) => {
	const { object, loading } = props;

	if (object?.invoiceLayoutDetails?.pdfUri) {
		return (
			<Cell type="invoice-number" object={object} loading={loading}>
				<Button
					variant="link"
					size="sm"
					label={object?.meta?.invoiceNumber}
					// icon="document"
					href={TranslationService.get(
						'page.invoices.pending.table.open',
						undefined,
						{ uri: object?.invoiceLayoutDetails?.pdfUri },
					)}
					target="__blank"
				/>
			</Cell>
		);
	}

	return <InvoiceCellBase {...props} />;
});

InvoiceCellInvoiceNumber.displayName = 'InvoiceCellInvoiceNumber';
InvoiceCellInvoiceNumber.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellClientName = React.memo((props) => {
	const { object, loading } = props;

	const dispatch = useDispatch();

	const clientId =
		object?.clientId ?? object?.client?._id ?? object?.client?.id;

	const clientName = useSelector(
		(store) =>
			AppStore.selectors.client(store, clientId)?.name ??
			object?.client?.name,
	);

	const onClientClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientOverview,
				data: { _id: clientId },
			}),
		);
	}, [clientId, dispatch]);

	return (
		<Cell type="client-name" object={object} loading={loading}>
			<Button
				variant="link"
				size="sm"
				label={clientName}
				onClick={onClientClick}
			/>
		</Cell>
	);
});

InvoiceCellClientName.displayName = 'InvoiceCellClientName';
InvoiceCellClientName.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellClientDelivery = React.memo((props) => {
	const { object, loading } = props;

	const dispatch = useDispatch();

	const clientId =
		object?.clientId ?? object?.client?._id ?? object?.client?.id;

	const client = useSelector(
		(store) => AppStore.selectors.client(store, clientId),
		(a, b) => isEqual(a, b),
	);

	const onClientEdit = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: clientId },
			}),
		);
	}, [clientId, dispatch]);

	const form = client?.service?.client;
	const formMethod = form?.delivery?.method ?? null;
	const method = formMethod ?? 'PAPER';

	return (
		<Cell type="client-delivery" object={object} loading={loading}>
			<Button
				variant="link"
				className="asteria--type-client-delivery"
				label={TranslationService.get(
					[
						`client.overview.details.service.delivery.method.value.${method}`,
						`client.overview.details.${client?.type}.service.delivery.method.value.${method}`,
					],
					undefined,
					{ client: client },
				)}
				badge={
					!formMethod
						? {
								icon: 'warning',
								size: 'sm',
								tooltip: TranslationService.get([
									`invoices.table.row.cell.client-delivery.badge.tooltip`,
								]),
						  }
						: null
				}
				size="sm"
				onClick={onClientEdit}
			/>
		</Cell>
	);
});

InvoiceCellClientDelivery.displayName = 'InvoiceCellClientDelivery';
InvoiceCellClientDelivery.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellInvoiceSell = React.memo((props) => {
	const { object, loading } = props;

	if (object?.type === 'credit') {
		return (
			<Cell type="invoice-sell" object={object} loading={loading}>
				<Text size="sm">-</Text>
			</Cell>
		);
	}

	return (
		<Cell type="invoice-sell" object={object} loading={loading}>
			<Select name={`${object._id}.factoringOperation`}>
				<Option value="SELL">
					{TranslationService.get('invoice.sell.option1')}
				</Option>
				<Option value="NOSELL_1">
					{TranslationService.get('invoice.sell.option2')}
				</Option>
				<Option value="NOSELL_2">
					{TranslationService.get('invoice.sell.option3')}
				</Option>
				<Option value="NOSELL_3">
					{TranslationService.get('invoice.sell.option4')}
				</Option>
				<Option value="NOSELL_4">
					{TranslationService.get('invoice.sell.option5')}
				</Option>
				<Option value="NOSELL_5">
					{TranslationService.get('invoice.sell.option6')}
				</Option>
				<Option value="NOSELL_6">
					{TranslationService.get('invoice.sell.option7')}
				</Option>
			</Select>
		</Cell>
	);
});

InvoiceCellInvoiceSell.displayName = 'InvoiceCellInvoiceSell';
InvoiceCellInvoiceSell.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellBatch = React.memo((props) => {
	const { object, loading } = props;

	const dispatch = useDispatch();

	const batchId = object?.lastService?.id;

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, batchId),
		(a, b) => isEqual(a, b),
	);

	const onClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: 'BATCH_REVIEW',
				skipValidation: true,
				data: { id: batchId },
			}),
		);
	}, [batchId, dispatch]);

	if (!batch) {
		return <Cell type="batch" object={object} loading={loading} />;
	}

	return (
		<Cell type="batch" object={object} loading={loading}>
			<Button
				variant="link"
				size="sm"
				label={TranslationService.get(
					[
						'invoices.table.row.cell.batch',
						'invoices.table.row.cell.batch.value',
					],
					undefined,
					{ invoice: object, batch: batch },
				)}
				onClick={onClick}
			/>
		</Cell>
	);
});

InvoiceCellBatch.displayName = 'InvoiceCellBatch';
InvoiceCellBatch.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellMessage = React.memo((props) => {
	const { object, loading } = props;

	const errors = object.lastService?.invoice?.errors ?? [];

	if (!errors.length) {
		return <Cell type="message" object={object} loading={loading} />;
	}

	return (
		<Cell type="message" object={object} loading={loading}>
			<Badge
				size="sm"
				icon="warning"
				tooltip={TranslationService.get(
					['invoices.table.row.cell.message.value.tooltip'],
					undefined,
					{ invoice: object, errors: errors },
				)}
			/>
			<Translation
				translationKey="invoices.table.row.cell.message.value"
				data={{ invoice: object, errors: errors }}
				Component={Text}
				size="sm"
			/>
		</Cell>
	);
});

InvoiceCellMessage.displayName = 'InvoiceCellMessage';
InvoiceCellMessage.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const InvoiceCellCustomAction = React.memo(function InvoiceCellCustomAction(
	props,
) {
	const { name, object, tooltip, loading, children, render } = props;

	return (
		<Cell type={name} object={object} tooltip={tooltip} loading={loading}>
			{render ? render({ name, object, tooltip, loading }) : children}
		</Cell>
	);
});

InvoiceCellCustomAction.propTypes = {
	name: PropTypes.string,
	tooltip: PropTypes.bool,
	object: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,

	children: PropTypes.node,
	render: PropTypes.func,
};

const InvoiceCellMap = {
	selector: InvoiceCellSelector,
	'invoice-number': InvoiceCellInvoiceNumber,
	'client-name': InvoiceCellClientName,
	'client-delivery': InvoiceCellClientDelivery,
	'invoice-sell': InvoiceCellInvoiceSell,
	batch: InvoiceCellBatch,
	message: InvoiceCellMessage,
	'custom-action': InvoiceCellCustomAction,
};

const Row = React.memo((props) => {
	const { object, fields, onAction, loading } = props;

	const dispatch = useDispatch();

	const selected = useSelector((store) =>
		loading
			? false
			: InvoiceStore.selectors.isSelected(
					store,
					object?._id ?? object.id,
			  ),
	);

	const onDetailsClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: 'INVOICE_DETAILS',
				data: { _id: object?._id ?? object?.id },
				skipValidation: true,
			}),
		);
	}, [dispatch, object?._id, object?.id]);

	return (
		<TableRow className={cn({ 'asteria--state-selected': selected })}>
			{(fields ?? []).map(({ name, tooltip, children, render }) => {
				const InvoiceCell = InvoiceCellMap[name] ?? InvoiceCellBase;

				return (
					<InvoiceCell
						key={name}
						name={name}
						tooltip={tooltip}
						object={object}
						onAction={onAction}
						loading={loading}
						render={render}
					>
						{children}
					</InvoiceCell>
				);
			})}

			<Cell type="details" object={object} loading={loading}>
				<Button
					icon="chevron-right"
					onClick={onDetailsClick}
					disabled={loading}
				/>
			</Cell>
		</TableRow>
	);
});

Row.displayName = 'Row';
Row.propTypes = {
	object: PropTypes.object,
	onAction: PropTypes.func,
	fields: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, tooltip: PropTypes.bool }),
	),
	loading: PropTypes.bool,
};

export default Row;
