import { useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { LanguageService } from '@asteria/backend-utils-services';

import {
	setTranslations,
	setTranslationsLoaded,
} from '@asteria/datalayer/stores/language';

import { TranslationService } from '@asteria/language';

import { AuthContext } from '../context';

const selectors = {
	language: createSelector(
		(store) => store?.language?.language,
		(value) => value,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	translations: createSelector(
		(store) => store?.language?.translations,
		(value) => value,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	loaded: createSelector(
		(store) => store?.language?.loaded,
		(value) => value,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Languages = (props) => {
	const { children } = props;
	const dispatch = useDispatch();

	const language = useSelector(selectors.language);
	const translations = useSelector(selectors.translations);
	const loaded = useSelector(selectors.loaded);

	const { accessToken, partnerId, themeId } = useContext(AuthContext);

	useEffect(() => {
		if (!accessToken || !partnerId || !language) {
			return;
		}

		LanguageService.translation
			.fetchOne(
				{
					code: language,
					partnerId: partnerId,
					themeId: themeId || partnerId,
					fields: `translations`,
				},
				{ token: accessToken },
			)
			.catch(() => ({}))
			.then((response) => {
				dispatch(setTranslations(response?.translations ?? {}));
				dispatch(setTranslationsLoaded(true));
			});
	}, [dispatch, accessToken, language, partnerId, themeId]);

	if (!loaded || translations === null) {
		return null;
	}

	TranslationService.setTranslations(translations);
	TranslationService.code = language;

	return children;
};

Languages.propTypes = {
	children: PropTypes.node,
};

export default Languages;
