import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { QuestionPropTypes } from './PropTypes';

import './styles.scss';

const FAQQuestion = (props) => {
	const { className, onAction, section, ...question } = props;
	const { title } = question;

	const handleClick = React.useCallback(
		() => onAction?.('faq:details', { ...question, section: section }),
		[onAction, question, section],
	);

	return (
		<ListItem
			className={cn('asteria-component__faq-question', className)}
			onClick={handleClick}
		>
			<Text size="lg">{TranslationService.get(title, title)}</Text>
			<Button variant="default" icon="chevron-right" size="sm" />
		</ListItem>
	);
};

FAQQuestion.displayName = 'FAQQuestion';

FAQQuestion.propTypes = {
	...QuestionPropTypes,
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default FAQQuestion;
