import AsteriaCore from '@asteria/core';

let currentTheme = null;

const loadTheme = async (themeName = 'wings', debug = false) => {
	let name = themeName;

	if (name === 'wings') {
		name = 'asteria';
	}

	try {
		if (currentTheme) {
			currentTheme.unuse();
		}

		const styles = await import(
			`!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!@asteria/themes/${name}/main.scss`
		);

		styles.use();

		currentTheme = styles;
	} catch (e) {
		if (debug) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	}
};

export const loadDebounce = AsteriaCore.utils.throttle(loadTheme, 500);

export const resetTheme = () => {
	if (currentTheme) {
		currentTheme.unuse();
	}

	currentTheme = null;
};

export default loadTheme;
