import React from 'react';

import PropTypes from 'prop-types';

import withAnalytics from '@asteria/component-core/utils/withAnalytics';

import { cn } from '@asteria/utils-funcs/classes';

import InputWrapper from '../generic/InputWrapper';
import { withLabel } from '../generic/Label';
import withRegister from '../withRegister';

import './styles.scss';

const Input = React.forwardRef((props, ref) => {
	const {
		className,
		active,
		disabled,
		error,
		prefix,
		postfix,
		icon,
		size,
		name,
		helpText,
		value,
		autocomplete,
		onChange,
		visibleValue,
		hideVisibleOnFocus,
		...input
	} = props;

	delete input.setValueAs;
	delete input.label;
	delete input.uncontrolled;
	delete input.defaultValue;
	delete input.multiplier;

	return (
		<InputWrapper
			active={active}
			disabled={disabled}
			error={error}
			className={cn('asteria-component__form-v2__input', className)}
			prefix={prefix}
			postfix={postfix}
			icon={icon}
			size={size}
			helpText={helpText}
			value={value}
			name={name}
			visibleValue={visibleValue}
			hideVisibleOnFocus={hideVisibleOnFocus}
		>
			<input
				ref={ref}
				name={name}
				autoComplete={autocomplete ?? 'off'}
				onChange={onChange}
				{...input}
			/>
		</InputWrapper>
	);
});

Input.displayName = 'Input';

Input.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	prefix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	postfix: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
	helpText: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
		PropTypes.node,
	]),
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	size: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	autocomplete: PropTypes.string,
	multiplier: PropTypes.number,
	onFocus: PropTypes.func,
	autoFocus: PropTypes.bool,

	onChange: PropTypes.func,
	visibleValue: PropTypes.string,
	hideVisibleOnFocus: PropTypes.bool,
};

Input.__docgenInfo = {
	displayName: Input.displayName,
	props: {
		onChange: { type: { name: 'func' } },
		visibleValue: {
			description: 'Value that can be shown in the input field',
			type: { name: 'string' },
		},
		hideVisibleOnFocus: {
			description: 'Should visible value disappear on focus',
			type: { name: 'bool' },
		},
		onFocus: { type: { name: 'func' } },
		autoFocus: { type: { name: 'func' } },
		autocomplete: { type: { name: 'string' } },
		multiplier: { type: { name: 'number' } },
		helpText: { type: { name: 'func | string | node' } },
		className: { type: { name: 'string' } },
		active: { type: { name: 'bool' } },
		disabled: { type: { name: 'bool' } },
		error: { type: { name: 'object' } },
		prefix: { type: { name: 'node' } },
		postfix: { type: { name: 'node' } },
		icon: { type: { name: 'object | string' } },
		size: { type: { name: 'string' } },
		name: { type: { name: 'string' } },
	},
};

export default withRegister(
	withAnalytics(withLabel(Input), {
		event: 'form.input',
		methods: [
			{
				key: 'onChange',
				format: (event, { analyticsKey }) => ({
					name: event?.target?.name,
					value: event?.target?.value,
					analyticsKey: analyticsKey,
				}),
			},
			'onBlur',
			'onFocus',
		],
	}),
);
