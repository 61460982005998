import { useCallback, useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { startOfMonth } from 'date-fns';
import format from 'date-fns/format';

import { AuthContext } from '../context';

import {
	fetch as fetchAccounts,
	update as updateAccounts,
} from './funcs/accounts';

const BankAccountsLogic = (props) => {
	const { children } = props;
	const { accessToken } = useContext(AuthContext);
	const [accounts, setAccounts] = useState([]);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		fetchAccounts({ accessToken: accessToken }).then((response) => {
			setAccounts(response);
		});
	}, []);

	const todayGraph = useSelector(
		(state) =>
			state?.graph?.data?.barGroups?.[
				format(startOfMonth(new Date()), 'yyyy-MM-dd')
			],
	);

	useEffect(() => {
		const [account] = todayGraph?.lines || [];
		if (todayGraph && account) {
			setTotal(account.value);
		}
	}, [todayGraph]);

	const onAction = useCallback(async (action, bankAccount) => {
		let accounts = [];

		if (action === 'enable') {
			accounts = await updateAccounts({
				accessToken: accessToken,
				input: {
					active: true,
					identifiers: { number: bankAccount.identifiers.number },
				},
			});
		}

		if (action === 'disable') {
			accounts = await updateAccounts({
				accessToken: accessToken,
				input: {
					active: false,
					identifiers: { number: bankAccount.identifiers.number },
				},
			});
		}

		setAccounts(accounts);
	}, []);

	return children({ accounts: accounts, total: total, onAction: onAction });
};

export default BankAccountsLogic;
