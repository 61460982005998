import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';

export function useCompanyVersion() {
	return useSelector(AppStore.selectors.companyVersion, (a, b) =>
		isEqual(a, b),
	);
}

export function useCompanyVersionQuery({ eq, neq, gt, gte, lt, lte, invert }) {
	const version = useCompanyVersion();

	let show = true;

	if (show && eq !== undefined) {
		show = version === eq;
	}

	if (show && neq !== undefined) {
		show = version !== neq;
	}

	if (show && gt !== undefined) {
		show = version > gt;
	}

	if (show && gte !== undefined) {
		show = version >= gte;
	}

	if (show && lt !== undefined) {
		show = version < lt;
	}

	if (show && lte !== undefined) {
		show = version <= lte;
	}

	if (invert) {
		show = !show;
	}

	return React.useMemo(() => show, [show]);
}
