import React from 'react';

import PropTypes from 'prop-types';

import { IntegrationDialog } from '@asteria/component-integrations-v2';

const IntegrationsLogic = (props) => {
	const { onFetch, hidden } = props;

	const refresh = React.useCallback(
		() => onFetch?.('integrations:list'),
		[onFetch],
	);

	React.useEffect(() => {
		const timer = setInterval(refresh, 30 * 1000);

		return () => {
			clearInterval(timer);
		};
	}, [refresh]);

	if (hidden) {
		return null;
	}

	return <IntegrationDialog />;
};

IntegrationsLogic.propTypes = {
	onFetch: PropTypes.func,
	hidden: PropTypes.bool,
};

export default IntegrationsLogic;
