import React, { useCallback, useContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Input from '@asteria/component-form/input';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../../context';
import Step, { Content, Footer, Header } from '../../../step';
import Guide from '../../guide';

import './index.scss';

const FlowField = (props) => {
	const { field, integrationKey, type } = props;

	if (field.type === 'checkbox') {
		return (
			<Checkbox
				name={field.key}
				label={TranslationService.get([
					`integrations.add.${field.label}`,
					`integrations.${type}.add.${field.label}`,
					`integrations.${integrationKey}.add.${field.label}`,
					`integrations.${type}.${integrationKey}.add.${field.label}`,
				])}
				value={field.value}
				required={field?.flags?.required}
				readonly={field?.flags?.readonly}
			/>
		);
	}

	return (
		<Input
			name={field.key}
			label={TranslationService.get([
				`integrations.add.${field.label}`,
				`integrations.${type}.add.${field.label}`,
				`integrations.${integrationKey}.add.${field.label}`,
				`integrations.${type}.${integrationKey}.add.${field.label}`,
			])}
			required={field?.flags?.required}
			readonly={field?.flags?.readonly}
		/>
	);
};

FlowField.propTypes = {
	field: PropTypes.object.isRequired,
};

const ConfigurationStep = (props) => {
	const { className, integration, flow, setIntegration, onClose } = props;

	const { navigation } = useContext(NavigationContext);
	const [showGuide, setShowGuide] = useState(false);

	const step = `${integration.type}.configuration`;

	const onSubmit = useCallback(
		(form) => {
			setIntegration(form);
			navigation.next();
		},
		[navigation, setIntegration],
	);

	const fields = useMemo(() => flow?.fields ?? [], [flow]);

	return (
		<Form onSubmit={onSubmit} defaultValues={integration}>
			<Step
				className={cn(
					'asteria-component__integration-configuration__info',
					{
						[`asteria-component__integration-configuration--type-${integration.type}`]:
							integration.type,
					},
					className,
				)}
				step={step}
			>
				{flow?.steps?.info?.guide && (
					<Guide
						open={showGuide}
						steps={flow?.steps?.info?.guide?.steps}
						onClose={() => setShowGuide(false)}
					/>
				)}
				<Header onClose={onClose}>
					{TranslationService.get([
						`integrations.add.title`,
						`integrations.${integration.type}.add.title`,
					])}
				</Header>
				<Content step={step} scroll>
					<TextGroup>
						<Title>{flow?.name}</Title>
						<Text>
							{TranslationService.get(
								[
									`integrations.add.content`,
									`integrations.${integration.type}.add.content`,
									`integrations.${integration.type}.${integration.key}.add.content`,
								],
								undefined,
								{ integration: integration },
							)}
						</Text>
					</TextGroup>
					<Contenter content={flow?.steps?.info?.content} />

					<FormWrapper>
						<Content>
							{fields.map((field) => (
								<FlowField
									key={field.key}
									field={field}
									type={integration.type}
									integrationKey={integration.key}
								/>
							))}
						</Content>
					</FormWrapper>

					{flow?.steps?.info?.guide && (
						<Button
							className="asteria-component__integration-step__help"
							variant="assist"
							label={TranslationService.get(
								[
									`integrations.add.help`,
									`integrations.${integration.type}.add.help`,
									`integrations.${integration.type}.${integration.key}.add.help`,
								],
								undefined,
								{ integration: integration },
							)}
							// icon="triangle-right"
							// iconPosition="last"
							onClick={() => setShowGuide(true)}
						/>
					)}
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get('action.abort')}
							onClick={navigation.abort}
						/>
						<Button
							variant="secondary"
							label={TranslationService.get('action.back')}
							onClick={navigation.back}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get('action.continue')}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Step>
		</Form>
	);
};

ConfigurationStep.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	flow: PropTypes.object,

	defaultConfigValue: PropTypes.object,

	setIntegration: PropTypes.func,
};

ConfigurationStep.defaultProps = {
	className: null,
	type: null,
};

export default ConfigurationStep;
