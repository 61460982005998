import React from 'react';

const LayoutContext = React.createContext({
	onAction: () => null,
	onSubmit: () => null,
});

const MenuContext = React.createContext({
	state: false,
	toggle: () => {},
	open: () => {},
	close: () => {},
});

export default LayoutContext;
export { MenuContext };
