import { AuthService } from '@asteria/backend-utils-services';

import { setConfig } from '@asteria/datalayer/stores/app';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export default async (options = {}) => {
	const { accessToken, dispatch } = options;

	const response = await AuthService.auth
		.me({ isBulk: true, fields: `config` }, { token: accessToken })
		.catch(() => {});

	dispatch?.(setConfig(response?.config));

	return response;
};
