import React from 'react';

import { loremIpsum } from 'lorem-ipsum';

import { Text } from '@asteria/component-core/typography';

import '../styles.scss';

const Buyer = () => {
	return <Text>{loremIpsum({ count: 14 })}</Text>;
};

Buyer.displayName = 'Buyer';

Buyer.propTypes = {};

export default Buyer;
