import React from 'react';

import { useDispatch } from 'react-redux';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { AuthContext } from '../context';
import { onAction as onFeedbackAction } from '../logic/actions/feedback';

import useIntegrationRequest from './useIntegrationRequest';

const useModalHandlers = (props) => {
	const { onAction, onClose, callback } = props;

	const dispatch = useDispatch();
	const { accessToken } = React.useContext(AuthContext);

	const handleIntegrationRequest = useIntegrationRequest({
		onAction: onAction,
		callback: callback,
	});

	const handleModalAction = React.useCallback(
		async (action, data) => {
			if (action?.startsWith?.('feedback')) {
				await onFeedbackAction({
					dispatch: dispatch,
					accessToken: accessToken,
					event: { type: action, data: data },
				});

				dispatch(ModalStore.close());
			}

			return onAction?.(action, data);
		},
		[accessToken, dispatch, onAction],
	);

	const handleModalClose = React.useCallback(
		(event) => {
			dispatch(ModalStore.close());
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[dispatch, onClose],
	);

	const handleModalSubmit = React.useCallback(
		async (action, data) => {
			if (action?.startsWith?.('integrations:')) {
				return handleIntegrationRequest(action, data);
			}
		},
		[handleIntegrationRequest],
	);

	return React.useMemo(
		() => ({
			onClose: handleModalClose,
			onAction: handleModalAction,
			onSubmit: handleModalSubmit,
		}),
		[handleModalAction, handleModalClose, handleModalSubmit],
	);
};

export default useModalHandlers;
