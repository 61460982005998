import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useFormValues } from '@asteria/component-form';
import Input from '@asteria/component-form/input';

import { TranslationService } from '@asteria/language';

import Cell from './Cell';

import './styles.scss';

const SearchInput = React.memo((props) => {
	const { onChange } = props;

	const { resetField } = useFormContext();

	const value = useFormValues({ name: 'search-value' });

	const handleReset = React.useCallback(() => {
		resetField('search-value');
		return onChange?.({ value: '' });
	}, [onChange, resetField]);

	return (
		<Cell className="asteria--type-search">
			<Input
				name="search-value"
				className="asteria-component__invoice-search__input"
				onChange={onChange}
				placeholder={TranslationService.get([
					'invoices.search.placeholder',
				])}
				icon="magnifier"
				iconSize="md"
				postfix={
					value ? (
						<Button icon="close" size="sm" onClick={handleReset} />
					) : null
				}
				debounce={500}
			/>
		</Cell>
	);
});

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = { onChange: PropTypes.func };

export default SearchInput;
