import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { GuidePropTypes } from './PropTypes';

import './styles.scss';

const Guide = (props) => {
	const { className, id, icon, onAction } = props;

	const handleClick = React.useCallback(
		() => onAction?.('guide:details', id),
		[id, onAction],
	);

	return (
		<div className={cn('asteria-component__guides-guide', className)}>
			<Icon icon={icon} />
			<TextGroup>
				<Title size="sm">
					{TranslationService.get([
						`guide.box.title`,
						`guide.box.${id}.title`,
					])}
				</Title>
				<Text>
					{TranslationService.get([
						`guide.box.description`,
						`guide.box.${id}.description`,
					])}
				</Text>
			</TextGroup>
			<Button
				variant="link"
				label={TranslationService.get([
					`guide.box.action`,
					`guide.box.${id}.action`,
				])}
				size="lg"
				onClick={handleClick}
				icon="guide-box"
				iconPosition="first"
			/>
		</div>
	);
};

Guide.displayName = 'Guide';

Guide.propTypes = { ...GuidePropTypes, className: PropTypes.string };

export default Guide;
