import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';
import { withDeepMemo } from '@asteria/utils-hooks/useDeep';

import './styles.scss';

const BatchRemove = (props) => {
	const { onClose, onAction, onSubmit, id } = props;

	const [loading, setLoading] = React.useState(false);

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, id),
		(a, b) => isEqual(a, b),
	);

	const config = useConfig(['modals.batch.remove'], { deep: true });

	const onRemove = React.useCallback(
		async (event) => {
			setLoading(true);

			try {
				await onAction?.('invoices:batch:remove', { id });
			} catch (err) {
				//
			}

			setLoading(false);

			return onClose?.(event);
		},
		[onAction, onClose, id],
	);

	const contenterData = React.useMemo(() => ({ batch: batch }), [batch]);

	const contenterActions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.get(
					['invoice.modal.batch.remove.title'],
					undefined,
					batch,
				)}
			</Header>
			<Content scroll>
				<Contenter
					content={config?.content}
					data={contenterData}
					actions={contenterActions}
				/>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'invoice.modal.batch.remove.action.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<Button
					variant="primary"
					label={TranslationService.get([
						'button.remove',
						'action.remove',
						'invoice.modal.batch.remove.action.remove',
					])}
					onClick={onRemove}
					disabled={loading}
					loading={loading}
				/>
			</Footer>
		</Wrapper>
	);
};

BatchRemove.displayName = 'BatchRemove';

BatchRemove.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	id: PropTypes.string,
};

const BatchRemoveModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__invoice-modal',
				'asteria--type-batch-remove',
			)}
			scroll
			size="sm"
		>
			<BatchRemove {...props} />
		</Modal>
	);
};

BatchRemoveModal.displayName = 'BatchRemoveModal';

BatchRemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	id: PropTypes.string,
};

const BatchRemoveButton = withDeepMemo((props) => {
	const { id, onAction, onSubmit, toggle, onOpen, onClose } = props;

	const [open, setOpenState] = React.useState(false);

	const handleOpen = React.useCallback(
		(event) => {
			setOpenState(true);

			return onOpen?.(event);
		},
		[onOpen],
	);

	const handleClose = React.useCallback(
		(event) => {
			setOpenState(false);

			return onClose?.(event);
		},
		[onClose],
	);

	return (
		<>
			<BatchRemoveModal
				open={open}
				onClose={handleClose}
				id={id}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<Button
				variant="link"
				label={TranslationService.get([
					'invoice.batch.remove.button.label',
				])}
				onClick={handleOpen}
				{...toggle}
			/>
		</>
	);
});

BatchRemoveButton.displayName = 'BatchRemoveButton';
BatchRemoveButton.propTypes = {
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	toggle: PropTypes.object,
};

export default BatchRemoveModal;
export { BatchRemoveButton };
