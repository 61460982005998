import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Stepper from '../../components/Stepper';
import LayoutContext from '../../layout/context';

import './styles.scss';

const Layouts = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const layouts = useSelector(
		(store) =>
			InvoiceStore.selectors.layouts(store, {
				status: ['PENDING', 'LOADING'],
			}),
		(a, b) => isEqual(a, b),
	);

	const agreements = useSelector(
		(store) =>
			AppStore.selectors.company(store)?.service?.data?.agreements ?? [],
		(a, b) => isEqual(a, b),
	);

	const { data: hasInvoices } = useQuery({
		queryKey: ['invoices', 'exist'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				pageFilters: { first: 1 },
				fields: `_id`,
			}),
		select: (response) => !!response?.pageInfo?.count,

		placeholderData: false,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		retry: false,
	});

	const onContinue = React.useCallback(
		() => onAction?.('go', '/invoices'),
		[onAction],
	);

	return (
		<div className={cn('asteria-page', 'asteria-page__layouts', className)}>
			<Stepper onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get(
							[
								`page.invoices.title`,
								`page.invoices.empty.title`,
								'page.layouts.title',
							],
							undefined,
							{
								layouts: layouts,
								agreements:
									TranslationService.toIterable(agreements),
							},
						)}
					</Title>
					<Text size="lg">
						{TranslationService.get(
							[
								`page.invoices.content`,
								`page.invoices.empty.content`,
								'page.layouts.content',
							],
							undefined,
							{
								layouts: layouts,
								agreements:
									TranslationService.toIterable(agreements),
							},
						)}
					</Text>
				</TextGroup>

				<div className="asteria-page__placeholder">
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
					>
						<Icon icon="document" />
						<Title size="sm" align="center">
							{TranslationService.get(
								[
									'invoices.table.empty.title',
									`invoices.table.empty.printer.title`,
									!layouts.length
										? `invoices.table.empty.printer.empty.title`
										: null,
									'page.layouts.placeholder.title',
									!layouts.length
										? 'page.layouts.placeholder.empty.title'
										: null,
								],
								undefined,
								{
									layouts: layouts,
									agreements:
										TranslationService.toIterable(
											agreements,
										),
								},
							)}
						</Title>
					</Group>
					<Text align="center">
						{TranslationService.get(
							[
								'invoices.table.empty.content',
								`invoices.table.empty.printer.content`,
								!layouts.length
									? `invoices.table.empty.printer.empty.content`
									: null,
								`page.layouts.placeholder.content`,
								!layouts.length
									? `page.layouts.placeholder.empty.content`
									: null,
							],
							undefined,
							{
								layouts: layouts,
								agreements:
									TranslationService.toIterable(agreements),
							},
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								'invoices.table.empty.content.1',
								`invoices.table.empty.printer.content.1`,
								!layouts.length
									? `invoices.table.empty.printer.empty.content.1`
									: null,
								`page.layouts.placeholder.content.1`,
								!layouts.length
									? `page.layouts.placeholder.empty.content.1`
									: null,
							],
							undefined,
							{
								layouts: layouts,
								agreements:
									TranslationService.toIterable(agreements),
							},
						)}
					</Text>
				</div>

				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="right"
				>
					<Button
						variant="primary"
						label={TranslationService.get(
							[
								'button.continue',
								'action.continue',
								'page.layouts.action.continue',
							],
							undefined,
							{
								layouts: layouts,
								agreements:
									TranslationService.toIterable(agreements),
							},
						)}
						disabled={!hasInvoices}
						onClick={onContinue}
					/>
				</Group>
			</div>
		</div>
	);
};

Layouts.displayName = 'Layouts';

Layouts.propTypes = { className: PropTypes.string };

export default Layouts;
