import React, { useCallback, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { NotificationService } from '@asteria/backend-utils-services';

import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

import { AuthContext } from '../context';

import ActionDialog from './actionDialog';
import ActionSplash from './actionSplash';

const Popups = (props) => {
	const { onAction } = props;
	const { accessToken } = useContext(AuthContext);

	const [modals, setModals] = useState([]);
	const [dialogs, setDialogs] = useState([]);

	const useTestActionDialogs = useFeature('action-dialog-test');

	const fetchNotifications = useCallback(async () => {
		const response = await NotificationService.notification.extension.list(
			{},
			{ token: accessToken },
		);

		const notifications = response?.edges?.map(({ node }) => node) || [];

		if (useTestActionDialogs) {
			notifications.push({
				id: 'test',
				type: 'dialog',
				title: 'dialog.simulate.filter',
				data: {
					className:
						'asteria-component__action-dialog--simulate-filter',
					action: {
						action: 'setFilters',
						data: [
							{
								type: 'tag',
								tagName: '$customer',
								categoryName: '$invoices',
							},
							{
								type: 'status',
								name: 'UNPAID',
							},
						],
					},
				},
			});

			notifications.push({
				id: 'test',
				type: 'dialog',
				title: 'dialog.erp',
				data: {
					className: 'asteria-component__action-dialog--add-erp',
					action: {
						action: 'go',
						data: '/integrations/add/erp',
					},
				},
			});

			notifications.push({
				id: 'test',
				type: 'modal',
				title: 'splash.returning.user',
				data: {
					className: 'asteria-component__modal__splash__returning',
					action: {
						action: 'close',
					},
				},
			});

			notifications.push({
				id: 'test',
				type: 'modal',
				title: 'dialog.credit',
				data: {
					className: 'asteria-component__modal__splash__returning',
					action: {
						action: 'go',
						data: '/credit',
					},
				},
			});

			notifications.push({
				id: 'test',
				type: 'dialog',
				title: 'dialog.credit',
				data: {
					className:
						'asteria-component__action-dialog--simulate-credit',
					action: {
						action: 'go',
						data: '/credit',
					},
				},
			});

			notifications.push({
				id: 'test',
				type: 'dialog',
				title: 'dialog.add.transaction',
				data: {
					className:
						'asteria-component__action-dialog--simulate-event',
					action: {
						action: 'addTransaction',
					},
				},
			});
		}

		setModals(notifications.filter(({ type }) => type === 'modal'));
		setDialogs(notifications.filter(({ type }) => type === 'dialog'));
	}, [accessToken, useTestActionDialogs]);

	useEffect(() => {
		fetchNotifications();
	}, []);

	const popupActions = useCallback(
		async (action, data) => {
			if (action === 'markNotificationAsViewed' && data) {
				try {
					await NotificationService.notification.extension.mark(
						{ id: data },
						{ token: accessToken },
					);
				} catch (e) {
					// Do nothing
				}

				if (!useTestActionDialogs) {
					fetchNotifications();
				}
			}
			onAction?.(action, data);
		},
		[onAction, accessToken, fetchNotifications],
	);

	const [dialog] = dialogs;
	const [modal] = modals;

	const onCloseDialog = useCallback(() => {
		setDialogs(dialogs.filter((d) => d !== dialog));
		popupActions?.('markNotificationAsViewed', dialog.id);
	}, [dialogs, dialog, popupActions]);

	const onCloseModal = useCallback(() => {
		setModals(modals.filter((d) => d !== modal));
		popupActions?.('markNotificationAsViewed', modal.id);
	}, [modals, modal, popupActions]);

	if (modal) {
		return (
			<FeatureFlag feature="action-dialogs">
				<ActionSplash
					onAction={popupActions}
					className={cn(modal?.data?.className)}
					onClose={onCloseModal}
					action={modal?.data?.action}
					text={modal.title}
				/>
			</FeatureFlag>
		);
	}

	if (dialog) {
		return (
			<FeatureFlag feature="action-dialogs">
				<ActionDialog
					onAction={popupActions}
					className={cn(dialog?.data?.className)}
					onClose={onCloseDialog}
					action={dialog?.data?.action}
					text={dialog.title}
				/>
			</FeatureFlag>
		);
	}

	return null;
};

Popups.propTypes = {
	onAction: PropTypes.func,
};

export default Popups;
