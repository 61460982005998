import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import FAQ from '@asteria/component-faq';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../components/Actions';
import Guides from '../../components/Guides';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import './styles.scss';

const FAQPage = (props) => {
	const { className } = props;

	const ref = useRef(null);
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const sectionsConfig = useConfig('pages.faq.sections', { deep: true });
	const guideConfig = useConfig('pages.common.guides', { deep: true });

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn('asteria-page', 'asteria-page__faq', className)}
			ref={ref}
		>
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get(['page.faq.title'])}
					</Title>
					<Text size="lg">
						{TranslationService.get(['page.faq.content'])}
					</Text>
				</TextGroup>
				<FAQ sections={sectionsConfig} onAction={onAction} />
				<Guides guides={guideConfig} onAction={onAction} />
			</div>
			<Support hideFAQ onAction={onAction} />
		</div>
	);
};

FAQPage.displayName = 'FAQPage';

FAQPage.propTypes = { className: PropTypes.string };

export default FAQPage;
