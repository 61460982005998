import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';

import { ImportantActions } from '../../../components/Actions';
import Stepper from '../../../components/Stepper';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import VariantDetails from './Details';
import VariantEmpty from './Empty';
import VariantInitial from './Initial';
import VariantLoading from './Loading';

import './styles.scss';

const PAGE_VARIANT = {
	INITIAL: 'initial',
	LOADING: 'loading',
	EMPTY: 'empty',
	DETAILS: 'details',
};

function usePageVariant({ onSubmit }) {
	const hasIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
			}).length,
	);

	const hasInitiating = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				status: 'INITIATING',
			}).length,
	);

	const { data: hasInvoices } = useQuery({
		queryKey: ['invoices', 'exist'],
		queryFn: async () =>
			onSubmit?.('invoices:available', {
				pageFilters: { first: 1 },
				fields: `_id`,
			}),
		select: (response) => !!response?.pageInfo?.count,

		placeholderData: false,

		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		retry: false,
	});

	const hasBatches = useSelector(
		(store) => !!InvoiceStore.selectors.batches(store).length,
	);

	if (!hasInvoices && !hasBatches) {
		if (!hasIntegrations) {
			return PAGE_VARIANT.INITIAL;
		}

		if (hasInitiating) {
			return PAGE_VARIANT.LOADING;
		}

		return PAGE_VARIANT.EMPTY;
	}

	return PAGE_VARIANT.DETAILS;
}

const Invoices = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const variant = usePageVariant({ onSubmit });

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices',
				{ [`asteria--variant-${variant}`]: variant },
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<ImportantActions
				onAction={onAction}
				onSubmit={onSubmit}
				ignore="errorBatch"
			/>

			<Alert
				title={TranslationService.get(['page.invoices.alert.title'])}
				level="info"
				icon="info"
			>
				<Text>
					{TranslationService.get(['page.invoices.alert.content'])}
				</Text>
			</Alert>

			{variant === PAGE_VARIANT.INITIAL ? <VariantInitial /> : null}
			{variant === PAGE_VARIANT.LOADING ? <VariantLoading /> : null}
			{variant === PAGE_VARIANT.EMPTY ? <VariantEmpty /> : null}
			{variant === PAGE_VARIANT.DETAILS ? <VariantDetails /> : null}

			<Support onAction={onAction} />
		</div>
	);
};

Invoices.displayName = 'Invoices';

Invoices.propTypes = { className: PropTypes.string };

export default Invoices;
