import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Placeholder = () => <div className="wings-sidebar__placeholder" />;

const Item = (props) => {
	const {
		placeholder,
		icon,
		label,
		collapse,
		active,
		onClick,
		onCollapse,
		sub,
		open,
	} = props;

	return (
		<div
			className={cn('wings-sidebar__item', {
				'asteria--state-active': active,
				'asteria--state-collapsible': collapse,
				'asteria--variant-sub': sub,
				'asteria--state-no-icon': !(placeholder || icon),
			})}
			onClick={onClick}
		>
			{placeholder || icon ? (
				<div className="wings-sidebar__item__icon">
					{placeholder ? <Placeholder /> : <Icon icon={icon} />}
				</div>
			) : null}
			<div className="wings-sidebar__item__label">
				{placeholder ? (
					<Placeholder />
				) : typeof label === 'string' ? (
					<Text size="lg">{label}</Text>
				) : (
					label
				)}
			</div>
			{collapse ? (
				<div
					className="wings-sidebar__item__collapse"
					onClick={onCollapse}
				>
					<Icon icon={open ? 'chevron-up' : 'chevron-down'} />
				</div>
			) : null}
		</div>
	);
};

Item.displayName = 'Item';
Item.propTypes = {
	placeholder: PropTypes.bool,
	icon: PropTypes.string,
	label: PropTypes.string,
	collapse: PropTypes.bool,
	active: PropTypes.bool,
	onClick: PropTypes.func,
	sub: PropTypes.bool,
	onCollapse: PropTypes.func,
	open: PropTypes.bool,
};

const Sidebar = (props) => {
	const { className, fullscreen, navigation } = props;

	const [open, setOpen] = React.useState(true);

	const toggle = React.useCallback(() => {
		setOpen((value) => !value);
	}, []);

	const onChangeLayout = React.useCallback(
		() => fullscreen?.onChange?.((value) => !value),
		[fullscreen],
	);

	const onRedirect = React.useCallback(
		(path) => {
			return () => {
				navigation?.navigate?.(path);
			};
		},
		[navigation],
	);

	const onOpen = React.useCallback(
		(path) => {
			return () => {
				navigation?.open?.(path);
			};
		},
		[navigation],
	);

	return (
		<div
			className={cn(
				'wings-sidebar',
				'lf-sidebar',
				'asteria-component__layout__sidebar',
				className,
			)}
		>
			<Item
				label={TranslationService.get([
					'widget.navigation.cashflow',
					'sidebar.navigation.cashflow',
				])}
				icon="cashflow"
				active={navigation?.current === '/'}
				collapse
				onClick={onRedirect('/')}
				onCollapse={toggle}
				open={open}
			/>
			{open ? (
				<div className="asteria-component__layout__sidebar-sub">
					<Item
						label={TranslationService.get([
							'widget.navigation.forecaster',
							'sidebar.navigation.forecaster',
						])}
						sub
						active={navigation?.current?.includes?.('/forecaster')}
						onClick={onRedirect('/forecaster')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.financial',
							'sidebar.navigation.financial',
						])}
						sub
						active={navigation?.current?.includes?.('/financial')}
						onClick={onRedirect('/financial')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.onboarding',
							'sidebar.navigation.onboarding',
						])}
						sub
						active={navigation?.current === '/onboarding'}
						onClick={onRedirect('/onboarding')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.statement',
							'sidebar.navigation.statement',
						])}
						sub
						onClick={onOpen('/statement')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.overview',
							'sidebar.navigation.overview',
						])}
						sub
						onClick={onOpen('/overview')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.accounts',
							'sidebar.navigation.accounts',
						])}
						sub
						onClick={onOpen('/accounts')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.clients',
							'sidebar.navigation.clients',
						])}
						sub
						onClick={onOpen('/clients')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.help',
							'sidebar.navigation.help',
						])}
						sub
						onClick={onOpen('/support')}
					/>
					<Item
						label={TranslationService.get([
							'widget.navigation.settings',
							'sidebar.navigation.settings',
						])}
						sub
						onClick={onOpen('/settings')}
					/>
				</div>
			) : null}
			<Item placeholder collapse />
			<Item placeholder />
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder collapse /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			{/* <Item placeholder /> */}
			<Item
				onClick={() => {
					window.open(`https://www.lansforsakringar.se/`, '_blank');
				}}
				label={
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="left"
					>
						<Text>Länsförsäkringar.se</Text>
						<Icon icon="external" />
					</Group>
				}
			/>
			<Item
				icon={fullscreen?.state ? 'chevron-right' : 'chevron-left'}
				label={
					fullscreen?.state
						? TranslationService.get(
								['sidebar.minimize'],
								'Minimize',
						  )
						: TranslationService.get(
								['sidebar.maximize'],
								'Maximize',
						  )
				}
				onClick={onChangeLayout}
			/>
		</div>
	);
};

Sidebar.displayName = 'Sidebar';

Sidebar.propTypes = {
	className: PropTypes.string,
	fullscreen: PropTypes.shape({
		state: PropTypes.bool,
		onChange: PropTypes.func,
	}),
	navigation: PropTypes.shape({
		current: PropTypes.string,
		navigate: PropTypes.func,
		open: PropTypes.func,
	}),
};

export default Sidebar;
