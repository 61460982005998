import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Feedback from '@asteria/component-feedback';
import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import Stepper from '../../../components/Stepper';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const BatchSuccessPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const hasFAQFeature = useFeature('freja-faq');

	const config = useConfig(['pages.invoices.batch.form.success'], {
		deep: true,
	});

	const handleFAQClick = React.useCallback(
		() => onAction?.('go', '/faq'),
		[onAction],
	);

	const handleSupportClick = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	const contenterActions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const onDone = React.useCallback(() => onAction?.('go', '/'), [onAction]);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices-batch',
				'asteria--variant-success',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} forceShow />
			<div className="asteria-page__wrapper">
				<Wrapper scroll>
					<Header>
						<Title size="page-title">
							{TranslationService.get([
								'invoice.batch.form.title',
								'invoice.batch.form.success.title',
							])}
						</Title>
					</Header>
					<Content scroll>
						<Contenter
							content={config?.content}
							actions={contenterActions}
						/>

						<Feedback
							onAction={onAction}
							feedbackKey="invoice-batch-feedback-2"
							description={TranslationService.get(
								'invoice.batch.form.feedback.description',
								'invoice.batch.form.success.feedback.description',
							)}
							minText={TranslationService.get(
								'invoice.batch.form.feedback.min',
								'invoice.batch.form.success.feedback.min',
							)}
							maxText={TranslationService.get(
								'invoice.batch.form.feedback.max',
								'invoice.batch.form.success.feedback.max',
							)}
							values={[1, 2, 3, 4, 5]}
							size="lg"
							validate
							type="invoice-batch"
							onSupportRequest={handleSupportClick}
							nodismiss
						/>
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.done',
									'action.done',
									'invoice.batch.form.done',
									'invoice.batch.form.success.done',
								])}
								onClick={onDone}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>

				<div className="asteria-component__invoice-batch__help">
					<Text size="lg" separator>
						{TranslationService.get([
							'onboarding.linear.help.title',
							'invoice.batch.form.help.title',
							'invoice.batch.form.settings.help.title',
						])}
					</Text>
					<Group verticalAlign="center" horizontalAlign="center">
						{hasFAQFeature ? (
							<Button
								variant="link"
								size="lg"
								label={TranslationService.get([
									'onboarding.linear.help.faq',
									'invoice.batch.form.help.faq',
									'invoice.batch.form.settings.help.faq',
								])}
								onClick={handleFAQClick}
							/>
						) : null}

						<Button
							variant="link"
							size="lg"
							label={TranslationService.get([
								'onboarding.linear.help.support',
								'invoice.batch.form.help.support',
								'invoice.batch.form.settings.help.support',
							])}
							onClick={handleSupportClick}
						/>
					</Group>
				</div>
			</div>
		</div>
	);
};

BatchSuccessPage.displayName = 'BatchSuccessPage';

BatchSuccessPage.propTypes = { className: PropTypes.string };

export default BatchSuccessPage;
