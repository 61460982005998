import React, { useRef } from 'react';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { AuthService } from '@asteria/backend-utils-services';
import { LanguageService } from '@asteria/backend-utils-services';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { TranslationService } from '@asteria/language';
import { AsteriaWidget as AsteriaCreditsWidget } from '@asteria/widget-credits';
import { AsteriaWidget as AsteriaFrejaWidget } from '@asteria/widget-freja';
import { AsteriaWidget as AsteriaOnboardingWidget } from '@asteria/widget-onboarding';
import { AsteriaWidget } from '@asteria/widget-thor';
import { AsteriaWidget as AsteriaWelcomeWidget } from '@asteria/widget-welcome';

import Login from './login';

function getUrlParams(search) {
	const hashes = search.slice(search.indexOf('?') + 1).split('&');
	return hashes.reduce((params, hash) => {
		const [key, val] = hash.split('=');
		return Object.assign(params, { [key]: decodeURIComponent(val) });
	}, {});
}

const isDemo = () => {
	const { search } = location;
	const searchParams = getUrlParams(search);

	if (searchParams.demo) {
		return true;
	}

	return false;
};

const getLanguage = () => {
	const { search } = location;
	const searchParams = getUrlParams(search);

	if (searchParams.lang) {
		return searchParams.lang;
	}

	return localStorage.getItem('asteriaLanguage') || 'sv';
};

const useLanguages = (props) => {
	const { language, partnerId, themeId, token } = props;
	const [translations, setTranslations] = useState({});

	// eslint-disable-next-line no-unused-vars
	const controller = useRef(null);

	useEffect(() => {
		if (language) {
			localStorage.setItem('asteriaLanguage', language);
		}

		if (language && partnerId) {
			if (controller?.current) {
				controller?.current?.abort?.();

				controller.current = null;
			}

			controller.current = new AbortController();

			LanguageService.translation
				.fetchOne(
					{
						code: language,
						partnerId: partnerId,
						themeId: themeId || partnerId,
						signal: controller.current.signal,
						fields: `translations`,
					},
					{ token: token },
				)
				.then(({ translations }) => {
					TranslationService.code = language;
					TranslationService.setTranslations(translations);
					setTranslations(translations);
				})
				.catch(() => {});
		}
	}, [language, partnerId, themeId, token]);

	return translations;
};

function Router(props) {
	const { partnerId, themeId, widget, callback } = props;
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(localStorage.wingsToken || null);
	const [language, setLanguage] = useState(getLanguage());

	const isDemoMode = isDemo();

	useLanguages({ language, partnerId, themeId, token });

	useEffect(() => {
		if (!token) {
			return;
		}

		if (token === '__OFFLINE__') {
			const value = window.confirm(`Do you want to continue offline?`);

			if (!value) {
				window.localStorage.clear();

				const url = new URL('', window.location.origin);
				url.search = window.location.search;

				window.location.replace(url.toString());

				return;
			}

			setUser({});
			return;
		}

		AuthService.auth
			.me({}, { token })
			.then((data) => {
				setUser(data);
			})
			.catch(() => {
				setToken(null);
				setUser(null);
			});
	}, [token]);

	if (!partnerId) {
		return null;
	}

	if (widget === 'freja') {
		return (
			<AsteriaFrejaWidget
				accessToken={token}
				partnerId={partnerId}
				languageCode={language}
				analytics={false}
				demo={isDemoMode}
				theme={null}
				callback={callback}
			/>
		);
	}

	if (
		widget === 'onboarding' ||
		widget === 'welcome' ||
		widget === 'credits'
	) {
		if (!isDemoMode) {
			if (!token) {
				return (
					<Login
						setToken={setToken}
						partnerId={partnerId}
						setLanguage={setLanguage}
						language={language}
					/>
				);
			}

			if (!user) {
				return null;
			}
		}
	}

	if (widget === 'onboarding') {
		const _callback = (action, data) => {
			/* eslint-disable no-console */
			console.group('callback');
			console.log('action', action);
			console.log('data', data);
			console.groupEnd();
			/* eslint-enable */

			if (action === 'create.history') {
				window.AsteriaHistory = data;
			}

			return callback?.(action, data);
		};

		return (
			<>
				<div>
					<Group>
						<Button
							label="reset"
							size="sm"
							variant="primary"
							onClick={() => {
								window.AsteriaHistory.push('/');
							}}
						/>
						<Button
							label="/integrations"
							size="sm"
							variant="primary"
							onClick={() => {
								window.AsteriaHistory.push('/integrations');
							}}
						/>
						<Button
							label="/integrations/add"
							size="sm"
							variant="primary"
							onClick={() => {
								window.AsteriaHistory.push('/integrations/add');
							}}
						/>
						<Button
							label="/integrations/add/erp"
							size="sm"
							variant="primary"
							onClick={() => {
								window.AsteriaHistory.push(
									'/integrations/add/erp',
								);
							}}
						/>
						<Button
							label="/integrations/add/bank"
							size="sm"
							variant="primary"
							onClick={() => {
								window.AsteriaHistory.push(
									'/integrations/add/bank',
								);
							}}
						/>
					</Group>
				</div>

				<AsteriaOnboardingWidget
					accessToken={token}
					partnerId={partnerId}
					languageCode={language}
					analytics={false}
					demo={isDemoMode}
					theme={null}
					callback={_callback}
				/>
			</>
		);
	}

	if (widget === 'welcome') {
		return (
			<AsteriaWelcomeWidget
				accessToken={token}
				partnerId={partnerId}
				languageCode={language}
				analytics={false}
				demo={isDemoMode}
				theme={null}
				callback={callback}
			/>
		);
	}
	if (widget === 'credits') {
		return (
			<AsteriaCreditsWidget
				accessToken={token}
				partnerId={partnerId}
				languageCode={language}
				analytics={false}
				demo={isDemoMode}
				theme={null}
				callback={callback}
			/>
		);
	}

	return (
		<AsteriaWidget
			accessToken={token}
			partnerId={partnerId}
			languageCode={language}
			analytics={false}
			demo={isDemoMode}
			theme={null}
			callback={callback}
			router={{ type: 'browser' }}
			debug={!!localStorage.getItem('AsteriaDebug')}
		/>
	);
}

Router.propTypes = {
	partnerId: PropTypes.string,
	themeId: PropTypes.string,
	widget: PropTypes.string,
	callback: PropTypes.func,
};

export default Router;
