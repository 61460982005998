import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import { FAQSection, useFAQ } from '@asteria/component-faq';
import { useFlow } from '@asteria/component-integrations-v2/hooks';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../../components/Actions';
import Stepper from '../../../components/Stepper';
import Support from '../../../components/Support';
import LayoutContext from '../../../layout/context';

import InfoStep from './info';
import MacOSXSteps from './macosx';
import OSStep from './os';
import SuccessStep from './success';
import WaitingStep from './waiting';
import WindowsSteps from './windows';

import './styles.scss';

const VismaAdministrationPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = React.useRef(null);

	const type = 'erp';
	const key = 'visma.administration';

	const flow = useFlow(type, key);
	const faqSections = useConfig('pages.faq.sections', { deep: true }) ?? [];

	const faqSection = useFAQ(faqSections, { name: flow?.faq });

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__connection',
				'asteria--type-printer',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			<div className="asteria-page__wrapper">
				<div
					className={cn(
						'asteria-component__linear-onboarding',
						'asteria-component__linear-onboarding__wrapper',
					)}
				>
					<Routes>
						<Route index path="/" element={<InfoStep />} />
						<Route
							index
							path="/waiting/:os/:id?"
							element={<WaitingStep />}
						/>
						<Route
							index
							path="/success"
							element={<SuccessStep />}
						/>
						<Route index path="/os" element={<OSStep />} />
						<Route
							index
							path="/os/windows/*"
							element={<WindowsSteps />}
						/>
						<Route
							index
							path="/os/macosx/*"
							element={<MacOSXSteps />}
						/>
					</Routes>
				</div>
			</div>

			{flow?.faq && faqSection ? (
				<FAQSection
					{...faqSection}
					title={TranslationService.get([
						'faq.content.title',
						`faq.content.title.${faqSection?.name}`,
						'page.connection.faq',
						`page.connection.faq.${faqSection?.name}`,
						`page.connection.faq.${type}`,
						`page.connection.faq.${key}`,
						`page.connection.faq.${type}.${key}`,
					])}
					onAction={onAction}
				/>
			) : null}
			<Support onAction={onAction} />
		</div>
	);
};

VismaAdministrationPage.displayName = 'VismaAdministrationPage';

VismaAdministrationPage.propTypes = { className: PropTypes.string };

export default VismaAdministrationPage;
