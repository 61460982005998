import { parseISO } from 'date-fns';

export function parseDate(date) {
	if (typeof date === 'string') {
		return parseISO(date);
	}

	if (Array.isArray(date)) {
		return date.map((value) => parseDate(value)).filter(Boolean);
	}

	if (date?.startDate || date?.endDate) {
		return {
			startDate: parseDate(date?.startDate),
			endDate: parseDate(date?.endDate),
		};
	}

	return date ?? null;
}
