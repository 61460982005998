import React from 'react';
import ReactDOM from 'react-dom';

import { Route } from 'react-router-dom';

import { compareAsc, parseISO } from 'date-fns';
import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import AuthView from '@asteria/view-auth';

import { useFeature } from '@asteria/component-tools/featureflag';

import store, { reset } from '@asteria/datalayer';

import { TranslationService } from '@asteria/language';
import BaseWidget, {
	AuthValidate,
	ErrorBoundary,
	Redirect,
	TranslationContext,
} from '@asteria/widget-base';

import * as ClientsAPI from './api/clients';
import * as InvoiceLayoutAPI from './api/invoice-layouts';
import * as InvoiceAPI from './api/invoices';
import './groove.live';
import Layout from './layout';
import LayoutContext from './layout/context';
import ConnectionPage, {
	PrinterPage,
	VismaAdministrationPage,
} from './pages/Connection';
import FAQPage from './pages/FAQ';
import FAQDetailsPage from './pages/FAQ/Details';
import FAQSectionPage from './pages/FAQ/Section';
import GuidePage from './pages/Guide';
import GuideDetailsPage, { VPrintPage } from './pages/Guide/Details';
import IntegrationsPage from './pages/Integrations';
import IntroductionPage from './pages/Introduction';
import Invoices from './pages/Invoices';
import Layouts from './pages/Layouts';
import NotFound from './pages/NotFound';
import PendingInvoices from './pages/PendingInvoices';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import SupportPage from './pages/Support';

function getPostfixes(bank) {
	const banks = [].concat(bank).filter(Boolean);

	if (!banks.length) {
		return [];
	}

	const postfixes = [];

	for (const bank of banks) {
		if (typeof bank === 'string') {
			postfixes.push(bank.toLowerCase());
		}

		if (typeof bank === 'object') {
			const value = bank?.value;
			const parent = bank?.parent;

			postfixes.push(...getPostfixes(parent), ...getPostfixes(value));
		}
	}

	return postfixes;
}

const AuthPage = React.memo(function AuthPage({ partnerId, logo, homepage }) {
	const { onSubmit, onAction } = React.useContext(LayoutContext);
	const { language } = React.useContext(TranslationContext);

	// eslint-disable-next-line spellcheck/spell-checker
	const passwordReset = useFeature('login.forgotpassword');
	const languages = useFeature('multi-language');

	return (
		<AuthView
			key={language}
			partnerId={partnerId}
			logo={logo}
			homepage={homepage}
			languages={languages}
			passwordReset={passwordReset}
			signup={false}
			signupCode={false}
			onAction={onAction}
			onSubmit={onSubmit}
		/>
	);
});

AuthPage.displayName = 'AuthPage';
AuthPage.propTypes = {
	partnerId: PropTypes.string,
	logo: PropTypes.node,
	homepage: PropTypes.bool,
};

function AsteriaWidget(props) {
	const { partnerId, auth } = props;

	React.useLayoutEffect(() => {
		document.title = 'Fakturaportalen';
	}, [partnerId]);

	const datalayer = React.useMemo(
		() => ({
			loadingOnRefresh: true,

			onUserLoad: (user) => {
				TranslationService.updateGlobals({ user });
			},

			onCompanyLoad: (company) => {
				TranslationService.updateGlobals({ company });

				const service = Array.from(company?.services ?? [])
					.sort(({ updatedAt: a }, { updatedAt: b }) =>
						compareAsc(
							a ? parseISO(a) : new Date(),
							b ? parseISO(b) : new Date(),
						),
					)
					.reduce(
						(acc, object) => ({
							...acc,
							...object,
							data: merge({}, acc?.data, object?.data),
						}),
						{},
					);

				if (service?.data?.bank) {
					TranslationService.postfix.reset();

					const postfixes = getPostfixes(service.data.bank);

					const last = postfixes.slice(-1)[0];

					TranslationService.updateGlobals({
						bank: TranslationService.get(
							[last, `bank.${last}`],
							last,
						),
					});

					for (let index = 0; index < postfixes.length; index += 1) {
						const key = postfixes.slice(0, index + 1).join('.');

						TranslationService.postfix.set(`provider.${key}`, true);
					}
				}

				TranslationService.postfix.set(
					`service.version.${service?.data?.version ?? 1}`,
					true,
				);
			},

			fetchExtra: ({ accessToken, dispatch }) =>
				Promise.allSettled([
					InvoiceAPI.batches({
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: props.partnerId,
					}),
					InvoiceLayoutAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
						filters: { status: ['PENDING', 'LOADING'] },
					}),
					ClientsAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
					InvoiceAPI.invoiceCountries({
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: props.partnerId,
					}),
				]),
		}),
		[props.partnerId],
	);

	const routes = React.useMemo(
		() => (
			<Route
				path="/"
				element={
					<AuthValidate
						otherwise={
							<Layout
								debug={!!localStorage.getItem('AsteriaDebug')}
								auth
							/>
						}
					>
						<Layout
							debug={!!localStorage.getItem('AsteriaDebug')}
						/>
					</AuthValidate>
				}
				errorElement={<ErrorBoundary />}
			>
				<Route
					index
					element={
						<AuthValidate>
							<IntroductionPage />
						</AuthValidate>
					}
				/>
				<Route
					path="integrations"
					element={
						<AuthValidate>
							<IntegrationsPage />
						</AuthValidate>
					}
				/>
				<Route
					path="integrations/erp/visma.administration/*"
					element={
						<AuthValidate>
							<VismaAdministrationPage />
						</AuthValidate>
					}
				/>
				<Route
					path="integrations/erp/printer/*"
					element={
						<AuthValidate>
							<PrinterPage />
						</AuthValidate>
					}
				/>
				<Route
					path="integrations/:type/:key"
					element={
						<AuthValidate>
							<ConnectionPage />
						</AuthValidate>
					}
				/>
				<Route
					path="faq"
					element={
						<AuthValidate>
							<FAQPage />
						</AuthValidate>
					}
				/>
				<Route
					path="faq/:name"
					element={
						<AuthValidate>
							<FAQSectionPage />
						</AuthValidate>
					}
				/>
				<Route
					path="faq/:name/:id"
					element={
						<AuthValidate>
							<FAQDetailsPage />
						</AuthValidate>
					}
				/>
				<Route
					path="guide"
					element={
						<AuthValidate>
							<GuidePage />
						</AuthValidate>
					}
				/>
				<Route
					path="guide/v-print"
					element={
						<AuthValidate>
							<VPrintPage />
						</AuthValidate>
					}
				/>
				<Route
					path="guide/:id"
					element={
						<AuthValidate>
							<GuideDetailsPage />
						</AuthValidate>
					}
				/>
				<Route
					path="support"
					element={
						<AuthValidate>
							<SupportPage />
						</AuthValidate>
					}
				/>
				<Route
					path="settings"
					element={
						<AuthValidate>
							<Settings />
						</AuthValidate>
					}
				/>
				<Route
					path="layouts"
					element={
						<AuthValidate>
							<Layouts />
						</AuthValidate>
					}
				/>
				<Route
					path="invoices/*"
					element={
						<AuthValidate>
							<Invoices />
						</AuthValidate>
					}
				/>
				<Route
					path="reports/*"
					element={
						<AuthValidate>
							<Reports />
						</AuthValidate>
					}
				/>
				<Route
					path="pendingInvoices/"
					element={
						<AuthValidate>
							<PendingInvoices />
						</AuthValidate>
					}
				/>
				<Route
					path="welcome/*"
					element={<Redirect to="/auth/welcome" />}
				/>
				<Route
					path="recover/*"
					element={<Redirect to="/auth/recover" />}
				/>
				<Route
					path="auth/*"
					element={
						<AuthPage {...(auth ?? {})} partnerId={partnerId} />
					}
				/>
				<Route
					path="*"
					element={
						<AuthValidate>
							<NotFound />
						</AuthValidate>
					}
				/>
			</Route>
		),
		[auth, partnerId],
	);

	const routing = React.useMemo(
		() => ({ type: 'browser', routes: routes }),
		[routes],
	);

	return <BaseWidget {...props} routing={routing} datalayer={datalayer} />;
}

AsteriaWidget.displayName = 'AsteriaWidget';

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	languageCode: PropTypes.string,
	analytics: PropTypes.bool,
	demo: PropTypes.bool,
	theme: PropTypes.string,
	callback: PropTypes.func,
	children: PropTypes.node,

	datalayer: PropTypes.shape({
		fetchExtra: PropTypes.func,
		loader: PropTypes.bool,
	}),
	routing: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
		environment = 'production',
		routing,
		datalayer,
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			callback={callback}
			theme={theme}
			environment={environment}
			routing={routing}
			datalayer={datalayer}
		/>,
		element,
	);

	return true;
};

const cleanup = (element) => {
	reset(store.dispatch);

	ReactDOM.unmountComponentAtNode(element);
};

export default AsteriaWidget;
export { AsteriaWidget, cleanup, create };
