import React from 'react';

import PropTypes from 'prop-types';

import Table from '@asteria/component-core/table';

import { cn } from '@asteria/utils-funcs/classes';

// import { findParentByClassname } from '@asteria/utils-funcs/node';
import { Invoices } from '.';
import Filters from './Filters';
import { useInvoiceSorting } from './hooks';
import { getGridColumn } from './utils';

import './styles.scss';

const ReviewTable = React.memo((props) => {
	const { invoices, onAction, fields, loading } = props;

	const ref = React.useRef(null);

	const [{ sorting, direction }, setSorting] = React.useState({
		sorting: null,
		direction: 'ASC',
	});

	const sortedInvoices = useInvoiceSorting({
		objects: invoices,
		sorting: sorting,
		direction: direction,
	});

	const handleSorting = React.useCallback((event) => {
		setSorting((current) => {
			if (current.sorting === event) {
				if (current.direction === 'ASC') {
					return { ...current, direction: 'DESC' };
				}

				return { sorting: null, direction: null };
			}

			return { sorting: event, direction: 'ASC' };
		});
	}, []);

	const style = React.useMemo(() => {
		const available = (fields ?? [])
			.map(({ name }) => name)
			.concat('details');

		const hasMessage = available.includes('message');

		return {
			'--size': available.length,
			'--invoice-table-template': available
				.map((name) =>
					getGridColumn(
						!hasMessage && name === 'client-name'
							? 'flexible'
							: name,
					),
				)
				.join(' '),
		};
	}, [fields]);

	return (
		<Table
			className={cn(
				'asteria-component__invoice-table',
				'asteria-component__invoice-review-table',
			)}
			ref={ref}
			style={style}
		>
			<Filters
				onAction={onAction}
				objects={invoices}
				onSorting={handleSorting}
				sorting={sorting}
				direction={direction}
				fields={fields}
			/>
			<Invoices
				ref={ref}
				objects={sortedInvoices}
				onAction={onAction}
				useViewport={false}
				fields={fields}
				loading={loading}
			/>
		</Table>
	);
});

ReviewTable.displayName = 'ReviewTable';

ReviewTable.propTypes = {
	invoices: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	fields: PropTypes.arrayOf(
		PropTypes.shape({ name: PropTypes.string, tooltip: PropTypes.bool }),
	),
	loading: PropTypes.bool,
};

export default ReviewTable;
