import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';
import Feedback from '@asteria/component-feedback';

import { TranslationService } from '@asteria/language';

import './styles.scss';

function SupportPageSuccess(props) {
	const { onAction, response } = props;

	const [isFeedbackSent, setFeedbackState] = React.useState(false);

	const onSupportRequest = React.useCallback(
		() => onAction?.('reset'),
		[onAction],
	);

	const handleHideFeedback = React.useCallback(() => {
		setFeedbackState(true);
	}, []);

	return [
		<Alert
			key="alert"
			icon={response?.error ? 'warning' : 'check'}
			level={response?.error ? 'error' : 'success'}
			title={TranslationService.get(
				[
					!response?.error
						? 'page.support.success.alert.title'
						: null,
					response?.error ? 'page.support.error.alert.title' : null,
				],
				undefined,
				response,
			)}
		>
			<Text>
				{TranslationService.get(
					[
						!response?.error
							? 'page.support.success.alert.content'
							: null,
						response?.error
							? 'page.support.error.alert.content'
							: null,
					],
					undefined,
					response,
				)}
			</Text>
		</Alert>,
		!response?.error && !isFeedbackSent ? (
			<Feedback
				key="feedback"
				onAction={onAction}
				onSupportRequest={onSupportRequest}
				feedbackKey="contact-feedback"
				description={TranslationService.get(
					'page.support.success.feedback.description',
				)}
				minText={TranslationService.get(
					'page.support.success.feedback.min',
				)}
				maxText={TranslationService.get(
					'page.support.success.feedback.max',
				)}
				values={[1, 2, 3, 4, 5]}
				size="lg"
				validate
				onEnd={handleHideFeedback}
			/>
		) : null,
	];
}

SupportPageSuccess.displayName = 'SupportPageSuccess';
SupportPageSuccess.propTypes = {
	onAction: PropTypes.func,
	response: PropTypes.object,
};

export default React.memo(SupportPageSuccess);
