import React from 'react';

import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

const PRIORITY = {
	background: 1,
	image: 2,
};

export function usePlaceholder(type, key, step) {
	const flow = useFlow(type, key);

	const placeholders = []
		.concat(flow?.steps?.[step]?.placeholder)
		.filter(Boolean)
		.sort(
			({ type: a }, { type: b }) =>
				(PRIORITY[a] ?? 0) - (PRIORITY[b] ?? 0),
		);

	if (!placeholders.length) {
		return null;
	}

	return placeholders.map((placeholder) => (
		<div
			key={placeholder?.type}
			className={cn(
				'asteria-component__linear-onboarding__placeholder',
				{
					[`asteria-component__linear-onboarding__placeholder--type-${placeholder?.type}`]:
						placeholder?.type,
				},
				placeholder?.className,
			)}
			style={{
				backgroundImage:
					placeholder?.type === 'background'
						? `url(${placeholder?.src})`
						: null,
			}}
		>
			{placeholder?.type === 'image' ? (
				<div
					className="asteria-component__placeholder-image"
					style={{
						backgroundImage: `url(${placeholder?.src})`,
					}}
				/>
			) : null}
			{placeholder?.type === 'contenter' ? (
				<Contenter content={placeholder?.content} />
			) : null}
		</div>
	));
}
