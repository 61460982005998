import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import { useUsername } from '../../hooks';

import './styles.scss';

const HeaderButton = (props) => {
	const { children, variant, onClick } = props;

	return (
		<div
			className={cn('wings-header__button', {
				[`wings-header__button--variant-${variant}`]: variant,
			})}
			onClick={onClick}
		>
			{children}
		</div>
	);
};

HeaderButton.displayName = 'HeaderButton';
HeaderButton.propTypes = {
	children: PropTypes.node,
	variant: PropTypes.string,
	onClick: PropTypes.func,
};

const User = React.memo(() => {
	const name = useUsername();

	if (!name) {
		return null;
	}

	return (
		<HeaderButton variant="user">
			<Icon size="sm" icon="person" />
			<Text>{name}</Text>
			{/* <Icon size="sm" icon="chevron-down" /> */}
		</HeaderButton>
	);
});

User.displayName = 'User';

const Header = (props) => {
	const { className } = props;

	const onLogoClick = React.useCallback(() => {
		const url = new URL('', window.location.origin);
		url.search = window.location.search;

		window.location.replace(url.toString());
	}, []);

	return (
		<div
			className={cn(
				'wings-header',
				'lf-header',
				'asteria-component__layout__header',
				className,
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
			>
				<div className="wings-header__logo" onClick={onLogoClick} />
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
					className="asteria--type-wrapper"
				>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
						className="asteria--type-navigation"
					>
						<div className="wings-header__action asteria--variant-icon">
							<Icon size="lg" icon="notification" />
						</div>
						<div className="wings-header__action asteria--variant-icon">
							<Icon size="lg" icon="letter" />
						</div>
					</Group>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
						className="asteria--type-user"
					>
						<div className="wings-header__action">
							<User />
						</div>
						<div className="wings-header__action">
							<HeaderButton
								variant="logout"
								onClick={() => {
									localStorage.removeItem('wingsToken');
									localStorage.removeItem(
										'wingsRefreshToken',
									);
									location.reload();
								}}
							>
								<Text>Logga ut</Text>
							</HeaderButton>
						</div>
					</Group>
				</Group>
			</Group>
		</div>
	);
};

Header.displayName = 'Header';

Header.propTypes = { className: PropTypes.string };

export default Header;
