import React, { useContext, useEffect, useState } from 'react';

import { Provider, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import {
	AuthService,
	CompanyService,
	FeatureService,
	IntegrationGateway,
	TagService,
} from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import { setAccounts, setCredit } from '@asteria/datalayer/stores/accounts';
import {
	setCompany,
	setFilters,
	setTags,
	setUser,
} from '@asteria/datalayer/stores/app';
import { setFeatures } from '@asteria/datalayer/stores/features';
import { setIntegrations } from '@asteria/datalayer/stores/integrations';
import { setLanguage } from '@asteria/datalayer/stores/language';

import LoadingScreen from '@asteria/layout/loading';

import { AuthContext } from '../context';
import { fetch as fetchAccounts } from '../logic/funcs/accounts';

import Languages from './language';

FeatureService.feature.extend({
	getQuery: () => `
    query GetActiveForPartner(
        $partnerId: ID
    ) {
        activeFeatures(partnerId: $partnerId)
    }
  `,
	key: `partnerActive`,
	loggerMethod: `services.featureService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.featureService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const DataLoader = (props) => {
	const { children, loader } = props;
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const { accessToken, partnerId, themeId, language } =
		useContext(AuthContext);

	useEffect(() => {
		if (!accessToken || !partnerId) {
			return;
		}

		Promise.all([
			FeatureService.feature.extension.partnerActive(
				{ partnerId },
				{ token: accessToken },
			),
			AuthService.auth.me(
				{
					fields: `
							id
							settings {
								layout
								flags
								compressList
								listColumns
								hasReadAdjustable
							}
							feedback
					`,
				},
				{ token: accessToken },
			),
			CompanyService.company.fetchOne(
				{
					fields: `
							id
							name

							settings {
								currency
							}

							config
					`,
				},
				{ token: accessToken },
			),
			fetchAccounts({ accessToken: accessToken }),
			IntegrationGateway.integration.fetch(
				{
					fields: `
								id
								name: key
								type
								disabled
								lastSync
								status {
									state
									progress
									entities
								}
								actions {
									_id
									action
									status
									data
								}
							`,
				},
				{ token: accessToken },
			),
			TagService.tag.fetch(
				{
					fields: `
							_id
							id
							name
							color
							category {
								_id
								id
								name
							}
					`,
					pageFilters: {
						first: 0,
						orderField: 'name',
					},
				},
				{ token: accessToken },
			),
		])
			.then((response) => {
				const [features, me, company, accounts, integrations, tags] =
					response;

				//TODO: Clean this up in database ???
				if (me?.settings?.layout?.graph?.layout === 'flat') {
					me.settings.layout.graph.layout =
						me?.settings?.layout?.graph?.barLayout || 'grouped';
				}

				dispatch(setUser(me));
				dispatch(setCompany(company));
				dispatch(setFeatures(features));
				dispatch(
					setCredit(
						Math.round(
							accounts.reduce(
								(total, account) =>
									total +
									(account?.sums?.display?.credit || 0),
								0,
							),
						),
					),
				);
				dispatch(setAccounts(accounts));
				dispatch(setIntegrations(integrations));
				dispatch(setTags(tags));

				const customerTag = tags.find(
					({ name }) => name === '$customer',
				);

				if (customerTag) {
					dispatch(
						setFilters([
							{
								type: 'tag',
								id: customerTag.id,
								item: customerTag,
							},
						]),
					);
				}

				setLoading(false);
			})
			.catch(() => {});
	}, [accessToken, partnerId, themeId, language]);

	useEffect(() => {
		if (!language) {
			return;
		}

		dispatch(setLanguage(language));
	}, [language]);

	if (loading) {
		if (!loader) {
			return null;
		}
		return <LoadingScreen />;
	}

	return children;
};

const DataLayer = (props) => {
	const { children, loader } = props;
	return (
		<Provider store={store}>
			<Languages>
				<DataLoader loader={loader}>{children}</DataLoader>
			</Languages>
		</Provider>
	);
};

DataLayer.propTypes = {
	children: PropTypes.node,
};

export default DataLayer;
