import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import List, { ListGroup, ListItem } from '@asteria/component-list';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useClient } from '../hooks';

const ClientDetailsInfoItem = (props) => {
	const {
		className,
		value,
		data,
		useLabel = () => props?.label,
		useVisibility = () => true,
	} = props;

	const label = useLabel(data);
	const visible = useVisibility(data);

	if (!visible) {
		return null;
	}

	return (
		<ListItem
			className={cn(
				'asteria-component__client-overview__details__info__item',
				className,
			)}
		>
			<div className="asteria-component__client-overview__details__info__item__label">
				<Text size="sm">{label}</Text>
			</div>
			<div className="asteria-component__client-overview__details__info__item__value">
				{typeof value === 'string' ? (
					<Text size="sm">{value}</Text>
				) : (
					value
				)}
			</div>
		</ListItem>
	);
};

ClientDetailsInfoItem.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

	data: PropTypes.object,
	useLabel: PropTypes.func,
	useVisibility: PropTypes.func,
};

const ClientDetailsInfo = (props) => {
	const { className, client } = props;

	const dispatch = useDispatch();

	const form = client?.service?.client;
	const method = form?.delivery?.method ?? null;

	const id = client?._id ?? client?.id;

	const values = useClient(client).flatMap((object) => {
		if (object?.fields) {
			return object?.fields.map(({ name, value }) => ({
				...object,
				name,
				value,
			}));
		}

		return object;
	});

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const handleEdit = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { _id: id },
			}),
		);
	}, [dispatch, id]);

	const clientServiceDelivery = client?.service?.client?.delivery;

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__info',
				className,
			)}
		>
			<List size="lg">
				{values
					.concat(
						[
							{
								name: 'service.delivery.method',
								label: TranslationService.get(
									[
										'client.overview.details.service.delivery.method',
										`client.overview.details.${client?.type}.service.delivery.method`,
									],
									undefined,
									{ client: client },
								),
								value:
									method && companyService === 'INVOICE'
										? TranslationService.get(
												[
													`client.overview.details.service.delivery.method.value.${method}`,
													`client.overview.details.${client?.type}.service.delivery.method.value.${method}`,
												],
												undefined,
												{ client: client },
										  )
										: null,
							},
							{
								name: 'service.delivery.van',
								label: TranslationService.get(
									[
										'client.overview.details.service.delivery.van',
										`client.overview.details.${client?.type}.service.delivery.van`,
									],
									undefined,
									{ client: client },
								),
								value:
									method && companyService === 'INVOICE'
										? clientServiceDelivery?.van ??
										  client?.delivery?.van
										: null,
							},
							{
								name: 'service.delivery.buyerId',
								label: TranslationService.get(
									[
										'client.overview.details.service.delivery.buyerId',
										`client.overview.details.${client?.type}.service.delivery.buyerId`,
									],
									undefined,
									{ client: client },
								),
								value:
									method && companyService === 'INVOICE'
										? clientServiceDelivery?.buyerId ??
										  client?.delivery?.buyerId
										: null,
							},
						].concat(
							companyService === 'INVOICE'
								? (clientServiceDelivery?.reference ?? []).map(
										({ value }, index) => ({
											name: `service.delivery.reference.${index}.value`,
											label: TranslationService.get(
												[
													'client.overview.details.service.delivery.reference',
													`client.overview.details.${client?.type}.service.delivery.reference`,
												],
												undefined,
												{
													client: client,
													index: index + 1,
												},
											),
											value: TranslationService.get(
												[
													`client.overview.details.service.delivery.reference.option.${value}`,
													`client.overview.details.${client?.type}.service.delivery.reference.option.${value}`,
												],
												undefined,
												{
													client: client,
													index: index + 1,
												},
											),
										}),
								  )
								: [],
						),
					)
					.map(
						({
							label,
							value,
							name,
							alwaysVisible,
							format,
							useLabel,
							useVisibility,
						}) =>
							value || alwaysVisible ? (
								<ListGroup key={name}>
									<ClientDetailsInfoItem
										label={label}
										value={format ? format(value) : value}
										useLabel={useLabel}
										useVisibility={useVisibility}
										data={client}
									/>
								</ListGroup>
							) : null,
					)}
			</List>
			{!method && companyService === 'INVOICE' ? (
				<div className="asteria-component__placeholder">
					<Group direction="horizontal">
						<div className="asteria-component__placeholder-section">
							<Icon icon="document" />
						</div>
						<div className="asteria-component__placeholder-section">
							<Title size="sm">
								{TranslationService.get([
									'client.overview.details.placeholder.delivery.title',
									`client.overview.details.${client?.type}.placeholder.delivery.title`,
								])}
							</Title>
							<Text>
								{TranslationService.get([
									'client.overview.details.placeholder.delivery.content',
									`client.overview.details.${client?.type}.placeholder.delivery.content`,
								])}
							</Text>
							<Button
								variant="link"
								label={TranslationService.get([
									'client.overview.details.placeholder.delivery.action.edit',
									`client.overview.details.${client?.type}.placeholder.delivery.action.edit`,
								])}
								onClick={handleEdit}
							/>
						</div>
					</Group>
				</div>
			) : null}
		</div>
	);
};

ClientDetailsInfo.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,
};

export default ClientDetailsInfo;
