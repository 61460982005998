import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { cn } from '@asteria/utils-funcs/classes';

import ClientSelector from './Clients';
import CurrencySelector from './Currencies';
import DateSelector from './Date';
import SearchInput from './Input';
import QuickFilters, {
	useVisibility as useQuickFilterVisibility,
} from './QuickFilters';
import SearchReset from './Reset';
import SearchContext from './context';
import { useClients, useCurrencies } from './hooks';

import './styles.scss';

function useStatecn() {
	const clients = useClients();
	const currencies = useCurrencies();

	const hasFilters = useSelector(
		(store) => !!InvoiceStore.selectors.filters(store).length,
	);

	const isQuickFiltersVisible = useQuickFilterVisibility();

	const className = cn({
		'asteria--state-clients': clients?.length > 1,
		'asteria--state-currencies': currencies?.length > 1,
		'asteria--state-quick-filters': isQuickFiltersVisible,
		'asteria--state-reset': hasFilters,
	});

	return React.useMemo(() => className, [className]);
}

const SearchContent = React.memo(function SearchContent(props) {
	const { className, onChange } = props;

	const dispatch = useDispatch();

	const state = useStatecn();

	const handleChange = React.useCallback(
		(event) => {
			const value = event?.value?.trim();

			dispatch(InvoiceStore.search(value));

			return onChange?.(event);
		},
		[dispatch, onChange],
	);

	return (
		<Form
			className={cn(
				'asteria-component__invoice-search',
				state,
				className,
			)}
		>
			<SearchInput onChange={handleChange} />
			<ClientSelector />
			<CurrencySelector />
			<DateSelector />
			<QuickFilters />
			<SearchReset />
		</Form>
	);
});

SearchContent.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
};

const Search = React.memo((props) => {
	const { className, onChange, onAction, onSubmit, data } = props;

	const ctx = React.useMemo(
		() => ({ data, onAction, onSubmit }),
		[data, onAction, onSubmit],
	);

	return (
		<SearchContext.Provider value={ctx}>
			<SearchContent className={className} onChange={onChange} />
		</SearchContext.Provider>
	);
});

Search.displayName = 'Search';

Search.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	data: PropTypes.arrayOf(PropTypes.object),
};

export default Search;
