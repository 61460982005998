import React from 'react';

import Button from '@asteria/component-core/button';

import DatepickerContext from '../context';

import './styles.scss';

const DatepickerContentTypes = () => {
	const {
		type: active,
		types,
		onTypeChange,
	} = React.useContext(DatepickerContext);

	if ((types?.length ?? 0) < 2) {
		return null;
	}

	return (
		<div className="asteria-component__form-v2__datepicker-content__types">
			{types.map((type) => (
				<Button
					key={type}
					variant="link"
					label={type}
					onClick={() => onTypeChange?.(type)}
					active={type === active}
					size="sm"
				/>
			))}
		</div>
	);
};

DatepickerContentTypes.displayName = 'DatepickerContentTypes';

export default React.memo(DatepickerContentTypes);
