import React from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import ConversationModal from '@asteria/component-conversation';

import { TranslationService } from '@asteria/language';

const InvoicesMessageInfo = (props) => {
	const { id, layout } = props;

	return (
		<TextGroup>
			<Title size="sm">
				{TranslationService.get(
					'invoices.pending.modal.message.title',
					undefined,
					{ id: id, layout: layout },
				)}
			</Title>
			<Text size="sm">
				{TranslationService.get(
					'invoices.pending.modal.message.text',
					undefined,
					{
						id: id,
						layout: layout,
					},
				)}
			</Text>
		</TextGroup>
	);
};

InvoicesMessageInfo.displayName = 'InvoicesMessageInfo';

InvoicesMessageInfo.propTypes = {
	id: PropTypes.string,
	layout: PropTypes.object,
};

const InvoicesMessageModal = (props) => {
	const { className, id, onAction, onSubmit } = props;

	const [layout, setLayout] = React.useState(null);

	const handleSubmit = React.useCallback(
		async (action, data) => {
			if (action === 'conversation:chat') {
				const response = await onSubmit?.('invoice-layout:details', {
					id: id,
				});

				setLayout(response);

				const messages = response?.messages?.map((item) => ({
					...item,
					createdBy: { type: item.userType },
					content: item.message,
				}));

				return messages;
			}

			return onSubmit?.(action, data);
		},
		[id, onSubmit],
	);

	const onClose = React.useCallback(() => {
		onAction?.('close:message');
	}, [onAction]);

	return (
		<ConversationModal
			className={className}
			open={!!id}
			onSubmit={handleSubmit}
			id={id}
			onClose={onClose}
			info={<InvoicesMessageInfo id={id} layout={layout} />}
		/>
	);
};

InvoicesMessageModal.displayName = 'InvoicesMessageModal';

InvoicesMessageModal.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InvoicesMessageModal;
