import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper from '@asteria/component-core/wrapper';
import Content from '@asteria/component-core/wrapper/content';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';
import Header from '@asteria/component-core/wrapper/header';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import GridContext from '../GridContext';

import GridItemContext from './GridItemContext';

import './styles.scss';

const GridItem = React.memo((props) => {
	const {
		className,
		id,
		children,
		rows = 1,
		cols = 1,
		style,
		...rest
	} = props;

	const [size, setSize] = useState({ rows: 1, cols: 1 });
	const [showModal, setShowModal] = useState(false);

	useEffect(
		() =>
			setSize((prev) => ({
				rows: rows ?? prev.rows,
				cols: cols ?? prev.cols,
			})),
		[cols, rows],
	);

	const {
		hide: gridHide,
		show: gridShow,
		showFull,
		hidden,
		removed,
		fullItem,
	} = useContext(GridContext);

	const hide = useCallback(
		({ remove }) => gridHide({ id: id, remove: remove }),
		[gridHide, id],
	);
	const show = useCallback(() => gridShow(id), [gridShow, id]);

	const full = useCallback(() => showFull(id), [id, showFull]);

	const displayBox = useCallback(({ rows, cols }) => {
		setSize((prev) => ({
			rows: rows ?? prev.rows,
			cols: cols ?? prev.cols,
		}));
	}, []);

	const displayModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowModal(false);
	}, []);

	const context = useMemo(
		() => ({
			id: id,
			hide: hide,
			show: show,
			showFull: full,
			displayModal: displayModal,
			closeModal: handleClose,
			displayBox: displayBox,
		}),
		[displayBox, full, handleClose, hide, id, show, displayModal],
	);

	const styles = useMemo(
		() => ({ ...style, '--rows': size.rows, '--cols': size.cols }),
		[size.cols, size.rows, style],
	);

	if (removed.includes(id)) {
		return null;
	}

	return (
		<GridItemContext.Provider value={context}>
			<div
				className={cn(
					'asteria-component__grid__item',
					{
						'asteria--state-hidden': hidden.includes(id),
						'asteria--state-fullscreen': fullItem === id,
					},
					className,
				)}
				style={styles}
				{...rest}
			>
				{children}
			</div>
			<Modal
				open={showModal}
				onClose={handleClose}
				className="asteria-component__grid__item__modal"
			>
				<Wrapper>
					<Header onClose={handleClose}>
						{TranslationService.get([
							'grid.item.modal.title',
							`grid.item.${id}.modal.title`,
						])}
					</Header>
					<Content className="asteria-component__grid__item__modal__content">
						{children}
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								variant="secondary"
								label={TranslationService.get([
									'button.close',
									'grid.item.modal.close',
									`grid.item.${id}.modal.close`,
								])}
								onClick={handleClose}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Modal>
		</GridItemContext.Provider>
	);
});

GridItem.displayName = 'GridItem';

GridItem.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	children: PropTypes.node,
	cols: PropTypes.number,
	rows: PropTypes.number,
	style: PropTypes.object,
	onClick: PropTypes.func,
};

export default GridItem;
