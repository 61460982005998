import React, { useCallback, useContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../../../context';
import Step, { Content, Footer, Header } from '../../step';
import Guide from '../guide';

import './index.scss';

const CodeInfo = function CodeInfo(props) {
	const { className, integration, flow, setIntegration, onClose } = props;

	const { navigation } = useContext(NavigationContext);
	const [showGuide, setShowGuide] = useState(false);

	const step = `${integration.type}.code`;

	const onSubmit = useCallback(
		(form) => {
			setIntegration(form);
			navigation.next();
		},
		[navigation, setIntegration],
	);

	const fieldConfiguration = useMemo(() => {
		let fields = flow?.fields;

		if (Array.isArray(fields)) {
			fields = fields[0];
		}

		return {
			key: fields?.key ?? 'config.client.authorizationCode',
			label: fields?.label ?? 'label.API-Key',
		};
	}, [flow, integration?.key]);

	return (
		<Form onSubmit={onSubmit} defaultValues={integration}>
			<Step
				className={cn(
					'asteria-component__integration-code__info',
					{
						[`asteria-component__integration-code--type-${integration.type}`]:
							integration.type,
					},
					className,
				)}
				step={step}
			>
				{flow?.steps?.info?.guide && (
					<Guide
						open={showGuide}
						steps={flow?.steps?.info?.guide?.steps}
						onClose={() => setShowGuide(false)}
					/>
				)}
				<Header onClose={onClose}>
					{TranslationService.get([
						`integrations.add.title`,
						`integrations.${integration.type}.add.title`,
					])}
				</Header>
				<Content step={step} scroll>
					<Title>{flow?.name}</Title>
					<Text>
						{TranslationService.get(
							[
								`integrations.add.content`,
								`integrations.${integration.type}.add.content`,
								`integrations.${integration.type}.${integration.key}.add.content`,
							],
							undefined,
							{ integration: integration },
						)}
					</Text>

					<FormWrapper>
						<Content>
							<Contenter content={flow?.steps?.info?.content} />
							<Input
								direction="vertical"
								name={fieldConfiguration.key}
								label={TranslationService.get(
									fieldConfiguration.label,
								)}
								required
							/>
						</Content>
					</FormWrapper>

					{flow?.steps?.info?.guide && (
						<Button
							className="asteria-component__integration-step__help"
							variant="assist"
							label={TranslationService.get(
								[
									`integrations.add.help`,
									`integrations.${integration.type}.add.help`,
									`integrations.${integration.type}.${integration.key}.add.help`,
								],
								undefined,
								{ integration: integration },
							)}
							// icon="triangle-right"
							// iconPosition="last"
							onClick={() => setShowGuide(true)}
						/>
					)}
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get('action.abort')}
							onClick={navigation.abort}
						/>
						<Button
							variant="secondary"
							label={TranslationService.get('action.back')}
							onClick={navigation.back}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get('action.continue')}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Step>
		</Form>
	);
};

CodeInfo.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	flow: PropTypes.object,

	defaultConfigValue: PropTypes.object,

	setIntegration: PropTypes.func,
};

CodeInfo.defaultProps = {
	className: null,
	type: null,
};

export default CodeInfo;
