import React from 'react';

import PropTypes from 'prop-types';

import LF from './lf/footer';

const Footer = (props) => {
	const { partner, fullscreen, theme } = props;

	if (theme === 'lf') {
		return <LF partner={partner} fullscreen={fullscreen} />;
	}

	return null;
};

Footer.displayName = 'Footer';

Footer.propTypes = {
	className: PropTypes.string,
	partner: PropTypes.object,
	fullscreen: PropTypes.shape({
		state: PropTypes.bool,
		onChange: PropTypes.func,
	}),
	theme: PropTypes.string,
};

export default Footer;
