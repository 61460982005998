import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import List, { ListGroup, ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import Guide from '../../guide';

import './actions.scss';

const Action = function Action(props) {
	const { action, integration, onAction } = props;

	const { action: type, data, status } = action || {};
	const [showGuide, setShowGuide] = useState(false);

	const onClick = useCallback(() => onAction?.(action));
	const config = useConfig([
		`integration.actions.${type}`,
		`integration.actions.${integration?.key}.${type}`,
	]);

	if (!action) {
		return null;
	}

	return (
		<ListItem
			className={cn(
				'asteria-component__integration-action',
				`asteria-component__integration-action--status-${status?.toLowerCase()}`,
			)}
		>
			{config?.guide && (
				<Guide
					open={showGuide}
					steps={config?.guide?.steps}
					onClose={() => setShowGuide(false)}
				/>
			)}
			<TextGroup>
				<Title>
					{TranslationService.get(
						[
							`integrations.actions.${type}`,
							`integrations.${integration.type}.actions.${type}`,
							`integrations.${integration.type}.${integration.key}.actions.${type}`,
						],
						undefined,
						{ integration: integration },
					)}
				</Title>
				<Text>{data?.message}</Text>
			</TextGroup>

			{config?.guide && status !== 'PROCESSED' && (
				<Button
					//label={TranslationService.get('label.Guide')}
					onClick={() => setShowGuide(true)}
					icon="help"
				/>
			)}
			{status !== 'PROCESSED' && (
				<Button
					//label={TranslationService.get('action.done')}
					variant="link"
					disabled={status !== 'PENDING'}
					loading={status === 'PROGRESS'}
					onClick={onClick}
					icon="check"
					size="lg"
				/>
			)}
			{status === 'PROCESSED' && <Icon icon="check" size="lg" />}
		</ListItem>
	);
};

Action.propTypes = {
	action: PropTypes.any,
	integration: PropTypes.object,
	onAction: PropTypes.func,
};

const GenericActions = function GenericActions(props) {
	const { className, integration, actions, onAction } = props;

	if (!actions?.length) {
		return null;
	}

	return (
		<div
			className={cn('asteria-component__integration-actions', className)}
		>
			<Title type="title" size="base">
				{TranslationService.get([
					`integrations.actions`,
					`integrations.${integration.type}.actions`,
					`integrations.${integration.type}.${integration.key}.actions`,
				])}
			</Title>
			<List
				className="asteria-component__integration-list"
				applyTypographySizes={false}
			>
				<ListGroup>
					{actions?.map((action) => (
						<Action
							key={action._id}
							action={action}
							integration={integration}
							onAction={onAction}
						/>
					))}
				</ListGroup>
			</List>
		</div>
	);
};

GenericActions.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	onAction: PropTypes.func,
	actions: PropTypes.arrayOf(PropTypes.object),
};

GenericActions.defaultProps = {
	className: null,
};

export default GenericActions;
