import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import { useUsername } from '../../hooks';

import './styles.scss';

const HeaderLogo = React.memo(() => <div className="wings-header__logo" />);

HeaderLogo.displayName = 'HeaderLogo';
HeaderLogo.propTypes = {};

const HeaderButton = React.memo((props) => {
	const { children, icon } = props;

	return (
		<div className="wings-header__button">
			{icon ? <Icon icon={icon} size="lg" /> : null}
			{children}
		</div>
	);
});

HeaderButton.displayName = 'HeaderButton';

HeaderButton.propTypes = { children: PropTypes.node, icon: PropTypes.string };

const HeaderUserButton = React.memo(() => {
	const name = useUsername();

	if (!name) {
		return null;
	}

	return (
		<HeaderButton icon="framework-account">
			<Text size="sm">{name}</Text>
		</HeaderButton>
	);
});

HeaderUserButton.displayName = 'HeaderUserButton';
HeaderUserButton.propTypes = {};

const Header = React.memo((props) => {
	const { className } = props;

	return (
		<div
			className={cn(
				'wings-header',
				'swedbank-header',
				'asteria-component__layout__header',
				className,
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
			>
				<div className="wings-header__section">
					<HeaderLogo />
				</div>
				<div className="wings-header__section">
					<HeaderUserButton />
				</div>
			</Group>
		</div>
	);
});

Header.displayName = 'Header';

Header.propTypes = { className: PropTypes.string };

export default Header;
