import React from 'react';

import Toolbar from './toolbar';

const AsteriaToolbar = () => {
	const show = false;
	if (!show) {
		return null;
	}
	return <Toolbar key="toolbar" />;
};

export default AsteriaToolbar;
