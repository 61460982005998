import React from 'react';

import PropTypes from 'prop-types';

import PartnerSelector from './selector';

const Partners = (props) => {
	const { partner } = props;

	return <PartnerSelector partner={partner} />;
};

Partners.propTypes = {
	partner: PropTypes.shape({
		state: PropTypes.object,
		onChange: PropTypes.func,
	}),
};

export default Partners;
