import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';

import './styles.scss';

const Empty = React.memo(() => {
	return (
		<div className="asteria-component__placeholder">
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="center"
			>
				<Icon icon="document" />
				<Title size="page-title">
					{TranslationService.get(['page.reports.empty.title'])}
				</Title>
			</Group>
			<Translation
				translationKey="page.reports.empty.content"
				Component={Text}
				align="center"
				size="lg"
			/>
		</div>
	);
});

Empty.displayName = 'Empty';

Empty.propTypes = { className: PropTypes.string };

export default Empty;
