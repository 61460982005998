import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import ActionBar, {
	ActionBarSectionActions,
	ActionBarSectionContent,
	ActionBarSectionExtra,
} from '@asteria/component-actionbar';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Currencies from './Currencies';

import './styles.scss';

const InvoiceActionBarContent = React.memo(() => {
	const statistics = useSelector(InvoiceStore.selectors.statistics);
	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank;
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.();
		}

		return bank?.value?.toLowerCase?.();
	});

	return (
		<ActionBarSectionContent
			title={TranslationService.get(
				['invoices.actionbar.title', `invoices.actionbar.${bank}.title`]
					.concat(
						!statistics.count
							? [
									'invoices.actionbar.empty.title',
									`invoices.actionbar.${bank}.empty.title`,
							  ]
							: [],
					)
					.concat(
						(statistics?.details?.length ?? 0) === 1
							? [
									'invoices.actionbar.single.title',
									`invoices.actionbar.${bank}.single.title`,
							  ]
							: [],
					),
				undefined,
				statistics,
			)}
			content={TranslationService.get(
				[
					'invoices.actionbar.content',
					`invoices.actionbar.${bank}.content`,
				]
					.concat(
						!statistics.count
							? [
									'invoices.actionbar.empty.content',
									`invoices.actionbar.${bank}.empty.content`,
							  ]
							: [],
					)
					.concat(
						(statistics?.details?.length ?? 0) === 1
							? [
									'invoices.actionbar.single.content',
									`invoices.actionbar.${bank}.single.content`,
							  ]
							: [],
					),
				undefined,
				statistics,
			)}
		/>
	);
});

InvoiceActionBarContent.displayName = 'InvoiceActionBarContent';

const InvoiceActionBar = React.memo((props) => {
	const { className, onAction } = props;

	const [loading, setLoading] = React.useState(false);

	const hasCurrencies = useSelector(
		(store) =>
			(InvoiceStore.selectors.statistics(store)?.details?.length ?? 0) >
			1,
	);

	const hasActions = useSelector(
		(store) => !!InvoiceStore.selectors.statistics(store)?.count,
	);

	const bank = useSelector((store) => {
		const bank = AppStore.selectors.company(store)?.service?.data?.bank;

		if (!bank) {
			return bank;
		}

		if (typeof bank === 'string') {
			return bank?.toLowerCase?.();
		}

		return bank?.value?.toLowerCase?.();
	});

	const state = useSelector(
		(store) => AppStore.selectors.company(store)?.state,
	);

	const handleCancel = React.useCallback(
		() => onAction?.('invoices:cancel'),
		[onAction],
	);

	const handleDiscard = React.useCallback(async () => {
		setLoading(true);

		try {
			await onAction?.('invoices:discard');
		} catch (err) {
			//
		}

		setLoading(false);
	}, [onAction]);

	const handleApprove = React.useCallback(async () => {
		setLoading(true);

		try {
			await onAction?.('invoices:review');
		} catch (err) {
			//
		}

		setLoading(false);
	}, [onAction]);

	return (
		<ActionBar
			className={cn(
				'asteria-component__invoice-actionbar',
				{
					'asteria-state--actions': hasActions,
					'asteria-state--currencies': hasCurrencies,
				},
				className,
			)}
		>
			<InvoiceActionBarContent />
			{hasActions ? (
				<ActionBarSectionActions>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.cancel',
							'action.cancel',
							'invoices.actionbar.button.cancel',
							`invoices.actionbar.${bank}.button.cancel`,
						])}
						analyticsKey="invoices.actionbar.button.cancel"
						onClick={handleCancel}
					/>
					<Button
						variant="secondary"
						label={TranslationService.get([
							'button.discard',
							'action.discard',
							'invoices.actionbar.button.discard',
							`invoices.actionbar.${bank}.button.discard`,
						])}
						analyticsKey="invoices.actionbar.button.discard"
						onClick={handleDiscard}
						loading={loading}
						disabled={loading}
					/>
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.approve',
							'action.approve',
							'invoices.actionbar.button.approve',
							`invoices.actionbar.${bank}.button.approve`,
						])}
						analyticsKey="invoices.actionbar.button.approve"
						onClick={handleApprove}
						tooltip={
							state !== 'APPROVED'
								? TranslationService.get([
										'invoices.actionbar.button.approve.tooltip',
										`invoices.actionbar.${bank}.button.approve.tooltip`,
								  ])
								: null
						}
						loading={loading}
						disabled={loading || state !== 'APPROVED'}
					/>
				</ActionBarSectionActions>
			) : null}

			{hasCurrencies ? (
				<ActionBarSectionExtra>
					<Currencies />
				</ActionBarSectionExtra>
			) : null}
		</ActionBar>
	);
});

InvoiceActionBar.displayName = 'InvoiceActionBar';

InvoiceActionBar.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default InvoiceActionBar;
