import { InvoiceService } from '@asteria/backend-utils-services';

export async function fetch({ accessToken, options }) {
	const response = await InvoiceService.invoice
		.sendRequest(
			{
				query: `
					query Request(
						$pageFilters: PageInput = {}
						$filters: InvoiceReportFilters = {}
						$companyId: ID
					) {
						response: invoiceReports(
							pageFilters: $pageFilters
							filters: $filters
							companyId: $companyId
						) {
							pageInfo {
								hasNextPage
								hasPreviousPage
								count
								currentCursor
								startCursor
								endCursor
							}
							edges {
								cursor
								node {
									_id
									id

									name
									status
									total
									currency
									attachments

									createdAt
									updatedAt
								}
							}
						}
					}
				`,
				variables: {
					filters: options?.filters,
					pageFilters: options?.pageFilters,
				},
			},
			{ token: accessToken },
		)
		.then(({ response }) => response);

	return (response?.edges ?? []).map(({ node }) => node);
}
