import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';
import WaitingStep from './waiting';

import './styles.scss';

function TextStep(props) {
	const { nextStep } = props;
	const { onAction } = React.useContext(LayoutContext);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() => onAction?.('go', `/integrations/erp/printer/${nextStep}`),
		[onAction, nextStep],
	);

	return <BaseStep {...props} onBack={onBack} onNext={onNext} />;
}

const InstallationStep = (props) => {
	return (
		<Routes>
			<Route
				index
				element={
					<TextStep
						{...props}
						step="os/macosx/add"
						nextStep="os/macosx/continue"
					/>
				}
			/>
			<Route
				path="continue"
				element={
					<TextStep
						{...props}
						step="os/macosx/continue"
						nextStep="os/macosx/print"
					/>
				}
			/>
			<Route
				path="print"
				element={
					<TextStep
						{...props}
						step="os/macosx/print"
						nextStep="os/macosx/waiting"
					/>
				}
			/>
			<Route
				path="waiting"
				element={<WaitingStep {...props} step="os/macosx/waiting" />}
			/>
		</Routes>
	);
};

InstallationStep.displayName = 'InstallationStep';

InstallationStep.propTypes = { className: PropTypes.string };

export default InstallationStep;
