import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import Form, { useDebounceFormValues } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import LayoutContext from '../../layout/context';

import Empty from './empty';
import QuickFilters from './quick-filters';
import Table from './table';

import './styles.scss';

const Content = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const [state, setState] = React.useState('ALL');

	const [sort, setSort] = React.useState({
		field: 'createdAt',
		direction: 'DESC',
	});

	const search = useDebounceFormValues({ name: 'search', delay: 500 });
	const date = useDebounceFormValues({ name: 'date', delay: 1_000 });

	const timestamp = useSelector(InvoiceStore.selectors.reports.timestamp);

	const { data, isFetching, isInitialLoading } = useQuery({
		queryKey: [
			'reports',
			{ search },
			{ date },
			{ state },
			{ timestamp },
			{ sort },
		],
		queryFn: async () => {
			const direction = sort?.direction === 'ASC' ? 'first' : 'last';

			const filters = {};

			if (search) {
				filters.search = search;
			}

			if (date?.startDate) {
				if (filters.createdAt === undefined) {
					filters.createdAt = {};
				}

				filters.createdAt.gte = date.startDate;
			}

			if (date?.endDate) {
				if (filters.createdAt === undefined) {
					filters.createdAt = {};
				}

				filters.createdAt.lte = date.endDate;
			}

			if (state === 'NEW' && timestamp) {
				if (filters.createdAt === undefined) {
					filters.createdAt = {};
				}

				filters.createdAt.gte = timestamp;
			}

			const response = await onSubmit?.('reports:list', {
				filters: filters,
				pageFilters: {
					[direction]: 0, // TODO: Add pagination
					orderField: sort?.field,
				},
			});

			return response;
		},
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		placeholderData: [],
		keepPreviousData: true,
	});

	const onSort = React.useCallback((data) => {
		setSort((current) => {
			if (current.field === data) {
				const direction = current.direction === 'DESC' ? 'ASC' : 'DESC';

				return { field: data, direction: direction };
			}

			return { field: data, direction: 'ASC' };
		});
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'table:filter') {
				return onSort(data);
			}

			if (action === 'chip:select') {
				setState(data);
				return;
			}

			return onAction?.(action, data);
		},
		[onAction, onSort],
	);

	return (
		<>
			<QuickFilters
				state={state}
				onAction={handleAction}
				onSubmit={onSubmit}
			/>

			{isInitialLoading || isFetching || data.length ? (
				<Table
					loading={isInitialLoading && isFetching}
					data={data}
					sort={sort}
					onAction={handleAction}
				/>
			) : (
				<Empty />
			)}
		</>
	);
});

Content.displayName = 'Content';
Content.propTypes = { onSubmit: PropTypes.func, onAction: PropTypes.func };

const Reports = React.memo((props) => {
	const { className } = props;

	const { onSubmit, onAction } = React.useContext(LayoutContext);

	const dispatch = useDispatch();

	React.useLayoutEffect(() => {
		dispatch(
			AppStore.setCompanySettingsFlags({
				'freja:reports:timestamp': new Date().toISOString(),
			}),
		);

		onSubmit?.('company:settings:flags:refresh');
	}, [dispatch, onSubmit]);

	return (
		<div className={cn('asteria-page', 'asteria-page__reports', className)}>
			<div className="asteria-page__wrapper">
				<TextGroup>
					<Title size="page-title">
						{TranslationService.get([`page.reports.title`])}
					</Title>
					<Translation
						translationKey="page.reports.content"
						Component={Text}
						size="lg"
					/>
				</TextGroup>

				<Form>
					<Content onSubmit={onSubmit} onAction={onAction} />
				</Form>
			</div>
		</div>
	);
});

Reports.displayName = 'Reports';

Reports.propTypes = { className: PropTypes.string };

export default Reports;
