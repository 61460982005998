import { Service as FeatureService } from '@asteria/component-tools/featureflag';

export const MENU_ITEMS = [
	{ id: 'home', icon: 'home', path: '/' },
	{ id: 'connections', icon: 'integrations', path: '/integrations' },
	{ id: 'invoices', icon: 'document', path: '/invoices' },
	{
		id: 'pending-invoices',
		icon: 'printer',
		path: '/pendingInvoices',
		onValidate: (data) =>
			FeatureService.isActive('page-invoices-pending') &&
			data?.hasLayouts,
	},
	{
		id: 'reports',
		icon: 'reports',
		path: '/reports',
		onValidate: (data) => data?.apiVersion >= 2,
	},
	// {
	// 	id: 'settings',
	// 	icon: 'user',
	// 	path: '/settings',
	// 	onValidate: (data) => data?.user?.companies?.length === 1,
	// },
	{
		id: 'customers',
		icon: 'clients',
		action: ['modal:customers:open'],
	},
	{
		id: 'settings',
		icon: 'user',
		icons: [{ icon: 'chevron-down', iconPosition: 'last', iconSize: 'sm' }],
		items: [
			{ id: 'user-settings', path: '/settings' },
			{
				id: 'user-profile',
				path: '/profile',
				onValidate: (data) => data?.user?.companies?.length > 1,
			},
			{ id: 'help', icon: 'help', path: '/support' },
			{ id: 'logout', icon: 'logout', action: ['logout'] },
		],
	},

	{
		id: 'mobile-menu',
		icon: 'menu',
		iconActive: 'close',
		action: ['menu:toggle'],
	},
];
