import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import AsteriaCore from '@asteria/core';

import { NotificationService } from '@asteria/backend-utils-services';

import { useFeature } from '@asteria/component-tools/featureflag';

import Overview from '@asteria/layout/overview';

import { AuthContext } from '../context';

import BankAccountsLogic from './bankAccounts';

NotificationService.notification.extend({
	getQuery: () => `
    mutation MarkAsViewed($id: ObjectId!) {
        updateNotificationSetViewed(input: { _id: $id, value: true }) {
            ok
        }
    }
  `,
	key: `mark`,
	loggerMethod: `services.NotificationService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.NotificationService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

NotificationService.notification.extend({
	getQuery: () => `
    query GetNotifications {
		notifications(filters: { options: { viewed: false } }) {
			edges {
				node {
					id
					type
					data
					title
					notificationKey
					options {
						sticky
						badge
						viewed
					}
					createdAt
				}
			}
		}
	}
  `,
	key: `list`,
	loggerMethod: `services.NotificationService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.NotificationService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const OverviewLogic = (props) => {
	const { accessToken } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);
	const { onAction, showCurrency } = props;
	const currencies = useSelector((store) => store?.app?.currencies || null);
	const company = useSelector((store) => store?.app?.company);
	const useDemoNotifications = useFeature('notifications-demo');

	const fetch = useCallback(async () => {
		const response = await NotificationService.notification.extension.list(
			{},
			{ token: accessToken },
		);

		const notifications = response?.edges?.map(({ node }) => node) || [];
		if (useDemoNotifications) {
			notifications.unshift({
				id: 'test_add_erp',
				type: 'text',
				title: 'notification.add.erp.title',
				data: {
					body: 'notification.add.erp.body',
					action: {
						text: 'notification.add.erp.action',
						action: 'go',
						data: '/integrations/add/erp',
					},
				},
			});

			notifications.unshift({
				id: 'test_credit',
				type: 'text',
				title: 'notification.credit.title',
				data: {
					body: 'notification.credit.body',
					action: {
						text: 'notification.credit.action',
						action: 'go',
						data: '/credit',
					},
				},
			});

			notifications.unshift({
				id: 'test_currency',
				type: 'text',
				title: 'notification.currency.title',
				data: {
					body: 'notification.currency.body',
					action: {
						text: 'notification.currency.action',
						action: 'setFilters',
						data: [
							{
								type: 'currency',
								code: 'USD',
							},
						],
					},
				},
			});

			notifications.unshift({
				id: 'test_forecast',
				type: 'FORECAST',
				notificationKey: 'forecast.taxes',
				// eslint-disable-next-line spellcheck/spell-checker
				title: 'Skatter',
				data: {
					startDate: '2022-04-01T00:00:00.000Z',
					endDate: '2022-06-31T00:00:00.000Z',
					probability: null,
					total: 17119.3638,
					bar: {
						tag: '$tax',
						data: {
							values: [
								{
									size: 20,
									status: 'FORECAST',
								},
							],
						},
					},
					tag: {
						name: '$tax',
						status: 'FORECAST',
						category: {
							name: '$taxes',
						},
					},
					action: {
						graphStartDate: '2022-06-01T00:00:00.000Z',
						listStartDate: '2022-06-01T00:00:00.000Z',
						types: ['WITHDRAW'],
						filters: [
							{
								categoryName: '$taxes',
								tagName: '$tax',
							},
						],
					},
					totalAvg: 51106.336050000005,
					count: 12,
				},
			});

			notifications.unshift({
				id: 'test_forecast_update',
				type: 'FORECAST',
				notificationKey: 'forecast.taxes.updated',
				// eslint-disable-next-line spellcheck/spell-checker
				title: 'Skatter',
				data: {
					startDate: '2022-04-01T00:00:00.000Z',
					endDate: '2022-06-31T00:00:00.000Z',
					probability: null,
					total: 192834.173,
					totalAvg: 182685.00600000002,
					totalAvgPrev: 39273.1677,
					bar: {
						tag: '$tax',
						data: {
							values: [
								{
									size: 20,
									status: 'FORECAST',
								},
							],
						},
					},
					tag: {
						name: '$tax',
						status: 'FORECAST',
						category: {
							name: '$taxes',
						},
					},
					action: {
						graphStartDate: '2022-06-01T00:00:00.000Z',
						listStartDate: '2022-06-01T00:00:00.000Z',
						types: ['WITHDRAW'],
						filters: [
							{
								categoryName: '$taxes',
								tagName: '$tax',
							},
						],
					},
					count: 12,
				},
			});
		}

		setNotifications(
			notifications.filter(
				({ type }) => type !== 'dialog' && type !== 'modal',
			),
		);
	}, [useDemoNotifications]);

	const overviewActions = useCallback(
		async (action, data) => {
			if (action === 'markNotificationAsViewed' && data) {
				await NotificationService.notification.extension.mark(
					{ id: data },
					{ token: accessToken },
				);

				fetch();
			}
			onAction?.(action, data);
		},
		[onAction, fetch],
	);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return (
		<BankAccountsLogic fields={'active sums { display { total } }'}>
			{({ accounts, total }) => (
				<Overview
					accounts={accounts}
					onAction={overviewActions}
					open={showCurrency}
					notifications={notifications}
					companyCurrencyRisk={
						currencies.find((currency) => currency?.risk?.alert) !==
						null
					}
					total={total}
					hasCurrencies={currencies.length > 1}
					company={company}
				/>
			)}
		</BankAccountsLogic>
	);
};

export default OverviewLogic;
