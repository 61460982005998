import React from 'react';

import { addYears, eachYearOfInterval, subYears } from 'date-fns';

import Cell from '../Cell';

import '../styles.scss';

const Year = React.memo(() => {
	const dates = React.useMemo(
		() =>
			eachYearOfInterval({
				start: subYears(new Date(), 5),
				end: addYears(new Date(), 6),
			}),
		[],
	);

	return (
		<div className="asteria-component__form-v2__datepicker-content__calendar-content">
			{dates.map((date) => (
				<Cell key={date} date={date} type="year" />
			))}
		</div>
	);
});

Year.displayName = 'Year';
Year.propTypes = {};

export default Year;
