import { CompanyService } from '@asteria/backend-utils-services';

import { setCompany } from '@asteria/datalayer/stores/app';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export default async (options = {}) => {
	const { accessToken, dispatch } = options;

	const response = await CompanyService.company
		.fetchOne(
			{
				isBulk: true,
				fields: `
				_id
				id
				name

				settings {
					currency
				}

				config
				questions

				name
				vat
				orgnumber
				contact {
					phone
					zipCode
					email
					url
					address
					contactFirstName
					contactLastName
				}
		`,
			},
			{ token: accessToken },
		)
		.catch(() => {});

	dispatch?.(setCompany(response));

	return response;
};
