import React from 'react';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import useConfig from '@asteria/utils-hooks/useConfig';

export function useIntegrations() {
	const integrations = useConfig('integrations');

	return React.useMemo(
		() =>
			(integrations ?? []).filter(
				(object) =>
					object.type === 'erp' &&
					(!object.feature ||
						FeatureService.isActive(object.feature)),
			),
		[integrations],
	);
}
