import React from 'react';

import { Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

import CountrySelect from '../inputs/country';

import '../styles.scss';

const Contact = () => {
	return (
		<>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.name.label',
					'invoice.edit.field.contact.general.name.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.name"
				disabled
			/>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.street.label',
					'invoice.edit.field.contact.general.street.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.street"
				disabled
			/>
			<Input
				label={TranslationService.get([
					'invoice.edit.field.contact.street2.label',
					'invoice.edit.field.contact.general.street2.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.street2"
				disabled
			/>
			<Input
				className="asteria--type-zip"
				label={TranslationService.get([
					'invoice.edit.field.contact.zipcode.label',
					'invoice.edit.field.contact.general.zipcode.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.zipcode"
				disabled
			/>
			<Input
				className="asteria--type-city"
				label={TranslationService.get([
					'invoice.edit.field.contact.city.label',
					'invoice.edit.field.contact.general.city.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.city"
				disabled
			/>
			<CountrySelect
				className="asteria--type-country"
				label={TranslationService.get([
					'invoice.edit.field.contact.country.label',
					'invoice.edit.field.contact.general.country.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.general.country"
				disabled
			/>
		</>
	);
};

Contact.displayName = 'Contact';

Contact.propTypes = {};

export default Contact;
