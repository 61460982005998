import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import LayoutContext from '../../../layout/context';

import BaseStep from './Base';

import './styles.scss';

const TokenStep = (props) => {
	const { onAction } = React.useContext(LayoutContext);
	const dispatch = useDispatch();

	const { id } = useParams();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() =>
			onAction?.(
				'go',
				`/integrations/erp/visma.administration/${id}/tray`,
			),
		[id, onAction],
	);

	const onCopy = React.useCallback(
		(value) => {
			navigator.clipboard.writeText(value);

			SnackbarStore.show(dispatch, {
				title: 'snackbar.integration.token.copy.title',
				content: 'snackbar.integration.token.copy.content',
				type: 'integration-token-copy',
				variant: 'success',
				icon: 'clipboard',
				hideActions: true,
			});
		},
		[dispatch],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'copy') {
				return onCopy(data);
			}

			return onAction?.(action, data);
		},
		[onAction, onCopy],
	);

	const data = React.useMemo(
		() => ({ integration: integration }),
		[integration],
	);

	return (
		<BaseStep
			{...props}
			step="token"
			data={data}
			onAction={handleAction}
			onBack={onBack}
			onNext={onNext}
		/>
	);
};

TokenStep.displayName = 'TokenStep';

TokenStep.propTypes = { className: PropTypes.string };

export default TokenStep;
