import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

function ResponseError({ error }) {
	if (!error) {
		return null;
	}

	return (
		<Alert
			level="error"
			title={TranslationService.get(
				'invoices.review.error.title',
				'Please contact support and supply the following message',
				{ error: error },
			)}
		>
			{error?.errors?.length ? (
				<Group>
					{error?.errors.map((error, index) => (
						<Text key={index}>
							{TranslationService.get(
								[
									'invoices.review.error.message',
									error?.invoice?.id
										? 'invoices.review.error.invoice.message'
										: null,
									error?.client?.id
										? 'invoices.review.error.client.message'
										: null,
									error?.invoice?.id && error?.client?.id
										? 'invoices.review.error.invoice.client.message'
										: null,
								],
								'{{{ error }}}{{# client.number }} Client number: {{ client.number }}.{{/ client.number}}{{# invoice.number }} Invoice number: {{ invoice.number }}.{{/ invoice.number}}',
								{
									error: error?.message ?? error,
									invoice: error?.invoice,
									client: error?.client,
								},
							)}
						</Text>
					))}
				</Group>
			) : (
				TranslationService.get(
					'invoices.review.error.message',
					'{{{error}}}',
					{ error: error },
				)
			)}
		</Alert>
	);
}

ResponseError.propTypes = { error: PropTypes.object };

export default ResponseError;
