import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import { get, merge } from 'lodash-es';
import PropTypes from 'prop-types';

import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { useChange, useFormValues } from './hooks';

/**
 * @template T
 * @param { T } Component
 * @param {{ exclude?: string[] }} [options]
 * @returns { T }
 */
function withRegister(Component, options) {
	const exclude = options?.exclude ?? [];

	const Uncontrolled = React.memo(
		React.forwardRef((props, ref) => {
			const { isRawEvent, ...args } = props;

			const prevRef = React.useRef(props.value);

			const onChange = useChange(props);

			const handleChange = React.useCallback(
				(event) => {
					if (isRawEvent) {
						return onChange?.(event);
					}

					const prev = prevRef.current;

					prevRef.current = event?.target?.value;

					return onChange?.({
						name: event?.target?.name,
						value: event?.target?.value,
						prev: prev,
						checked: event?.target?.checked,
					});
				},
				[isRawEvent, onChange],
			);

			return <Component {...args} onChange={handleChange} ref={ref} />;
		}),
	);

	Uncontrolled.displayName = ['Uncontrolled', Component.displayName].join(
		'-',
	);
	Uncontrolled.propTypes = Component.propTypes;
	Uncontrolled.defaultProps = Component.defaultProps;
	Uncontrolled.__docgenInfo = merge(
		{ displayName: Component.displayName },
		Uncontrolled.__docgenInfo,
		Component?.__docgenInfo,
		Component?.type?.__docgenInfo,
	);

	const Controlled = React.memo(
		React.forwardRef((props, ref) => {
			const {
				name,
				required,
				min,
				max,
				maxLength,
				minLength,
				pattern,
				validate,
				valueAsNumber,
				valueAsDate,
				setValueAs,
				disabled,
				onBlur,
				shouldUnregister,
				multiplier,
				deps,
				onError,
			} = props;

			const onChange = useChange(props);

			const { register, getValues } = useFormContext();
			const { errors } = useFormState({ name: name });

			const prevRef = React.useRef(getValues(name) ?? props?.value);

			const { ref: onRefChange, ...field } = register(name, {
				required: required,
				maxLength: maxLength,
				minLength: minLength,
				max: max,
				min: min,
				pattern: pattern,
				validate: validate,
				valueAsNumber: valueAsNumber,
				valueAsDate: valueAsDate,
				setValueAs: (value) => {
					let next = setValueAs?.(value) ?? value;

					if (multiplier !== undefined) {
						next *= multiplier;
					}

					return next;
				},
				disabled: disabled,
				onChange: (event) => {
					const prev = prevRef.current;

					prevRef.current = event?.target?.value;

					return onChange?.({
						name: event?.target?.name,
						value: event?.target?.value,
						prev: prev,
						checked: event?.target?.checked,
					});
				},
				onBlur: onBlur,
				value: props.value,
				shouldUnregister: shouldUnregister,
				deps: deps,
			});

			const value = useFormValues({ name: name });

			const $ref = useCombinedRefs(ref, onRefChange);

			const $error = props?.error ?? get(errors, name) ?? errors?.[name];
			const error = useDeepMemo(() => $error, [$error]);

			React.useEffect(() => {
				if (error) {
					onError?.(error);
				}
			}, [error, onError]);

			return (
				<Uncontrolled
					{...props}
					{...field}
					defaultValue={props.value}
					value={value ?? props.value}
					isRawEvent
					ref={$ref}
					error={error}
				/>
			);
		}),
	);

	Controlled.displayName = ['Controlled', Component.displayName].join('-');

	Controlled.propTypes = {
		...Uncontrolled.propTypes,
		name: PropTypes.string,

		debounce: PropTypes.number,
		onError: PropTypes.func,

		/**
		 * A Boolean which, if true, indicates that the input must have a value before the form can be submitted.
		 * You can assign a string to return an error message in the errors object.
		 */
		required: PropTypes.bool,

		/**
		 * The minimum value to accept for this input.
		 */
		min: PropTypes.number,

		/**
		 * The maximum value to accept for this input.
		 */
		max: PropTypes.number,

		/**
		 * The maximum length of the value to accept for this input.
		 */
		maxLength: PropTypes.number,

		/**
		 * The minimum length of the value to accept for this input.
		 */
		minLength: PropTypes.number,

		/**
		 * The regex pattern for the input.
		 */
		pattern: PropTypes.instanceOf(RegExp),

		/**
		 * You can pass a callback function as the argument to validate,
		 * or you can pass an object of callback functions to validate all of them.
		 * This function will be executed on its own without depending on other validation rules included in the required attribute.
		 */
		validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

		/**
		 * Returns a Number normally. If something goes wrong NaN will be returned.
		 */
		valueAsNumber: PropTypes.bool,

		/**
		 * Returns a Date object normally. If something goes wrong Invalid Date will be returned.
		 */
		valueAsDate: PropTypes.bool,

		/**
		 * Return input value by running through the function.
		 */
		setValueAs: PropTypes.func,

		/**
		 * Set disabled to true will lead input value to be undefined and input control to be disabled.
		 */
		disabled: PropTypes.bool,

		/**
		 * onChange function event to be invoked in the change event.
		 */
		onChange: PropTypes.func,

		/**
		 * onBlur function event to be invoked in the blur event.
		 */
		onBlur: PropTypes.func,

		/**
		 * Set up value for the registered input.
		 * This prop should be utilized inside useEffect or invoke once,
		 * each re-run will update or overwrite the input value which you have supplied.
		 */
		value: PropTypes.any,

		/**
		 * Input will be unregistered after un-mount and defaultValues will be removed as well.
		 */
		shouldUnregister: PropTypes.bool,

		/**
		 * Validation will be triggered for the dependent inputs,it only limited to register api not trigger.
		 */
		deps: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string),
		]),
	};

	Controlled.defaultProps = Component.defaultProps;
	Controlled.__docgenInfo = merge(
		{ displayName: Component.displayName },
		Controlled.__docgenInfo,
		Uncontrolled?.__docgenInfo,
		{
			props: Object.fromEntries(
				Object.entries({
					onError: {
						description: 'Callback to get an available error',
						type: { name: 'func' },
					},
					debounce: {
						description:
							'Trigger onChange callback after some time',
						type: { name: 'number' },
					},
					required: {
						description:
							'A Boolean which, if true, indicates that the input must have a value before the form can be submitted. You can assign a string to return an error message in the errors object.',
						type: { name: 'bool' },
						required: false,
					},
					min: {
						description:
							'The minimum value to accept for this input.',
						type: { name: 'number' },
						required: false,
					},
					max: {
						description:
							'The maximum value to accept for this input.',
						type: { name: 'number' },
						required: false,
					},
					maxLength: {
						description:
							'The maximum length of the value to accept for this input.',
						type: { name: 'number' },
						required: false,
					},
					minLength: {
						description:
							'The minimum length of the value to accept for this input.',
						type: { name: 'number' },
						required: false,
					},
					pattern: {
						description: 'The regex pattern for the input.',
						type: { name: 'RegEx' },
						required: false,
					},
					validate: {
						description:
							'You can pass a callback function as the argument to validate, or you can pass an object of callback functions to validate all of them. This function will be executed on its own without depending on other validation rules included in the required attribute.',
						type: { name: 'func' },
						required: false,
					},
					valueAsNumber: {
						description:
							'Returns a Number normally. If something goes wrong NaN will be returned.',
						type: { name: 'bool' },
						required: false,
					},
					valueAsDate: {
						description:
							'Returns a Date object normally. If something goes wrong Invalid Date will be returned.',
						type: { name: 'bool' },
						required: false,
					},
					setValueAs: {
						description:
							'Return input value by running through the function.',
						type: { name: 'func' },
						required: false,
					},
					disabled: {
						description:
							'Set disabled to true will lead input value to be undefined and input control to be disabled.',
						type: { name: 'bool' },
						required: false,
					},
					onChange: {
						description:
							'onChange function event to be invoked in the change event.',
						type: { name: 'func' },
						required: false,
					},
					onBlur: {
						description:
							'onBlur function event to be invoked in the blur event.',
						type: { name: 'func' },
						required: false,
					},
					value: {
						description:
							'Set up value for the registered input. This prop should be utilized inside useEffect or invoke once, each re-run will update or overwrite the input value which you have supplied.',
						type: { name: 'any' },
						required: false,
					},
					shouldUnregister: {
						description:
							'Input will be unregistered after un-mount and defaultValues will be removed as well.',
						type: { name: 'bool' },
						required: false,
					},
					deps: {
						description:
							'Validation will be triggered for the dependent inputs,it only limited to register api not trigger.',
						type: { name: 'string | string[]' },
						required: false,
					},
				}).filter(([key]) => !exclude.includes(key)),
			),
		},
	);

	const Wrapper = React.memo(
		React.forwardRef((props, ref) => {
			if (props.uncontrolled) {
				return <Uncontrolled {...props} ref={ref} />;
			}

			return <Controlled {...props} ref={ref} />;
		}),
	);

	Wrapper.displayName = ['ControllingWrapper', Component.displayName].join(
		'-',
	);

	Wrapper.propTypes = {
		...Controlled.propTypes,

		/**
		 * Should input use react-hook-form?
		 */
		uncontrolled: PropTypes.bool,
	};

	Wrapper.defaultProps = Component.defaultProps;
	Wrapper.__docgenInfo = merge(
		{ displayName: Component.displayName },
		Wrapper.__docgenInfo,
		Controlled?.__docgenInfo,
		{
			props: {
				uncontrolled: {
					description: 'Should input use react-hook-form?',
					type: { name: 'bool' },
					required: false,
				},
			},
		},
	);

	return Wrapper;
}

export default withRegister;
