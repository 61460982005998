export const STATUSES = ['PENDING', 'LOADING', 'PROCESSED', 'ERROR', 'IGNORED'];

export const COLUMNS = [
	{ name: '_id', sortable: true },
	{ name: 'createdAt', sortable: true },
	{ name: 'status' },
	{ name: 'pdfUri' },
	{
		name: 'numbers',
		format: (object) =>
			(object?.invoices ?? []).map(
				(object) => object?.meta?.invoiceNumber,
			),
	},
	{ name: 'messages' },
	{ name: 'options' },
];
