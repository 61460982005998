import React from 'react';

import Analytics from '@asteria/utils-analytics';

/**
 * @template T
 * @param { T } Component
 * @param {{ event: string, methods?: string[] }} [options]
 * @returns { T }
 */
function withAnalytics(Component, options) {
	const { event, methods = [] } = options;

	const Wrapper = React.memo(
		React.forwardRef((props, ref) => {
			const { analyticsKey, className } = props;

			const actions = methods.reduce(
				(acc, value) => ({
					...acc,
					[value?.key ?? value]: {
						action: props[value?.key ?? value],
						format: value?.format,
					},
				}),
				[],
			);

			const analytics = React.useMemo(
				() =>
					Object.fromEntries(
						Object.entries(actions)
							.filter(([, { action }]) => action)
							.map(([key, { action, format }]) => [
								key,
								(e) => {
									Analytics.event(
										[event, key].join('.'),
										format
											? format(e, {
													analyticsKey:
														analyticsKey ??
														className,
											  })
											: {
													analyticsKey:
														analyticsKey ??
														className,
											  },
									);

									return action?.(e);
								},
							]),
					),
				[actions, analyticsKey, className],
			);

			return <Component {...props} {...analytics} ref={ref} />;
		}),
	);

	Wrapper.displayName = Component.displayName;
	Wrapper.propTypes = Component.propTypes;
	Wrapper.defaultProps = Component.defaultProps;
	Wrapper.__docgenInfo = Component.__docgenInfo;

	return Wrapper;
}

export default withAnalytics;
