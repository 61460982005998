import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import { Text, Title } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import {
	ActionBarSectionActions,
	Action as BaseAction,
	Actions as BaseActions,
} from '@asteria/component-actionbar';
import { IntegrationErrorTooltip } from '@asteria/component-integrations-v2/components/Integration';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useImportantActions } from './hooks';

function useIntegration({ type }) {
	const integrations = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { type: type }),
		(a, b) => isEqual(a, b),
	);

	return React.useMemo(() => {
		if (!integrations.length) {
			return { status: 'create' };
		}

		let integration = integrations.find(
			(object) =>
				object?.status?.state === 'ERROR' ||
				object?.config?.errors?.length,
		);

		if (integration) {
			return { status: 'error', integration: integration };
		}

		integration = integrations.find((object) =>
			['INITIATING'].includes(object?.status?.state),
		);

		if (integration) {
			return { status: 'importing', integration: integration };
		}

		return { status: 'connected', integration: integrations[0] };
	}, [integrations]);
}

const ActionIntegration = React.memo((props) => {
	const { id, type, onSubmit, data, className, onClick } = props;

	const { status, integration } = useIntegration({ type });

	const onRemove = React.useCallback(
		() => onSubmit?.('integrations:delete', integration),
		[integration, onSubmit],
	);

	return (
		<BaseAction
			variant="integration"
			onClick={onClick}
			className={className}
			title={
				<Title size="sm">
					{TranslationService.get(
						[
							'actions.action.title',
							`actions.action.${id}.title`,
							'actions.action.integration.title',
							`actions.action.integration.${id}.title`,
							`actions.action.integration.${status}.title`,
							`actions.action.integration.${status}.${id}.title`,
							integration?.type
								? [
										`actions.action.integration.${integration?.type}.title`,
										`actions.action.integration.${id}.${integration?.type}.title`,
										`actions.action.integration.${status}.${integration?.type}.title`,
										`actions.action.integration.${status}.${id}.${integration?.type}.title`,
								  ]
								: [],
							integration?.key
								? [
										`actions.action.integration.${integration?.key}.title`,
										`actions.action.integration.${id}.${integration?.key}.title`,
										`actions.action.integration.${status}.${integration?.key}.title`,
										`actions.action.integration.${status}.${id}.${integration?.key}.title`,
								  ]
								: [],
							integration?.type && integration?.key
								? [
										`actions.action.integration.${integration?.type}.${integration?.key}.title`,
										`actions.action.integration.${id}.${integration?.type}.${integration?.key}.title`,
										`actions.action.integration.${status}.${integration?.type}.${integration?.key}.title`,
										`actions.action.integration.${status}.${id}.${integration?.type}.${integration?.key}.title`,
								  ]
								: [],
						].flat(),
						undefined,
						{ ...data, integration: integration },
					)}
				</Title>
			}
			content={
				<Text>
					{TranslationService.get(
						[
							'actions.action.content',
							`actions.action.${id}.content`,
							'actions.action.integration.content',
							`actions.action.integration.${id}.content`,
							`actions.action.integration.${status}.content`,
							`actions.action.integration.${status}.${id}.content`,
							integration?.type
								? [
										`actions.action.integration.${integration?.type}.content`,
										`actions.action.integration.${id}.${integration?.type}.content`,
										`actions.action.integration.${status}.${integration?.type}.content`,
										`actions.action.integration.${status}.${id}.${integration?.type}.content`,
								  ]
								: [],
							integration?.key
								? [
										`actions.action.integration.${integration?.key}.content`,
										`actions.action.integration.${id}.${integration?.key}.content`,
										`actions.action.integration.${status}.${integration?.key}.content`,
										`actions.action.integration.${status}.${id}.${integration?.key}.content`,
								  ]
								: [],
							integration?.type && integration?.key
								? [
										`actions.action.integration.${integration?.type}.${integration?.key}.content`,
										`actions.action.integration.${id}.${integration?.type}.${integration?.key}.content`,
										`actions.action.integration.${status}.${integration?.type}.${integration?.key}.content`,
										`actions.action.integration.${status}.${id}.${integration?.type}.${integration?.key}.content`,
								  ]
								: [],
						].flat(),
						undefined,
						{ ...data, integration: integration },
					)}
				</Text>
			}
			actions={
				<ActionBarSectionActions
					className={cn({
						[`asteria-integration--status-${status}`]: status,
					})}
				>
					<Group flex horizontalAlign="center" verticalAlign="center">
						{status === 'create' ? (
							<Button icon="plus" variant="primary" />
						) : null}
						{status === 'error'
							? [
									<Button
										key="help"
										icon="help"
										tooltip={
											<IntegrationErrorTooltip
												integration={integration}
											/>
										}
									/>,
							  ]
							: null}
						{status === 'importing'
							? [
									<Group
										flex
										verticalAlign="center"
										horizontalAlign="center"
										key="loading"
									>
										<Spinner />
										<Text size="xs">
											{TranslationService.get(
												[
													'actions.action.loading',
													`actions.action.${id}.loading`,
													'actions.action.integration.loading',
													`actions.action.integration.${id}.loading`,
												],
												undefined,
												{
													...data,
													integration: integration,
												},
											)}
										</Text>
									</Group>,
									<Button
										key="remove"
										icon="close"
										variant="tertiary"
										size="sm"
										onClick={onRemove}
									/>,
							  ]
							: null}
						{status === 'connected' ? (
							<Button icon="check" variant="primary" />
						) : null}
					</Group>
				</ActionBarSectionActions>
			}
		/>
	);
});

ActionIntegration.displayName = 'ActionIntegration';
ActionIntegration.propTypes = {
	id: PropTypes.string,
	type: PropTypes.oneOf(['erp', 'bank']),
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

const ActionLink = React.memo((props) => {
	const { id, loading, icon, data, onClick, className } = props;

	return (
		<BaseAction
			variant="link"
			icon={icon}
			onClick={onClick}
			loading={loading}
			className={className}
			title={
				<Title size="sm">
					{TranslationService.get(
						[
							'actions.action.title',
							`actions.action.${id}.title`,
							'actions.action.link.title',
							`actions.action.link.${id}.title`,
						],
						undefined,
						data,
					)}
				</Title>
			}
			content={
				<Text>
					{TranslationService.get(
						[
							'actions.action.content',
							`actions.action.${id}.content`,
							'actions.action.link.content',
							`actions.action.link.${id}.content`,
						],
						undefined,
						data,
					)}
				</Text>
			}
		/>
	);
});

ActionLink.displayName = 'ActionLink';
ActionLink.propTypes = {
	id: PropTypes.string,
	loading: PropTypes.bool,
	icon: PropTypes.string,
	data: PropTypes.object,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

const Action = React.memo((props) => {
	const {
		className: $className,
		id,
		variant,
		type,
		disabled,
		path,
		external,
		onAction,
	} = props;

	const onClick = React.useCallback(() => {
		if (variant === 'link') {
			if (external) {
				window.open(path, '_blank').focus();

				return;
			}

			if (path) {
				return onAction?.('go', path);
			} else {
				return onAction?.(id);
			}
		}

		if (variant === 'integration') {
			return onAction?.('go', '/integrations');
		}
	}, [external, onAction, id, path, variant]);

	const className = cn(
		{ [`asteria-action--id-${id}`]: id },
		{ [`asteria-action--type-${type}`]: type },
		stateClasses({ disabled: disabled }),
		$className,
	);

	if (variant === 'integration') {
		return (
			<ActionIntegration
				{...props}
				className={className}
				onClick={onClick}
			/>
		);
	}

	return <ActionLink {...props} className={className} onClick={onClick} />;
});

Action.displayName = 'Action';
Action.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	variant: PropTypes.oneOf(['link', 'integration']),
	type: PropTypes.string,
	disabled: PropTypes.bool,
	path: PropTypes.string,
	external: PropTypes.bool,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const Actions = React.memo((props) => {
	const { className, actions, onAction, onSubmit } = props;

	if (!actions?.length) {
		return null;
	}

	return (
		<BaseActions className={className}>
			{actions.map((object) => (
				<Action
					key={object?.id}
					{...object}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
			))}
		</BaseActions>
	);
});

Actions.displayName = 'Actions';
Actions.propTypes = {
	className: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ImportantActions = React.memo((props) => {
	const { ignore } = props;

	const actions = useImportantActions({ ignore: ignore });

	return <Actions {...props} actions={actions} />;
});

ImportantActions.displayName = 'ImportantActions';
ImportantActions.propTypes = {
	className: PropTypes.string,
	ignore: PropTypes.string,
};

export default Actions;
export { Action, ImportantActions };
