import React, { useCallback, useMemo, useReducer } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext({});

const NAVIGATION_NEXT = 'NAVIGATION.NEXT';
const NAVIGATION_BACK = 'NAVIGATION.BACK';
const NAVIGATION_GO = 'NAVIGATION.GO';
const NAVIGATION_ABORT = 'NAVIGATION.ABORT';
const NAVIGATION_DONE = 'NAVIGATION.DONE';

export const AVAILABLE_NAVIGATION_STEPS = {
	select: 'select',
	flow: 'flow',
	info: 'info',
	connecting: 'connecting',
	success: 'success',
};

function ContextReducer(state, action) {
	switch (action.type) {
		case NAVIGATION_NEXT: {
			const { currentStep } = state;

			switch (currentStep) {
				case AVAILABLE_NAVIGATION_STEPS.select:
					return {
						...state,
						currentStep: AVAILABLE_NAVIGATION_STEPS.flow,
					};

				case AVAILABLE_NAVIGATION_STEPS.flow:
				case AVAILABLE_NAVIGATION_STEPS.info:
					return {
						...state,
						currentStep: AVAILABLE_NAVIGATION_STEPS.connecting,
					};

				case AVAILABLE_NAVIGATION_STEPS.connecting:
					return {
						...state,
						currentStep: AVAILABLE_NAVIGATION_STEPS.success,
					};

				default:
					return state;
			}
		}

		case NAVIGATION_BACK: {
			const { currentStep } = state;

			switch (currentStep) {
				case AVAILABLE_NAVIGATION_STEPS.flow:
					return {
						...state,
						currentStep: AVAILABLE_NAVIGATION_STEPS.select,
					};

				case AVAILABLE_NAVIGATION_STEPS.connecting:
					return {
						...state,
						currentStep: AVAILABLE_NAVIGATION_STEPS.select,
					};

				default:
					return state;
			}
		}

		case NAVIGATION_GO: {
			if (AVAILABLE_NAVIGATION_STEPS[action.payload] === undefined) {
				return state;
			}

			return {
				...state,
				currentStep: AVAILABLE_NAVIGATION_STEPS[action.payload],
			};
		}

		case NAVIGATION_ABORT:
		case NAVIGATION_DONE:
			return {
				...state,
				currentStep: AVAILABLE_NAVIGATION_STEPS.select,
			};

		default:
			return state;
	}
}

const getDefaultState = (state) => {
	const { currentStep, ...rest } = state;

	return {
		currentStep: currentStep || AVAILABLE_NAVIGATION_STEPS.select,
		...rest,
	};
};

const Provider = (props) => {
	const { children, onNavigate, defaultStep } = props;

	const [state, dispatch] = useReducer(
		ContextReducer,
		{ currentStep: defaultStep },
		getDefaultState,
	);

	const navigationNext = useCallback(
			() => dispatch({ type: NAVIGATION_NEXT }),
			[],
		),
		navigationBack = useCallback(
			() => dispatch({ type: NAVIGATION_BACK }),
			[],
		),
		navigationGo = useCallback(
			(path) => dispatch({ type: NAVIGATION_GO, payload: path }),
			[],
		),
		navigationAbort = useCallback(
			() => dispatch({ type: NAVIGATION_ABORT }),
			[],
		),
		navigationDone = useCallback(
			() => dispatch({ type: NAVIGATION_DONE }),
			[],
		);

	const handleNavigate = useCallback(
		(action, path, state) => {
			if (onNavigate) {
				onNavigate(action, path, state);
			}
		},
		[onNavigate],
	);

	const value = useMemo(() => {
		const data = {
			...state,
			navigation: {
				go: (path) => {
					navigationGo(path);
					handleNavigate('go', path, data);
				},
				next: () => {
					navigationNext();
					handleNavigate('next', null, data);
				},
				back: () => {
					navigationBack();
					handleNavigate('back', null, data);
				},
				abort: () => {
					navigationAbort();
					handleNavigate('abort', null, data);
				},
				done: () => {
					navigationDone();
					handleNavigate('done', null, data);
				},
			},
		};

		return data;
	}, [state, onNavigate]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.displayName = 'IntegrationContext.Provider';

Provider.propTypes = {
	children: PropTypes.node,
	integration: PropTypes.any,
	onNavigate: PropTypes.func,
	flow: PropTypes.any,
	defaultStep: PropTypes.oneOf(Object.keys(AVAILABLE_NAVIGATION_STEPS)),
};

export default Context;
export { Provider };
