import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const TourButton = (props) => {
	const { name, onAction, className } = props;

	const onTourOpen = React.useCallback(
		() => onAction?.('tour:start', name),
		[name, onAction],
	);

	const config = useConfig(['widget.tour'])?.[name];

	if (!config?.length) {
		return null;
	}

	return (
		<Button
			variant="tour"
			icon="help"
			className={cn('asteria-page__tour-button', className)}
			onClick={onTourOpen}
		/>
	);
};

TourButton.displayName = 'TourButton';

TourButton.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	onAction: PropTypes.func,
};

export default TourButton;
