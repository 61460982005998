import { SupportService } from '@asteria/backend-utils-services';

import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

export async function report({ accessToken, input, dispatch }) {
	const response = await SupportService.issue
		.reportIssues(
			{ input: input, fields: 'ok error reportId' },
			{ token: accessToken },
		)
		.then((data) => data?.[0])
		.catch((error) => ({ ok: false, error: error }));

	if (!response.ok) {
		SnackbarStore.show(dispatch, {
			title: 'snackbar.support.report.error.title',
			content: 'snackbar.support.report.error.content',
			type: 'support.error',
			variant: 'error',
			icon: 'warning',
			hideActions: true,
			data: response,
		});

		return response;
	}

	SnackbarStore.show(dispatch, {
		title: 'snackbar.support.report.success.title',
		content: 'snackbar.support.report.success.content',
		type: 'support.success',
		variant: 'success',
		icon: 'check',
		hideActions: true,
		data: response,
	});

	return response;
}
