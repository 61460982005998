import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';
import Steps, { Step as GuideStep } from '@asteria/component-steps';
import Contenter from '@asteria/component-tools/contenter';

const Guide = function Guide(props) {
	const { onClose, steps } = props;

	return (
		<Modal {...props} size="sm">
			<Steps onEnd={onClose} title="Guide">
				{steps?.map((step) => (
					<Contenter
						key={step.id}
						as={GuideStep}
						content={step.content}
					/>
				))}
			</Steps>
		</Modal>
	);
};

Guide.propTypes = {
	onClose: PropTypes.func,
	steps: PropTypes.arrayOf(PropTypes.object),
};

export default Guide;
