import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { useFlow } from '@asteria/component-integrations-v2/hooks';
import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const CreateBox = React.memo((props) => {
	const { className, type, name, onAction, variant } = props;

	const flow = useFlow(type, name);

	const handleClick = React.useCallback(
		() => onAction?.('go', `/integrations/${type}/${name}`),
		[name, onAction, type],
	);

	return (
		<Group
			direction="horizontal"
			verticalAlign="top"
			horizontalAlign="space-between"
			className={cn(
				'asteria-component__onboarding-box',
				{ [`asteria-integration--variant-${variant}`]: variant },
				className,
			)}
			onClick={handleClick}
		>
			<div className="asteria-component__onboarding-box__content">
				<Contenter content={flow?.info?.box} />
			</div>
			<Group className="asteria-component__onboarding-box__actions">
				<Button size="sm" icon="onboarding-box" />
			</Group>
		</Group>
	);
});

CreateBox.displayName = 'CreateBox';

CreateBox.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	onAction: PropTypes.func,

	variant: PropTypes.oneOf(['create', 'update']),
};

export default CreateBox;
