import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Cell from './Cell';

import './styles.scss';

const SearchReset = React.memo((props) => {
	const { className } = props;

	const { resetField } = useFormContext();

	const search = useSelector(InvoiceStore.selectors.search);
	const filters = useSelector(InvoiceStore.selectors.filters, (a, b) =>
		isEqual(a, b),
	);

	const dispatch = useDispatch();

	const onReset = React.useCallback(() => {
		dispatch(InvoiceStore.filter({ type: null, value: null }));
		dispatch(InvoiceStore.search(''));
		resetField('search-value');
		resetField('date');
	}, [dispatch, resetField]);

	if (!filters.length && !search) {
		return null;
	}

	return (
		<Cell className="asteria--type-reset">
			<Group
				className={cn(
					'asteria-component__invoice-search__reset',
					className,
				)}
				verticalAlign="center"
				horizontalAlign="center"
			>
				<Button
					variant="link"
					label={TranslationService.get([
						'invoices.search.reset.label',
					])}
					onClick={onReset}
				/>
			</Group>
		</Cell>
	);
});

SearchReset.displayName = 'SearchReset';

SearchReset.propTypes = { className: PropTypes.string };

export default SearchReset;
