import React from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

import { STATUSES } from './constants';

import './styles.scss';

const StatusChip = React.memo((props) => {
	const { status, onSubmit, onSelect, active } = props;

	const { data } = useQuery({
		queryKey: ['pending', 'layouts', 'status', status],
		queryFn: async () =>
			Promise.all([
				onSubmit?.('invoice-layout:count', {
					filters: { status: status },
				}),
				onSubmit?.('invoice-layout:unread', {
					filters: {
						messages: { read: false, userType: 'AdminToken' },
						status: status,
					},
				}),
			]).then(([count, unread]) => ({
				count,
				unread: unread?.length,
			})),
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,
	});

	const onClick = React.useCallback(
		() => onSelect?.(status),
		[onSelect, status],
	);

	const count = data?.count ?? 0;
	const unread = data?.unread ?? 0;

	if (!count) {
		return null;
	}

	return (
		<BadgeWrapper
			badge={
				unread ? (
					<Text size="xs">{unread > 9 ? '9+' : unread}</Text>
				) : null
			}
			size="sm"
		>
			<Chip
				label={TranslationService.get(
					[
						'page.invoices.pending.selector.status.item.label',
						`page.invoices.pending.selector.status.item.${status}.label`,
					],
					undefined,
					{ status: status, count: count },
				)}
				active={active}
				onClick={onClick}
				size="sm"
			/>
		</BadgeWrapper>
	);
});

StatusChip.displayName = 'StatusChip';
StatusChip.propTypes = {
	status: PropTypes.string,
	onSubmit: PropTypes.func,
	onSelect: PropTypes.func,
	active: PropTypes.bool,
};

const StatusChips = React.memo((props) => {
	const { onSubmit, selected, onSelect } = props;

	return (
		<div className="asteria-component__pending-invoices-statuses">
			{STATUSES.map((status) => (
				<StatusChip
					key={status}
					status={status}
					onSubmit={onSubmit}
					active={selected?.includes?.(status)}
					onSelect={onSelect}
				/>
			))}
		</div>
	);
});

StatusChips.displayName = 'StatusChips';
StatusChips.propTypes = {
	onSubmit: PropTypes.func,
	selected: PropTypes.arrayOf(PropTypes.string),
	onSelect: PropTypes.func,
};

export default StatusChips;
export { StatusChip };
