import React from 'react';

import { TranslationService } from '@asteria/language';

import InputReset from '../../../../InputReset';
import CountrySelect from '../inputs/country';

import '../styles.scss';

const Delivery = () => {
	return (
		<>
			<InputReset
				label={TranslationService.get([
					'invoice.edit.field.contact.name.label',
					'invoice.edit.field.contact.shipping.name.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.name"
			/>
			<InputReset
				label={TranslationService.get([
					'invoice.edit.field.contact.street.label',
					'invoice.edit.field.contact.shipping.street.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.street"
			/>
			<InputReset
				label={TranslationService.get([
					'invoice.edit.field.contact.street2.label',
					'invoice.edit.field.contact.shipping.street2.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.street2"
			/>
			<InputReset
				className="asteria--type-zip"
				label={TranslationService.get([
					'invoice.edit.field.contact.zipcode.label',
					'invoice.edit.field.contact.shipping.zipcode.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.zipcode"
			/>
			<InputReset
				className="asteria--type-city"
				label={TranslationService.get([
					'invoice.edit.field.contact.city.label',
					'invoice.edit.field.contact.shipping.city.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.city"
			/>
			<CountrySelect
				className="asteria--type-country"
				label={TranslationService.get([
					'invoice.edit.field.contact.country.label',
					'invoice.edit.field.contact.shipping.country.label',
				])}
				direction="horizontal"
				name="service.invoice.contact.shipping.country"
			/>
		</>
	);
};

Delivery.displayName = 'Delivery';

Delivery.propTypes = {};

export default Delivery;
