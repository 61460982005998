import React from 'react';

import { eachMonthOfInterval, endOfYear, startOfYear } from 'date-fns';

import DatepickerContentCalendarCell from '../Cell';

import '../styles.scss';

const Month = React.memo(() => {
	const dates = React.useMemo(
		() =>
			eachMonthOfInterval({
				start: startOfYear(new Date()),
				end: endOfYear(new Date()),
			}),
		[],
	);

	return (
		<div className="asteria-component__form-v2__datepicker-content__calendar-content">
			{dates.map((date) => (
				<DatepickerContentCalendarCell
					key={date}
					date={date}
					type="month"
				/>
			))}
		</div>
	);
});

Month.displayName = 'Month';
Month.propTypes = {};

export default Month;
